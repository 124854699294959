import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { globalConstant } from '../../../safebuilding-global-constants';
import { environment } from '../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { QuarantineService } from '../../quarantine.service';
import { CredentialsService } from '../../../../login/credentials.service';
import { AppService } from '../../../../app.service';
import { MatChipInputEvent } from '@angular/material/chips';
import { PreviewFormComponent } from '../preview-form/preview-form.component';
import { PublishFormComponent } from '../publish-form/publish-form.component';
@Component({
  selector: 'app-create-form',
  templateUrl: './create-form.component.html',
  styleUrls: ['./create-form.component.scss']
})
export class CreateFormComponent implements OnInit {

  createSuervey = new FormGroup({
    question: new FormControl(''),
    radioSelection: new FormControl(''),
    checkboxBoxselection: new FormControl(''),
    response: new FormControl(''),
  });
  @Input() surveryObj: any;
  @Input() editSurveyId: any;
  @Input() actionType: any;
  @Output() toggleComponent = new EventEmitter();
  masterSurveryData: any;
  masterSurveryTitles: any = [];
  formCollection = new FormArray([]);
  surveySelectedValue: any = [];
  formTitle: string;
  formDescription: string;
  startDate: any;
  endDate: any;
  liveSurveyExists: boolean = false;
  responseArr = ['Yes', 'No'];
  responseTypeArr: any = [];
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  isShowCreateForm: boolean = true;
  radioTypeLength: number = 2;
  checkboxTypeLength: number = 3;
  chipListEnable: any = [];
  titleFlag: boolean = false;
  editFormTitle: string;
  liveSurveyData: any = [];
  notPublishedData: any = [];
  surveyStatus: string;
  timeZone: string = globalConstant.timeZone;
  selectedFacility: any;
  selectedCountry: any;
  facilityLevel: any;
  countryLevel: any;
  selection: any;
  employeeId: any;

  facilities: any;
  formCreateAt: string;
  selectedList: any;
  countries: any[];
  checkSelected: boolean = false;
  countryData: any;
  facilityData: any;
  selectedData: any;
  selectedTypeId: any;
  isLightTheme: boolean = environment.isLightTheme;
  isMobileView : boolean;
  constructor(
    public service: QuarantineService,
    public dialog: MatDialog,
    private credentialsService: CredentialsService,
    private appservice: AppService
  ) { }

  ngOnInit(): void {
    this.isMobileView = this.appservice.isMobileDevice();
    this.appservice.currentTheme.subscribe(theme => this.isLightTheme = (theme == 'theme-light' ? true : false));
    this.selection = '1';
    this.formCreateAt = 'facility';
    this.getFacilityList();
    this.getCountryList();
    this.masterSurveryData = this.surveryObj;
    console.log('RAM master survy data');
    console.log(this.masterSurveryData);
    this.masterSurveyTitles();
    this.createForm();
  }

  getCountryList() {
    this.service.getCountry().subscribe((data: any) => {
      console.log(data);
      this.countries = [];
      if (data) {
        console.log(data);
        for (let i = 0; i < data.length; i++) {
          this.countries.push({ countryName: data[i].countryName, countryId: data[i].countryId });
        }
      }
      // this.countrySelection(this.countries[0].countryId);
      console.log(this.countries);
    });
  }
  getFacilityList() {
    this.service.getOrganization().subscribe((resp: any) => {
      this.facilities = [];
      if (resp) {
        resp.map((space: any) => {
          space['spaceProperties'].forEach((element: any) => {
            if (element.propertyKey.toLowerCase() === 'name') {
              this.facilities.push({
                value: space['spaceId'],
                viewValue: element.propertyValue,
              });
            }
          });
        });
      }
      console.log(this.facilities);
    });
  }
  createForm() {
    this.formCollection = new FormArray([]);
    this.responseTypeArr = [];
    this.chipListEnable = [];
    this.formTitle = '';
    this.formDescription = '';
    this.surveySelectedValue = [];
    this.selection = '1';
    this.formCreateAt = 'facility';
    if (this.editSurveyId) {
      let index = this.masterSurveryData.findIndex((data: any) => data.surveyId === this.editSurveyId);
      console.log('RAM survery id ', this.editSurveyId, index, this.actionType);
      let selectedSurvey = this.masterSurveryData[index];
      console.log('RAM selected ssurvery ', selectedSurvey);
      this.formTitle = selectedSurvey.formTitle;
      this.editFormTitle = this.actionType === 'edit' ? selectedSurvey.formTitle : '';
      this.formDescription = selectedSurvey.formDescription;

      if (selectedSurvey.locationTypeId === 1) {
        // console.log('in country', selectedSurvey.locationTypeId, selectedSurvey.locationId);
        this.selectedTypeId = selectedSurvey.locationTypeId;
        this.selectedCountry = selectedSurvey.locationId;
        this.countryLevel = selectedSurvey.locationName;
        this.selection = '2';
        this.formCreateAt = 'country';
      } else if (selectedSurvey.locationTypeId === 3) {
        // console.log('in facility', selectedSurvey.locationTypeId, selectedSurvey.locationId);
        this.selectedTypeId = selectedSurvey.locationTypeId;
        this.selectedFacility = selectedSurvey.locationId;
        this.facilityLevel = selectedSurvey.locationName;
        this.selection = '1';
        this.formCreateAt = 'facility';
      }

      console.log(this.countryLevel);
      console.log(this.selection);
      this.surveySelectedValue = this.masterSurveryTitles[index + 1];
      this.startDate = selectedSurvey.startDate;
      this.endDate = selectedSurvey.endDate;
      this.surveyStatus = selectedSurvey.status;
      let surveryQuestions = selectedSurvey.masterSurveyQuestions;
      surveryQuestions.sort(this.compare);
      if (surveryQuestions.length > 0 && surveryQuestions[0]) {
        surveryQuestions.forEach((question: any) => {
          this.formCollection.push(
            new FormGroup({
              questionId: new FormControl(question.questionId),
              question: new FormControl(question.question, Validators.required),
              radioSelection: new FormControl(
                question.responseType === 'radio' ? 'radio' : 'notselect',
                Validators.required
              ),
              checkboxBoxselection: new FormControl(
                question.responseType === 'checkbox' ? 'checkbox' : false,
                Validators.required
              ),
              response: new FormControl(question.questionOptions, Validators.required),
            })
          );
          this.responseTypeArr.push(question.questionOptions);
          this.chipListEnable.push(true);
        });
      } else {
        this.addQuestion();
      }
      console.log('Response type arr');
      console.log(this.responseTypeArr);
      console.log(this.chipListEnable);
    } else {
      this.surveySelectedValue = this.masterSurveryTitles[0];
      this.addQuestion();
    }
    this.titleCheck();
    if (this.selection === '1') {
      let x = this.facilityLevel;

      if (x != '' && x != null && x != undefined) {
        this.checkSelected = true;
      }
    } else if (this.selection === '2') {
      let y = this.countryLevel;

      if (y != '' && y != null && y != undefined) {
        this.checkSelected = true;
      }
    }

    this.filterLivePublished();
  }

  filterLivePublished() {
    if (this.selection === '2') {
      this.liveSurveyData = this.masterSurveryData.filter(
        (data: any) =>
          data.status.toLocaleLowerCase() === 'live' &&
          data.locationId === this.selectedCountry &&
          data.locationTypeId === this.selectedTypeId
      );
      this.notPublishedData = this.masterSurveryData.filter(
        (data: any) =>
          data.status.toLocaleLowerCase() === 'published' &&
          data.surveyId != this.editSurveyId &&
          data.locationId === this.selectedCountry &&
          data.locationTypeId === this.selectedTypeId
      );
    }
    if (this.selection === '1') {
      this.liveSurveyData = this.masterSurveryData.filter(
        (data: any) =>
          data.status.toLocaleLowerCase() === 'live' &&
          data.locationId === this.selectedFacility &&
          data.locationTypeId === this.selectedTypeId
      );
      this.notPublishedData = this.masterSurveryData.filter(
        (data: any) =>
          data.status.toLocaleLowerCase() === 'published' &&
          data.surveyId != this.editSurveyId &&
          data.locationId === this.selectedFacility &&
          data.locationTypeId === this.selectedTypeId
      );
      //console.log('in filter', this.editSurveyId, this.selectedFacility, this.selectedTypeId, this.notPublishedData);
    }
  }
  compare(a: any, b: any) {
    const firstVal = a.questionId;
    const secondVal = b.questionId;

    let comparison = 0;
    if (firstVal < secondVal) {
      comparison = -1;
    } else if (firstVal > secondVal) {
      comparison = 1;
    }
    return comparison;
  }
  addQuestion() {
    this.formCollection.push(
      new FormGroup({
        questionId: new FormControl(''),
        question: new FormControl('', Validators.required),
        radioSelection: new FormControl('radio', Validators.required),
        checkboxBoxselection: new FormControl(false, Validators.required),
        response: new FormControl(['Yes', 'No'], Validators.required),
      })
    );
    this.responseTypeArr.push(['Yes', 'No']);
    this.chipListEnable.push(false);
  }
  delete(index: any) {
    const form = this.formCollection.at(index);
    this.formCollection.removeAt(index);
    this.responseTypeArr.splice(index, 1);
    if (this.formCollection.length === 0) {
      this.addQuestion();
      //this.responseTypeArr[index] = [];
      this.chipListEnable[index] = false;
    }
  }
  masterSurveyTitles() {
    this.masterSurveryData.forEach((survey: any) => {
      this.masterSurveryTitles.push({
        surveyId: survey.surveyId,
        formTitle: survey.formTitle,
      });
    });
    let liveSurvey = this.masterSurveryData.find((data: any) => data.status.toLowerCase() === 'live');
    if (liveSurvey) {
      this.liveSurveyExists = true;
    }
    let selectObj = { surveyId: '', formTitle: 'Select from Previous Forms' };
    this.masterSurveryTitles.unshift(selectObj);
    this.surveySelectedValue = this.masterSurveryTitles[0];
    console.log(this.masterSurveryTitles);
  }
  selectResponse(event: any, index: any, type: any) {
    this.chipListEnable[index] = true;
    if (type === 'radio') {
      this.formCollection.controls[index].get('checkboxBoxselection').setValue(false);
    }
    if (type === 'checkbox') {
      this.formCollection.controls[index].get('radioSelection').setValue('notselect');
    }
  }
  preparePostJson(action: any) {
    let questionArr = [];
    for (const [i, form] of this.formCollection.controls.entries()) {
      console.log(form.value);
      questionArr.push({
        surveyId: this.actionType === 'edit' && this.editSurveyId ? this.editSurveyId : 0,
        questionId: i + 1,
        question: form.value.question,
        responseType:
          form.value.radioSelection == 'radio' ? 'radio' : form.value.checkboxBoxselection ? 'checkbox' : '',
        questionOptions: Array.isArray(form.value.response) ? form.value.response : form.value.response.split(','),
        //questionOptions: form.value.response
      });
    }
    let locationIdVal: any;
    let locationTypeVal: any;
    if (this.selection === '1') {
      locationTypeVal = 3;
      locationIdVal = this.selectedFacility;
    } else if (this.selection === '2') {
      locationTypeVal = 1;
      locationIdVal = this.selectedCountry;
    }
    let json = {
      surveyId: this.actionType === 'edit' && this.editSurveyId ? this.editSurveyId : 0,
      formTitle: this.formTitle,
      formDescription: this.formDescription,
      locationId: locationIdVal,
      locationType: locationTypeVal,
      startDate: this.actionType === 'edit' && this.editSurveyId ? this.startDate : null,
      endDate: this.actionType === 'edit' && this.editSurveyId ? this.endDate : null,
      frequency: 'daily', //this.surveyStatus
      status: this.actionType === 'edit' && this.editSurveyId ? this.surveyStatus : 'Not Published',
      timeZone: this.timeZone,
      MasterSurveyQuestionsPostReq: questionArr,
    };
    return json;
  }
  saveSurvery() {
    if (this.formCollection.valid && this.formTitle && this.formDescription && this.checkSelected) {
      let postJson = this.preparePostJson('save');
      console.log(postJson);
      this.service.postMasterHealthSurvey(postJson).subscribe(
        (response: any) => {
          console.log(response);
          this.toggleComponent.emit('form-list');
        },
        (err: any) => {
          console.log(err);
          if (err.error.toLocaleLowerCase().includes('same name or id')) {
            this.titleFlag = true;
          }
        }
      );
      console.log(JSON.stringify(postJson));
    } else {
      console.log('RAM Form not valid');
    }
  }
  changeSurvey(event: any) {
    this.actionType = 'copy';
    this.editSurveyId = event.value.surveyId;
    this.createForm();
    this.titleCheck();
  }
  savePublishSurvery() {
    let postData = this.preparePostJson('savepublish');
    const dialogRef = this.dialog.open(PublishFormComponent, {
      width: '540px',
      height: '350px',
      panelClass: 'survey-form',
      data: {
        surveyData: postData,
        actionType: this.actionType,
        liveSurveyExists: this.liveSurveyExists,
        liveSurvey: this.liveSurveyData,
        notPublishedData: this.notPublishedData,
      },
    });
    dialogRef.afterClosed().subscribe((resuslt: any) => {
      console.log('RAM The dialog was closed');
      console.log(resuslt);
      if (resuslt.data.action === 'publish' || resuslt.data.action === 'publishlater') {
        this.toggleComponent.emit('form-list');
      }
      if (resuslt.data.isDuplicate) {
        this.titleFlag = false;
      }
    });
  }
  previewForm() {
    let postData = this.preparePostJson('preview');
    const dialogRef = this.dialog.open(PreviewFormComponent, {
      width: '540px',
      panelClass: 'survey-form',
      data: { surveyData: postData },
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('RAM The dialog was closed');
    });
  }
  cancleSurvey() {
    //this.isShowCreateForm = !this.isShowCreateForm
    this.toggleComponent.emit('form-list');
    if(this.isMobileView){
      this.service.setShow(true);
    }
  }
  add(event: MatChipInputEvent, index: any): void {
    const input = event.input;
    const value = event.value;
    let chipLength = 2;
    if (this.formCollection.controls[index].get('radioSelection').value === 'radio') {
      chipLength = this.radioTypeLength;
    }
    if (this.formCollection.controls[index].get('checkboxBoxselection').value === true) {
      chipLength = this.checkboxTypeLength;
    }
    let checkDuplicate;
    if (this.responseTypeArr[index].length > 0) {
      checkDuplicate = this.responseTypeArr[index].find(
        (data: any) => data.toLowerCase() === value.trim().toLocaleLowerCase()
      );
    }
    let inputLowerval = (value || '').trim().toLocaleLowerCase();
    if (this.formCollection.controls[index].get('radioSelection').value === 'radio') {
      if (inputLowerval === 'yes' || inputLowerval === 'no') {
        this.responseTypeArr[index].push(value.trim());
      }
    } else {
      this.responseTypeArr[index].push(value.trim());
    }

    if (this.responseTypeArr[index].length > chipLength || checkDuplicate) {
      this.responseTypeArr[index].pop();
    }
    if (this.responseTypeArr[index].length == chipLength) {
      this.formCollection.controls[index].get('response').setValue(this.responseTypeArr[index]);
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }
  }

  removeRes(res: any, resIndex: any) {
    this.formCollection.controls[resIndex].get('response').setValue('');
    const index = this.responseTypeArr[resIndex].indexOf(res);
    let chipLength = 2;
    if (this.formCollection.controls[resIndex].get('radioSelection').value === 'radio') {
      chipLength = this.radioTypeLength;
    }
    if (this.formCollection.controls[resIndex].get('checkboxBoxselection').value === true) {
      chipLength = this.checkboxTypeLength;
    }
    if (index >= 0) {
      this.responseTypeArr[resIndex].splice(index, 1);
    }
    if (this.responseTypeArr[resIndex].length == chipLength) {
      this.formCollection.controls[resIndex].get('response').setValue(this.responseTypeArr[resIndex]);
    }
  }
  titleCheck() {
    this.titleFlag = false;
    let dupTitle = '';
    if (this.actionType !== 'edit' || this.formTitle.trim().toLowerCase() != this.editFormTitle.trim().toLowerCase())
      dupTitle = this.masterSurveryTitles.find(
        (data: any) => data.formTitle.trim().toLowerCase() === this.formTitle.trim().toLowerCase()
      );
    if (dupTitle) {
      this.titleFlag = true;
    } else {
      this.titleFlag = false;
    }
  }
  facilitySelection(e: any) {
    let x: any;
    this.facilities.forEach((i: any) => {
      if (i.viewValue === e.value) {
        x = i.value;
        return;
      }
    });
    this.selectedTypeId = 3;

    this.selectedFacility = x;
    if (this.selection === '1') {
      let z = e.value;
      console.log('in', z);
      if (z != '' && z != null && z != undefined) {
        this.checkSelected = true;
        console.log('in');
      }
    } else if (this.selection === '2') {
      let w = this.countryLevel;
      console.log('in');
      if (w != '' && w != null && w != undefined) {
        this.checkSelected = true;
        console.log('in');
      }
    }
    this.filterLivePublished();
  }
  onSelectionChange(e: any) {
    if (e.value === '1') {
      this.selection = '1';
      this.formCreateAt = 'facility';
      this.selectedList = this.facilities;
      //console.log(this.facilityLevel);
      this.facilityLevel = '';
    } else if (e.value === '2') {
      this.selection = '2';
      this.formCreateAt = 'country';
      this.selectedList = this.countries;
      this.countryLevel = '';
      // console.log(this.countryLevel);
    }
    this.checkSelected = false;
  }
  countrySelection(e: any) {
    let y: any;
    this.countries.forEach((i: any) => {
      if (i.countryName === e.value) {
        y = i.countryId;
        return;
      }
    });
    this.selectedTypeId = 1;
    this.selectedCountry = y;
    if (this.selection === '1') {
      let z = this.facilityLevel;
      console.log('in', this.facilityLevel);
      if (z != '' && z != null && z != undefined) {
        this.checkSelected = true;
        console.log('in');
      }
    } else if (this.selection === '2') {
      let w = e.value;
      console.log('in', w);
      if (w != '' && w != null && w != undefined) {
        this.checkSelected = true;
        console.log('in');
      }
    }
    this.filterLivePublished();
  }
}
