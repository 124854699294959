<div class="location-header row">
  <p>Add New Building</p>
  <button mat-dialog-close mat-icon-button>
    <span class="material-icons">cancel</span>
  </button>
</div>

<hr class="border-line" />
<mat-dialog-content>
  <div class="building-form">
    <form [formGroup]="buildingDetailsArray">
      <div class="row">
        <div class="col-4">
          <mat-label>Building Name &nbsp;<span class="red"> * </span> </mat-label>
          <span *ngIf="isExistingBuilding" class="exceeding-area">Building Name already exists</span>
          <input
            class="input-field"
            autocomplete="off"
            matInput
            type="text"
            placeholder=""
            formControlName="buildingName"
            (input)="nameCheck($event)"
            required
          />
        </div>
        <div class="col-4">
          <mat-label>Total Carpet Area (sq ft) </mat-label>
          <span *ngIf="isExceedingArea" class="exceeding-area">Carpet Area exceeds total area </span>
          <input
            class="input-field"
            autocomplete="off"
            matInput
            type="number"
            maxlength="10"
            min="0"
            placeholder=""
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            formControlName="squareFeetArea"
            (input)="onArea($event)"
          />
        </div>
        <div class="col-4">
          <mat-label>Seating Capacity</mat-label>
          <span *ngIf="isExceedingCapacity" class="exceeding-area">Seating Capacity exceeds total capacity </span>
          <input
            class="input-field"
            autocomplete="off"
            matInput
            type="number"
            maxlength="8"
            min="0"
            placeholder=""
            onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
            formControlName="buildingCapacity"
            (keyup)="onCapacity($event)"
          />
        </div>
      </div>
    </form>
  </div>
  <div class="customElement">
    <ng-container *ngFor="let form of formCollection.controls; index as i">
      <form class="m-t-15" [formGroup]="form">
        <div class="fields row">
          <div class="col-4">
            <mat-label class="label-input">
              Key
            </mat-label>
            <input class="input-field" autocomplete="off" matInput type="text" formControlName="key" />
          </div>
          <div class="col-4">
            <mat-label class="label-input">
              Value
            </mat-label>
            <input
              class="input-field"
              autocomplete="off"
              matInput
              type="text"
              formControlName="value"
              (input)="onValueChange($event, i)"
            />
          </div>
          <div class="bgicon delete" (click)="deleteForm(i)">
            <!-- <img class="bg" src="assets/bg.png" alt="" />
            <span style="color: #fff;">x</span> -->
            <img src="assets/Group 1381.svg" alt="" />
          </div>
          <div class="bgicon upload">
            <input type="file" (change)="onKeyValueFileChange($event, i)" />
            <!-- <img class="bg" src="assets/bg.png" alt="" />
            <img src="assets/Group 983.png" class="add-upload" alt="" /> -->
            <img src="assets/Group 1422.svg" alt="" />
          </div>
          <div *ngIf="addedInfoFileType[i] == 'file'" (click)="downloadMoreInfoFile(i)" class="view">
            <span class="material-icons">visibility</span>
          </div>
        </div>
      </form>
    </ng-container>
    <div class="addfield" (click)="addRow()">
      <div>
        <img src="assets/Add.svg" alt="" />
      </div>
      <div>
        Add Additional Field
      </div>
    </div>
  </div>

  <hr class="border-line" />

  <div class="location-building row">
    <p>Building Mapping</p>
    <form [formGroup]="buildingDetailsArray">
      <input class="input-field" autocomplete="off" matInput type="text" placeholder="" formControlName="latLong" />
      <!-- <span class="clear-icon" (click)="clearLocation()">
        <img src="assets/bg.png" alt="" (click)="clearLocation()" />
        <span class="close">X</span>
      </span> -->
      <i class="material-icons closs-button" (click)="clearLocation()">
        cancel
      </i>
    </form>
  </div>

  <!-- <div class="map-block">
    <agm-map
      [latitude]="latitude"
      [longitude]="longitude"
      [zoom]="zoom"
      [zoomControl]="true"
      [mapTypeControl]="true"
      [gestureHandling]="'coopeative'"
      [scrollwheel]="true"
      (mapClick)="placeMarker($event)"
    >
      <agm-marker [latitude]="latitude" [longitude]="longitude" [iconUrl]="getimagename()"> </agm-marker>
    </agm-map>
  </div> -->

  <div class="select-location">
    <button
      mat-stroked-button
      class="stroked-btn"
      [disabled]="!buildingDetailsArray.value.spaceId"
      [mat-dialog-close]="addNew"
    >
      ADD NEW
    </button>
    <button
      mat-raised-button
      class="raised-btn"
      [disabled]="buildingDetailsArray.invalid || isExceedingCapacity || isExistingBuilding || isExceedingArea"
      (click)="close()"
    >
      SAVE
    </button>
  </div>
</mat-dialog-content>
