<div
  class="scan-div"
  *ngIf="latestScannedEmp"
  [ngClass]="{
    success: latestScannedEmp.isPermitted,
    failure: !latestScannedEmp.isPermitted
  }"
>
  <div class="row">
    <div class="date-loc">
    <div class="date_time" *ngIf="latestScannedEmp.timeStamp">
      {{ latestScannedEmp.timeStamp | date: "dd MMM yyyy" }}|{{
        latestScannedEmp.timeStamp | date: "hh:mm a" | lowercase
      }}
    </div>
    <div class="location_dropdown">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              <img
                *ngIf="isLightTheme"
                class="location_img"
                src="assets/Light-Theme/Group 55.png"
              />
              <img
                *ngIf="!isLightTheme"
                class="location_img"
                src="assets/maps-and-flags.png"
              />
              <span
                class="loc_details"
                *ngIf="selectedFacility.length > 2"
                [matTooltip]="getFacilityTooltip()"
                [matTooltipPosition]="position"
              >
                {{ selectedFacility }}
              </span>
              |
              <span
                class="loc_details"
                *ngIf="selectedBuilding.length > 2"
                [matTooltip]="getBuildingTooltip()"
                [matTooltipPosition]="position"
              >
                {{ selectedBuilding }}</span
              >
              |
              <span
                class="loc_details"
                *ngIf="selectedLocation.length > 4"
                [matTooltip]="getFloorTooltip()"
                [matTooltipPosition]="position"
                >{{ selectedLocation }}</span
              >
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div>
            <form [formGroup]="securityLocation">
              <div class="border-top">
                <mat-label class="label">Facility</mat-label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-select
                    (selectionChange)="facilitySelection($event)"
                    formControlName="facility"
                  >
                    <mat-option [value]="f.spaceId" *ngFor="let f of facilities"
                      >{{ f.faciityName }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <mat-label class="label">Building</mat-label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-select
                    (selectionChange)="buildingSelection($event)"
                    formControlName="building"
                  >
                    <mat-option
                      [value]="f.spaceId"
                      *ngFor="let f of buildings"
                      >{{ f.buildingName }}</mat-option
                    >
                  </mat-select>
                </mat-form-field>
              </div>
              <div>
                <mat-label class="label">Location</mat-label>
                <mat-form-field appearance="outline" style="width: 100%">
                  <mat-select
                    (selectionChange)="floorSelection($event)"
                    formControlName="floor"
                  >
                    <mat-option [value]="f.spaceId" *ngFor="let f of floors">{{
                      f.floorNumber
                    }}</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </form>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>

    <div class="no-result" *ngIf="waitingScan">
      <div style="text-align: center">
        <mat-icon>error_outline</mat-icon>
      </div>
      <p>Waiting for the thermal scan data</p>
    </div>

    <div class="scan-results col-12" *ngIf="latestScannedEmp && !waitingScan">
      <div class="success-msg">
        <img src="../../../../assets/success_tick.svg" />
        <div class="scans">Scan Successful</div>
      </div>
      <div class="emp-details">
        <div>
          Employee ID :
          <span class="bold"> {{ latestScannedEmp.employeeID }}</span>
        </div>
        <!--<div>
          {{ latestScannedEmp.timeStamp | date: 'dd MMM yyyy' }} |
          {{ latestScannedEmp.timeStamp | date: 'hh:mm a' | lowercase }}
        </div> -->
      </div>
      <div class="emp-status">
        <div class="emp-status-content">
          <div class="status_wrap float-left">
            <div class="status_label">Return to office</div>
            <div class="status_div">
              <div class="temp_value">
                <span
                  *ngIf="latestScannedEmp.isR2OPermitted == true"
                  class="bold"
                  >Permitted</span
                >
                <span
                  *ngIf="
                    latestScannedEmp.isR2OPermitted == false ||
                    latestScannedEmp.isR2OPermitted == null
                  "
                  class="bold"
                  >Not Permitted</span
                >
              </div>
              <img
                *ngIf="
                  latestScannedEmp.isR2OPermitted == false ||
                  latestScannedEmp.isR2OPermitted == null
                "
                class="status_img"
                src="../../../../assets/status-cross.svg"
              />
              <img
                *ngIf="latestScannedEmp.isR2OPermitted == true"
                class="status_img"
                src="../../../../assets/success_tick.svg"
              />
            </div>
          </div>
          <div class="status_wrap float-left">
            <div class="status_label">Temperature</div>
            <div class="status_div">
              <div class="temp_value">
                <span>{{ latestScannedEmp.temperature }}</span
                >&#8457;
              </div>
              <img
                *ngIf="latestScannedEmp.isElevatedTemperature == true"
                class="status_img"
                src="../../../../assets/status-cross.svg"
              />
              <img
                *ngIf="latestScannedEmp.isElevatedTemperature == false"
                class="status_img"
                src="../../../../assets/success_tick.svg"
              />
            </div>
          </div>
          <div class="status_wrap float-left">
            <div class="status_label">Mask</div>
            <div class="status_div float-left">
              <div class="status_mask">
                <img src="../../../../assets/mask.svg" />
              </div>
              <img
                *ngIf="latestScannedEmp.isMaskDetected == true"
                class="status_img mask"
                src="../../../../assets/success_tick.svg"
              />
              <img
                *ngIf="latestScannedEmp.isMaskDetected == false"
                class="status_img mask"
                src="../../../../assets/status-cross.svg"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="final_status"
        [ngClass]="{
          success: latestScannedEmp.isPermitted,
          failure: !latestScannedEmp.isPermitted
        }"
      >
        You
        <span *ngIf="latestScannedEmp.isPermitted == true" class="bold"
          >can</span
        >
        <span *ngIf="latestScannedEmp.isPermitted == false" class="bold"
          >cannot</span
        >
        enter the premises
      </div>
    </div>
  </div>
</div>
<div *ngIf="loader" class="loading-spinner-div">
  <img src="assets/loading_bar.svg" class="loading-spinner" />
</div>
