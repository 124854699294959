import { Component, OnInit, Inject } from '@angular/core';
import { EmployeeService } from '../service/employee.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-employee-qrcode',
  templateUrl: './employee-qrcode.component.html',
  styleUrls: ['./employee-qrcode.component.scss']
})
export class EmployeeQrcodeComponent implements OnInit {

  name: string;
  qrCodeDetails: any;
  employeeId: any;
  constructor(
    private service: EmployeeService,
    public dialogRef: MatDialogRef<EmployeeQrcodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.name = 'Jai Sri Ram';
    this.employeeId = data.empId;
    console.log('QR  Data value');
    console.log(this.employeeId);
  }

  ngOnInit(): void {
    this.getEmployeeData(this.employeeId); //13501, 13594, 13533, 13534, 13537, 13554, 13574, 13634 - this.employeeId
  }

  getEmployeeData(empId: any) {
    /*this.service.getEmployeeDetailsById(empId).subscribe(
      (employee: any) => {
        this.qrCodeDetails = `
          Emp Id: ${employee.employeeId},
          Name: ${employee.name},
          Email: ${employee.email},
          Contact: ${employee.contact},
          Role: ${employee.role},
          Space Name: ${employee.spaceName}
        `;
      },
      (err) => {
        console.log(err);
      }
    );*/
    if (this.data.expiryTime) {
      let date = this.data.expiryTime;
      this.qrCodeDetails = `Employee Id: ${empId},
Expiry Time: ${new Date(date).getTime()}`;
    } else {
      this.qrCodeDetails = `Employee Id: ${empId}`;
    }
  }
  onCloseClick(): void {
    this.dialogRef.close();
  }
}
