import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class HandHygieneService {
  // employee_aggregate_baseURL = 'https://onefacility-hh-web-api.azurewebsites.net/HygieneCompliance';

  baseUrl = environment.HH_baseURL + 'HygieneCompliance';

  constructor(private http: HttpClient) {}

  getHygieneComplianceCountById(id: number, spaceTypeId: any, startTime: string, endTime: string, timeZone: string) {
    return this.http.get(
      this.baseUrl +
        `/Count?SpaceId=${id}&SpaceTypeId=${spaceTypeId}&GroupBy=ChildSpaceId&StartTime=${startTime}&EndTime=${endTime}&timeZone=${timeZone}`
    );
  }

  getLocation(id: number, spaceTypeId: any, startTime: string, endTime: string, timeZone: string) {
    return this.http.get(
      this.baseUrl +
        `/Count?SpaceId=${id}&SpaceTypeId=${spaceTypeId}&GroupBy=SpaceId&StartTime=${startTime}&EndTime=${endTime}&timeZone=${timeZone}`
    );
  }

  getFloors(id: number, spaceTypeId: any, startTime: string, endTime: string, timeZone: string) {
    return this.http.get(
      this.baseUrl +
        `/Count?SpaceId=${id}&SpaceTypeId=${spaceTypeId}&GroupBy=ChildSpaceId&StartTime=${startTime}&EndTime=${endTime}&timeZone=${timeZone}`
    );
  }
  getPeopleData(
    id: number,
    startTime: string,
    endTime: string,
    sortBy: string,
    orderBy: string,
    limit: number,
    offset: number,
    spaceTypeId: number,
    timeZone: string
  ) {
    return this.http.get(
      this.baseUrl +
        `/?SpaceId=${id}&StartTime=${startTime}&EndTime=${endTime}&SortBy=${sortBy}&OrderBy=${orderBy}&Limit=${limit}&Offset=${offset}&SpaceTypeId=${spaceTypeId}&timeZone=${timeZone}`
    );
  }

  getHourlyView(spaceId: number, from: string, to: string, timeZone: string) {
    return this.http.get(
      this.baseUrl + `/Aggregate?SpaceId=${spaceId}&GroupBy=Hourly&StartTime=${from}&EndTime=${to}&timeZone=${timeZone}`
    );
  }

  getDailyView(spaceId: number, from: string, to: string, timeZone: string) {
    return this.http.get(
      this.baseUrl + `/Aggregate?SpaceId=${spaceId}&GroupBy=Daily&StartTime=${from}&EndTime=${to}&timeZone=${timeZone}`
    );
  }

  getweeklyView(spaceId: number, from: string, to: string, timeZone: string) {
    return this.http.get(
      this.baseUrl + `/Aggregate?SpaceId=${spaceId}&GroupBy=Weekly&StartTime=${from}&EndTime=${to}&timeZone=${timeZone}`
    );
  }

  getMonthlyView(spaceId: number, from: string, to: string, timeZone: string) {
    return this.http.get(
      this.baseUrl +
        `/Aggregate?SpaceId=${spaceId}&GroupBy=Monthly&StartTime=${from}&EndTime=${to}&timeZone=${timeZone}`
    );
  }

  getEmployeeAggregateData(
    spaceId: number,
    spaceTypeId: number,
    start: string,
    end: string,
    sortBy: string,
    orderBy: string,
    limit: number,
    offset: number,
    timeZone: string
  ) {
    return this.http.get(
      `${this.baseUrl}/EmployeeAggregate?SpaceId=${spaceId}&SpaceTypeId=${spaceTypeId}&StartTime=${start}&EndTime=${end}&SortBy=${sortBy}&OrderBy=${orderBy}&Offset=${offset}&Limit=${limit}&timeZone=${timeZone}`
    );
  }
}
