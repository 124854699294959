import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import * as moment from 'moment';
@Component({
  selector: 'app-add-asset',
  templateUrl: './add-asset.component.html',
  styleUrls: ['./add-asset.component.scss']
})
export class AddAssetComponent implements OnInit {

  // Variable Declaration
  systemForm: FormGroup;
  assetForm: FormGroup;
  spaceForm: FormGroup;
  systemList: any = ['HVAC', 'HVAC1'];
  assetList: any = ['Printer', 'Air Compressor'];
  regionList: any = ['North America', 'South America'];
  countryList: any = ['USA', 'UK'];
  facilityList: any = ['ABC', 'XYZ'];
  buildingList: any = ['Building 1', 'Building 2', 'Building 3'];
  floorList: any = ['Floor 1', 'Floor 2', 'Floor 3'];
  zoneList: any = ['Zone 1', 'Zone 2', 'Zone 3'];
  step = -1;
  selectedPurchaseDate: any;
  selectedWarrantyEndDate: any;

  @Output() assetListView = new EventEmitter<any>();
  
  constructor(private fb: FormBuilder) { }

  ngOnInit(): void {
    this.createSystemForm();
    this.createAssetForm();
    this.createSpaceForm();
  }

  // Method to create the system section form
  createSystemForm() {
    this.systemForm = this.fb.group({
      system: ['', Validators.required],
      assetType: ['', Validators.required],
    });
  }

  // Method to create the asset form
  createAssetForm() {
    this.assetForm = this.fb.group({
      assetName: ['', Validators.required],
      manufacterName: ['', Validators.required],
      serialNo: ['', Validators.required],
      purchaseCost: ['', Validators.required],
      purchaseDate: ['', Validators.required],
      warrantyDate: ['', Validators.required],
      additionalFieldDetails: this.fb.array([], [Validators.required])
    });
  }

  get additionalFieldDetails() {
    return this.assetForm.get('additionalFieldDetails') as FormArray;
  }

  // Method to create a space form
  createSpaceForm() {
    this.spaceForm = this.fb.group({
      region: ['', Validators.required],
      country: ['', Validators.required],
      facility: ['', Validators.required],
      building: ['', Validators.required],
      floor: ['', Validators.required],
      zone: ['', Validators.required],
    });
  }

  // Method called on every time panel clcked
  setStep(index: number) {
    this.step = index;
  }

  // Method called on click of next
  nextStep() {
    this.step++;
  }

  // Create Additional Field
  createAdditionalField() {
    return this.fb.group({
      key: ['', Validators.required],
      value: ['', Validators.required]
    });
  }

  // Method on click of addNewField
  addNewField() {
    this.additionalFieldDetails.push(this.createAdditionalField());
  }

  // Method called on click of back buton
  onBack() {
    this.assetListView.emit();
  }

  
  // Method called on click of cancel
  onCancel(index:any) {
      console.log(index);
      this.additionalFieldDetails.removeAt(index);
  }

  // Method called on click of purchase calender
  onPurchaseDate(e: any) {
    console.log(e);
    this.selectedPurchaseDate =  moment(e).format('MM/DD/YYYY');
  }

   // Method called on click of warranty calender
  onWarrantyEndDate(e:any) {
    this.selectedWarrantyEndDate = moment(e).format('MM/DD/YYYY');
  }

}
