<div class="root">
  <div class="title" style="margin-top: -7px;">
    Success
    <div>
      <img (click)="close()" src="assets/Group 99.png" alt="" />
    </div>
  </div>
  <hr />
  <div class="message">
    {{ data.message }}
  </div>
  <div class="btn">
    <button mat-button class="btn-save" color="primary" (click)="close()">OK</button>
  </div>
</div>
