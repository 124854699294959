import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { SecurityScanComponent } from '../security-scan/security-scan.component';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '@env/environment';
import { FormGroup, FormControl } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ThermalService } from '../service/thermal.service';
import { AppService } from '@app/app.service';
import { AdmitPopupComponent } from '../admit-popup/admit-popup.component';
import { HTTPStatus } from '@app/workspace-experience/@core/services/auth-interceptor';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];
@Component({
  selector: 'app-thermal-screen',
  templateUrl: './thermal-screen.component.html',
  styleUrls: ['./thermal-screen.component.scss'],
})
export class ThermalScreenComponent implements OnInit {
  alertsData: any = [];
  alertdata: any = [];
  resultLength = 0;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(SecurityScanComponent)
  private securityScanComponent: SecurityScanComponent;
  displayedColumns: string[] = [
    'name',
    'employeeId',
    'temperature',
    'faceMask',
    'time',
    'status',
  ];
  message = '';
  dataSource = new MatTableDataSource();

  temperatureThershold = '0';

  isComplaint = true;
  isTableEmpty: boolean;
  timer: any;
  timerEverySecond: any;
  latestScannedPerson: any;

  isLightTheme: boolean = environment.isLightTheme;
  selectedFacility: any = localStorage.getItem('selectedFacilityThermal');
  selectedBuilding: any = localStorage.getItem('selectedBuildingThermal');
  selectedLocation: any = localStorage.getItem('selectedLocationThermal');
  securityLocation = new FormGroup({
    facility: new FormControl(''),
    building: new FormControl(''),
    floor: new FormControl(''),
  });
  facilities: any;
  position: string;
  selectedSpaceId: any;
  buildings: any;
  floors: any;
  floorValue: any;
  noData: boolean;
  initialSetFloorValue: any;
  @Output() error: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() floorChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  loader: boolean;

  constructor(
    private dialog: MatDialog,
    public router: Router,
    private changeDetectorRefs: ChangeDetectorRef,
    private service: ThermalService,
    private appservice: AppService,
    private httpStatus: HTTPStatus
  ) {}
  ngOnDestroy(): void {
    // clearInterval(this.timer);
    // clearInterval(this.timerEverySecond);
  }

  ngOnInit() {
    this.service.getFacilityByRegion().subscribe(
      (data: any) => {
        if (data) {
          const facility: any = [];
          let count = 0;
          data.map((value: any, spaceIndex: number) => {
            let facName = '';
            value.spaceProperties.map((property: any, index: number) => {
              if (property.propertyKey === 'name') {
                count++;
                facName = property.propertyValue;
              }
            });
            if (!!facName) {
              facility.push({
                spaceId: value.spaceId,
                faciityName: facName,
              });
            }
          });
          this.facilities = facility;
          console.log(facility[0]);
          if (localStorage.getItem('selectedFacilityThermal') == null) {
            localStorage.setItem(
              'selectedFacilityThermal',
              facility[0].faciityName
            );
            this.selectedFacility = localStorage.getItem(
              'selectedFacilityThermal'
            );
          }
          //inside facility we are passing default first record space id to get first building value
          this.service
            .getSpaceDetailsByParentSpace(facility[0].spaceId)
            .subscribe(
              (buildings: any) => {
                //  console.log(buildings)
                const buildingsDrilldown: any = [];
                buildings.map((value: any) => {
                  let buildingName = '';
                  value.spaceProperties.map((property: any) => {
                    if (property.propertyKey === 'name') {
                      buildingName = property.propertyValue;
                    }
                  });
                  if (!!buildingName) {
                    buildingsDrilldown.push({
                      spaceId: value.spaceId,
                      buildingName: buildingName,
                    });
                  }
                });
                this.buildings = buildingsDrilldown;
                this.floors = [];
                console.log(buildingsDrilldown[0]);
                if (localStorage.getItem('selectedBuildingThermal') == null) {
                  localStorage.setItem(
                    'selectedBuildingThermal',
                    buildingsDrilldown[0].buildingName
                  );
                  this.selectedBuilding = localStorage.getItem(
                    'selectedBuildingThermal'
                  );
                }
                this.service
                  .getSpaceDetailsByParentSpace(buildingsDrilldown[0].spaceId)
                  .subscribe(
                    (floors: any) => {
                      //  console.log(floors);

                      const floorsDrilldown: any = [];

                      floors.map((value: any) => {
                        let floorName = '';
                        value.spaceProperties.map(
                          (property: any, index: number) => {
                            if (property.propertyKey === 'name') {
                              floorName = property.propertyValue;
                            }
                          }
                        );
                        if (!!floorName) {
                          floorsDrilldown.push({
                            spaceId: value.spaceId,
                            floorNumber: floorName,
                            spaceTypeId: value.spaceType.spaceTypeId,
                            type: value.spaceType.type,
                          });
                        }
                      });

                      this.floors = floorsDrilldown;
                      let floor = floorsDrilldown.find((floor: any) => {
                        return floor.spaceTypeId === 3;
                      });
                      if (
                        localStorage.getItem('selectedLocationThermal') == null
                      ) {
                        localStorage.setItem(
                          'selectedLocationThermal',
                          floor.floorNumber
                        );
                        this.selectedLocation = localStorage.getItem(
                          'selectedLocationThermal'
                        );
                        this.initialSetFloorValue = floor.spaceId;
                      } else {
                        this.initialSetFloorValue = floor.spaceId;
                      }
                      this.getThershHold();
                    },
                    (err) => console.log(err)
                  );
              },
              (err) => console.log(err)
            );
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
    this.appservice.currentTheme.subscribe(
      (theme) => (this.isLightTheme = theme == 'theme-light' ? true : false)
    );
    // this.getThershHold();
    // this.every5Seconds();
    // this.everySecond();
    this.position = 'above';
    console.log(localStorage.getItem('selectedFacilityThermal'));

    // this.timer = setInterval(() => {
    //   this.every5Seconds();
    // }, 5000); // 5 Seconds

    // this.timerEverySecond = setInterval(() => {
    //   this.everySecond();
    // }, 1000); // 1 Seconds
    this.appservice.currentTheme.subscribe(
      (theme) => (this.isLightTheme = theme == 'theme-light' ? true : false)
    );
  }

  getThershHold() {
    this.service
      .getThresholdTempBySpaceId(localStorage.getItem('safebuildingSpaceId'))
      .subscribe((tempData: any) => {
        let tempVal: any;
        tempData.settingItems.forEach((i: any) => {
          if (i.settingsKeysValues === 'TSBodyTemperatureThreshold') {
            tempVal = i.minValue;
          }
        });
        this.temperatureThershold = !!tempVal ? tempVal : 98.4;
        // this.every5Seconds();
        // this.everySecond();
        this.floorChanged.emit(true);
      });
  }

  getScannedTooltip() {
    return this.latestScannedPerson.name;
  }

  thermalfacilitySelection(fac: any) {
    let target = fac.source.selected._element.nativeElement;
    let selectedData = {
      text: target.innerText.trim(),
    };
    this.selectedFacility = selectedData.text;
    localStorage.setItem('selectedFacilityThermal', selectedData.text);
    this.selectedSpaceId = this.securityLocation.value.facility;
    // console.log(this.securityLocation.value.facility);
    this.service.getSpaceDetailsByParentSpace(this.selectedSpaceId).subscribe(
      (buildings: any) => {
        //  console.log(buildings)
        const buildingsDrilldown: any = [];
        buildings.map((value: any) => {
          let buildingName = '';
          value.spaceProperties.map((property: any) => {
            if (property.propertyKey === 'name') {
              buildingName = property.propertyValue;
            }
          });
          if (!!buildingName) {
            buildingsDrilldown.push({
              spaceId: value.spaceId,
              buildingName: buildingName,
            });
          }
        });
        this.buildings = buildingsDrilldown;
        this.floors = [];
      },
      (err) => console.log(err)
    );
  }

  thermalbuildingSelection(event: any) {
    let target = event.source.selected._element.nativeElement;
    let selectedData = {
      text: target.innerText.trim(),
    };
    this.selectedBuilding = selectedData.text;
    localStorage.setItem('selectedBuildingThermal', selectedData.text);
    this.selectedSpaceId = this.securityLocation.value.building;
    // console.log(this.securityLocation.value.building);
    this.service.getSpaceDetailsByParentSpace(this.selectedSpaceId).subscribe(
      (floors: any) => {
        //  console.log(floors);

        const floorsDrilldown: any = [];

        floors.map((value: any) => {
          let floorName = '';
          value.spaceProperties.map((property: any, index: number) => {
            if (property.propertyKey === 'name') {
              floorName = property.propertyValue;
            }
          });
          if (!!floorName) {
            floorsDrilldown.push({
              spaceId: value.spaceId,
              floorNumber: floorName,
            });
          }
        });

        this.floors = floorsDrilldown;
        console.log(this.securityLocation.value);
        // console.log(floorsDrilldown);
      },
      (err) => console.log(err)
    );
  }

  thermalfloorSelection(event: any) {
    let target = event.source.selected._element.nativeElement;
    let selectedData = {
      text: target.innerText.trim(),
    };
    this.selectedLocation = selectedData.text;
    localStorage.setItem('selectedLocationThermal', selectedData.text);
    localStorage.setItem(
      'selectedSpaceIdThermal',
      this.securityLocation.value.floor
    );
    this.floorChanged.emit(true);
  }
  everySecond() {
    if (this.securityLocation.value.floor) {
      this.floorValue = this.securityLocation.value.floor;
    } else if (localStorage.getItem('selectedSpaceIdThermal') != null) {
      this.floorValue = localStorage.getItem('selectedSpaceIdThermal');
    } else {
      this.floorValue = this.initialSetFloorValue;
    }
    this.loader = true;
    this.service.getSecurityScanningDetilsLatest(this.floorValue).subscribe(
      (details: any) => {
        this.loader = false;
        this.latestScannedPerson = {
          name: details.name,
          temperature: Math.ceil(details.data * 10) / 10,
          status: false,
        };
        if (
          Math.ceil(details.data * 10) / 10 <=
          parseFloat(this.temperatureThershold)
        ) {
          this.latestScannedPerson.status = true;
          this.isComplaint = true;
        } else if (
          Math.ceil(details.data * 10) / 10 >
          parseFloat(this.temperatureThershold)
        ) {
          this.latestScannedPerson.status = false;
          this.isComplaint = false;
        }
      },
      (error: any) => {
        console.log(error);
        this.loader = false;
        this.httpStatus.setHttpStatus(false);
        this.error.emit(true);
      }
    );
  }
  getFacilityTooltip() {
    return this.selectedFacility;
  }
  getBuildingTooltip() {
    return this.selectedBuilding;
  }
  getFloorTooltip() {
    return this.selectedLocation;
  }

  every5Seconds() {
    if (this.securityLocation.value.floor) {
      this.floorValue = this.securityLocation.value.floor;
    } else if (localStorage.getItem('selectedSpaceIdThermal') != null) {
      this.floorValue = localStorage.getItem('selectedSpaceIdThermal');
    } else {
      this.floorValue = this.initialSetFloorValue;
    }
    this.loader = true;
    this.service.getSecurityScanningDetils(this.floorValue).subscribe(
      (scandetails: any) => {
        this.loader = false;
        const tempScan: any = [];
        if (scandetails) {
          this.noData = false;
          scandetails.map((value: any) => {
            let peopleStatus = false;
            let alertConfig = false;
            let secondaryTemperature = '';
            let isNormal = false;
            if (
              Math.ceil(value.data * 10) / 10 <=
              parseFloat(this.temperatureThershold)
            ) {
              peopleStatus = true;
            } else if (
              Math.ceil(value.data * 10) / 10 >
                parseFloat(this.temperatureThershold) &&
              !value.overrideValue
            ) {
              // console.log(value.overrideValue, 'value.overrideValue');
              peopleStatus = false;
              this.alertsData = this.alertsData.filter((element: any) => {
                return (
                  element.employeeId != value.employeeId ||
                  new Date(element.time).getTime() !=
                    new Date(value.reportedDateTime).getTime()
                );
              });
              //console.log(this.alertsData);
              if (value.employeeId !== -1) {
                this.alertsData.push({
                  employeeId: value.employeeId,
                  name: value.name,
                  spaceName: value.spaceName,
                  temperature: Math.ceil(value.data * 10) / 10,
                  status: peopleStatus,
                  time: value.reportedDateTime,
                  vendorName: value.vendorName,
                  isR2oPermission: value.isR2oPermission,
                  isMaskDetected: value.isMaskDetected,
                });
                this.alertsData.sort(this.compare);
              }
            } else if (
              Math.ceil(value.data * 10) / 10 >
                parseFloat(this.temperatureThershold) &&
              value.overrideValue
            ) {
              peopleStatus = true;
              alertConfig = true;
              secondaryTemperature = value.overrideValue;
              if (
                Math.ceil(value.overrideValue * 10) / 10 <=
                parseFloat(this.temperatureThershold)
              ) {
                isNormal = true;
              } else {
                isNormal = false;
              }
            }

            tempScan.push({
              employeeId: value.employeeId,
              name: value.name,
              spaceName: value.spaceName,
              temperature: Math.ceil(value.data * 10) / 10,
              status: peopleStatus,
              time: value.reportedDateTime,
              vendorName: value.vendorName,
              config: alertConfig,
              newTemp: secondaryTemperature,
              isNormal,
              isMaskDetected: value.isMaskDetected,
              isR2oPermission: value.isR2oPermission,
            });
          });
        } else {
          this.noData = true;
        }

        this.dataSource = new MatTableDataSource(tempScan);
        this.dataSource.paginator = this.paginator;
        if (this.dataSource.data.length === 0) {
          this.isTableEmpty = true;
        } else {
          this.isTableEmpty = false;
        }
      },
      (error: any) => {
        console.log(error);
        this.loader = false;
        this.httpStatus.setHttpStatus(false);
        this.error.emit(true);
      }
    );
  }

  compare(a: any, b: any) {
    const timeOne = new Date(a.time).getTime();
    const timeTwo = new Date(b.time).getTime();

    let comparison = 0;
    if (timeOne < timeTwo) {
      comparison = 1;
    } else if (timeOne > timeTwo) {
      comparison = -1;
    }
    return comparison;
  }

  admitEmployee(admitId: any, empId: any) {
    this.service.getEmployeeDoctorsNote(empId).subscribe((note: any) => {
      const dialogConfig = new MatDialogConfig();

      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.width = '540px';
      dialogConfig.height = '428px';
      dialogConfig.id = 'popup';
      dialogConfig.panelClass = 'security-pop-up';
      dialogConfig.data = {
        id: this.alertsData[admitId].employeeId,
        timeStamp: this.alertsData[admitId].time,
        empName: this.alertsData[admitId].name,
        temperature: this.alertsData[admitId].temperature,
        config: true,
        newTemperature: 0,
        uploaded: note.available,
        temperatureThershold: this.temperatureThershold,
      };
      const dialogRef = this.dialog.open(AdmitPopupComponent, dialogConfig);
      dialogRef.afterClosed().subscribe((data) => {
        if (
          !!data &&
          data.temperature !== '' &&
          data.reason !== '' &&
          data.approvedBy !== ''
        ) {
          const selectedId = this.alertsData[admitId].employeeId;
          this.alertsData.splice(admitId, 1);
          const datasource: any = this.dataSource.data;

          datasource.map((record: any, i: any) => {
            if (record.employeeId === selectedId) {
              datasource[i].config = true;
              datasource[i].newTemp = data.temperature;
              datasource[i].isNormal = data.isNormal;
              datasource[i].peopleStatus = true;
            }
          });
          this.dataSource = new MatTableDataSource(datasource);
          this.dataSource.paginator = this.paginator;
          if (this.dataSource.data.length === 0) {
            this.isTableEmpty = true;
          } else {
            this.isTableEmpty = false;
          }
        }
      });
    });
  }
}
