<mat-sidenav-container fxFill class="sidenav-container-class">
  <mat-sidenav
    #sidenav
    (openedChange)="onOpenedChange($event)"
    ngClass.gt-sm="has-border"
  >
    <!-- [mode]="isMobile ? 'over' : 'side'"
      [opened]="showSideNav"
      [disableClose]="!isMobile" -->
    <mat-toolbar class="menu-toolbar">
      <div class="toolbar-content">
        <span class="title-center" translate>Menu</span>
        <button class="menu-close" mat-icon-button (click)="sidenav.close()">
          <img src="../../assets/menu_close.png" />
        </button>
      </div>
    </mat-toolbar>
    <nav>
      <mat-accordion
        [multi]="true"
        #accordion="matAccordion"
        (click)="$event.stopPropagation()"
      >
        <mat-expansion-panel
          *ngIf="
            role == 'FacilityManager' ||
            role == 'PortfolioManager' ||
            role == 'Admin'
          "
          class="menu-expansion-panel"
          #expansion
          hideToggle
          [ngClass]="expansion.expanded ? 'show-border' : 'hide-border'"
        >
          <mat-expansion-panel-header
            class="menu-header"
            [ngClass]="
              expansion.expanded ? 'hide-header-border' : 'show-header-border'
            "
          >
            <mat-panel-title class="module-title">
              <!-- <div class="module-icon">
                <img *ngIf="!isLightTheme" src="/assets/images/energy.svg" />
                <img
                  *ngIf="isLightTheme"
                  src="/assets/images/energy-light.svg"
                />
              </div> -->
              <div>energy management</div>
            </mat-panel-title>
            <mat-panel-description class="module-arrow">
              <div *ngIf="expansion.expanded">
                <img *ngIf="!isLightTheme" src="/assets/images/Path 6575.svg" />
                <img *ngIf="isLightTheme" src="/assets/images/Path 6577.svg" />
              </div>
              <div *ngIf="!expansion.expanded">
                <img *ngIf="!isLightTheme" src="/assets/images/Path 174.svg" />
                <img
                  *ngIf="isLightTheme"
                  src="/assets/images/Path 174-light.svg"
                />
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngIf="role == 'PortfolioManager'">
            <div class="sub-content">Overview</div>
          </div>
          <div *ngIf="role == 'Admin'" (click)="energyRedirect('admin')">
            <div class="sub-content">Administration</div>
          </div>
          <div *ngIf="role == 'FacilityManager'">
            <div class="sub-content" (click)="energyRedirect('overview')">
              Overview
            </div>
            <div class="sub-content" (click)="energyRedirect('consumption')">
              Consumption Trend
            </div>
            <div class="sub-content" (click)="energyRedirect('peak-profile')">
              Peak Profile
            </div>
            <div
              class="sub-content"
              (click)="energyRedirect('sourcing-breakup')"
            >
              Sourcing Breakup
            </div>
            <div class="sub-content" (click)="energyRedirect('conservation')">
              Conservation Measures
            </div>
            <div
              class="sub-content"
              (click)="energyRedirect('data-point-correlation')"
            >
              Flexible Data Point Correlation
            </div>
            <div class="sub-content" (click)="energyRedirect('target-setting')">
              Target Setting and Tracking
            </div>
            <div
              class="sub-content"
              (click)="energyRedirect('electricity-tariff-definition')"
            >
              Electricity Tariff Definition
            </div>
            
          <mat-accordion>
            <mat-expansion-panel
              #adminPanel4
              (opened)="subPanelOpen(adminPanel4)"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> Operational Efficiency </mat-panel-title>
              </mat-expansion-panel-header>
                  <div
                class="sub-content"
                (click)="energyRedirect('asset-view')"
              >
              Asset View
              </div>
            </mat-expansion-panel>
          </mat-accordion>
            <!-- <div class="sub-content" (click)="energyRedirect('map')">Map</div> -->
          </div>

        </mat-expansion-panel>
        <mat-expansion-panel
          *ngIf="
            role == 'Employee' ||
            role == 'Security' ||
            role == 'Admin' ||
            role == 'FacilityManager'
          "
          class="menu-expansion-panel"
          #workspace
          hideToggle
          [ngClass]="workspace.expanded ? 'show-border' : 'hide-border'"
        >
          <mat-expansion-panel-header
            class="menu-header"
            [ngClass]="
              workspace.expanded ? 'hide-header-border' : 'show-header-border'
            "
          >
            <mat-panel-title class="module-title">
              <!-- <div class="module-icon">
                <img *ngIf="!isLightTheme" src="/assets/images/coworking.svg" />
                <img
                  *ngIf="isLightTheme"
                  src="/assets/images/coworking-light.svg"
                />
              </div> -->
              <div>workspace experience</div>
            </mat-panel-title>
            <mat-panel-description class="module-arrow">
              <div *ngIf="workspace.expanded">
                <img *ngIf="!isLightTheme" src="/assets/images/Path 6575.svg" />
                <img *ngIf="isLightTheme" src="/assets/images/Path 6577.svg" />
              </div>
              <div *ngIf="!workspace.expanded">
                <img *ngIf="!isLightTheme" src="/assets/images/Path 174.svg" />
                <img
                  *ngIf="isLightTheme"
                  src="/assets/images/Path 174-light.svg"
                />
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngIf="role == 'Admin'">
            <!-- <mat-accordian>
              <mat-expansion-panel
                #adminPanel
                (opened)="subPanelOpen(adminPanel)"
              > -->
            <!-- <mat-expansion-panel-header>
                  <mat-panel-title> Administration </mat-panel-title>
                </mat-expansion-panel-header> -->
            <div class="sub-content" (click)="workSpaceRedirect('admin')">
              Organization Configuration
            </div>
            <div
              class="sub-content"
              (click)="workSpaceRedirect('facility-list')"
            >
              Facility Onboarding
            </div>
            <div class="sub-content" (click)="workSpaceRedirect('user-groups')">
              User Groups
            </div>
            <div class="sub-content" (click)="workSpaceRedirect('desk-groups')">
              Desk Groups
            </div>
            <div
              class="sub-content"
              (click)="workSpaceRedirect('meeting-room-onboard')"
            >
              Meeting Room Onboarding
            </div>
            <div
              class="sub-content"
              (click)="workSpaceRedirect('hotdesk-onboard')"
            >
              Hot Desk Onboarding
            </div>
            <div class="sub-content" (click)="workSpaceRedirect('devices')">
              Device Onboarding
            </div>
            <div class="sub-content" (click)="workSpaceRedirect('sensors')">
              Sensor Onboarding
            </div>
            <!-- <div
                  class="sub-content"
                  (click)="workSpaceRedirect('parking-groups')"
                >
                  Parking Groups
                </div> -->
            <!-- <div class="sub-content" (click)="workSpaceRedirect('space')">
              Space Utilization
            </div> -->
            <div
              class="sub-content"
              (click)="workSpaceRedirect('rules-configuration')"
            >
              Rules and Configuration
            </div>
            <div
              class="sub-content"
              (click)="workSpaceRedirect('cost-center-reports')"
            >
              Cost Center Reports
            </div>
            <div class="sub-content" (click)="workSpaceRedirect('inferences')">
              Inferences
            </div>
            <!-- <div
                  class="sub-content"
                  (click)="workSpaceRedirect('parking-spot')"
                >
                  Parking Spot Onboard
                </div> -->
            <!-- </mat-expansion-panel>
            </mat-accordian> -->
          </div>
          <div *ngIf="role == 'FacilityManager'">
            <mat-accordion>
              <mat-expansion-panel
                #adminPanel2
                (opened)="subPanelOpen(adminPanel2)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Operational Dashboard </mat-panel-title>
                </mat-expansion-panel-header>
                <div
                  class="sub-content"
                  (click)="workSpaceRedirect('meeting-list')"
                >
                  Meeting Room Details
                </div>
                <div
                  class="sub-content"
                  (click)="workSpaceRedirect('zone-list')"
                >
                  Zone Details
                </div>
              </mat-expansion-panel>
            </mat-accordion>
            <mat-accordion>
              <mat-expansion-panel
                #adminPanel3
                (opened)="subPanelOpen(adminPanel3)"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title> Utilization Dashboard</mat-panel-title>
                </mat-expansion-panel-header>
                <div class="sub-content" (click)="workSpaceRedirect('space')">
                  Meeting Space Utilization
                </div>
                <div
                  class="sub-content"
                  (click)="workSpaceRedirect('zone-space')"
                >
                  Zone Space Utilization
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
          <div *ngIf="role == 'Employee'">
            <div class="sub-content" (click)="workSpaceRedirect('my-bookings')">
              My Bookings
            </div>
            <div
              class="sub-content"
              (click)="workSpaceRedirect('book-meeting')"
            >
              Book Meeting Room
            </div>
            <div class="sub-content" (click)="workSpaceRedirect('hot-desking')">
              Book Hot Desk
            </div>
            <div class="sub-content" (click)="workSpaceRedirect('visitor')">
              Visitor Management
            </div>
          </div>
          <div *ngIf="role == 'Security'">
            <div
              class="sub-content"
              (click)="workSpaceRedirect('visitor-scan')"
            >
              Scan Visitor QR Code
            </div>
            <div
              class="sub-content"
              (click)="workSpaceRedirect('visitor-list')"
            >
              Active Visitors List
            </div>
          </div>
        </mat-expansion-panel>
        <!-- <mat-expansion-panel
          *ngIf="role == 'Admin'"
          class="menu-expansion-panel"
          #operationEffe
          hideToggle
          [ngClass]="operationEffe.expanded ? 'show-border' : 'hide-border'"
        >
          <mat-expansion-panel-header
            class="menu-header"
            [ngClass]="
              operationEffe.expanded
                ? 'hide-header-border'
                : 'show-header-border'
            "
          >
            <mat-panel-title class="module-title">
              <div>operational efficiency</div>
            </mat-panel-title>
            <mat-panel-description class="module-arrow">
              <div *ngIf="operationEffe.expanded">
                <img *ngIf="!isLightTheme" src="/assets/images/Path 6575.svg" />
                <img *ngIf="isLightTheme" src="/assets/images/Path 6577.svg" />
              </div>
              <div *ngIf="!operationEffe.expanded">
                <img *ngIf="!isLightTheme" src="/assets/images/Path 174.svg" />
                <img
                  *ngIf="isLightTheme"
                  src="/assets/images/Path 174-light.svg"
                />
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngIf="role == 'FacilityManager'">
            <div
              class="sub-content"
              [ngClass]="selectedSubMenu == 'dashboard' ? 'active-sub' : ''"
              (click)="raceTracRedirect('dashboard')"
            >
              Overview
            </div>
            <div
              class="sub-content"
              [ngClass]="selectedSubMenu == 'alertList' ? 'active-sub' : ''"
              (click)="raceTracRedirect('alertList')"
            >
              Alert List
            </div>
            <div
              class="sub-content"
              [ngClass]="selectedSubMenu == 'workorderList' ? 'active-sub' : ''"
              (click)="raceTracRedirect('workorderList')"
            >
              Work Order List
            </div>
            <div
              class="sub-content"
              [ngClass]="selectedSubMenu == 'simulator' ? 'active-sub' : ''"
              (click)="raceTracRedirect('simulator')"
            >
              Simulator
            </div>
          </div>
          <div *ngIf="role == 'Admin'">
            <div
              class="sub-content"
              [ngClass]="selectedSubMenu == 'admin' ? 'active-sub' : ''"
              (click)="raceTracRedirect('admin')"
            >
              Overview
            </div>
            <div
              class="sub-content"
              [ngClass]="selectedSubMenu == 'assets' ? 'active-sub' : ''"
              (click)="raceTracRedirect('assets')"
            >
              Assets
            </div>
            <div
              class="sub-content"
              [ngClass]="selectedSubMenu == 'assetModel' ? 'active-sub' : ''"
              (click)="raceTracRedirect('assetModel')"
            >
              Asset Model Form
            </div>
            <div
              class="sub-content"
              [ngClass]="selectedSubMenu == 'alertRule' ? 'active-sub' : ''"
              (click)="raceTracRedirect('alertRule')"
            >
              Configure Alert Rules
            </div>
          </div>
        </mat-expansion-panel> -->
        <mat-expansion-panel
          *ngIf="role != 'PortfolioManager'"
          class="menu-expansion-panel"
          #safe
          hideToggle
          [ngClass]="safe.expanded ? 'show-border' : 'hide-border'"
        >
          <mat-expansion-panel-header
            class="menu-header"
            [ngClass]="
              safe.expanded ? 'hide-header-border' : 'show-header-border'
            "
          >
            <mat-panel-title class="module-title">
              <!-- <div class="module-icon building-icon">
                <img *ngIf="!isLightTheme" src="/assets/images/building.svg" />
                <img
                  *ngIf="isLightTheme"
                  src="/assets/images/building-light.svg"
                />
              </div> -->
              <div>safe buildings</div>
            </mat-panel-title>
            <mat-panel-description class="module-arrow">
              <div *ngIf="safe.expanded">
                <img *ngIf="!isLightTheme" src="/assets/images/Path 6575.svg" />
                <img *ngIf="isLightTheme" src="/assets/images/Path 6577.svg" />
              </div>
              <div *ngIf="!safe.expanded">
                <img *ngIf="!isLightTheme" src="/assets/images/Path 174.svg" />
                <img
                  *ngIf="isLightTheme"
                  src="/assets/images/Path 174-light.svg"
                />
              </div>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div *ngIf="role == 'FacilityManager'">
            <div class="sub-content" (click)="redirect('dashboard')">
              Overview
            </div>
            <div class="sub-content" (click)="redirect('facilityview')">
              Thermal Screening
            </div>
            <div class="sub-content" (click)="redirect('physicalDistancing')">
              Physical Distancing
            </div>
            <div class="sub-content" (click)="redirect('handHygiene')">
              Hand Hygiene
            </div>
            <div class="sub-content" (click)="redirect('peopleDensity')">
              People Density
            </div>
            <div class="sub-content" (click)="redirect('airquality')">
              Air Quality
            </div>
          </div>
          <div *ngIf="role == 'Admin'">
            <div class="sub-content" (click)="redirect('facilityOnboard')">
              Facility Onboarding
            </div>
            <div class="sub-content" (click)="redirect('deviceOnboard')">
              Device Onboarding
            </div>
            <div class="sub-content" (click)="redirect('employeeManagement')">
              Employee and Band Management
            </div>
            <div class="sub-content" (click)="redirect('ruleConfig')">
              Rules and Configuration
            </div>
          </div>
          <div *ngIf="role == 'Security'">
            <div class="sub-content" (click)="redirect('security')">
              Security
            </div>
            <div class="sub-content" (click)="redirect('thermalScan')">
              Thermal Scan
            </div>
          </div>
          <div *ngIf="role == 'HR'">
            <div class="sub-content" (click)="redirect('hrDashboard')">
              Dashboard
            </div>
            <div class="sub-content" (click)="redirect('modifyAssociate')">
              Modify Associate Details
            </div>
            <div class="sub-content" (click)="redirect('quarantine')">
              Quarantine Tracker
            </div>
            <div class="sub-content" (click)="redirect('forms')">Forms</div>
          </div>
          <div *ngIf="role == 'Employee'">
            <div class="sub-content" (click)="redirect('employee')">
              Employee Compliance History
            </div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </nav>
  </mat-sidenav>
  <mat-sidenav-content class="sidenav-content-card">
    <div class="header-wrapper">
      <div class="logo-title-section">
        <div [ngClass]="currentUrl != '/overview' ? 'logo' : ''">
          <img
            src="assets/images/Group 2697.svg"
            alt="logo"
            (click)="onLogoNavigate()"
            class="logo-icon"
            style="cursor: pointer"
          />
        </div>
        <!-- <div
          *ngIf="
            currentUrl != '/overview' &&
            currentUrl != '/energy/map' &&
            !currentUrl.includes('energy/overview')
          "
        >
          <div class="title" *ngIf="headerText && subHeaderText">
            {{ headerText }} : {{ subHeaderText }}
          </div>
          <div
            *ngIf="
              (role == 'FacilityManager' || role == 'PortfolioManager') &&
              showBack
            "
          >
            <span class="back" (click)="back()">&lt;&nbsp; Back &nbsp;</span>
          </div>
        </div> -->
        <div
          class="location_override"
          *ngIf="
            showLocation &&
            currentUrl != '/overview' &&
            currentUrl != '/energy/map' &&
            !currentUrl.includes('energy/overview') &&
            breadcrumb == 'WORKSPACE EXPERIENCE'
          "
        >
          |
          <img class="location_img" src="./assets/icon_Small Location.svg" />
          <span>{{ getLocation() }}</span>
          <img
            *ngIf="role == 'Employee' && hotdeskPage"
            src="./assets/Group 2957.png"
            (click)="goToOverride()"
            class="edit_icon"
          />
        </div>
        <div
          class="mob_location_override"
          *ngIf="
            showLocation &&
            currentUrl != '/overview' &&
            currentUrl != '/energy/map' &&
            !currentUrl.includes('energy/overview') &&
            breadcrumb == 'WORKSPACE EXPERIENCE'
          "
        >
          <button class="iconsmall" mat-icon-button [matMenuTriggerFor]="userMenu">
            <div>
              <img src="./assets/icon_Small Location@2x.png" class="image" style="margin-bottom: 5px" />
            </div>
          </button>
          <mat-menu #userMenu="matMenu" class="customize" xPosition="before">
            <div class="glocation">
              <div class="location">{{ getLocation() }}</div>
              <button class="btn" *ngIf="role === 'Employee'" [routerLink]="['workspace/override']" (click)="goToOverride()">
                Change Location
              </button>
            </div>
          </mat-menu>
        </div>
      </div>

      <div class="profile-wrapper">
        <div *ngIf="currentUrl != '/overview'">
          <div
            class="alert-icon"
            *ngIf="
              authRole == 'facility' &&
              headerText.toLowerCase() == 'operational efficiency'
            "
          >
            <img src="assets/images/Group 1796.svg" alt="alert-icon" />
            <span class="alert-count"> {{ alarmscount }}</span>
          </div>
        </div>

        <div
          [ngClass]="
            currentUrl != '/overview' ? 'user-info' : 'overview-user-info '
          "
        >
          <div class="user-name">{{ name }}</div>
          <div class="user-desc" *ngIf="currentUrl == '/overview'">
            {{ role }}
          </div>
          <div class="user-desc" *ngIf="currentUrl != '/overview'">
            {{ employeeId }} | {{ role }}
          </div>
        </div>
        <div>
          <button class="logoutMenuButton" [matMenuTriggerFor]="logoutMenu">
            <img src="assets/images/Group 1.png" alt="user-icon" />
          </button>
          <mat-menu #logoutMenu="matMenu" class="logout_menu">
            <!-- <div class="roleDiv switch pb" (click)="$event.stopPropagation()">
          Light Theme
          <span
            class="theme-switcher"
            [ngClass]="{ 'selector-on': isLightTheme }"
            (click)="switchTheme()"
          >
            <span class="selector"></span>
          </span>
        </div> -->
            <div class="roleSwitchContainer">
              <div
                class="role"
                *ngFor="let roleName of rolesList"
                (click)="switchRole(roleName)"
              >
                <p>{{ roleName }}</p>
              </div>
              <!-- <p (click)="changeRole('admin')" *ngIf="authRole !== 'admin'">
            Admin
          </p>
          <p (click)="changeRole('facility')" *ngIf="authRole !== 'facility'">
            Facility Manager
          </p>
          <p
            (click)="changeRole('hr')"
            *ngIf="
              authRole !== 'hr' &&
              headerText.toLowerCase() != 'operational efficiency'
            "
          >
            HR
          </p>
          <p
            (click)="changeRole('security')"
            *ngIf="
              authRole !== 'security' &&
              headerText.toLowerCase() != 'operational efficiency'
            "
          >
            Security
          </p>
          <p
            (click)="changeRole('employee')"
            *ngIf="
              authRole !== 'employee' &&
              headerText.toLowerCase() != 'operational efficiency'
            "
          >
            Employee
          </p> -->
            </div>
            <button
              mat-menu-item
              (click)="logout()"
              class="logoutButton"
              translate
            >
              <img src="../../assets/logout.svg" />
              Logout
            </button>
          </mat-menu>
        </div>
      </div>
    </div>
    <div
      class="header-wrapper-2"
      *ngIf="currentUrl != '/overview' && currentUrl != '/energy/map'"
    >
      <img
        src="assets/menu-icon.svg"
        class="menu-icon"
        alt="menu"
        mat-icon-button
        (click)="sidenav.toggle()"
      />
      <span class="crumb" (click)="onLogoNavigate()">Home</span>
      <span class="arrow"> > </span>
      <span
        class="crumb"
        (click)="goToFacilityView()"
        *ngIf="role == 'FacilityManager' || role == 'PortfolioManager'"
        >Facility View</span
      >
      <span
        class="arrow"
        *ngIf="
          breadcrumb &&
          (role == 'FacilityManager' || role == 'PortfolioManager')
        "
      >
        >
      </span>
      <span *ngIf="breadcrumb" class="bcrumb">{{ breadcrumb }}</span>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
