import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacilityService } from '../facility.service';

@Component({
  selector: 'app-view-facility',
  templateUrl: './view-facility.component.html',
  styleUrls: ['./view-facility.component.scss']
})
export class ViewFacilityComponent implements OnInit {

  facilityDetailsForm = new FormGroup({
    facilityName: new FormControl('', Validators.required),
    facilityType: new FormControl('', Validators.required),
    facilityCapacity: new FormControl('', Validators.required),
    squareFeetArea: new FormControl('', Validators.required),
    facilityLayout: new FormControl('', Validators.required),
    layoutName: new FormControl('', Validators.required),
    region: new FormControl('', Validators.required),
    spaceId: new FormControl(''),
    noOfBuildings: new FormControl(''),
    countryregion: new FormControl(''),
    addressline1: new FormControl('', Validators.required),
    addressline2: new FormControl('', Validators.required),
    pincode: new FormControl('', Validators.required),
    latlong: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    province: new FormControl('', Validators.required),
  });

  countries: any = [];
  states: any = [];
  cities: any = [];
  regions: any;
  facilityTypes: any = [];
  isDownloadable = false;

  constructor(
    public dialogRef: MatDialogRef<ViewFacilityComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: FacilityService
  ) {}

  ngOnInit(): void {
    this.FacilityType();
    this.getCountries();
    this.getGlobalRegions();
  }

  ngAfterViewInit() {
    this.service.getSpaceDetailsBySpaceId(this.data.spaceId).subscribe((facility: any) => {
      console.log(facility);
      facility.spaceProperties.map((property: any) => {
        if (property.propertyKey === 'name') {
          this.facilityDetailsForm.patchValue({
            facilityName: property.propertyValue,
          });
        }
        if (property.propertyKey === 'layoutName') {
          console.log('layout ', property.propertyValue);
          this.facilityDetailsForm.patchValue({
            layoutName: property.propertyValue,
          });
        }
        if (property.propertyKey === 'type') {
          this.facilityDetailsForm.patchValue({
            facilityType: property.propertyValue,
          });
        }

        if (property.propertyKey === 'capacity') {
          this.facilityDetailsForm.patchValue({
            facilityCapacity: property.propertyValue,
          });
        }
        if (property.propertyKey === 'squareFeetArea') {
          this.facilityDetailsForm.patchValue({
            squareFeetArea: property.propertyValue,
          });
        }

        if (property.propertyKey === 'region') {
          this.facilityDetailsForm.patchValue({
            region: Number(property.propertyValue),
          });
        }
        if (property.propertyKey === 'facilityLayout') {
          this.facilityDetailsForm.patchValue({
            facilityLayout: property.propertyValue,
          });
          this.isDownloadable = true;
        }
        if (property.propertyKey === 'latlong') {
          this.facilityDetailsForm.patchValue({
            latlong: property.propertyValue,
          });
        }

        if (property.propertyKey === 'addressline1') {
          this.facilityDetailsForm.patchValue({
            addressline1: property.propertyValue,
          });
        }
        if (property.propertyKey === 'addressline2') {
          this.facilityDetailsForm.patchValue({
            addressline2: property.propertyValue,
          });
        }
        if (property.propertyKey === 'country') {
          this.facilityDetailsForm.patchValue({
            country: Number(property.propertyValue),
          });
        }
        if (property.propertyKey === 'province') {
          this.facilityDetailsForm.patchValue({
            province: Number(property.propertyValue),
          });
        }
        if (property.propertyKey === 'city') {
          this.facilityDetailsForm.patchValue({
            city: Number(property.propertyValue),
          });
        }
        if (property.propertyKey === 'pincode') {
          this.facilityDetailsForm.patchValue({
            pincode: property.propertyValue,
          });
        }
        if (property.propertyKey === 'countryregion') {
          this.facilityDetailsForm.patchValue({
            countryregion: property.propertyValue,
          });
        }
      });
      this.getStates();
      this.getCities();
    });
  }

  FacilityType() {
    this.service.getFacilityTypes().subscribe((types: any) => {
      types.map((type: any) => {
        if (type.isRoot) {
          this.facilityTypes.push(type.type);
        }
      });
    });
  }

  getCountries() {
    this.service.getAllCountries().subscribe((resp) => {
      this.countries = resp;
    });
  }

  getStates() {
    this.service.getAllState(this.facilityDetailsForm.value.country).subscribe((states: any) => {
      this.states = [];
      states.map((state: any) => {
        const temp = {
          regionId: state.regionId,
          name: '',
        };

        state.regionProperties.map((property: any) => {
          if (property.propertyKey === 'Name') {
            temp.name = property.propertyValue;
          }
        });
        this.states.push(temp);
      });
    });
  }

  getCities() {
    if (this.facilityDetailsForm.value.province) {
      this.service.getAllCities(this.facilityDetailsForm.value.province).subscribe((states: any) => {
        this.cities = [];
        states.map((state: any) => {
          const temp = {
            regionId: state.regionId,
            name: '',
          };

          state.regionProperties.map((property: any) => {
            if (property.propertyKey === 'Name') {
              temp.name = property.propertyValue;
            }
          });
          this.cities.push(temp);
        });
      });
    }
  }

  getGlobalRegions() {
    this.service.getGlobalRegions().subscribe((resp) => {
      console.log('regions ', this.regions);
      this.regions = resp;
    });
  }

  close() {
    this.dialogRef.close();
  }

  downloadLayout() {
    if (this.isDownloadable) {
      console.log(this.facilityDetailsForm.value.facilityLayout);
      const filename = this.facilityDetailsForm.value.facilityLayout;
      let fileType = '';
      if (
        filename.toLowerCase().includes('png') ||
        filename.toLowerCase().includes('jpg') ||
        filename.toLowerCase().includes('jpeg') ||
        filename.toLowerCase().includes('ico')
      ) {
        fileType = 'image/' + filename.split('.')[1];
      }

      this.service.getUploadedSpacePropertyFile(filename).subscribe((resp: any) => {
        console.log('file resp ', resp);
        const blob = new Blob([resp], { type: fileType });
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);

        link.setAttribute('visibility', 'hidden');
        link.download = filename.split('.')[0];

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }
  }
}
