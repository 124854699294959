<div
  *ngIf="authenticate"
  style="
    height: max-content;
    position: sticky;
    width: 100%;
    top: 0;
    z-index: 10;
  "
>
  <!-- <app-home-screen></app-home-screen> -->
  <app-header></app-header>
  <!-- <app-menu></app-menu> -->

  <!-- <nb-layout>
    <nb-layout-column>
        <router-outlet></router-outlet>
    </nb-layout-column>
</nb-layout> -->
</div>
<router-outlet></router-outlet>
<div *ngIf="loader" class="loading-spinner-div">
  <img src="assets/loading_bar.svg" class="loading-spinner" />
</div>
