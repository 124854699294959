import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class EnergyManagementService {
  baseUrlEnergy = environment.Energy_TS_baseURL + 'energy-management/';
  baseURLECM = environment.Energy_TS_baseURL + 'energy-conservation/';
  baseURLTariff = environment.Energy_TS_baseURL + 'tariff-definition/';
  constructor(private http: HttpClient) {}

  getEnergyCostDetails() {
    return this.http.get(this.baseUrlEnergy + 'energy/data');
  }
  getGlobalDetails() {
    return this.http.get(this.baseUrlEnergy + 'globalFaciltyDetails');
  }
  getCostTrend() {
    return this.http.get(this.baseUrlEnergy + 'energy/cost/trend');
  }
  getSystemEnergyData() {
    return this.http.get(this.baseUrlEnergy + 'energy/system');
  }
  getRegionalEnergyData() {
    return this.http.get(this.baseUrlEnergy + 'energy/region');
  }

  getTopConsumingFacilities() {
    return this.http.get(this.baseUrlEnergy + 'energy/top/facility');
  }

  getRenewableResource(spaceId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/renewable/consumption/space/${spaceId}/from/${from}/to/${to}`
    );
  }
  
  getFacilityMonthlyData(spaceId: any, from: any, to: any, source: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/facility/trend/${spaceId}/from/${from}/to/${to}/source/${source}/day`
    );
  }

  getFacilityTodayData(spaceId: any, from: any, to: any, source: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/facility/trend/${spaceId}/from/${from}/to/${to}/source/${source}/hour`
    );
  }

  getBuildingEnergyDataByFacility(spaceId: any, from: any, to: any, type: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/facility/consumption/${spaceId}/from/${from}/to/${to}/building?type=${type}`
    );
  }

  getSystemEnergyDataByFacility(spaceId: any, from: any, to: any, type: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/facility/consumption/${spaceId}/from/${from}/to/${to}/system?type=${type}`
    );
  }

  getFacilityYTDData(spaceId: any, from: any, to: any, source: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/facility/trend/${spaceId}/from/${from}/to/${to}/source/${source}/month`
    );
  }

  getWeatherByCityName(city: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/facility/weather/city/${city}`
    );
  }

  getCurrentOccupancy(spaceId: any, timestamp: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/facility/${spaceId}/${timestamp}`
    );
  }

  getFacilityOverview(spaceId: any, from: any, to: any, type: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/facility/${spaceId}/from/${from}/to/${to}?type=${type}`
    );
  }

  getPeakProfileData(
    spaceId: any,
    assetType: any,
    assetId: any,
    year: any,
    month: any,
    deviceId: any,
    source: any,
    offset: any
  ) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/peakprofile/facility/${spaceId}/${year}/${month}/${assetType}/${assetId}/${deviceId}/${source}/offset/${offset}`
    );
  }

  getConsumptionDataByHour(
    spaceId: any,
    assetType: any,
    assetId: any,
    deviceId: any,
    type: any,
    from: any,
    to: any
  ) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/facility/consumptionTrend/${spaceId}/${assetType}/${assetId}/${deviceId}/type/${type}/from/${from}/to/${to}/hour`
    );
  }
  getConsumptionDataByDay(
    spaceId: any,
    assetType: any,
    assetId: any,
    deviceId: any,
    type: any,
    from: any,
    to: any
  ) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/facility/consumptionTrend/${spaceId}/${assetType}/${assetId}/${deviceId}/type/${type}/from/${from}/to/${to}/day`
    );
  }

  getConsumptionDataByMonth(
    spaceId: any,
    assetType: any,
    assetId: any,
    deviceId: any,
    type: any,
    from: any,
    to: any
  ) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/facility/consumptionTrend/${spaceId}/${assetType}/${assetId}/${deviceId}/type/${type}/from/${from}/to/${to}/month`
    );
  }

  getOccupancyByHour(spaceId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `occupancy/space/${spaceId}/from/${from}/to/${to}/hour`
    );
  }

  getOccupancyByDay(spaceId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `occupancy/space/${spaceId}/from/${from}/to/${to}/day`
    );
  }

  getOccupancyByMonth(spaceId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `occupancy/space/${spaceId}/from/${from}/to/${to}/month`
    );
  }

  getCddByHour(spaceId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/cdd/${spaceId}/from/${from}/to/${to}/hour`
    );
  }

  getCddByDay(spaceId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/cdd/${spaceId}/from/${from}/to/${to}/day`
    );
  }

  getCddByMonth(spaceId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/cdd/${spaceId}/from/${from}/to/${to}/month`
    );
  }

  getEnergySourceBreakUp(spaceId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/facility/sourcebreakup/${spaceId}/from/${from}/to/${to}`
    );
  }

  getAssetByEquipmentTypeandSpace(spaceId: any, assetTypeId: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/assets/facility/${spaceId}/assetTypeId/${assetTypeId}`
    );
  }

  getAssetDataByDay(assetId: any, datapointId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/correlation/day/asset/${assetId}/datapoint/${datapointId}/from/${from}/to/${to}   `
    );
  }

  getAssetDataByMonth(assetId: any, datapointId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/correlation/month/asset/${assetId}/datapoint/${datapointId}/from/${from}/to/${to}   `
    );
  }

  getAssetDataByYear(assetId: any, datapointId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/correlation/year/asset/${assetId}/datapoint/${datapointId}/from/${from}/to/${to}   `
    );
  }

  getDeviceByModelAndSapce(spaceId: any, vendorDeviceModelId: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/device/facility/${spaceId}/${vendorDeviceModelId}`
    );
  }

  getWeatherDataPoints(spaceId: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/weather/datapoint/${spaceId}`
    );
  }

  getweatherDataByHour(
    spaceId: any,
    weatherDataTypeId: any,
    from: any,
    to: any
  ) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/weather/datapointdata/${spaceId}/${weatherDataTypeId}/from/${from}/to/${to}/hour`
    );
  }

  getweatherDataByDay(
    spaceId: any,
    weatherDataTypeId: any,
    from: any,
    to: any
  ) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/weather/datapointdata/${spaceId}/${weatherDataTypeId}/from/${from}/to/${to}/day`
    );
  }

  getweatherDataByMonth(
    spaceId: any,
    weatherDataTypeId: any,
    from: any,
    to: any
  ) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/weather/datapointdata/${spaceId}/${weatherDataTypeId}/from/${from}/to/${to}/month`
    );
  }

  getDeviceDataByHour(deviceId: any, dataPointId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/device/datapointdata/${deviceId}/${dataPointId}/from/${from}/to/${to}/hour`
    );
  }

  getDeviceDataByMonth(deviceId: any, dataPointId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/device/datapointdata/${deviceId}/${dataPointId}/from/${from}/to/${to}/month`
    );
  }

  getDeviceDataByDay(deviceId: any, dataPointId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/device/datapointdata/${deviceId}/${dataPointId}/from/${from}/to/${to}/day`
    );
  }
  getAllECMs(spaceId: any) {
    return this.http.get(this.baseURLECM + `ecm/all/${spaceId}`);
  }

  getECMById(ecmId: any) {
    return this.http.get(this.baseURLECM + `ecm/${ecmId}`);
  }

  saveNewECM(json: any) {
    return this.http.post(this.baseURLECM + 'ecm', json);
  }

  getAssetBySpaceAndAssetType(spaceId: any, assetTypeId: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/assets/facility/${spaceId}/assetTypeId/${assetTypeId}`
    );
  }

  getYearlyEnergyConsumption(assetTypeId: any, start: any, end: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/yearly/assetType/${assetTypeId}/start/${start}/end/${end}`
    );
  }

  checkECMName(name: any) {
    return this.http.get(this.baseURLECM + `ecm/check/${name}`);
  }

  getSavingsByECM(assetType: any, actonDate: any, end: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/savings/overall/assetType/${assetType}/date/${actonDate}/end/${end}`
    );
  }

  getMonthlyPrediction(assetType: any, actonDate: any, end: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/savings/yearly/assetType/${assetType}/date/${actonDate}/end/${end}`
    );
  }

  getForecastBySpace(
    spaceId: any,
    start: any,
    end: any,
    timeframe: any,
    source: any
  ) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/forecast/space/${spaceId}/source/${source}/timeframe/${timeframe}/start/${start}/end/${end}`
    );
  }

  getForecastByAsset(
    assetId: any,
    start: any,
    end: any,
    timeframe: any,
    source: any
  ) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/forecast/asset/${assetId}/source/${source}/timeframe/${timeframe}/start/${start}/end/${end}`
    );
  }

  getSavings(spaceId: any, from: any, to: any, source: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/savings/space/${spaceId}/from/${from}/to/${to}/source/${source}`
    );
  }

  getAlertsBySpace(spaceId: any, from: any, to: any) {
    return this.http.get(
      this.baseUrlEnergy + `alert/space/${spaceId}/from/${from}/to/${to}`
    );
  }

  addNewTariffPeriod(Json: any) {
    return this.http.post(this.baseURLTariff + `tariff/period`, Json);
  }

  editTariffByPeriodId(Json: any, periodId: any) {
    console.log(this.baseURLTariff + `tariff/period/${periodId}`);
    return this.http.put(
      this.baseURLTariff + `tariff/period/${periodId}`,
      Json
    );
  }

  getAllPeriodsBySpaceId(spaceId: any) {
    return this.http.get(this.baseURLTariff + `tariff/space/${spaceId}`);
  }

  saveTarget(data: any) {
    return this.http.post(this.baseUrlEnergy + `energy/target`, data);
  }

  getTarget(spaceId: any) {
    return this.http.get(this.baseUrlEnergy + `energy/target/space/${spaceId}`);
  }

  getActualConsumptionMonth(spaceId: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/space/${spaceId}/actual-consumption/month`
    );
  }

  getActualConsumptionYear(spaceId: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/space/${spaceId}/actual-consumption/year`
    );
  }

  getBaselineConsumptionMonth(spaceId: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/space/${spaceId}/baseline-consumption/month`
    );
  }

  getBaselineConsumptionYear(spaceId: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/space/${spaceId}/baseline-consumption/year`
    );
  }

  getForecastConsumptionMonth(spaceId: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/space/${spaceId}/forecast-consumption/month`
    );
  }

  getForecastConsumptionYear(spaceId: any) {
    return this.http.get(
      this.baseUrlEnergy + `energy/space/${spaceId}/forecast-consumption/year`
    );
  }

  getDevicesBySpaceAndAssetType(spaceId: any, assetType: any) {
    return this.http.get(
      this.baseUrlEnergy +
        `energy/devices/facility/${spaceId}/assetType/${assetType}`
    );
  }

  energySource() {
    return this.http.get(this.baseUrlEnergy + `/energy/source`);
  }
}
