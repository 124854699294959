<mat-tab-group
  [selectedIndex]="selectedSecurityTab"
  style="width: 100%; padding-left: 5px"
  class="hrtab"
  (selectedTabChange)="securityTabClick($event)"
  #tabGroup
>
  <mat-tab label="SECURITY">
    <app-thermal-screen
      (error)="clearTimer($event)"
      (floorChanged)="setTimer($event)"
    ></app-thermal-screen>
  </mat-tab>
  <mat-tab label="THERMAL SCAN">
    <app-security-scan
      (error)="clearTimer($event)"
      (floorChanged)="setTimer($event)"
    ></app-security-scan>
  </mat-tab>
</mat-tab-group>
