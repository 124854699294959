<div class="container-fluid">
  <div class="row" style="margin-top: 15px;">
    <div class="col-3 floor-styles" [ngClass]="{'displaying': display}" *ngIf="!pageExpand" style="display: flex; flex-direction: column;">
      <mat-card class="left-pane" style="height: 106vh; overflow-y: auto; margin-bottom: 2%;">
        <div class="row data-row">
          <div class="col-12">
            <div class="filr" style="display: flex; justify-content: space-around;">
              <div class="row" style="display: flex; justify-content: space-around;">
                <div class="col-6 add-border" style="height: 50px;">
                  <mat-form-field appearance="outline" style="width: 100%;">
                    <mat-select [(value)]="durationSelected" (selectionChange)="durationSelectionChange($event)">
                      <mat-option *ngFor="let d of durationList" [value]="d.value">
                        {{ d.view }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-6 filr-btn" style="padding-top: 8px;">
                  <button type="button" class="btn btn-primary save-button" (click)="filterDuration()">
                    FILTER
                  </button>
                </div>
              </div>
            </div>

            <div class="filter" style="justify-content: start;">
              <!-- <div
                  (click)="activeTabVal('All')"
                  [ngClass]="activeTab === 'All' ? 'active' : ''"
                  class="filter-option"
                >
                  ALL
                </div> -->
              <div style="display: flex; margin-right: 30px;">
                <div><span class="vertical-line-red"></span></div>

                <div
                  (click)="activeTabVal('REPORTED')"
                  [ngClass]="activeTab === 'REPORTED' ? 'active' : ''"
                  class="filter-option"
                >
                  REPORTED
                </div>
              </div>

              <div style="display: flex;">
                <div>
                  <span class="vertical-line-orange"></span>
                </div>

                <div
                  (click)="activeTabVal('EXPOSED')"
                  [ngClass]="activeTab === 'EXPOSED' ? 'active' : ''"
                  class="filter-option"
                >
                  EXPOSED
                </div>
              </div>
            </div>

            <!-- <mat-card style="margin: 15px 0px; padding-bottom: 15px; box-shadow: none;">
                  <mat-icon (click)="scrollUp()" class="iconClass">keyboard_arrow_up </mat-icon>
                </mat-card> -->
            <div class="search_inp_div">
              <div class="input-group">
                <input
                  type="text"
                  class="form-control search-input"
                  placeholder="Search by Name or ID"
                  #searchString
                  [(ngModel)]="searchedPerson"
                  (keydown.enter)="enterMember($event.target.value)"
                />
                <div class="input-group-append" style="margin-top: 7px;">
                  <!-- color: #2e9ccc; -->
                  <mat-icon
                    matSuffix
                    style="opacity: 1; cursor: pointer; font-size: 24px; margin: 0 3px;"
                    (click)="searchMember(searchString.value)"
                  >
                    search</mat-icon
                  >
                </div>
              </div>
            </div>
            <div *ngIf="noResult" class="search-res" style="display: flex; justify-content: center;">
              No Results Found
            </div>
            <div *ngIf="responseData" id="floorList">
              <div
                class="side-pane-employee"
                [class.selectedBar]="selectedNavBar == i"
                style="cursor: pointer;"
                *ngFor="let search of responseData; let i = index"
              >
                <div (click)="showSelectedAssociate(i)">
                  <div style="display: flex;">
                    <div [ngClass]="search.status === 'Exposed' ? 'side-pane-img-yellow' : 'side-pane-img'">
                      <!-- <img class="icon" src="assets/Mask Group 4.png" /> -->
                      <span class="material-icons" style="font-size: 72px !important;">account_circle</span>
                    </div>
                    <div class="side-pane-content">
                      <span class="emp-name">{{ search.personName }}</span>

                      <span>{{ search.designation }}</span>
                      <span>{{ search.reportedDate | countDay }} days ago</span>
                      <!-- <span>{{search.duration}}</span> -->
                    </div>
                    <div class="next" *ngIf= isMobileView>
                      <img src="../../../../assets/Path 7973.svg">
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <mat-card>
                  <mat-icon (click)="scrollDown()" class="iconClass"> keyboard_arrow_down</mat-icon>
                </mat-card> -->

            <!-- <hr class="border-line" /> -->
          </div>
        </div>
      </mat-card>
    </div>

    <div
    [ngClass]="{'col-12 graph-card': pageExpand , 'col-9 graph-card': !pageExpand ,'displaying': displaycard}"
      *ngIf="detailsDisplay && tracedData; else emptyResponse"
      style="padding-left: 0px;"

    >
    <div class="back" *ngIf= isMobileView><img src="../../../../assets/Path 7285.svg" (click)="back()">BACK</div>

      <div>
        <mat-card class="materialcard" style="height: 106vh; overflow-y: auto; margin-bottom: 2%; overflow-x: hidden;">
          <div class="row quarantine-header">
            <div *ngIf="pageExpand" style="margin-left: 97%; margin-top: -27px;">
              <img
                *ngIf="!isLightTheme"
                style="cursor: pointer; opacity: 1;"
                (click)="expandView()"
                src="assets/Group 99.png"
                alt=""
              />
              <img
                *ngIf="isLightTheme"
                style="cursor: pointer; opacity: 1;"
                (click)="expandView()"
                src="assets/Light-Theme/Group 99.png"
                alt=""
              />
            </div>
            <div class="col-xl-12 padding-left-zero emp-card" style="display: flex; padding-right: 0px;">
              <div
                class="col-xl-5.5 padding-left-zero"
                style="display: flex;"
                [ngStyle]="{ 'margin-top': pageExpand ? '-5px' : '' }"
              >
                <div class="employee-details col-5" style="padding-left: 0px !important;">
                  <div
                    class="image"
                    [ngClass]="this.tracedData?.status === 'Exposed' ? 'status-exposed' : 'status-quarantined'"
                  >
                    <span class="material-icons user-icon">account_circle</span>
                    <!-- <span class="material-icons user-icon">account_circle</span> -->
                  </div>
                  <div class="employee-contact col-7">
                    <div
                      *ngIf="this.tracedData?.personName.length > 15"
                      [matTooltip]="getName()"
                      matTooltipPosition="above"
                      style="width: max-content; margin-left: 15px;"
                      class="names"
                    >
                      {{ selectedName }} ({{ this.tracedData?.personId }})
                    </div>
                    <div *ngIf="this.tracedData?.personName.length <= 15" class="name" style="width: max-content;">
                      {{ this.tracedData?.personName }} ({{ this.tracedData?.personId }})
                    </div>
                    <div class="desgination">
                      {{ this.tracedData?.designation }}
                      <!-- {{ employee?.role }} -->
                    </div>
                    <div class="emp-subinfo" style="margin-top: 1em;">
                      <div class="mail">
                        <div>
                          <div class="box">
                            <img *ngIf="!isLightTheme" src="assets/Group 53.png" alt="email" />
                            <img *ngIf="isLightTheme" src="assets/Light-Theme/Group 53.png" alt="email" />
                          </div>
                        </div>
                        <div [matTooltip]="getEmail()" matTooltipPosition="right">
                          {{ selectedEmail }}
                        </div>
                      </div>
                      <div class="phone">
                        <div>
                          <div class="box">
                            <img *ngIf="!isLightTheme" src="assets/Group 54.png" alt="phone" />
                            <img *ngIf="isLightTheme" src="assets/Light-Theme/Group 54.png" alt="phone" />
                          </div>
                        </div>
                        <div>
                          {{ personalDetailById?.contact }}
                        </div>
                      </div>
                      <div class="location">
                        <div>
                          <div class="box">
                            <img *ngIf="!isLightTheme" src="assets/Group 55.png" alt="location" />
                            <img *ngIf="isLightTheme" src="assets/Light-Theme/Group 55.png" alt="location" />
                          </div>
                        </div>
                        <!-- <div>{{this.tracedData?.location}}</div> -->
                        <div>{{ personalDetailById?.spaceName }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="col-xl-6.5 .offset-xl-1 padding-left-zero row"
                style="padding-right: 0px !important;"
                [ngStyle]="{ 'margin-top': pageExpand ? '-5px' : '' }"
              >
                <div
                  class="quarantine-header-alert-col"
                  [ngClass]="pageExpand ? 'col-xl-10' : 'col-xl-12'"
                  *ngIf="tracedData?.status === 'InQuarantine' || tracedData?.status === 'In Quarantine'"
                  style="padding-left: 0px; padding-right: 0px;"
                >
                  <div
                    class="quarantine-header-alert"
                    style="width: fit-content;"
                    [ngStyle]="{ 'margin-right': pageExpand ? '-56px' : '' }"
                  >
                    <div class="access-data">
                      <span class="access"> Access : </span><span class="bold">Disabled </span>
                    </div>
                    <span class="color-grey">
                      Since {{ selectedIdData.quarantineStartDate | date: 'dd MMM yyyy' | uppercase }} </span
                    >
                    <div class="status-dataq">
                      <span class="access"><span class="sep-line">|</span> Status:</span><span class="bold"> Reported & in Quarantine </span>
                    </div>
                    <span class="color-grey">
                      Upto {{ selectedIdData.quarantineEndDate | date: 'dd MMM yyyy' | uppercase }}</span
                    >
                  </div>
                </div>

                <div class="quarantine-header-alert-col" [ngClass]="pageExpand ? 'col-xl-12' : 'col-xl-12'" *ngIf="tracedData?.status === 'Exposed'">
                  <div
                    class="quarantine-header-alert"
                    style="width: 270px;"
                    [ngStyle]="{ 'margin-left': pageExpand ? '21vw' : '15vw' }"
                  >
                    <div class="access-data">
                      <span class="access"> Access : </span><span class="bold">Disabled </span>
                    </div>
                    <span class="color-grey">
                      Since {{ selectedIdData.reportedDate | date: 'dd MMM yyyy' | uppercase }} </span
                    >
                    <div class="status-data">
                    <span class="access"><span class="sep-line">|</span> Status:</span>
                    <span class="bold" style="color: #fccf5a !important;"> Exposed </span>
                    </div>

                  </div>
                </div>
                <!-- <div *ngIf="pageExpand" class="col-xl-2"></div>
                            <div *ngIf="pageExpand" class="close-expand"><img (click)="expandView()" src="assets/Group 99.png"
                                alt="" /></div>-->
                <div class="col-xl-4"></div>
                <div class="comp">
                <div class="col-xl-4" class="date-compliance">
                  <div class="compliance" [ngStyle]="{ 'padding-left': pageExpand ? '55px' : '' }">
                    <div class="complianceCount" style="text-align: left;">
                      <span
                        *ngIf="selectedIdData.reportedDate !== null || selectedIdData.reportedDate !== ''"
                        class="day"
                        ><span class="datespan">{{ selectedIdData.reportedDate | date: 'dd' }}</span>
                      </span>
                      <span
                        *ngIf="selectedIdData.reportedDate !== null || selectedIdData.reportedDate !== ''"
                        class="super-text"
                      >
                        <span>{{ selectedIdData.reportedDate | date: 'MMM' }}</span> <br />
                        <span>{{ selectedIdData.reportedDate | date: 'yyyy' }}</span>
                      </span>

                      <span *ngIf="selectedIdData.reportedDate === null || selectedIdData.reportedDate === ''"> -</span>
                    </div>

                    <div
                      class="complianceDiv"
                      [ngClass]="this.tracedData?.status === 'Exposed' ? 'exposed-border' : 'non-compliant-border'"
                    ></div>
                    <div class="complianceStatus">
                      <span>Reported on</span>
                      <span class="small-font">{{ selectedIdData.reportedDate | countDay }} Days ago</span>
                    </div>
                  </div>
                </div>
                <div class="date-comp">
                  <div class="compliance" [ngStyle]="{ 'padding-left': pageExpand ? '55px' : '' }">
                    <div class="complianceCount">
                      <span>{{ contactTraceCount }} </span>
                    </div>
                    <div
                      class="complianceDiv"
                      [ngClass]="this.tracedData?.status === 'Exposed' ? 'exposed-border' : 'non-compliant-border'"
                    ></div>
                    <div class="complianceStatus">
                      <span> People </span>
                      <span>Contacted</span>
                    </div>
                  </div>
                </div>
              </div>
                <!-- <div class="col-xl-4">
                            <div class="compliance">
                              <div class="complianceCount">
                                <span>0 1 </span>
                              </div>
                              <div
                                class="complianceDiv"
                                [ngClass]="this.tracedData?.status === 'Exposed' ? 'exposed-border' : 'non-compliant-border'"
                              ></div>
                              <div class="complianceStatus">
                                <span> Locations </span>
                                <span>Visited</span>
                              </div>

                            </div>
                          </div> -->
              </div>
            </div>
            <div class="textinfo" style="margin-left: 245px; margin-top: 10px; margin-bottom: 3px; font-weight: 500;">
              Contact Tracing Data of last {{ quarantineDay }} days ({{ lastNDays }} to {{ date }})
            </div>
          </div>
          <div class="expand" *ngIf= "isMobileView && showarrow" (click)=expandData()>
            <span *ngIf = expand><img src="assets/upward-arrow.svg" /></span>
            <span *ngIf = !expand><img src="assets/downward-arrow.svg" /></span>
          </div>
          <div class="listing-lvl1 quarantine-header" *ngIf="lvl1data">
            <div class="col-xl-12 padding-left-zero emp-card" style="display: flex; padding-right: 0px;">
              <div
                class="col-xl-5.5 padding-left-zero"
                style="display: flex;"
                [ngStyle]="{ 'margin-top': pageExpand ? '-5px' : '' }"
              >
                <div class="employee-details col-5" style="padding-left: 0px !important;">
                  <div
                    class="image"
                    [ngClass]="this.listTracedData?.status === 'Exposed' ? 'status-exposed' : 'status-quarantined'"
                  >
                    <span class="material-icons user-icon">account_circle</span>
                    <!-- <span class="material-icons user-icon">account_circle</span> -->
                  </div>
                  <div class="employee-contact col-7">
                    <div
                      *ngIf="this.listTracedData?.personName.length > 15"
                      [matTooltip]="getListName()"
                      matTooltipPosition="above"
                      style="width: max-content; margin-left: 15px;"
                      class="names"
                    >
                      {{ listSelectedName }} ({{ this.listTracedData?.personId }})
                    </div>
                    <div *ngIf="this.listTracedData?.personName.length <= 15" class="name" style="width: max-content;">
                      {{ this.listTracedData?.personName }} ({{ this.listTracedData?.personId }})
                    </div>
                    <div class="emp-subinfo" style="margin-top: 1em;">
                      <div class="location">
                        <div>
                          <div class="box">
                            <img *ngIf="!isLightTheme" src="assets/Group 55.png" alt="location" />
                            <img *ngIf="isLightTheme" src="assets/Light-Theme/Group 55.png" alt="location" />
                          </div>
                        </div>
                        <!-- <div>{{this.tracedData?.location}}</div> -->
                        <div>{{ listPersonalDetailById?.spaceName }}</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr class="remove-margin" />
          <div class="row">
            <div class="col-12 level-data">
              <div style="margin-bottom: 2%; padding-top: 0;">
                <!--<div class="zoom-controls">
                  <div class="graph-view" *ngIf="selectedTab === 1">
                    <span (click)="zoomIn()">&nbsp;+</span>&nbsp;&nbsp;
                    <span (click)="zoomOut()">&nbsp;-</span>
                  </div>

                </div>
                <div class="zoom-controls" *ngIf="!pageExpand">
                  <div class="list-view">
                    <span (click)="expandView()">&nbsp;&nbsp;=</span>
                  </div>
                </div>-->
                <div class="facilityHeader">
                  <div class="title">
                    <div class="zoom-controls" [ngStyle]="(isLandscape|| !isMobileView) ? { display: 'block' } : { display: 'none' }">
                      <div class="graph-view" *ngIf="selectedTab === 1" [ngStyle]="zoomControlesStyle()">
                        <span (click)="zoomIn()"><i class="material-icons" style="opacity: 1;">add</i></span>
                        <span (click)="zoomOut()"><i class="material-icons" style="opacity: 1;">remove</i></span>
                        <span (click)="resetView()"><i class="material-icons" style="opacity: 1;">replay</i></span>
                      </div>
                      <div class="list-view" *ngIf="!pageExpand">
                        <!--<span (click)="zoomFit()">&nbsp;<i class="material-icons">fullscreen</i>&nbsp;</span>-->

                        <span (click)="zoomFit()">&nbsp;<i class="material-icons expand-icon">code</i>&nbsp;</span>
                      </div>
                    </div>
                    <mat-tab-group
                      [selectedIndex]="selectedTab"
                      (selectedTabChange)="changeTab($event)"
                      class="remove-border"
                      [ngClass]="selectedTab === 1 ? 'qur-tab' : ''"
                    >
                      <mat-tab label="List View">
                        <ng-template matTabContent>
                          <!-- <div style="margin-left: 245px; margin-top: 10px; margin-bottom: 10px;">
                            Contact Tracing Data of last {{ quarantineDay }} days ({{ lastNDays }} to {{ date }})
                          </div> -->
                          <div *ngIf="responseDataRes">
                            <div *ngIf="responseDataRes.contactTraces[0].firstContactDetails">
                              <div class="row">
                                <div class="col-md-6 level-border-line" [ngClass]="{'displaying': level1}">
                                  <div class="level-label">1st level</div>
                                  <div class="mob-level-label">1ST LEVEL VIEW</div>
                                  <hr class="border-line" />
                                  <div>
                                    <div *ngFor="let level of level1Data; let i = index">
                                      <div
                                        class="level-row"
                                        [class.selectedBar]="i == selectedIndex1"
                                        style="padding: 20px 0px 20px 0px; cursor: pointer; height: 100px;"
                                        (click)="setLevel2(level, i)"
                                      >
                                        <div
                                          class="pl-1 row m-0"
                                          style="height: 60px;"
                                          [class.st_red]="level.status == 'In Quarantine'"
                                          [class.st_yellow]="level.status == 'Exposed'"
                                          [class.st_green]="level.status == 'Normal'"
                                        >
                                          <div class="col-md-10 level-info">
                                            <div class="level-person" style="font-size: 14px; font-weight: bold; font-color: #fff;">
                                              {{ level.personName }}
                                              ({{ level.personId }})
                                            </div>
                                            <div class="level-location" style="font-size: 14px; font-weight: 100; font-color: #fff;">
                                              {{ level.location }}
                                            </div>
                                            <div class="level-duration" style="font-size: 14px; font-weight: 100; font-color: #fff;">
                                              <span *ngIf="level.duration">{{ level.duration }} sec</span>
                                              <span *ngIf="!level.duration">{{ 0 }}</span>
                                            </div>
                                          </div>
                                          <div
                                            *ngIf="!isLightTheme"
                                            class="col-md-2 arrow"
                                            style="float: right; padding-top: 15px;"
                                          >
                                            <img
                                              *ngIf="level.firstContactDetails"
                                              src="../../../assets/accordin-right-arrow.png"
                                              width="15"
                                              height="15"
                                            />
                                          </div>
                                          <div
                                            *ngIf="isLightTheme"
                                            class="col-md-2 arrow"
                                            style="float: right; padding-top: 15px;"
                                          >
                                            <img
                                              *ngIf="level.firstContactDetails"
                                              src="../../../assets/Light-Theme/accordin-right-arrow.png"
                                              width="15"
                                              height="15"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                      <hr class="border-line" style="margin: 0px;" />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 level-border-line" [ngClass]="{'displaying': level2}">
                                  <div class="level-label">2nd level</div>
                                  <div class="mob-level-label">
                                    <img src="../../../../assets/Path 7285.svg" (click)="goBack()"> 2ND LEVEL VIEW
                                  </div>
                                  <hr class="border-line" />
                                  <div *ngIf="level2Data">
                                    <div *ngFor="let level of level2Data">
                                      <div class="level-row" style="padding: 20px 0px 20px 0px; height: 100px;">
                                        <div
                                          class="pl-1 row m-0"
                                          style="height: 60px;"
                                          [class.st_red]="level.status == 'In Quarantine'"
                                          [class.st_yellow]="level.status == 'Exposed'"
                                          [class.st_green]="level.status == 'Normal'"
                                        >
                                          <div class="col-md-10 level-info">
                                            <div class="level-person" style="font-size: 14px; font-weight: bold; font-color: #fff;">
                                              {{ level.personName }}
                                              ({{ level.duplicateId }})
                                            </div>
                                            <div class="level-location" style="font-size: 14px; font-weight: 100; font-color: #fff;">
                                              {{ level.location }}
                                            </div>
                                            <div class="level-duration" style="font-size: 14px; font-weight: 100; font-color: #fff;">
                                              <span *ngIf="level.duration">{{ level.duration }} sec</span>
                                              <span *ngIf="!level.duration">{{ 0 }}</span>
                                            </div>
                                          </div>
                                          <div class="col-md-2" style="float: right; padding-top: 15px;">
                                            <!-- <img src="../../../assets/accordin-right-arrow.png" width="15" height="15"> -->
                                          </div>
                                        </div>
                                      </div>
                                      <hr class="border-line" style="margin: 0px;" />
                                    </div>
                                  </div>
                                  <div *ngIf="!level2Data">
                                    <div
                                      style="
                                        padding-top: 100px;
                                        height: 200px;
                                        text-align: center;
                                        margin: 0px;
                                        color: #7a798a;
                                      "
                                    >
                                      <div>No contact tracing found for selected employee</div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!responseDataRes.contactTraces[0].firstContactDetails">
                              <div
                                style="
                                  padding-top: 100px;
                                  height: 200px;
                                  text-align: center;
                                  margin: 0px;
                                  color: #7a798a;
                                "
                              >
                                <div>No contact tracing found for selected employee</div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </mat-tab>
                      <mat-tab label="Graph View">
                        <ng-template matTabContent >
                          <!-- <div style="margin-left: 245px; margin-top: 10px; margin-bottom: 10px;">
                            Contact Tracing Data of last {{ quarantineDay }} days ({{ lastNDays }} to {{ date }})
                          </div> -->
                          <span *ngIf="isMobileView && !isLandscape">Please rotate your screen to get a better visual of the graph.</span>
                          <div [ngStyle]="(isLandscape|| !isMobileView)? { display: 'block' } : { display: 'none' }">
                            <div>
                              <div class="row" style="overflow-x: scroll;" id="graphCollapse">
                                <div
                                  class="col-md-12"
                                  style="position: relative; top: 0; bottom: 0; left: 0; right: 0;"
                                >
                                  <!-- <div #orgChart id="orgChart" style="width: 100%; height: 100%;"></div> -->

                                  <pan-zoom [config]="panzoomConfig" id="zoomEle">
                                    <div
                                      #orgChart
                                      id="orgChart"
                                      style="width: 100%;"
                                      [ngStyle]="{
                                        height: responseDataRes.contactTraces[0].firstContactDetails ? '45vh' : '100%'
                                      }"
                                    ></div>
                                  </pan-zoom>
                                </div>
                              </div>
                            </div>
                            <div *ngIf="!responseDataRes.contactTraces[0].firstContactDetails">
                              <div
                                style="
                                  padding-top: 100px;
                                  height: 200px;
                                  text-align: center;
                                  margin: 0px;
                                  color: #7a798a;
                                "
                              >
                                <div>No contact tracing found for selected employee</div>
                              </div>
                            </div>
                          </div>
                        </ng-template>
                      </mat-tab>
                    </mat-tab-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
        <div class="scroll-to-top">
          <img src="../../../../assets/Group 7526.svg" (click)="goUp()" >
        </div>
      </div>
    </div>

    <ng-template #emptyResponse>
      <div class="col-9 graph-card" style="padding-left: 0px !important;" [ngClass]="{'displaying': displaycard}">
        <mat-card class="no-contact-section">
          <div style="display: flex; justify-content: center; align-items: center; flex-direction: column;">
            <div>
              <mat-icon class="no-result-text">error_outline</mat-icon>
            </div>
            <div class="no-result-text" style="font-size: 18px;">No contact tracing was found</div>
          </div>
        </mat-card>
      </div>
    </ng-template>
  </div>
</div>
