import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'addDay',
})
export class AddDayPipe implements PipeTransform {
  transform(date: any): any {
    let dateObj = new Date(date);
    return dateObj.setDate(dateObj.getDate() + 14);
  }
}
