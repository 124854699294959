<div class="" fxLayout="column" fxLayoutAlign="center" style="width: 100%;">
    <!-- <mat-toolbar fxFlex="70px" class="navbar" color="primary">
      <div fxLayoutAlign="start center">
        <img src="assets/Logo.png" alt="Cognizant Safe Buildings" />
      </div>
    </mat-toolbar> -->
    <div
      fxFlex
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="1.5rem"
      class="mat-typography background login-wrapper"
    >
      <div class="login-container" fxLayout="row" fxLayoutAlign="center">
        <mat-card fxFlex="90%" fxFlex.sm="66%" fxFlex.md="50%" fxFlex.gt-md="33%" class="login-box" class="foreground">
          <div class="login-text">LOGIN</div>
          <mat-divider></mat-divider>
          <form (ngSubmit)="login()" [formGroup]="loginForm" novalidate>
            <div class="login-error" [hidden]="!error || isLoading || isIncorrect" translate>
              Username or password incorrect.
            </div>
            <br />
            <div class="login-fields" fxLayout="column">
              <label>USERNAME</label>
              <mat-form-field [hideRequiredMarker]="true" appearance="outline">
                <!-- <mat-label>Username</mat-label> -->
                <input type="text" matInput formControlName="username" autocomplete="username" required />
                <!-- [placeholder]="'Username' | translate" required /> -->
                <mat-error *ngIf="loginForm.controls.username.invalid && loginForm.controls.username.touched">
                  <span translate>Username is required</span>
                </mat-error>
              </mat-form-field>
              <label>PASSWORD</label>
              <mat-form-field [hideRequiredMarker]="true" appearance="outline">
                <!-- <mat-label>Password</mat-label> -->
                <input type="password" matInput formControlName="password" autocomplete="current-password" required />
                <!-- [placeholder]="'Password' | translate" required /> -->
                <mat-error *ngIf="loginForm.controls.password.invalid && loginForm.controls.password.touched">
                  <span translate>Password is required</span>
                </mat-error>
              </mat-form-field>
              <mat-checkbox color="primary" formControlName="remember" translate>Remember Me</mat-checkbox>
              <br />
              <button mat-raised-button color="primary" type="submit">
                <!-- [disabled]="loginForm.invalid || isLoading" -->
                <!-- <app-loader class="inline-loader" [isLoading]="isLoading"></app-loader> -->
                <span translate>SIGN IN</span>
              </button>
            </div>
          </form>
        </mat-card>
      </div>
    </div>
  </div>
  