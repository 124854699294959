<div class="root">
  <div class="title">
    <div>Bulk Upload</div>
    <div class="remove-icon" (click)="cancel()"><i class="material-icons" style="font-size: 30px;"> cancel</i></div>
  </div>
  <hr class="border-line" />
  <div class="uploadText">
    Upload Document &nbsp;&nbsp;&nbsp; <span class="red" style="font-size: 12px;">{{ fileError }}</span>
    <span class="red" style="font-size: 12px;">{{ uploadFailure }}</span>
  </div>
  <div class="message">
    <div class="wrapper browse" (click)="fileInput.click()">
      <span class="filename"> {{ fileName }} </span>
      <span class="browse-text">BROWSE</span>
      <input
        class=""
        #fileInput
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        (change)="onFileChange($event)"
        style="display: none;"
      />
    </div>
    <button mat-button class="btn-save" [disabled]="!fileName" color="primary" (click)="upload()">
      <img src="assets/Group 983.png" style="width: 13px; height: 14px; margin-top: 10px; margin-right: 5px;" />
      UPLOAD
    </button>
    <div class="or">OR</div>
    <button mat-button class="btn-download" color="primary" (click)="download()">
      Download Template
    </button>
  </div>
</div>
