<mat-card>
  <div class="title">
    <div>Publish Form</div>
    <div>
      <img
        *ngIf="isLightTheme === true"
        (click)="closePublishForm('close')"
        src="assets/Light-Theme/Group 99.png"
        alt=""
      />
      <img
        *ngIf="isLightTheme === false"
        (click)="closePublishForm('close')"
        src="assets/Group 99.png"
        alt=""
      />
    </div>
  </div>
  <div class="seperator-space"></div>
  <mat-divider></mat-divider>
  <div class="seperator-space"></div>
  <!--<div *ngIf="isLiveSurveyExists">
    <p class="alert-msg">This survey cannot be published since another survey is active</p>
    <div class="close-btn">
      <button mat-button (click)="publishLater()" class="pub-btn">Save</button>
    </div>
  </div>-->
  <div>
    <!--<div *ngIf="!isLiveSurveyExists">-->
    <p class="">Form has been saved successfully! Publish the form now</p>
    <form [formGroup]="publishDetails">
      <div>
        <div class="label">
          Form start and end date
          <span class="red" *ngIf="isSameDate"
            >&nbsp;&nbsp;Start and end date should not be same</span
          >
        </div>
        <!-- bsDatepicker -->
        <div
          class="display"
          [minDate]="disableToday ? nextDay : today"
          [bsConfig]="{
            minMode: 'day',
            placement: 'top',
            showWeekNumbers: false,
            adaptivePosition: true,
            isAnimated: true,
            clearBtn: true
          }"
          bsDaterangepicker
          (bsValueChange)="onStartDate($event)"
        >
          <div class="date-placeholder">
            <div style="padding-top: 8px; padding-left: 10px">
              {{ selectedStartDate }}
            </div>
            <div>
              <i class="material-icons primary-color" style="margin-top: 5px"
                >date_range</i
              >
            </div>
          </div>
        </div>
      </div>
      <div class="date-override" *ngIf="isLiveSurveyExists">
        <div style="margin: 10px">
          <span class="img"><img src="assets/Group 1401.png" alt="" /></span>
          <div class="orverride-msg">
            Form <b>{{ liveConflictObj.formTitle }}</b> already exists from
            <b
              >{{ liveConflictObj.startDate | date: "dd MMM  yyyy" }} -
              {{ liveConflictObj.endDate | date: "dd MMM  yyyy" }}</b
            >. Publishing new form will override the existing form.
          </div>
        </div>
      </div>
      <div class="date-override" *ngIf="conflictSurveyIdArr.length > 0">
        <div style="margin: 10px">
          <span class="img"><img src="assets/Group 1401.png" alt="" /></span>
          <div class="orverride-msg">
            Form <b>{{ conflictSurveyObj.formTitle }}</b> already exists from
            {{ conflictSurveyObj.startDate | date: "dd MMM  yyyy" }} -
            {{ conflictSurveyObj.endDate | date: "dd MMM  yyyy" }}. Publishing
            new form will override the existing form and change its status to
            not published.
          </div>
        </div>
      </div>
      <!--<div>
      <p>Frequency</p>
      <mat-radio-group formControlName="surveryFrequency">
        <mat-radio-button value='daily'>Daily</mat-radio-button>
        <mat-radio-button value='weekly'>Once a week</mat-radio-button>
        <mat-radio-button value='monthly'>Once a month</mat-radio-button>
      </mat-radio-group>
      <mat-checkbox class="label" formControlName="includeWeekend">
        Include Weekend
      </mat-checkbox>
    </div>-->
    </form>
    <!--<div class="publish-btn">
      <button mat-button (click)="publishLater()">Publish Later</button>
    </div>-->
    <div class="save-publish-btn">
      <button
        [disabled]="isDisablePublishButton"
        mat-button
        (click)="savePublish()"
        class="pub-btn"
      >
        Publish
      </button>
    </div>
  </div>
</mat-card>
