import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuarantineService } from '../../quarantine.service';
@Component({
  selector: 'app-denied-list',
  templateUrl: './denied-list.component.html',
  styleUrls: ['./denied-list.component.scss']
})
export class DeniedListComponent implements OnInit {

  employeesList: any = [];
  employeesListOne = [
    {
      name: 'RAM one',
      employeeId: 13121,
      email: 'ramone@test.com',
      accessDeniedDate: '2020-07-15T09:00:00',
    },
    {
      name: 'RAM Two',
      employeeId: 13122,
      email: 'ramtwo@test.com',
      accessDeniedDate: '2020-07-14T13:15:00',
    },
    {
      name: 'RAM Three',
      employeeId: 13123,
      email: 'ramthree@test.com',
      accessDeniedDate: '2020-07-16T10:12:00',
    },
    {
      name: 'RAM Four',
      employeeId: 13124,
      email: 'ramfour@test.com',
      accessDeniedDate: '2020-07-16T12:00:00',
    },
    {
      name: 'RAM one',
      employeeId: 13121,
      email: 'ramone@test.com',
      accessDeniedDate: '2020-07-15T09:00:00',
    },
    {
      name: 'RAM Two',
      employeeId: 13122,
      email: 'ramtwo@test.com',
      accessDeniedDate: '2020-07-14T13:15:00',
    },
    {
      name: 'RAM Three',
      employeeId: 13123,
      email: 'ramthree@test.com',
      accessDeniedDate: '2020-07-16T10:12:00',
    },
    {
      name: 'RAM Four',
      employeeId: 13124,
      email: 'ramfour@test.com',
      accessDeniedDate: '2020-07-16T12:00:00',
    },
  ];
  displayedColumns: any[] = ['employeeName', 'employeeId', 'emailId', 'deniedDate'];
  dataSource = new MatTableDataSource();
  isLightTheme: boolean = environment.isLightTheme;
  constructor(
    public service: QuarantineService,
    public dialogRef: MatDialogRef<DeniedListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit(): void {
    this.loadDeniedList();
  }
  loadDeniedList() {
    this.service.getAccessDeniedList(this.data.surveyId, this.data.timeZone).subscribe(
      (deniedList: any) => {
        console.log('RAM RESPONSE');
        console.log(deniedList);
        this.employeesList = deniedList[0].employeesList;
        this.dataSource = new MatTableDataSource(this.employeesList);
      },
      (err: any) => {
        console.log(err);
      }
    );
    console.log(this.employeesList);
    if (this.employeesList) {
      console.log('RAM data array');
      //this.dataSource = new MatTableDataSource(this.employeesList);
    }
  }
  close() {
    this.dialogRef.close();
  }
}
