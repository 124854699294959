<div class="employee-full">
<div class="employee">
    <div class="summary">
      <mat-card>
        <div class="flex">
          <div class="col-xl-6 flex" style="padding: 0px;">
            <div class="title">EMPLOYEE COMPLIANCE HISTORY</div>
            <div class="currentDate">&nbsp;|&nbsp;&nbsp;{{ date }}</div>
          </div>
          <div class="col-xl-6" style="padding: 0px;" *ngIf="contactTracing?.status == 'in Quarantine'">
            <div class="access-status">
              <span class="access"
                >Access:
                <span
                  class="disable"
                  *ngIf="
                    contactTracing?.isAccessEnabled == 'False' || contactTracing?.isAccessEnabled == false;
                    else cond1
                  "
                >
                  Disabled
                </span>
                <ng-template #cond1>
                  <span class="disable">Enabled</span>
                </ng-template>
                <span id="quarantine-date">
                  Since {{ contactTracing?.quarantineStartDate | date: 'dd MMM yyyy' | uppercase }}
                </span>
                <span class="access"> | Status:</span>
                <span class="disable" *ngIf="contactTracing?.status == 'in Quarantine'">
                  Reported & {{ contactTracing?.status }}</span
                >
                <span id="quarantine-date" >
                  <!-- Upto 12 JUN 2020 -->
                  Upto {{ contactTracing?.quarantineEndDate | date: 'dd MMM yyyy' | uppercase }}
                </span>
              </span>
            </div>
          </div>
          <div class="col-xl-6" style="padding: 0px;" *ngIf="contactTracing?.status == 'Exposed'">
            <div class="access-status">
              <span class="access"
                >Access:
                <span
                  class="disable"
                  *ngIf="
                    contactTracing?.isAccessEnabled == 'False' || contactTracing?.isAccessEnabled == false;
                    else cond1
                  "
                >
                  Disabled
                </span>
                <ng-template #cond1>
                  <span class="disable">Enabled</span>
                </ng-template>
                <span id="quarantine-date" class="quarantine-date">
                  Since {{ contactTracing?.reportedDate | date: 'dd MMM yyyy' | uppercase }}
                </span>
                <span class="access">| Status:</span>
  
                <span class="disable amber" *ngIf="contactTracing?.status == 'Exposed'">{{
                  contactTracing?.status
                }}</span>
                <!-- style="color: #fccf5a !important;"-->
              </span>
            </div>
          </div>
        </div>
  
        <div class="row details">
          <div class="col-xl-5" style="padding-left: 0;">
            <div class="employee-details">
              <div class="image">
                <!-- <img src="assets/Mask Group 4.png" alt="User Image" /> -->
                <span class="material-icons user-icon">account_circle</span>
              </div>
              <div class="employee-contact">
                <div class="name">
                  <span *ngIf="employee?.name.length > 23" [matTooltip]="getName()" matTooltipPosition="above">{{
                    selectedName
                  }}</span>
                  <span *ngIf="employee?.name.length <= 23">{{ employee?.name }}</span>
                  ({{ employee?.employeeId }})
                  <!-- <span *ngIf="!employee?.permit" class="status red">Status - Disabled</span>
                  <span *ngIf="employee?.permit" class="status green">Status - Enabled</span> -->
                  <span
                    *ngIf="
                      isShowQRcode && contactTracing?.status != 'in Quarantine' && contactTracing?.status != 'Exposed'
                    "
                    class="qrcode"
                    (click)="openDialog()"
                    ><img *ngIf="!isLightTheme" src="../../../assets/qr-code.png" alt="qrcode" /><img
                      *ngIf="isLightTheme"
                      src="../../../assets/Light-Theme/qr-code (2).png"
                      alt="qrcode"
                  /></span>
                </div>
                <div class="desgination">
                  {{ employee?.role }}
                </div>
                <div class="mail">
                  <div>
                    <div class="box">
                      <img *ngIf="!isLightTheme" src="assets/Group 53.png" alt="email" />
                      <img *ngIf="isLightTheme" src="assets/Light-Theme/Group 53.png" alt="email" />
                    </div>
                  </div>
                  <div *ngIf="employee?.email.length <= 37">{{ employee?.email }}</div>
                  <div *ngIf="employee?.email.length > 37" [matTooltip]="getEmail()" matTooltipPosition="above">
                    {{ selectedEmail }}
                  </div>
                </div>
                <div class="phone">
                  <div>
                    <div class="box">
                      <img *ngIf="!isLightTheme" src="assets/Group 54.png" alt="phone" />
                      <img *ngIf="isLightTheme" src="assets/Light-Theme/Group 54.png" alt="phone" />
                    </div>
                  </div>
                  <div>{{ employee?.contact }}</div>
                </div>
                <div class="location">
                  <div>
                    <div class="box">
                      <img *ngIf="!isLightTheme" src="assets/Group 55.png" alt="location" />
                      <img *ngIf="isLightTheme" src="assets/Light-Theme/Group 55.png" alt="location" />
                    </div>
                  </div>
                  <div>{{ employee?.spaceName }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-7 row justify-content-end custom-col" style="padding-left: 0; align-items: center;">
            <div class="col-xl-4 custom-col-2" >
              <div class="compliance">
                <div class="complianceCount">
                  <span *ngIf="temperatureCurent"
                    >{{ temperatureCurent }} <span *ngIf="!!temperatureCurent && temperatureCurent != '-'">°F</span></span
                  >
                  <span *ngIf="!temperatureCurent">-</span>
                </div>
                <div class="complianceType">Current Temperature</div>
                <div
                  class="complianceDiv"
                  [ngClass]="{
                    'compliant-border': temperatureCurentComplaint == true,
                    'non-compliant-border': temperatureCurentComplaint == false
                  }"
                ></div>
                <div class="complianceStatus compliant" *ngIf="temperatureCurentComplaint">
                  Compliant
                </div>
                <div class="complianceStatus non-compliant" *ngIf="!temperatureCurentComplaint">
                  Non Compliant
                </div>
              </div>
            </div>
            <div class="col-xl-4 custom-col-2" >
              <div class="compliance">
                <div class="complianceCount">
                  <span *ngIf="physicalAlert">{{ physicalAlert }}</span>
                  <span *ngIf="!physicalAlert"> 0 </span>
                </div>
                <div class="complianceType">Physical Distance Alerts</div>
                <div
                  class="complianceDiv"
                  [ngClass]="{
                    'compliant-border': physicalAlertComplianceFlag == true,
                    'non-compliant-border': physicalAlertComplianceFlag == false
                  }"
                ></div>
                <div class="complianceStatus compliant" *ngIf="physicalAlertComplianceFlag">
                  Compliant
                </div>
                <div class="complianceStatus non-compliant" *ngIf="!physicalAlertComplianceFlag">
                  Non Compliant
                </div>
              </div>
            </div>
            <div class="col-xl-4 custom-col-2" >
              <div class="compliance">
                <div class="complianceCount">
                  <span *ngIf="lastHandwash"> {{ lastHandwash }}</span>
                  <span *ngIf="lastHandwash" class="hour">Hrs Ago</span>
                  <span *ngIf="!lastHandwash">0 </span>
                </div>
                <div class="complianceType">Last Handwash</div>
                <div
                  class="complianceDiv"
                  [ngClass]="{
                    'compliant-border': lastHandwashComplianceFlag == true,
                    'non-compliant-border': lastHandwashComplianceFlag == false
                  }"
                ></div>
                <div class="complianceStatus compliant" *ngIf="lastHandwashComplianceFlag">
                  Compliant
                </div>
                <div class="complianceStatus non-compliant" *ngIf="!lastHandwashComplianceFlag">
                  Non Compliant
                </div>
              </div>
            </div>
          </div>
          <div
            class="col-xl-12"
            *ngIf="
              HQ_enable && healthQuestionnaire && (contactTracing?.status == '' || contactTracing?.status === 'Normal')
            "
          >
            <div class="survey-link">
              <div class="retake" (click)="retakeSurvey()">RETAKE SELF DECLARATION &nbsp; &nbsp;</div>
              <!--<div class="copy">&nbsp; &nbsp; COPY FORM LINK</div> -->
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  
    <div class="detailedSummary">
      <div class="selector">
        <mat-form-field>
          <div class="float-label">Category</div>
          <mat-select [(value)]="tabSelected" (selectionChange)="tabChange($event)">
            <mat-option *ngFor="let tab of tabs" [value]="tab">
              {{ tab }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="dateinfo">Data of last 14 days ( {{ last14Days }} to {{ date }} )</div>
      <mat-tab-group #tabGroup (selectedIndexChange)="setDataSource($event)">
        <mat-tab *ngIf="TS_enable" label="Thermal Screening" style="height: 100px !important;">
          <mat-card>
            <div class="ttable">
            <table
              mat-table
              *ngIf="dataSource.filteredData.length > 0"
              [dataSource]="dataSource"
              class="temperaturetable mat-elevation-z8"
              style="box-shadow: none;"
            >
              <!--<ng-container matColumnDef="eventId">
                <th mat-header-cell *matHeaderCellDef>Event ID RAM</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.eventId }}
                  <span *ngIf="!!element.overrideValue">*</span>
                </td>
              </ng-container>-->
  
              <ng-container matColumnDef="screenAt">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;">Screened At</th>
                <td mat-cell *matCellDef="let element">
                  <div
                    class="status status-line"
                    [ngClass]="{ greenBg: element.status == true, redBg: element.status == false }"
                  ></div>
                  {{ element.screenAt }}
                  <span *ngIf="element.screenAt && element.vendorName"> - </span>
                  {{ element.vendorName }}
                </td>
              </ng-container>
  
              <ng-container matColumnDef="temperature">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;">Temperature</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.temperature }}
                  <span *ngIf="!!element.overrideValue">
                    <img src="../../../assets/Group 251.png" />
                  </span>
                  <span *ngIf="!!element.overrideValue">{{ element.overrideValue }}</span>
                  <span *ngIf="!!element.overrideValue">
                    <img style="margin-left: 5px;" src="../../../assets/Group 250.png" />
                  </span>
                </td>
              </ng-container>
  
              <ng-container matColumnDef="faceMask">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;">Face Mask Detected</th>
                <td mat-cell *matCellDef="let element">
                  <div *ngIf="element.isMaskDetected == true">YES</div>
                  <div *ngIf="element.isMaskDetected == false">NO</div>
                </td>
              </ng-container>
  
              <!-- Symbol Column -->
              <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;">Timestamp</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.timestamp | date: 'dd MMM, hh:mm' }}
                  {{ element.timestamp | date: 'a' | lowercase }}
                </td>
              </ng-container>
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef style="width: 20%;" class="status-header">Status</th>
                <td mat-cell *matCellDef="let element" class="status-container">
                  <div
                    class="status"
                    [ngClass]="{ greenBg: element.status == true, redBg: element.status == false }"
                  ></div>
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns" style="height: 48px;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
            <div *ngIf="dataSource.filteredData.length === 0" class="no-records">No records available</div>
          </mat-card>
          <mat-paginator class="paging1"
            #paginator
            [hidden]="dataSource.filteredData.length === 0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </mat-tab>
        <mat-tab *ngIf="PD_enable" label="Physical Distancing" class="physical-styles" style="height: 100px !important;">
          <mat-card>
            <div class="ttable">
            <table
              mat-table
              *ngIf="dataSource1.filteredData.length > 0"
              [dataSource]="dataSource1"
              class="temperaturetable mat-elevation-z8"
              style="box-shadow: none;"
            >
              <!--- Note that these columns can be defined in any order.
                                          The actual rendered columns are set as a property on the row definition" -->
  
              <!-- Position Column -->
              <!-- <ng-container matColumnDef="eventId">
                <th mat-header-cell *matHeaderCellDef>Event ID</th>
                <td mat-cell *matCellDef="let element">{{ element.eventId }}</td>
              </ng-container> -->
  
              <!-- Name Column -->
              <!-- <ng-container matColumnDef="spaceName">
                <th mat-header-cell *matHeaderCellDef>Entry Point</th>
                <td mat-cell *matCellDef="let element">{{ element.spaceName }}</td>
              </ng-container> -->
  
              <!-- Symbol Column -->
  
              <ng-container matColumnDef="secondaryPersonName">
                <th mat-header-cell *matHeaderCellDef>Secondary Person Name</th>
                <td mat-cell *matCellDef="let element">{{ element.secondaryPersonName }}</td>
              </ng-container>
  
              <ng-container matColumnDef="secondaryPersonId">
                <th mat-header-cell *matHeaderCellDef>Secondary Person ID</th>
                <td mat-cell *matCellDef="let element">{{ element.secondaryPersonId }}</td>
              </ng-container>
  
              <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef>Duration (in sec)</th>
                <td mat-cell *matCellDef="let element">{{ element.duration }} sec</td>
              </ng-container>
  
              <ng-container matColumnDef="timeStamp">
                <th mat-header-cell *matHeaderCellDef>Timestamp</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.timeStamp | date: 'dd MMM, hh:mm' }}
                  {{ element.timeStamp | date: 'a' | lowercase }}
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="complianceFlag">
                <th mat-header-cell *matHeaderCellDef>Compliance</th>
                <td mat-cell *matCellDef="let element">
                  <div
                    class="status"
                    [ngClass]="{ greenBg: element.complianceFlag == true, redBg: element.complianceFlag == false }"
                  ></div>
                </td>
              </ng-container> -->
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns1" style="height: 48px;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
            </table>
          </div>
            <div *ngIf="dataSource1.filteredData.length === 0" class="no-records">No records available</div>
          </mat-card>
          <mat-paginator class="paging2"
            #paginator1 
            [hidden]="dataSource1.filteredData.length === 0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </mat-tab>
        <mat-tab *ngIf="HH_enable" label="Hand Hygiene" style="height: 100px !important;" s>
          <mat-card>
            <div class="ttable">
            <table
              mat-table
              *ngIf="dataSource2.filteredData.length > 0"
              [dataSource]="dataSource2"
              class="temperaturetable mat-elevation-z8"
              style="box-shadow: none;"
            >
              <!--- Note that these columns can be defined in any order.
                                The actual rendered columns are set as a property on the row definition" -->
  
              <!-- Position Column -->
              <ng-container matColumnDef="eventId">
                <th mat-header-cell *matHeaderCellDef>Event ID</th>
                <td mat-cell *matCellDef="let element">
                  <div
                  class="status status-line"
                  [ngClass]="{ greenBg: element.complianceFlag == true, redBg: element.complianceFlag == false }"
                ></div>
                {{ element.eventId }}</td>
              </ng-container>
  
              <!-- Name Column -->
              <!-- <ng-container matColumnDef="spaceName">
                <th mat-header-cell *matHeaderCellDef>Entry Point</th>
                <td mat-cell *matCellDef="let element">{{ element.spaceName }}</td>
              </ng-container> -->
  
              <!-- Symbol Column -->
              <ng-container matColumnDef="timeStamp">
                <th mat-header-cell *matHeaderCellDef>Timestamp</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.timeStamp | date: 'dd MMM, hh:mm' }}
                  {{ element.timeStamp | date: 'a' | lowercase }}
                </td>
              </ng-container>
              <ng-container matColumnDef="complianceFlag">
                <th mat-header-cell *matHeaderCellDef class="status-header">Status</th>
                <td mat-cell *matCellDef="let element" class="status-container">
                  <div
                    class="status"
                    [ngClass]="{ greenBg: element.complianceFlag == true, redBg: element.complianceFlag == false }"
                  ></div>
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns2" style="height: 48px;"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
            </table>
          </div>
            <div *ngIf="dataSource2.filteredData.length === 0" class="no-records">No records available</div>
          </mat-card>
          <mat-paginator class="paging2"
            #paginator2
            [hidden]="dataSource2.filteredData.length === 0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="getHandHygineData($event)"
          >
          </mat-paginator>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
  
  <div class="employee-view">
    <!-- <app-employee-table></app-employee-table> -->
    <mat-card>
      <div class="row">
        <div class="col-4">
          <span class="material-icons user-icon">account_circle</span>
        </div>
        <div class="col-8">
          <div class="emp-name row">
            <div class="col-9">
              <span>{{ employee?.name }}</span> ({{ employee?.employeeId }})
            </div>
            <div
              class="col-3"
              *ngIf="isShowQRcode && contactTracing?.status != 'in Quarantine' && contactTracing?.status != 'Exposed'"
            >
              <img class="qrcode" src="../../../assets/mobile/qr_code.png" (click)="openDialog()" />
            </div>
          </div>
          <div class="role">{{ employee?.role }}</div>
          <div class="email">
            <div><img src="../../../assets/mobile/email.png" /></div>
            <div>{{ employee?.email }}</div>
          </div>
          <div class="email">
            <div><img src="../../../assets/mobile/phone.png" /></div>
            <div>{{ employee?.contact }}</div>
          </div>
          <div class="email">
            <div><img src="../../../assets/mobile/location.png" /></div>
            <div>{{ employee?.spaceName }}</div>
          </div>
        </div>
        <div
          class="access-status"
          *ngIf="contactTracing?.status == 'in Quarantine' || contactTracing?.status == 'Exposed'"
        >
          <div>
            Access:
            <strong
              *ngIf="contactTracing?.isAccessEnabled == 'False' || contactTracing?.isAccessEnabled == false; else cond1"
            >
              Disabled
            </strong>
            <ng-template #cond1>
              <strong>Enabled</strong>
            </ng-template>
            <span id="quarantine-date" *ngIf="contactTracing?.status == 'in Quarantine'">
              Since {{ contactTracing?.quarantineStartDate | date: 'dd MMM yyyy' | uppercase }}
            </span>
            <span id="quarantine-date" *ngIf="contactTracing?.status == 'Exposed'">
              Since {{ contactTracing?.reportedDate | date: 'dd MMM yyyy' | uppercase }}
            </span>
          </div>
          <div>
            Status:
            <strong *ngIf="contactTracing?.status == 'in Quarantine'"> Reported & {{ contactTracing?.status }}</strong>
            <strong *ngIf="contactTracing?.status == 'Exposed'" style="color: #fccf5a !important;">{{
              contactTracing?.status
            }}</strong>
            <span id="quarantine-date" *ngIf="contactTracing?.status == 'in Quarantine'">
              Upto {{ contactTracing?.quarantineEndDate | date: 'dd MMM yyyy' | uppercase }}
            </span>
          </div>
        </div>
        <div
          class="col-12 row"
          [ngClass]="{ align: !TS_enable || !PD_enable || HH_enable }"
          style="margin-left: 0px; margin-right: 0px;"
        >
          <div class="col-4" *ngIf="TS_enable" style="padding-left: 0px; padding-right: 0px;">
            <div class="compliance">
              <div class="complianceCount">
                {{ temperatureCurent }} <span *ngIf="!!temperatureCurent && temperatureCurent != '-'">°F</span>
              </div>
              <div class="complianceType">Current</div>
              <div class="complianceType">Temperature</div>
              <div
                class="complianceDiv"
                [ngClass]="{
                  'compliant-border': temperatureCurentComplaint == true,
                  'non-compliant-border': temperatureCurentComplaint == false
                }"
              ></div>
              <div class="complianceStatus compliant" *ngIf="temperatureCurentComplaint">
                Compliant
              </div>
              <div class="complianceStatus non-compliant" *ngIf="!temperatureCurentComplaint">
                Non Compliant
              </div>
            </div>
          </div>
          <div class="col-4" style="padding-left: 0px; padding-right: 0px;">
            <div class="compliance">
              <div class="complianceCount">
                <span *ngIf="physicalAlert">{{ physicalAlert }}</span>
                <span *ngIf="!physicalAlert"> 0 </span>
              </div>
  
              <div class="complianceType">Physical</div>
              <div class="complianceType">Distance Alerts</div>
  
              <div
                class="complianceDiv"
                [ngClass]="{
                  'compliant-border': physicalAlertComplianceFlag == true,
                  'non-compliant-border': physicalAlertComplianceFlag == false
                }"
              ></div>
              <div class="complianceStatus compliant" *ngIf="physicalAlertComplianceFlag">
                Compliant
              </div>
              <div class="complianceStatus non-compliant" *ngIf="!physicalAlertComplianceFlag">
                Non Compliant
              </div>
            </div>
          </div>
          <div class="col-4" style="padding-left: 0px; padding-right: 0px;">
            <div class="compliance">
              <div class="complianceCount">
                <span *ngIf="lastHandwash"> {{ lastHandwash }}</span>
                <span *ngIf="lastHandwash" class="hour">Hrs Ago</span>
                <span *ngIf="!lastHandwash">0 </span>
                <!-- <span *ngIf="lastHandwash" class="hour">Hrs</span> -->
              </div>
              <div class="complianceType">
                Last <br />
                Handwash
              </div>
              <div
                class="complianceDiv"
                [ngClass]="{
                  'compliant-border': lastHandwashComplianceFlag == true,
                  'non-compliant-border': lastHandwashComplianceFlag == false
                }"
              ></div>
              <div class="complianceStatus compliant" *ngIf="lastHandwashComplianceFlag">
                Compliant
              </div>
              <div class="complianceStatus non-compliant" *ngIf="!lastHandwashComplianceFlag">
                Non Compliant
              </div>
            </div>
          </div>
          <div
            class="line-seperator"
            *ngIf="
              HQ_enable && healthQuestionnaire && (contactTracing?.status == '' || contactTracing?.status === 'Normal')
            "
          ></div>
          <div
            class="col-12 mobile-survey"
            *ngIf="
              HQ_enable && healthQuestionnaire && (contactTracing?.status == '' || contactTracing?.status === 'Normal')
            "
          >
            <div class="survey-text">
              <div class="retake" (click)="retakeSurvey()" style="cursor: pointer;">
                RETAKE SELF DECLARATION
              </div>
              <!--<div class="seperator"></div>
              <div class="copy" style="cursor: pointer;">
                COPY FORM<br />
                LINK
              </div>-->
            </div>
          </div>
        </div>
      </div>
    </mat-card>
    <app-employee-table></app-employee-table>
  </div>
</div>
  