import {
  ChangeDetectorRef,
  Component,
  HostBinding,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '@env/environment';
import { AppService } from './app.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { CredentialsService } from './login/credentials.service';
import { NavigationEnd, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { MsalService } from '@azure/msal-angular';
import * as config from './@shared/services/msal.config';
import { HTTPStatus } from './workspace-experience/@core/services/auth-interceptor';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'core-art';
  isAuthenticated: any;
  authenticate: boolean = false;

  loader: boolean;
  myTimerAzure: any;
  counter: any = 0;
  newDate: Date;
  tokenexpVal: Date;

  constructor(
    private appService: AppService,
    private location: Location,
    private credentialsService: CredentialsService,
    private router: Router,
    private malauthService: MsalService,
    private httpStatus: HTTPStatus,
    private cdRef: ChangeDetectorRef
  ) {
    if (
      location.path() == '/workspace/my-bookings' ||
      location.path() == '/workspace/my-bookings/hot-desk' ||
      location.path() == '/workspace/my-bookings/visitor'
    )
      sessionStorage.setItem('testRoute', location.path());
  }
  @HostBinding('class.appRoot') appField: boolean = false;
  ngOnInit() {
    this.setTheme(environment.isLightTheme);

    const account = this.malauthService.getAccount();
    const tokenRequest: any = {
      scopes: [config.azure_authConfig.resources.resourceScope],
    };
    if (account == null) {
      //  this.malauthService.loginRedirect(tokenRequest).then((idToken: any) => { });
      this.malauthService.loginRedirect();
    }

    this.myTimerAzure = setInterval(() => {
      this.tokenexpVal = new Date(localStorage.getItem('azureTokenExpiry'));
      this.newDate = new Date();
      if (this.tokenexpVal) {
        //   console.log(this.tokenexpVal);
        //console.log("token", this.tokenexpVal,this.newDate, this.newDate > this.tokenexpVal )
        if (this.newDate > this.tokenexpVal) {
          localStorage.clear();
          sessionStorage.clear();
          this.malauthService.loginRedirect();
        }
      }
    }, 5000);

    this.malauthService
      .acquireTokenSilent(tokenRequest)
      .then((tokenResponse: any) => {
        // Callback code here
        console.log(tokenResponse);
        if (tokenResponse.accessToken) {
          localStorage.setItem('azureToken', tokenResponse.accessToken);
          localStorage.setItem('azureTokenExpiry', tokenResponse.expiresOn);
          this.appService.loggedUser.next(tokenResponse.account.name);
          this.authenticate = true;
          // this.appField = true;
          document.body.classList.add('main-document-body');
          this.appService.setTokenMsg(localStorage.getItem('azureToken'));
          const jwtHelper = new JwtHelperService();
          let tokenInfo = jwtHelper.decodeToken(tokenResponse.accessToken);
          console.log(tokenInfo);
          this.appService.roles.next(tokenInfo.roles);
          this.appService.department.next(tokenInfo.department);
          localStorage.setItem("employeeId",tokenInfo.employeeid);
          // this.router.navigate(['overview']);
          if (!localStorage.getItem('defaultRole')) {
            localStorage.setItem('defaultRole', tokenInfo.roles[0]);
            if (
              tokenInfo.roles[0] == 'FacilityManager' ||
              tokenInfo.roles[0] == 'PortfolioManager'
            ) {
              if (localStorage.getItem('currentUrl')) {
                this.router.navigate([localStorage.getItem('currentUrl')]);
              } 
              else{
                this.router.navigate(['/energy/map']);
              }
              
            } else {
              if (localStorage.getItem('currentUrl')) {
                this.router.navigate([localStorage.getItem('currentUrl')]);
              } 
              else{
                this.router.navigate(['overview']);
              }             
            }
          } else {
            if (localStorage.getItem('defaultRole') === 'Employee') {
              if (
                sessionStorage.getItem('currentUrl') &&
                this.location.path() == '/workspace/hot-desking'
              ) {
                this.router.navigate([sessionStorage.getItem('currentUrl')]);
              } else {
                this.router.navigate(['overview']);
              }
            } else if (localStorage.getItem('defaultRole') === 'Admin') {
              if (localStorage.getItem('currentUrl')) {
                this.router.navigate([localStorage.getItem('currentUrl')]);
              } else {
                this.router.navigate(['overview']);
              }
            } else if (localStorage.getItem('defaultRole') === 'Security') {
              if (localStorage.getItem('currentUrl')) {
                this.router.navigate([localStorage.getItem('currentUrl')]);
              } else {
                this.router.navigate(['overview']);
              }
            } else if (localStorage.getItem('defaultRole') === 'HR') {
              this.router.navigate(['overview']);
            } else {
              this.router.navigate(['/energy/map']);
            }
          }
        }
      })
      .catch(function (error) {
        console.log('inside catch block');
        //Acquire token silent failure, and send an interactive request
        // if (error instanceof InteractionRequiredAuthError) {
        //   this.malauthService
        //     .acquireTokenPopup(tokenRequest)
        //     .then(function (tokenResponse: any) {
        //       // Acquire token interactive success
        //       console.log('catch block');
        //       if (tokenResponse.accessToken) {
        //         localStorage.setItem('azureToken', tokenResponse.accessToken);
        //         localStorage.setItem('azureTokenExpiry', tokenResponse.expiresOn);

        //         this.appService.setTokenMsg(localStorage.getItem('azureToken'));
        //         const jwtHelper = new JwtHelperService();
        //         let tokenInfo = jwtHelper.decodeToken(tokenResponse.accessToken);
        //         if (tokenInfo['roles'][0] === 'Security') {
        //           sessionStorage.setItem('role', 'Security');
        //           this.router.navigate(['/visitor/visitor-scan']);
        //         } else if (tokenInfo['roles'][0] === 'Employee') {
        //           sessionStorage.setItem('role', 'Employee');
        //           if (this.location.path() === '') {
        //             if (sessionStorage.getItem('testRoute')) {
        //               this.router.navigate([sessionStorage.getItem('testRoute')]);
        //             } else {
        //               this.router.navigate(['home']);
        //             }
        //           }
        //         } else if (tokenInfo['roles'][0] === 'Admin') {
        //           sessionStorage.setItem('role', 'Admin');
        //           if (localStorage.getItem('currentUrl')) {
        //             this.router.navigate([localStorage.getItem('currentUrl')]);
        //           } else {
        //             this.router.navigate(['/admin']);
        //           }
        //         } else {
        //           sessionStorage.setItem('role', 'Employee');
        //           if (this.location.path() === '') {
        //             if (sessionStorage.getItem('testRoute')) {
        //               this.router.navigate([sessionStorage.getItem('testRoute')]);
        //             } else {
        //               this.router.navigate(['home']);
        //             }
        //           }
        //         }
        //       }
        //     })
        //     .catch(function (error: any) {
        //       // Acquire token interactive failure
        //       console.log(error);
        //     });
        // }
        console.log(error);
      });

    // this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //     this.isAuthenticated = this.credentialsService.empdetails; //Returns null if not authenticated
    //   }
    // });

    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {
      setTimeout(() => {
        if (status) this.loader = true;
        else this.loader = false;
      }, 0);
    });
  }

  setTheme(lightTheme: any) {
    let themeName =
      typeof Storage != 'undefined' && localStorage.getItem('theme')
        ? localStorage.getItem('theme')
        : lightTheme
        ? 'theme-light'
        : 'theme-dark';
    document.documentElement.className = themeName;
  }
}
