import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-delete-survey',
  templateUrl: './delete-survey.component.html',
  styleUrls: ['./delete-survey.component.scss']
})
export class DeleteSurveyComponent implements OnInit {
  isLightTheme: boolean = environment.isLightTheme;
  constructor(public dialogRef: MatDialogRef<DeleteSurveyComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }
  cancel(action: any) {
    this.dialogRef.close({ data: { action: action } });
  }

}
