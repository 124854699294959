import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { globalConstant } from '../safe-building/safebuilding-global-constants';
import { CredentialsService } from '@app/login/credentials.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { SharedService } from '../@theme/components/shared.service';
import { environment } from '@env/environment';
import { AppService } from '@app/app.service';
import { BookmeetingService } from '@app/workspace-experience/home/bookmeeting.service';
import { HotDeskingService } from '@app/workspace-experience/hot-desking/hot-desking.service';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  @ViewChild('accordion', { static: true }) Accordion: MatAccordion;
  hideMenuIcon: boolean = false;
  role: any = localStorage.getItem('defaultRole');
  isLightTheme: boolean = environment.isLightTheme;
  selectedSubMenu: any;
  locationDetails = '';
  locationCity: any;
  isExpanded: boolean;
  subPanels = [];
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  locationResponse: any;
  constructor(
    private router: Router,
    private credentialService: CredentialsService,
    private sharedService: SharedService,
    private appservice: AppService,
    private bookmeetingService: BookmeetingService,
    private hdservice: HotDeskingService
  ) {}
  building_spaceId: any = globalConstant.building_spaceId;
  accordion: false;
  ngOnInit(): void {
    console.log('role', this.role);
    // this.router.events.subscribe((event: any)=>{
    //   if(event instanceof NavigationEnd){
    //     this.role =  this.credentialService.role;
    //     console.log("this.role",this.role)
    //   }
    // });
    this.appservice.currentTheme.subscribe(
      (theme) => (this.isLightTheme = theme == 'theme-light' ? true : false)
    );
  }
  ngAfterContentChecked() {
    this.role = localStorage.getItem('defaultRole');
  }

  closePanels() {
    this.Accordion.closeAll();
    this.subPanels.forEach((panel: any) => panel.close());
    this.subPanels = [];
  }

  openMyMenu() {
    document
      .getElementsByClassName('menu-backdrop')[0]
      .addEventListener('click', () => {
        this.closePanels();
      });
    this.hideMenuIcon = true;
    this.trigger.openMenu();
    // this.Accordion.openAll();
    // this.Accordion.closeAll();
  }
  closeMyMenu() {
    this.hideMenuIcon = false;
    this.isExpanded = false;
    // this.Accordion.closeAll();
  }
  expandAcc() {
    this.isExpanded = this.isExpanded ? false : true;
  }
  subPanelOpen(event: any) {
    this.subPanels.push(event);
  }
  close(): void {
    setTimeout(() => {
      this.closePanels();
    }, 1);
    this.trigger.closeMenu();
    this.hideMenuIcon = false;
  }

  energyRedirect(val: string) {
    this.close();
    if (val == 'overview') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'overview');
      this.router.navigate(['/energy/overview']);
    }
    if (val == 'consumption') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Consumption Trend');
      this.router.navigate(['/energy/consumption-trend']);
    }
    if (val == 'peak-profile') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Peak Profile');
      this.router.navigate(['/energy/peak-profile']);
    }
    if (val == 'sourcing-breakup') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Sourcing Breakup');
      this.router.navigate(['/energy/sourcing-breakup']);
    }
    if (val == 'conservation') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Conservation Measures');
      this.router.navigate(['/energy/conservation']);
    }
    if (val == 'data-point-correlation') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Flexible Data Point Correlation');
      this.router.navigate(['/energy/data-point-correlation']);
    }
    if (val == 'target-setting') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Target Setting and Tracking');
      this.router.navigate(['/energy/target-setting']);
    }
    if (val == 'electricity-tariff-definition') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Electricity Tariff Definition');
      this.router.navigate(['/energy/electricity-tariff-definition']);
    }
    // if (val == 'map') {
    //   localStorage.setItem('headerText', 'Energy Management');
    //   localStorage.setItem('subHeaderText', 'Map');
    //   this.router.navigate(['/energy/map']);
    // }
    if(val =='admin'){
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'admin');
      this.router.navigate(['/energy/admin']);
    }
  }

  raceTracRedirect(val: string) {
    this.close();
    this.selectedSubMenu = val;
    if (val == 'dashboard') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'overview');
      this.router.navigate(['/racetrac']);
    }
    if (val == 'alertList') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'alert list');
      this.router.navigate(['/racetrac/alertList']);
    }
    if (val == 'workorderList') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'work order list');
      this.router.navigate(['/racetrac/WorkOrder']);
    }
    if (val == 'simulator') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'simulator');
      this.router.navigate(['/racetrac/simulator']);
    }
    if (val == 'admin') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'Store');
      this.sharedService.updateMenuText('Stores');
      this.router.navigate(['/racetrac/admin']);
    }
    if (val == 'assets') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'Assets');
      this.router.navigate(['racetrac/admin']);
      this.sharedService.updateMenuText('Assets');
    }
    if (val == 'assetModel') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'Assets Model Form');
      this.router.navigate(['racetrac/admin']);
      this.sharedService.updateMenuText('Assets Model Form');
    }
    if (val == 'alertRule') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'Configure Alert Rules');
      //this.router.navigate(['/racetrac/admin']);
      this.router.navigateByUrl('racetrac/admin');
      this.sharedService.updateMenuText('Configure Alert Rules');
    }
  }

  redirect(val: string) {
    this.close();
    console.log(val);
    if (val == 'physicalDistancing') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'physical distancing');
      this.router.navigateByUrl(
        '/physicaldistance' + '/' + this.building_spaceId
      );
    } else if (val == 'handHygiene') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'hand hygiene');
      this.router.navigateByUrl('/handhygiene' + '/' + this.building_spaceId);
    } else if (val == 'dashboard') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'overview');
      this.router.navigate(['/safe-buildings/dashboard']);
    } else if (val == 'facilityview') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'thermal screening');
      this.router.navigateByUrl('/facilityview' + '/' + this.building_spaceId);
    } else if (val == 'airquality') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'air quality');
      this.router.navigateByUrl('/airquality');
    } else if (val == 'peopleDensity') {
      this.router.navigateByUrl('/peopleDensity');
    } else if (val == 'facilityOnboard') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'facility onboard');
      this.router.navigateByUrl('admin');
    } else if (val == 'deviceOnboard') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'device onboard');
      this.router.navigateByUrl('admin');
    } else if (val == 'employeeManagement') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Employee and Band Management');
      this.router.navigateByUrl('admin');
    } else if (val == 'ruleConfig') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Rules and Configuration');
      this.router.navigateByUrl('admin');
    } else if (val == 'security') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Security');
      this.router.navigateByUrl('/security');
    } else if (val == 'thermalScan') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Thermal Scan');
      this.router.navigateByUrl('/security');
    } else if (val == 'hrDashboard') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Dashboard');
      this.router.navigateByUrl('/hrView');
    } else if (val == 'modifyAssociate') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Modify Associate Details');
      this.router.navigateByUrl('/hrView');
    } else if (val == 'quarantine') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Quarantine Tracker');
      this.router.navigateByUrl('/hrView');
    } else if (val == 'forms') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Forms');
      this.router.navigateByUrl('/hrView');
    } else if (val == 'employee') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Employee Compliance History');
      this.router.navigate(['/employee'], {
        replaceUrl: true,
      });
    }
  }

  workSpaceRedirect(val: string) {
    this.close();
    this.getLocationDetails();
    this.appservice.showLocation.next(true);
    if (val == 'visitor') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Visitor Dashboard');
      this.router.navigate(['/workspace/visitor-dashbaord'], {
        replaceUrl: true,
      });
    } else if (val == 'book-meeting') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Book Meeting Room');
      this.router.navigate(['/workspace/home'], {
        replaceUrl: true,
      });
    } else if (val == 'my-bookings') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'My Bookings');
      this.router.navigate(['/workspace/my-bookings'], {
        replaceUrl: true,
      });
    } else if (val == 'hot-desking') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Book Hot Desk');
      this.router.navigate(['/workspace/hot-desking'], {
        replaceUrl: true,
      });
    } else if (val == 'admin') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Organization Configuration');
      this.appservice.showLocation.next(false);
      this.router.navigate(['/workspace/admin'], {
        replaceUrl: true,
      });
    } else if (val == 'facility-list') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Facility Onboarding');
      this.router.navigate(['/workspace/admin/facility-list'], {
        replaceUrl: true,
      });
    } else if (val == 'user-groups') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'User Groups');
      this.router.navigate(['/workspace/admin/user-groups'], {
        replaceUrl: true,
      });
    } else if (val == 'desk-groups') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Desk Groups');
      this.router.navigate(['/workspace/admin/desk-groups'], {
        replaceUrl: true,
      });
    } else if (val == 'meeting-room-onboard') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Meeting Room Onboard');
      this.router.navigate(['/workspace/admin/meeting-room-onboard'], {
        replaceUrl: true,
      });
    } else if (val == 'hotdesk-onboard') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Hotdesk Onboard');
      this.router.navigate(['/workspace/admin/hotdesk-onboard'], {
        replaceUrl: true,
      });
    } else if (val == 'devices') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Devices');
      this.router.navigate(['/workspace/admin/devices'], {
        replaceUrl: true,
      });
    } else if (val == 'sensors') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Sensors');
      this.router.navigate(['/workspace/admin/sensors'], {
        replaceUrl: true,
      });
    } else if (val == 'rules-configuration') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Rules Configuration');
      this.router.navigate(['/workspace/admin/rules-configuration'], {
        replaceUrl: true,
      });
    }
    else if (val == 'cost-center-reports') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Cost Center Reports');
      this.router.navigate(['/workspace/admin/cost-reports'], {
        replaceUrl: true,
      });
    }
     else if (val == 'inferences') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Inferences');
      this.router.navigate(['/workspace/admin/inferences'], {
        replaceUrl: true,
      });
    } else if (val == 'parking-groups') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Parking Groups');
      this.router.navigate(['/workspace/admin/parking-groups'], {
        replaceUrl: true,
      });
    } else if (val == 'parking-spot') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Parking Spot');
      this.router.navigate(['/workspace/admin/parking-spot'], {
        replaceUrl: true,
      });
    } else if (val == 'zone-space') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Zone Space Utilization');
      this.router.navigate(['/workspace/admin/zone-space'], {
        replaceUrl: true,
      });
    } else if (val == 'space') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Spaces');
      this.router.navigate(['/workspace/admin/space'], {
        replaceUrl: true,
      });
    } else if (val == 'visitor-scan') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Visitor Scan');
      this.router.navigate(['/workspace/visitor-scan'], {
        replaceUrl: true,
      });
    } else if (val == 'visitor-list') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Visitor List');
      this.router.navigate(['/workspace/visitor-list'], {
        replaceUrl: true,
      });
    } else if (val == 'meeting-list') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Meeting List');
      this.router.navigate(['/workspace/admin/meetingroomlist'], {
        replaceUrl: true,
      });
    } else if (val == 'zone-list') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Zone List');
      this.router.navigate(['/workspace/admin/zoneroomlist'], {
        replaceUrl: true,
      });
    }
  }
  getLocationDetails() {
    this.appservice.getUserLocation().subscribe((response: any) => {
      if (response && response['locationName']) {
        // this.locationDetails = response['locationName'];
        this.locationResponse = response;
      }
      localStorage.setItem('regionId', response.regionId);
      localStorage.setItem('spaceId', response.spaceId);
      localStorage.setItem('facilitySpaceId', response.space[0].spaceId);
      localStorage.setItem('city', response.space[0].region);
      localStorage.setItem('facility', response.space[0].spaceName);
      localStorage.setItem('building', response.space[1].spaceName);
      localStorage.setItem('floor', response.space[2].spaceName);
    });
  }
}
