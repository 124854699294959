import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { environment } from '@env/environment';
import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import { timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { chartColors } from '../../../themes/chart-theme-variables';
import { globalConstant } from '../safebuilding-global-constants';
import { QuoteService } from './quote.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  @ViewChild('chart') chart: ElementRef;
  @ViewChild('chart1') chart1: ElementRef;
  @ViewChild('donut') donut: ElementRef;
  @ViewChild('donut1') donut1: ElementRef;
  @ViewChild('donut2') donut2: ElementRef;
  building_spaceId: any;
  building_spaceTypeId: any;
  intervalValue: any = globalConstant.intervalValue;
  // building_spaceId: any = globalConstant.building_spaceId;
  // building_spaceTypeId: any = globalConstant.building_spaceTypeId;
  // intervalValue: any = globalConstant.intervalValue;

  /* declaration for long polling */
  counter: number = 0;
  pollingData_TS: any;
  pollingData_PD: any;
  pollingData_AQ: any;
  pollingData_HH: any;
  pollingData_PY: any;
  errors: any;
  /* declaration for long polling */

  columnChart: any;
  context: any;
  quote: string | undefined;
  isLoading = false;
  todaysDate: any;
  facilities: any[] = [];
  spacetype: any;
  totaCount: number;
  nonCompliant: number;
  comparison: any;
  facilitySelected: any;
  hasIncreased: boolean;
  plotLine: any;
  pdPlotLine: number;
  physicalScreenData: any;
  selectedPoint: any;
  myDonut: Chart;
  myDonut1: Chart;
  myDonut2: Chart;
  label: any[];
  chartData: any[];
  label1: any[];
  chartData1: any[];
  label2: any[];
  chartData2: any[];
  maxChartData: any[];
  peopleChartData: any[];
  peopleScreenData: any;
  ppPlotLine: number;
  hygieneScreenData: any;
  hhPlotLine: number;
  physicalDataDiff: number;
  physicalDataDiffVal: number;
  hygieneDataDiff: number;
  hygieneDataDiffVal: number;
  peopleDataDiff: number;
  peopleDataDiffVal: number;

  TS: boolean = environment.TS_enable;
  PD: boolean = environment.PD_enable;
  HH: boolean = environment.HH_enable;
  PY: boolean = environment.PY_enable;
  AQ: boolean = environment.AQ_enable;
  isLightTheme: boolean = environment.isLightTheme;
  peopleDensityDiff: number;
  peopleDensityDiffVal: number;
  timeZone: string = globalConstant.timeZone;
  notificationLength: any;
  hygieneCount: any;
  physicalCount: any;
  chartColorCodes: any;
  airqualityData: any;
  complianceStatusName: any;
  complianceStatusId: any;
  iaqDataPoints: any;
  iaqPM10: any;
  iaqPM110StatusCode: any;
  iaqPM25: any;
  iaqPM25StatusCode: any;
  iaqco2: any;
  iaqco2StatusCode: any;
  iaqtvoc: any;
  iaqtvocStatusCode: any;

  isViewLoaded: boolean;
  iaqairflow: any;
  iaqairflowStatusCode: any;
  iarairflowText: any;
  iaqtemp: any;
  iaqtempStatusCode: any;
  iaqhumidStatusCode: any;
  iaqhumid: any;
  energySpaceId: string;
  href: string;
  dataLabelPlugin: Chart.PluginServiceGlobalRegistration &
    Chart.PluginServiceRegistrationOptions;

  constructor(
    private quoteService: QuoteService,
    private router: Router,
    private chartColors: chartColors,
    private appservice: AppService,
    public http: HttpClient
  ) {}

  ngOnInit() {
    this.href = this.router.url;
    this.appservice.currentTheme.subscribe((theme) => {
      this.isLightTheme = theme == 'theme-light' ? true : false;
      if (
        this.isViewLoaded &&
        location.pathname.toLowerCase().includes('dashboard')
      ) {
        location.reload();
      }
    });
    this.chartColorCodes = this.chartColors.getColorCodes();
    // Chart.plugins.unregister(ChartDataLabels);
    let plugins = Chart.pluginService.getAll();
    this.dataLabelPlugin = plugins.find(
      (plugin: any) => plugin.id === 'datalabels'
    );
    Chart.pluginService.unregister(this.dataLabelPlugin);
    this.todaysDate = moment(new Date()).utc().format('DD MMM YYYY, HH:mm');
    this.isLoading = true;
    /* this.quoteService
      .getRandomQuote({ category: 'dev' })
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((quote: string) => {
        this.quote = quote;
      }); */
    //commenting out code as hardcoded space id should be removed
    // if (this.quoteService.get_selected_spaceId() !== undefined) {
    //   this.facilitySelected = this.quoteService.get_selected_spaceId();
    //   this.building_spaceId = this.facilitySelected;
    // }
    this.getFacility();
    // this.getPeopleDensityData();
    // if (this.PD == true) {
    //   this.getPhysicalData();
    // }
    // if (this.HH == true) {
    //   this.getHygieneData();
    // }
    // if (this.AQ == true) {
    //   this.getAirQualityDetails();
    // }
    this.isViewLoaded = true;
  }
  back() {
    this.energySpaceId = localStorage.getItem('energySpaceID');
    localStorage.setItem('headerText', '');
    localStorage.setItem('subHeaderText', '');
    this.router.navigate([`/energy/overview/${this.energySpaceId}`]);
  }
  getFacility = () => {
    this.quoteService.getOrganization().subscribe((resp: any) => {
      if (resp) {
        resp.map((space: any) => {
          space['spaceProperties'].forEach((element: any) => {
            if (element.propertyKey.toLowerCase() === 'name') {
              this.facilities.push({
                value: space['spaceId'],
                viewValue: element.propertyValue,
              });
            }
          });
          this.spacetype = space['spaceType'].spaceTypeId;
        });
      }

      this.building_spaceId = this.facilities[0].value;
      this.building_spaceTypeId = this.spacetype;
      localStorage.setItem('safebuildingSpaceId', this.building_spaceId);
      localStorage.setItem(
        'safebuildingSpaceTypeId',
        this.building_spaceTypeId
      );
      this.facilities.map((facility: any, index: any) => {
        // if (facility.value === environment.spaceId && facility.viewValue === environment.spaceName) {
        //   this.facilitySelected = this.facilities[index].value;
        if (this.quoteService.get_selected_spaceId() == undefined) {
          this.facilitySelected = this.facilities[0].value;
          this.quoteService.set_selected_spaceId(this.building_spaceId);
          this.building_spaceId = this.facilitySelected;
        } else {
          this.facilitySelected = this.quoteService.get_selected_spaceId();
          this.building_spaceId = this.facilitySelected;
          // console.log(this.building_spaceId)
        }
      });
      this.getThermalData();
      if (this.PD == true) {
        this.getPhysicalData();
      }
      if (this.HH == true) {
        this.getHygieneData();
      }
      if (this.AQ == true) {
        this.getAirQualityDetails();
      }
      if (this.PY == true) {
        this.getPeopleDensityData();
      }
    });
  };

  chartDraw = (
    context: any,
    type: string,
    labels: any[],
    screen: any[],
    compliant: any[],
    chartColors: any
  ) => {
    const gradient = context.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, '#E65100');
    gradient.addColorStop(1, '#556270');

    this.columnChart = new Chart(context, {
      type,
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Non Compliant',
            data: compliant,
            fill: false,
            backgroundColor: chartColors.redColor,
            barThickness: 4,
            maxBarThickness: 12,
          },
          {
            label: 'Total Screened',
            data: screen,
            backgroundColor: chartColors.greenColor,
            barThickness: 4,
            maxBarThickness: 12,
          },
        ],
      },
      options: {
        animation: {
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.fillStyle = chartColors.normal_font_color; //'#fff';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function (dataset: any, i: any) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              meta.data.forEach(function (bar: any, index: any) {
                var nonconpliant = [];
                var ttlScreened;
                if (
                  bar._model.datasetLabel.toLowerCase() === 'total screened'
                ) {
                  ttlScreened = compliant[index] + ' / ' + dataset.data[index];
                  ctx.fillText(
                    ttlScreened,
                    bar._model.x + 45,
                    bar._model.y + 5
                  );
                }
                if (bar._model.datasetLabel.toLowerCase() === 'non compliant') {
                  nonconpliant.push(dataset.data[index]);
                }
              });
            });
          },
        },
        tooltips: {
          mode: 'single',
          backgroundColor: chartColors.toolTipBackgroundColor,
          callbacks: {
            afterBody: (data) => {
              if (data && data[0]) {
                this.selectedPoint = data[0];
              }
              return [''];
            },
          },
        },
        layout: {
          padding: {
            right: 50,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.normal_font_color, //'white',
                fontSize: 11,
                fontFamily: 'TTNorms',
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              display: false,
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.normal_font_color, //'white',
                fontSize: 11,
                beginAtZero: true,
                fontFamily: 'TTNorms',
              },
            },
          ],
        },
      },
    });
  };
  chartDrawHorizontal = (
    context: any,
    type: string,
    labels: any[],
    compliant: any[],
    chartColors: any
  ) => {
    const gradient = context.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, '#E65100');
    gradient.addColorStop(1, '#556270');

    this.columnChart = new Chart(context, {
      type,
      data: {
        labels: labels,
        datasets: [
          {
            label: 'Notifications',
            data: compliant,
            fill: false,
            backgroundColor: chartColors.redColor,
            barThickness: 50,
            maxBarThickness: 30,
          },
        ],
      },
      options: {
        animation: {
          onComplete: function () {
            var chartInstance = this.chart,
              ctx = chartInstance.ctx;
            ctx.fillStyle = chartColors.donutChartTextColor; //'#fff';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'bottom';

            this.data.datasets.forEach(function (dataset: any, i: any) {
              var meta = chartInstance.controller.getDatasetMeta(i);
              console.log(dataset.data);
              meta.data.forEach(function (bar: any, index: any) {
                var nonconpliant = [];
                var ttlScreened;
                if (bar._model.datasetLabel.toLowerCase() === 'notifications') {
                  ttlScreened = dataset.data[index];
                  ctx.fillText(
                    ttlScreened,
                    bar._model.x + 25,
                    bar._model.y + 5
                  );
                }
              });
            });
          },
        },
        tooltips: {
          mode: 'label',
          backgroundColor: chartColors.toolTipBackgroundColor,
          callbacks: {
            afterBody: (data) => {
              if (data && data[0]) {
                this.selectedPoint = data[0];
              }
              return [''];
            },
            // title: (data) => {
            //   if (data && data[0]) {
            //     this.selectedPoint = data[0];
            //   }
            //   return [''];
            // },
          },
        },
        layout: {
          padding: {
            right: 50,
          },
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.donutChartTextColor, //'white',
                fontSize: 11,
                fontFamily: 'TTNorms',
                callback: function (tick) {
                  var characterLimit = 15;
                  if (tick.toString().length >= characterLimit) {
                    return (
                      tick
                        .toString()
                        .slice(0, tick.toString().length)
                        .substring(0, characterLimit - 1)
                        .trim() + '...'
                    );
                  }
                  return tick;
                },
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
              display: false,
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: chartColors.donutChartTextColor, //'white',
                fontSize: 11,
                beginAtZero: true,
                fontFamily: 'TTNorms',
              },
            },
          ],
        },
      },
    });
  };

  getThermalData = () => {
    if (this.TS == true) {
      let serviceStatus = this.quoteService.getThermalScreenData(
        this.facilitySelected,
        moment(new Date()).format('YYYY-MM-DD')
      );
      this.pollingData_TS = timer(0, this.intervalValue)
        .pipe(switchMap((_: number) => serviceStatus))
        .subscribe((res) => {
          let labels: any[] = [];
          let compliant_data: any[] = [];
          let total_screened_data: any[] = [];
          let chartColorCodes = this.chartColorCodes;
          if (res) {
            this.totaCount = res['totalCount'];
            this.nonCompliant = res['nonCompliant'];
            this.plotLine = Math.ceil(
              ((this.totaCount - this.nonCompliant) / this.totaCount) * 100
            );
            this.comparison = Math.abs(parseInt(res['comparision']));
            if (res['comparison'] >= 0) {
              this.hasIncreased = true;
            } else {
              this.hasIncreased = false;
            }
            res['buildings'].forEach((element: any) => {
              labels.push(element.space);
              total_screened_data.push(element.totalCount);
              compliant_data.push(element.nonCompliant);
            });
            this.context = this.chart.nativeElement.getContext('2d');

            this.chartDraw(
              this.context,
              'horizontalBar',
              labels,
              total_screened_data,
              compliant_data,
              chartColorCodes
            );
          }
        });
    }
  };

  getPhysicalData = () => {
    let serviceStatus = this.quoteService.getPhysicalScreenData(
      this.building_spaceId,
      this.building_spaceTypeId,
      this.getStartDateTime(),
      this.getEndDateTime(),
      this.timeZone
    );
    this.pollingData_PD = timer(0, this.intervalValue)
      .pipe(switchMap((_: number) => serviceStatus))
      .subscribe(
        (data: any) => {
          if (data) {
            // console.log(data);
            this.physicalCount = data.counts.length;
            if (data.counts.length > 0) {
              this.label = [];
              this.chartData = [];
            }
            this.physicalScreenData = data;
            this.pdPlotLine = 0;
            for (var i = 0; i < this.physicalScreenData.counts.length; i++) {
              this.label.push(this.physicalScreenData.counts[i].spaceName);
              this.chartData.push(
                this.physicalScreenData.counts[i].noncompliantCount
              );
            }
            this.context = this.donut.nativeElement.getContext('2d');
            this.chartDrawDonut(
              this.context,
              'doughnut',
              this.label,
              this.chartData,
              this.chartColorCodes
            );

            this.quoteService
              .getPhysicalScreenData(
                this.building_spaceId,
                this.building_spaceTypeId,
                this.getPreviousDateTime(),
                this.getStartDateTime(),
                this.timeZone
              )
              .subscribe(
                (data1: any) => {
                  if (data1) {
                    this.physicalDataDiff =
                      data.totalNonCompliantCount -
                      data1.totalNonCompliantCount;
                    //  console.log(this.physicalDataDiff);
                    this.physicalDataDiffVal = Math.abs(this.physicalDataDiff);
                    // console.log(this.physicalDataDiffVal);
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          }
        },
        (err) => {
          console.log(err);
        }
      );
  };
  getAirQualityDetails = () => {
    let serviceStatus = this.quoteService.getAirQualityData(
      this.building_spaceId,
      this.getStartDateTime(),
      this.getEndDateTime(),
      this.timeZone
    );
    this.pollingData_AQ = timer(0, this.intervalValue)
      .pipe(switchMap((_: number) => serviceStatus))
      .subscribe(
        (data: any) => {
          if (data) {
            this.airqualityData = data.iaqDatas;
            //console.log(data.iaqDatas);
            this.complianceStatusName =
              this.airqualityData[0].complianceStatusName;
            this.complianceStatusId = this.airqualityData[0].complianceStatusId;
            this.iaqDataPoints = this.airqualityData[0].iaqDataPoints;
            for (var i = 0; i < this.iaqDataPoints.length; i++) {
              if (
                this.iaqDataPoints[i].airQualityParameter ==
                'Particulate Matter PM10'
              ) {
                this.iaqPM10 = this.iaqDataPoints[i].value;
                this.iaqPM110StatusCode =
                  this.iaqDataPoints[i].complianceStatusId;
              } else if (
                this.iaqDataPoints[i].airQualityParameter ==
                'Particulate Matter PM2.5'
              ) {
                this.iaqPM25 = this.iaqDataPoints[i].value;
                this.iaqPM25StatusCode =
                  this.iaqDataPoints[i].complianceStatusId;
              } else if (
                this.iaqDataPoints[i].airQualityParameter ==
                'Pollutant Gases CO'
              ) {
                this.iaqco2 = this.iaqDataPoints[i].value;
                this.iaqco2StatusCode =
                  this.iaqDataPoints[i].complianceStatusId;
              } else if (
                this.iaqDataPoints[i].airQualityParameter ==
                'Total Volatile Organic Compounds'
              ) {
                this.iaqtvoc = this.iaqDataPoints[i].value;
                this.iaqtvocStatusCode =
                  this.iaqDataPoints[i].complianceStatusId;
              } else if (
                this.iaqDataPoints[i].airQualityParameter ==
                'Pollutant Gases CO2'
              ) {
                this.iaqairflow = this.iaqDataPoints[i].value;
                this.iaqairflowStatusCode =
                  this.iaqDataPoints[i].complianceStatusId;

                this.iarairflowText =
                  this.iaqDataPoints[i].complianceStatusName;
              } else if (
                this.iaqDataPoints[i].airQualityParameter ==
                'Facility Temperature'
              ) {
                this.iaqtemp = this.iaqDataPoints[i].value;
                console.log(this.iaqDataPoints[i].value);
                this.iaqtempStatusCode =
                  this.iaqDataPoints[i].complianceStatusId;
              } else if (
                this.iaqDataPoints[i].airQualityParameter == 'Humidity'
              ) {
                this.iaqhumid = this.iaqDataPoints[i].value;
                this.iaqhumidStatusCode =
                  this.iaqDataPoints[i].complianceStatusId;
              }
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
  };
  getHygieneData = () => {
    let serviceStatus = this.quoteService.getHygieneScreenData(
      this.building_spaceId,
      this.building_spaceTypeId,
      this.getStartDateTime(),
      this.getEndDateTime(),
      this.timeZone
    );
    this.pollingData_HH = timer(0, this.intervalValue)
      .pipe(switchMap((_: number) => serviceStatus))
      .subscribe(
        (data: any) => {
          if (data) {
            this.hygieneCount = data.counts.length;
            if (data.counts.length > 0) {
              this.label1 = [];
              this.chartData1 = [];
            }
            this.hygieneScreenData = data;
            this.hhPlotLine = 0;
            for (var i = 0; i < this.hygieneScreenData.counts.length; i++) {
              this.label1.push(this.hygieneScreenData.counts[i].spaceName);
              this.chartData1.push(
                this.hygieneScreenData.counts[i].noncompliantCount
              );
              //   console.log(this.label1);
              //    console.log(this.chartData1);
            }
            this.context = this.donut1.nativeElement.getContext('2d');
            this.chartDrawDonut(
              this.context,
              'doughnut',
              this.label1,
              this.chartData1,
              this.chartColorCodes
            );

            this.quoteService
              .getHygieneScreenData(
                this.building_spaceId,
                this.building_spaceTypeId,
                this.getPreviousDateTime(),
                this.getStartDateTime(),
                this.timeZone
              )
              .subscribe(
                (data1: any) => {
                  if (data1) {
                    this.hygieneDataDiff =
                      data.totalNonCompliantCount -
                      data1.totalNonCompliantCount;
                    // console.log(this.physicalDataDiff);
                    this.hygieneDataDiffVal = Math.abs(this.hygieneDataDiff);
                    // console.log(this.hygieneDataDiffVal);
                  }
                },
                (err) => {
                  console.log(err);
                }
              );
          }
        },
        (err) => {
          console.log(err);
        }
      );
  };

  getPeopleDensityData = () => {
    if (this.PY) {
      let serviceStatus = this.quoteService.getPeopleData(
        this.building_spaceId,
        this.building_spaceTypeId,
        this.getStartDate(),
        this.getEndDate(),
        '00:00',
        this.timeZone
      );
      this.pollingData_PY = timer(0, this.intervalValue)
        .pipe(switchMap((_: number) => serviceStatus))
        .subscribe((data: any) => {
          if (data) {
            //  this.notificationLength = data.sdFacilityNotifications.length;
            if (data.sdFacilityNotifications.length > 0) {
              this.label2 = [];
              this.chartData2 = [];
              this.maxChartData = [];
              this.peopleChartData = [];
            }

            this.peopleScreenData = data;

            this.peopleScreenData.sdFacilityNotifications.sort(
              (a: any, b: any) => {
                return a.notificationCount < b.notificationCount ? 1 : -1;
              }
            );
            //console.log(this.peopleScreenData.sdFacilityNotifications);
            this.peopleChartData =
              this.peopleScreenData.sdFacilityNotifications.slice(
                4,
                this.peopleScreenData.sdFacilityNotifications.length
              );
            for (var i = 0; i < this.peopleChartData.length; i++) {
              this.maxChartData.push(this.peopleChartData[i].notificationCount);
            }
            this.ppPlotLine = 0;
            for (var i = 0; i < this.peopleScreenData.sdFacilityNotifications.length; i++) {
              if (i == 4) {
                this.label2.push('OTHERS');
                this.chartData2.push(
                  this.maxChartData.reduce((a: any, b: any) => a + b, 0)
                );
              } else {
                this.label2.push(
                  this.peopleScreenData.sdFacilityNotifications[
                    i
                  ].spaceName.toUpperCase()
                );
                this.chartData2.push(
                  this.peopleScreenData.sdFacilityNotifications[i]
                    .notificationCount
                );
              }
            }

            this.context = this.chart1.nativeElement.getContext('2d');
            this.chartDrawHorizontal(
              this.context,
              'horizontalBar',
              this.label2,
              this.chartData2,
              this.chartColorCodes
            );
          }
          this.quoteService
            .getPeopleData(
              this.building_spaceId,
              this.building_spaceTypeId,
              this.getPreviousDate(),
              this.getStartDate(),
              '00:00',
              this.timeZone
            )
            .subscribe(
              (data2: any) => {
                if (data2) {
                  this.peopleDensityDiff =
                    data.totalNoticiationCount - data2.totalNoticiationCount;
                  // console.log(this.physicalDataDiff);
                  this.peopleDensityDiffVal = Math.abs(this.peopleDensityDiff);
                  //  console.log(this.hygieneDataDiffVal);
                }
              },
              (err) => {
                console.log(err);
              }
            );
        });
    }
  };

  getStartDate() {
    let today = new Date();

    let startDate =
      today.getUTCFullYear() +
      '-' +
      (today.getUTCMonth() + 1) +
      '-' +
      today.getUTCDate();
    return startDate;
  }
  getEndDate() {
    var Onedayplus = new Date();
    Onedayplus.setUTCDate(Onedayplus.getUTCDate() + 1);

    let endDate =
      Onedayplus.getUTCFullYear() +
      '-' +
      (Onedayplus.getUTCMonth() + 1) +
      '-' +
      Onedayplus.getUTCDate();
    return endDate;
  }

  getPreviousDate() {
    var Onedayplus = new Date();
    Onedayplus.setUTCDate(Onedayplus.getUTCDate() - 1);

    let endDate =
      Onedayplus.getUTCFullYear() +
      '-' +
      (Onedayplus.getUTCMonth() + 1) +
      '-' +
      Onedayplus.getUTCDate();
    return endDate;
  }
  getStartDateTime() {
    let today = new Date();

    let startDate =
      today.getUTCFullYear() +
      '-' +
      (today.getUTCMonth() + 1) +
      '-' +
      today.getUTCDate() +
      'T' +
      '00:00:00';
    return startDate;
  }

  getEndDateTime() {
    var Onedayplus = new Date();
    Onedayplus.setUTCDate(Onedayplus.getUTCDate() + 1);

    let endDate =
      Onedayplus.getUTCFullYear() +
      '-' +
      (Onedayplus.getUTCMonth() + 1) +
      '-' +
      Onedayplus.getUTCDate() +
      'T' +
      '00:00:00';
    return endDate;
  }

  getPreviousDateTime() {
    var Onedayplus = new Date();
    Onedayplus.setUTCDate(Onedayplus.getUTCDate() - 1);

    let endDate =
      Onedayplus.getUTCFullYear() +
      '-' +
      (Onedayplus.getUTCMonth() + 1) +
      '-' +
      Onedayplus.getUTCDate() +
      'T' +
      '00:00:00';
    return endDate;
  }

  chartDrawDonut = (
    context: any,
    type: string,
    label: any,
    data: any,
    chartColors: any
  ) => {
    // console.log(label);
    // console.log(data);
    const gradient = context.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, '#E65100');
    gradient.addColorStop(1, '#556270');

    this.myDonut = new Chart(context, {
      plugins: [ChartDataLabels],
      type,
      data: {
        labels: label,
        datasets: [
          {
            label: 'Alerts',
            data: data,
            fill: false,
            backgroundColor: chartColors.redColor,
            barThickness: 12,
            maxBarThickness: 12,
            yAxisID: 'YAxis1',
            borderColor:
              chartColors.donutChartTextColor === '#ffffff'
                ? '#24233f'
                : '#fff',
            borderWidth: 4,
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            display: true,
            color: chartColors.donutChartTextColor,
            formatter: function (value, context) {
              return context.chart.data.labels[context.dataIndex];
            },
          },
        },
        tooltips: {
          mode: 'single',
          backgroundColor: chartColors.toolTipBackgroundColor,
          callbacks: {
            afterBody: (data) => {
              if (data && data[0]) {
                this.selectedPoint = data[0];
              }
              return [''];
            },
          },
        },
        legend: {
          display: false,
          labels: {
            fontSize: 100,
          },
        },
        scales: {
          // yAxes: [
          //   {
          //     id: 'YAxis1',
          //     position: 'left',
          //     gridLines: {
          //       display: false,
          //     },
          //     ticks: {
          //       fontColor: 'white',
          //       fontSize: 14,
          //       beginAtZero: true,
          //       fontFamily: 'TTNorms',
          //     },
          //     stacked: true,
          //   },
          // ],
          // xAxes: [
          //   {
          //     stacked: true,
          //     gridLines: {
          //       display: false,
          //     },
          //     ticks: {
          //       fontColor: 'white',
          //       fontSize: 14,
          //       beginAtZero: true,
          //       fontFamily: 'TTNorms',
          //     },
          //   },
          // ],
        },
      },
    });
  };

  redirect = (val: string) => {
    this.isViewLoaded = false;
    if (val == 'thermal') {
      this.router.navigateByUrl('/facilityview/' + this.facilitySelected);
      if (this.TS == true) {
        this.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.pollingData_PD.unsubscribe();
      }
      if (this.AQ == true) {
        this.pollingData_AQ.unsubscribe();
      }
      if (this.HH == true) {
        this.pollingData_HH.unsubscribe();
      }
      if (this.PY == true) {
        this.pollingData_PY.unsubscribe();
      }
    } else if (val == 'hygiene') {
      this.router.navigateByUrl('/handhygiene' + '/' + this.building_spaceId);
      if (this.TS == true) {
        this.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.pollingData_PD.unsubscribe();
      }
      if (this.AQ == true) {
        this.pollingData_AQ.unsubscribe();
      }
      if (this.HH == true) {
        this.pollingData_HH.unsubscribe();
      }
      if (this.PY == true) {
        this.pollingData_PY.unsubscribe();
      }
    } else if (val == 'physical') {
      this.router.navigateByUrl(
        '/physicaldistance' + '/' + this.building_spaceId
      );
      if (this.TS == true) {
        this.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.pollingData_PD.unsubscribe();
      }
      if (this.AQ == true) {
        this.pollingData_AQ.unsubscribe();
      }
      if (this.HH == true) {
        this.pollingData_HH.unsubscribe();
      }
      if (this.PY == true) {
        this.pollingData_PY.unsubscribe();
      }
    } else if (val == 'density') {
      this.router.navigateByUrl('/peopleDensity');
      if (this.TS == true) {
        this.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.pollingData_PD.unsubscribe();
      }
      if (this.AQ == true) {
        this.pollingData_AQ.unsubscribe();
      }
      if (this.HH == true) {
        this.pollingData_HH.unsubscribe();
      }
      if (this.PY == true) {
        this.pollingData_PY.unsubscribe();
      }
    } else if (val == 'airquality') {
      this.router.navigateByUrl('/airquality');
      if (this.TS == true) {
        this.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.pollingData_PD.unsubscribe();
      }
      if (this.AQ == true) {
        this.pollingData_AQ.unsubscribe();
      }
      if (this.HH == true) {
        this.pollingData_HH.unsubscribe();
      }
      if (this.PY == true) {
        this.pollingData_PY.unsubscribe();
      }
    }
  };

  facilitySelectionChange(id: any) {
    //localStorage.clear();
    localStorage.removeItem('this.building_spaceId');
    // localStorage.setItem(this.building_spaceId, id);
    if (this.TS == true) {
      this.pollingData_TS.unsubscribe();
      this.quoteService.set_selected_spaceId(id);
      this.building_spaceId = id;
      this.getThermalData();
    }
    if (this.PY == true) {
      this.pollingData_PY.unsubscribe();
      this.quoteService.set_selected_spaceId(id);
      this.building_spaceId = id;
      this.getPeopleDensityData();
    }
    if (this.PD == true) {
      this.pollingData_PD.unsubscribe();
      this.quoteService.set_selected_spaceId(id);
      this.building_spaceId = id;
      this.getPhysicalData();
      console.log(this.building_spaceId);
    }
    if (this.HH == true) {
      this.pollingData_HH.unsubscribe();
      this.quoteService.set_selected_spaceId(id);
      this.building_spaceId = id;
      this.getHygieneData();
    }
    if (this.AQ == true) {
      this.pollingData_AQ.unsubscribe();
      this.quoteService.set_selected_spaceId(id);
      this.building_spaceId = id;
      this.getAirQualityDetails();
    }
  }
  ngOnDestroy() {
    if (this.TS == true) {
      this.pollingData_TS.unsubscribe();
    }
    if (this.PD == true) {
      this.pollingData_PD.unsubscribe();
    }
    if (this.AQ == true) {
      this.pollingData_AQ.unsubscribe();
    }
    if (this.HH == true) {
      this.pollingData_HH.unsubscribe();
    }
    if (this.PY == true) {
      this.pollingData_PY.unsubscribe();
    }
    if(this.dataLabelPlugin){
      Chart.pluginService.register(this.dataLabelPlugin);
    }
  }
}
