import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Scene,
  PerspectiveCamera,
  WebGLRenderer,
  DirectionalLight,
  AmbientLight,
  ACESFilmicToneMapping,
  sRGBEncoding,
  Color,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { RoughnessMipmapper } from 'three/examples/jsm/utils/RoughnessMipmapper.js';
import { Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { QuoteService } from '@app/safe-building/home/quote.service';
import { EnergyManagementService } from '@app/enery-management/energy-management.service';
import { FacilityService } from '@app/enery-management/facility.service';
import * as moment from 'moment-timezone';
import { IotServiceService } from '@app/workspace-experience/admin/iot-service.service';

@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.scss'],
})
export class LandingpageComponent implements OnInit, OnDestroy {
  role: string = localStorage.getItem('defaultRole');
  timeZone: any = Intl.DateTimeFormat().resolvedOptions().timeZone;
  facilitySelected: any;
  building_spaceId: any;
  building_spaceTypeId: number = 2;
  noncomplianceCount: number;
  pdTotalNoticiationCount: number;
  airQualityData: any[] = [];
  profiles: any = [];
  facilities: any[] = [];
  spaceSelected: any;
  profileSelected: any;
  period: string = 'today';
  totalCost: number = 0;
  co2Emission: number = 0;
  energyConsumption: any = 0;
  overviewDate: any;
  renewableResource: number = 0;
  facilityData: any = {
    name: '',
    capacity: '',
    area: '',
    cityname: '',
    currentOccupancy: '',
    forecast: 0,
    savings: 0,
    yearSavings: 0,
    targetValue: 0,
  };
  controls: any;
  camera: any;
  renderer: any;
  scene: any;
  dlight4: any;
  dlight3: any;
  dlight2: any;
  dlight1: any;
  loader: any;
  id: number;
  constructor(
    private router: Router,
    private service: AppService,
    private quoteService: QuoteService,
    private EnergyService: EnergyManagementService,
    private facilityService: FacilityService,
    private activatedRoute: ActivatedRoute,
    private iotService: IotServiceService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: any) => {
      if (params.spaceId) {
        this.spaceSelected = parseInt(params.spaceId, 10);
      } else {
        this.spaceSelected = 3130;
      }
      this.iotService.spaceId.next(this.spaceSelected);
    });
    this.load3DModel();
    this.getSafeBuildingsData();
    this.energySource();
  }

  ngAfterContentChecked(): void {
    this.role = localStorage.getItem('defaultRole');
  }

  load3DModel(): void {
    // setTimeout(function () {
    //   const canvas = document.querySelector('#webgl');
    //   const scene = new Scene();
    //   const camera = new PerspectiveCamera(
    //     75,
    //     window.innerWidth / window.innerHeight,
    //     0.1,
    //     100
    //   );

    //   const renderer = new WebGLRenderer({
    //     canvas: canvas,
    //   });
    //   renderer.setSize(window.innerWidth, window.innerHeight);

    //   const loader = new GLTFLoader();

    //   loader.load(
    //     'assets/EntireBuilding.glb',
    //     function (gltf) {
    //       scene.add(gltf.scene);
    //     },
    //     undefined,
    //     function (error) {
    //       console.error(error);
    //     }
    //   );

    //   const light = new DirectionalLight(0xffffff, 1);
    //   // light.position(2, 2, 5);
    //   scene.add(light);

    //   camera.position.set(0, 1, 2);
    //   scene.add(camera);

    //   // renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    //   // renderer.shadowMap.enabled = true;
    //   // renderer.gammaOutput = true;

    //   function animate() {
    //     requestAnimationFrame(animate);
    //     renderer.render(scene, camera);
    //   }
    //   animate();
    // }, 1000);

    //context
    const self = this;
    const canvas = document.querySelector('#webgl');

    this.camera = new PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      1, //0.25,
      1000 //20
    );
    // camera.position.set(0, 5, 10);
    // this.camera.position.set(9.799, 3.6, 8.393);
    // this.camera.position.set(2.31, 3.1, 11.22);
    this.camera.position.set(6.65, 4.04, 10.72);
    // this.camera.rotation.set(-0.18, 0.16, 0.04);

    this.scene = new Scene();
    this.scene.background = new Color(0x24233f); //new Color(0xaaaaaa);

    this.loader = new GLTFLoader();

    this.loader.load(
      'assets/BuildingLOGO.glb',
      function (gltf) {
        self.scene.add(gltf.scene);
        console.log(gltf);
        gltf.scene.children[1].children[0].children[0].children[5].material.color.set(
          0xff0000
        );
        // gltf.scene.children[2].children[0].children[0].children[4].material.color.set(
        //   0xff0000
        // );
      },
      undefined,
      function (error) {
        console.error(error);
      }
    );

    this.dlight1 = new DirectionalLight(0xffffff, 0.5);
    this.dlight1.position.set(5, 3.866, -13.562);
    this.scene.add(this.dlight1);

    this.dlight2 = new DirectionalLight(0xffffff, 0.5);
    this.dlight2.position.set(3.239, 1.116, -7.06);
    this.scene.add(this.dlight2);

    this.dlight3 = new DirectionalLight(0xffffff, 0.5);
    this.dlight3.position.set(-20.831, 4.287, 7.058);
    this.scene.add(this.dlight3);

    this.dlight4 = new DirectionalLight(0xffffff, 0.5);
    this.dlight4.position.set(10.272, 1.123, 7.5);
    this.scene.add(this.dlight4);

    // this.dlight1 = new DirectionalLight(0xffffff, 3);
    // this.dlight1.position.set(6.32, 5.57, 13.2);
    // this.scene.add(this.dlight1);

    // this.dlight2 = new DirectionalLight(0xffffff, 1);
    // this.dlight2.position.set(-7.81, 3.4, -17.88);
    // this.scene.add(this.dlight2);

    // const light = new AmbientLight(0x404040, 1);
    // scene.add(light);

    this.renderer = new WebGLRenderer({ canvas: canvas, antialias: true });
    this.renderer.setPixelRatio(window.devicePixelRatio);
    this.renderer.setSize(window.innerWidth, window.innerHeight);
    // renderer.toneMapping = ACESFilmicToneMapping;
    // renderer.toneMappingExposure = 1;
    // renderer.outputEncoding = sRGBEncoding;

    function animate() {
      self.id = requestAnimationFrame(animate);
      self.renderer.render(self.scene, self.camera);
      // console.log(self.camera.position);
      // console.log(self.camera.rotation);
    }
    animate();

    this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    this.controls.addEventListener('change', render); // use if there is no animation loop
    // controls.minDistance = 2;
    // controls.maxDistance = 10;
    // controls.target.set(0, 0, -0.2);
    this.controls.update();

    window.addEventListener('resize', onWindowResize);

    function onWindowResize() {
      self.camera.aspect = window.innerWidth / window.innerHeight;
      self.camera.updateProjectionMatrix();
      self.renderer.setSize(window.innerWidth, window.innerHeight);
      render();
    }

    function render() {
      self.renderer.render(self.scene, self.camera);
    }
  }

  zoomIn(): void {
    const fov = this.getFov();
    this.camera.fov = this.clickZoom(fov, 'zoomIn');
    this.camera.updateProjectionMatrix();
  }

  zoomOut(): void {
    const fov = this.getFov();
    this.camera.fov = this.clickZoom(fov, 'zoomOut');
    this.camera.updateProjectionMatrix();
  }

  clickZoom(value, zoomType): number {
    if (value >= 20 && zoomType === 'zoomIn') {
      return value - 5;
    } else if (value <= 75 && zoomType === 'zoomOut') {
      return value + 5;
    } else {
      return value;
    }
  }

  getFov(): number {
    return Math.floor(
      (2 *
        Math.atan(
          this.camera.getFilmHeight() / 2 / this.camera.getFocalLength()
        ) *
        180) /
        Math.PI
    );
  }

  getSafeBuildingsData(): void {
    if (this.quoteService.get_selected_spaceId() !== undefined) {
      this.facilitySelected = this.quoteService.get_selected_spaceId();
      this.building_spaceId = this.facilitySelected;
    } else {
      this.facilitySelected = 1;
      this.building_spaceId = 1;
    }
    this.getAirQualityDetails();
  }

  getAirQualityDetails = () => {
    this.quoteService
      .getAirQualityData(
        this.building_spaceId,
        this.getStartDateTime(),
        this.getEndDateTime(),
        this.timeZone
      )
      .subscribe(
        (data: any) => {
          if (data) {
            // console.log(data);
            this.airQualityData = data.iaqDatas[0].iaqDataPoints.filter(
              (item: any) =>
                item.groupName == 'Temperature' ||
                item.groupName == 'Humidity' ||
                item.groupName == 'Air Flow' ||
                item.groupName == 'Particulate Matter'
            );
            console.log(this.airQualityData);
          }
        },
        (err) => {
          console.log(err);
        }
      );
  };

  getStartDateTime() {
    let today = new Date();
    let startDate =
      today.getUTCFullYear() +
      '-' +
      (today.getUTCMonth() + 1) +
      '-' +
      today.getUTCDate() +
      'T' +
      '00:00:00';
    return startDate;
  }

  getEndDateTime() {
    var Onedayplus = new Date();
    Onedayplus.setUTCDate(Onedayplus.getUTCDate() + 1);
    let endDate =
      Onedayplus.getUTCFullYear() +
      '-' +
      (Onedayplus.getUTCMonth() + 1) +
      '-' +
      Onedayplus.getUTCDate() +
      'T' +
      '00:00:00';
    return endDate;
  }

  onNavigate(title: any) {
    if (title == 'safe') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'overview');
      this.service.showLocation.next(false);
      if (this.role == 'FacilityManager') {
        this.router.navigate(['/airquality'], {
          replaceUrl: true,
        });
      }
    } else if (title == 'workspace') {
      localStorage.setItem('headerText', 'Workspace Experience');
      localStorage.setItem('subHeaderText', 'overview');
      this.service.showLocation.next(true);
      if (this.role == 'FacilityManager') {
        localStorage.setItem('subHeaderText', 'Spaces');
        this.router.navigate(['/workspace/admin/zone-space'], {
          replaceUrl: true,
        });
        this.iotService.spaceId.next(this.spaceSelected);
      }
    } else if (title == 'energy') {
      this.service.showLocation.next(false);
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'overview');
      if (this.role == 'FacilityManager') {
        this.router.navigate(['/energy/overview'], {
          replaceUrl: true,
        });
      } else if (this.role == 'PortfolioManager') {
        this.router.navigate(['/energy/dashboard'], {
          replaceUrl: true,
        });
      }
    }
  }

  energySource() {
    this.EnergyService.energySource().subscribe((response: any) => {
      this.profiles = response;
      this.profileSelected = response[0];
      this.getFacilities();
    });
  }

  getFacilities() {
    this.facilityService
      .getFacilityByRegion()
      .subscribe((facilityList: any) => {
        const facilityArr: any = [];
        facilityList.forEach((facility: any) => {
          const obj: any = {
            facilityName: '',
            spaceId: '',
          };
          facility.spaceProperties.map((property: any) => {
            if (property.propertyKey === 'name') {
              obj.facilityName = property.propertyValue;
              obj.spaceId = facility.spaceId;
            }
          });
          if (!!obj.facilityName) {
            facilityArr.push(obj);
          }
        });
        this.facilities = facilityArr.reverse();

        this.activatedRoute.params.subscribe((params: any) => {
          if (params.spaceId) {
            this.spaceSelected = parseInt(params.spaceId, 10);
          } else {
            this.spaceSelected = 3130;
          }
        });
        this.changePeriod(this.period);
        this.getOccupancy(this.spaceSelected);
      });
  }

  changePeriod(value: any) {
    this.period = value.toLowerCase();
    if (this.period === 'today') {
      for (let index = 0; index < 4; index++) {
        let from: any;
        let end: any;
        if (index === 0) {
          from = moment().tz(this.timeZone).startOf('day').valueOf();
          end = moment().tz(this.timeZone).valueOf();
          const to = moment()
            .tz(this.timeZone)
            .endOf('day')
            .subtract(1, 'minute')
            .valueOf();
          localStorage.setItem('from', from);
          localStorage.setItem('to', end);
          this.getFacilityOverViewData(from, end);
          this.getRenewableResource(from, end);
        } else {
          from = moment()
            .tz(this.timeZone)
            .startOf('day')
            .subtract(index, 'year')
            .valueOf();
          end = moment()
            .tz(this.timeZone)
            .endOf('day')
            .subtract(1, 'second')
            .subtract(index, 'year')
            .valueOf();
        }
        this.getTodayData(from, end, index, this.profileSelected.apiParam);
      }
    }
  }

  getOccupancy(spaceId: any) {
    const current = moment().tz(this.timeZone).valueOf();
    console.log('time epoch ', current);
    this.EnergyService.getCurrentOccupancy(spaceId, current).subscribe(
      (occupancy: any) => {
        this.facilityData.capacity = parseInt(occupancy.capacity);
        this.facilityData.area = parseInt(occupancy.areaManaged);
        this.facilityData.currentOccupancy = parseInt(occupancy.occupancy);
        console.log(this.facilityData, current);
      }
    );
  }

  getRenewableResource(from: any, to: any) {
    this.EnergyService.getRenewableResource(
      this.spaceSelected,
      from,
      to
    ).subscribe((response: any) => {
      this.renewableResource = parseInt(response.percentage);
    });
  }

  getFacilityOverViewData(from: any, to: any) {
    let type;
    if (this.period.toLowerCase() === 'ytd') {
      type = 'year';
    } else if (this.period.toLowerCase() === 'month') {
      type = 'month';
    } else if (this.period.toLowerCase() === 'today') {
      type = 'day';
    }
    this.EnergyService.getFacilityOverview(
      this.spaceSelected,
      from,
      to,
      type
    ).subscribe((overviewData: any) => {
      console.log('overview data', overviewData);
      this.overviewDate = overviewData;
      this.totalCost = Math.round(overviewData.totalCost);
      this.co2Emission = Math.round(overviewData.co2 / 1000);
      const sourceData = this.overviewDate.energySourceBreakup;
      const index = sourceData.findIndex(
        (source: any) =>
          source.source.toLowerCase() ===
          this.profileSelected.sourceType.toLowerCase()
      );

      // // this.energyConsumption = sourceData[index].value;
      // // this.energyConsumption = overviewData.totalEnergy;
    });
  }

  getTodayData(from: any, end: any, index: any, source: any) {
    this.energyConsumption = 0;
    this.EnergyService.getFacilityTodayData(
      this.spaceSelected,
      from,
      end,
      source
    ).subscribe((resp: any) => {
      const data: any = [];
      resp.forEach((element: any) => {
        const time = moment(element.timestamp).tz(this.timeZone).format('HH');
        data.push([time, element.value]);
        if (index == 0) {
          this.energyConsumption = parseInt(
            this.energyConsumption + element.value
          );
        }
      });
    });
  }

  ngOnDestroy(): void {
    cancelAnimationFrame(this.id); // Stop the animation
    this.scene = null;
    this.camera = null;
    this.controls = null;
  }
}
