<div class="pop-up-header">
  <div class="header" style="margin-top: -7px;">
    Facility Details
  </div>
  <!-- <div class="close-pop-up">
    <img src="assets/Add.png" (click)="close()" alt="" />
  </div> -->
  <div class="remove-icon" (click)="close()"><i class="material-icons"> cancel</i></div>
</div>

<hr />
<mat-dialog-content>
  <div class="pop-up-content">
    <form [formGroup]="facilityDetailsForm">
      <div class="fields row">
        <div class="col-2">
          <mat-label class="label-input"> Facility Name <span>*</span> </mat-label>
          <input
            class="input-field"
            autocomplete="off"
            readonly
            matInput
            type="text"
            formControlName="facilityName"
            required
            #facilityName
          />
        </div>
        <div class="col-2">
          <mat-label class="label-input">Facility Type </mat-label>
          <!-- <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select formControlName="facilityType">
              <mat-option [value]="f" *ngFor="let f of facilityTypes">{{ f }}</mat-option>
            </mat-select>
          </mat-form-field> -->
          <input
            class="input-field"
            autocomplete="off"
            readonly
            matInput
            type="text"
            formControlName="facilityType"
            required
            #facilityType
          />
        </div>
        <div class="col-3">
          <mat-label class="label-input"> Capacity (Number of People)</mat-label>
          <input
            class="input-field"
            autocomplete="off"
            matInput
            type="number"
            maxlength="10"
            formControlName="facilityCapacity"
            required
            readonly
          />
        </div>
        <div class="col-2">
          <mat-label class="label-input"> Total Sq Ft Area</mat-label>
          <input
            class="input-field"
            autocomplete="off"
            matInput
            type="number"
            maxlength="10"
            placeholder=""
            formControlName="squareFeetArea"
            required
            readonly
          />
        </div>
        <div class="col-2 facility-upload location">
          <mat-label class="label-input"> Facility Layout</mat-label>
          <input
            [ngClass]="{ 'downloadable-file': isDownloadable == true }"
            class="input-field"
            autocomplete="off"
            matInput
            type="text"
            maxlength="10"
            placeholder=""
            formControlName="facilityLayout"
            (click)="downloadLayout()"
            required
            readonly
          />
        </div>
        <!-- (click)="downloadLayout()" -->
      </div>
      <hr />
      <div class="sub-heading">Space Mapping</div>
      <div class="fields row">
        <div class="col-2">
          <mat-label class="label-input">Region<span>*</span></mat-label>
          <!-- <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select [disabled]="true" formControlName="region">
              <mat-option [value]="f.globalRegionId" *ngFor="let f of regions">{{ f.name }}</mat-option>
            </mat-select>
          </mat-form-field> -->
          <input
            class="input-field"
            autocomplete="off"
            readonly
            matInput
            type="text"
            formControlName="region"
            required
            #region
          />
        </div>
        <div class="col-2 p-b-25 location">
          <mat-label class="label-input"> Location </mat-label>
          <input
            class="input-field"
            autocomplete="off"
            matInput
            type="text"
            placeholder=""
            formControlName="latlong"
            readonly
            style="width: 80%; color: #2e9ccc;"
          />
          <!-- <input class="input-field" autocomplete="off" matInput type="text" placeholder="" formControlName="latlong"
          (input)="onLatLong($event)" style="width: 80%; border-radius: 8px 0px 0 8px; border-right: 0;" /> -->
          <!-- <span
            style="
              border: 1px solid #e6e6e6;
              padding-left: 0;
              height: 50px;
              border-left: 0;
              border-radius: 0px 8px 8px 0px;
              margin-left: -4px;
              top: -1px;
              padding: 13.5px;
              position: relative;
              padding-bottom: 16px;
              background-color: #e6e6e6;
            "
          >
            <img class="location" src="assets/Group 1009.png" />
          </span> -->
        </div>
        <div class="col-3">
          <mat-label class="label-input"> Address Line 1<span>*</span></mat-label>
          <input
            class="input-field"
            autocomplete="off"
            matInput
            type="text"
            placeholder=""
            formControlName="addressline1"
            readonly
          />
        </div>
        <div class="col-2">
          <mat-label class="label-input"> Address Line 2<span>*</span></mat-label>
          <input
            class="input-field"
            autocomplete="off"
            matInput
            type="text"
            placeholder=""
            formControlName="addressline2"
            readonly
          />
        </div>
        <div class="col-2">
          <mat-label class="label-input">Country<span>*</span></mat-label>
          <!-- <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select
              [disabled]="true"
              formControlName="country"
              (selectionChange)="getStates()"
              (change)="getStates()"
            >
              <mat-option [value]="f.countryId" *ngFor="let f of countries">{{ f.countryName }}</mat-option>
            </mat-select>
          </mat-form-field> -->
          <input
            class="input-field"
            autocomplete="off"
            readonly
            matInput
            type="text"
            formControlName="country"
            required
            #country
          />
        </div>
        <div class="col-2">
          <mat-label class="label-input"> Country region <span>*</span></mat-label>
          <input
            class="input-field"
            autocomplete="off"
            matInput
            type="text"
            placeholder=""
            formControlName="countryregion"
            readonly
          />
        </div>
        <div class="col-2">
          <mat-label class="label-input"> Province State <span>*</span></mat-label>
          <!-- <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select [disabled]="true" formControlName="province" (selectionChange)="getCities()">
              <mat-option [value]="f.regionId" *ngFor="let f of states">{{ f.name }}</mat-option>
            </mat-select>
          </mat-form-field> -->
          <input
            class="input-field"
            autocomplete="off"
            readonly
            matInput
            type="text"
            formControlName="province"
            required
            #region
          />
        </div>
        <div class="col-3">
          <mat-label class="label-input">City<span>*</span> </mat-label>
          <!-- <mat-form-field appearance="outline" style="width: 100%;">
            <mat-select [disabled]="true" formControlName="city">
              <mat-option [value]="f.regionId" *ngFor="let f of cities">{{ f.name }}</mat-option>
            </mat-select>
          </mat-form-field> -->
          <input
            class="input-field"
            autocomplete="off"
            readonly
            matInput
            type="text"
            formControlName="city"
            required
            #city
          />
        </div>
        <div class="col-2">
          <mat-label class="label-input">Pincode<span>*</span> </mat-label>
          <input
            class="input-field"
            readonly
            autocomplete="off"
            matInput
            type="number"
            maxlength="10"
            formControlName="pincode"
          />
        </div>
      </div>
    </form>
  </div>
</mat-dialog-content>
