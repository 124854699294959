<mat-card class="asset-list">
    <div class="asset-list-top-section">
        <div class="asset-title">Assets List</div>
        <div class="add-icons">
          <div class="search" #search>
            <input type="text" matInput class="input-field" placeholder="Search" (keyup)="applyFilter($event)" #input />
            <span class="material-icons search-icon" (click)="toggleClass()">
              search
            </span>
          </div>
          <button mat-icon-button aria-label="" class="add-new" (click)="onAddNew()">
            <img src="assets/Add.svg" alt="" />
            <span style="margin-left: 10px;">ADD NEW</span>
          </button>
          <button mat-icon-button aria-label="" class="add-new upload-div">
            <img src="assets/Group 1780.svg" alt="" />
            <span style="margin-left: 10px;">BULK UPLOAD</span>
          </button>
        </div>
    </div>
    <div>
        <div class="asset-table-container">
            <table mat-table [dataSource]="assetDataSource" class="mat-custom-table">
              <!-- Employee Column -->
              <ng-container matColumnDef="system">
                <th mat-header-cell *matHeaderCellDef>System mapped to</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{ element.system }}</span>
                </td>
              </ng-container>
      
              <ng-container matColumnDef="asset">
                <th mat-header-cell *matHeaderCellDef>Asset Name</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{ element.asset }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="manufacturer">
                <th mat-header-cell *matHeaderCellDef>Manufacturer Name</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{ element.manufacturer }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="space">
                <th mat-header-cell *matHeaderCellDef>Space mapped to</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{ element.space }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="onboardDate">
                <th mat-header-cell *matHeaderCellDef>Onboard Date</th>
                <td mat-cell *matCellDef="let element">
                  <span>{{ element.date }}</span>
                </td>
              </ng-container>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  Actions
                </th>
                <td mat-cell *matCellDef="let element">
                  <div class="action-links">
                    <div class="edit">
                      <span> <img src="assets/images/Group 372.svg" alt="edit-icon"/></span>EDIT
                    </div>
                    <div class="delete">
                      <span> <img src="assets/images/Group 373.svg" alt="delete-icon"/></span>DELETE
                    </div>
                  </div>
                
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="columnDisplayOrder"></tr>
              <tr mat-row *matRowDef="let row; columns: columnDisplayOrder"></tr>
            </table>
          </div>
    </div>
</mat-card>
