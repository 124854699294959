import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdmitPopupComponent } from './admit-popup/admit-popup.component';
import { SecurityScanComponent } from './security-scan/security-scan.component';
import { MaterialModule } from '@app/material.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SecurityTabComponent } from './security-tab/security-tab.component';
import { ThermalScreenComponent } from './thermal-screen/thermal-screen.component';
import { SecurityRoutingModule } from './security-routing.module';
import { ThermalService } from './service/thermal.service';



@NgModule({
  declarations: [AdmitPopupComponent, SecurityScanComponent, SecurityTabComponent, ThermalScreenComponent],
  imports: [
    CommonModule,
    MaterialModule, 
    ReactiveFormsModule,
    SecurityRoutingModule
  ],
  providers: [ThermalService],
})
export class SecurityModule { }
