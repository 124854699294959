<div class="form-wrapper" *ngIf="showFormlist">
  <mat-card>
    <div class="form-title">
      <span>form list </span>
      <button mat-raised-button (click)="createForm()">Create New Form</button>
    </div>
    <hr class="hr-line" />

    <div class="table-container">
    <mat-table
      matTableFilter
      [exampleEntity]="filterEntity"
      [filterType]="filterType"
      [dataSource]="dataSource"
      class="formlisttable"
    >
      <ng-container matColumnDef="formTitle">
        <mat-header-cell *matHeaderCellDef class="col-2.5">Form Title</mat-header-cell>
        <mat-cell class="col-2.5" *matCellDef="let element">
          <span *ngIf="element.action != 'editdelete'" class="col-link" (click)="previewForm(element.surveyForm)">{{
            element.formTitle
          }}</span>
          <span *ngIf="element.action == 'editdelete'">{{ element.formTitle }}</span>
          <span *ngIf="element.isStartDateGreater">*</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="locationName">
        <mat-header-cell class="col-1.5" *matHeaderCellDef>
          <ng-container *ngIf="countryFilters === true" class="country-form">
            <mat-form-field appearance="outline" style="padding-right: 12px; width: 170px;">
              <mat-label>Country / Facility </mat-label>

              <input matInput [(ngModel)]="filterEntity.surveyForm.locationName" />
              <mat-icon
                matSuffix
                class="icon-color"
                style="cursor: pointer; position: absolute; bottom: 12px; left: -12px;"
                (click)="clearCountryFilter()"
              >
                cancel
              </mat-icon>
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="countryFilters === false"
            >Country / Facility
            <mat-icon
              class="icon-color filtericon"
              style="cursor: pointer; height: auto !important;"
              (click)="applyCountryFilter()"
            >
              filter_list_alt</mat-icon
            >
          </ng-container>
        </mat-header-cell>
        <mat-cell class="col-1.5" *matCellDef="let element">{{ element.surveyForm.locationName }}</mat-cell>
      </ng-container>

      <ng-container matColumnDef="startDate">
        <mat-header-cell class="col-1" *matHeaderCellDef>Start Date</mat-header-cell>
        <mat-cell class="col-1" *matCellDef="let element">
          <span *ngIf="element.startDate"
            >{{ element.startDate | date: 'dd MMM' }}<br />{{ element.startDate | date: 'hh:mm a' }}</span
          >
          <span *ngIf="!element.startDate">NA</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="endDate">
        <mat-header-cell class="col-1" *matHeaderCellDef>End Date</mat-header-cell>
        <mat-cell class="col-1" *matCellDef="let element">
          <span *ngIf="element.endDate"
            >{{ element.endDate | date: 'dd MMM' }}<br />{{ element.endDate | date: 'hh:mm a' }}</span
          >
          <span *ngIf="!element.endDate">NA</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="status">
        <mat-header-cell class="col-1" *matHeaderCellDef style="padding-left: 0px !important;">
          <ng-container *ngIf="applyFilters === true" class="country-form">
            <mat-form-field appearance="outline" style="padding-right: 15px; width: 100px;">
              <mat-label>Status</mat-label>

              <input matInput [(ngModel)]="filterEntity.status" />
              <mat-icon
                matSuffix
                class="icon-color"
                style="
                  cursor: pointer;
                  width: 20px;
                  height: 23px;
                  display: block;
                  position: absolute;
                  bottom: 12px;
                  left: -12px;
                "
                (click)="clearFilters()"
                >cancel</mat-icon
              >
            </mat-form-field>
          </ng-container>
          <ng-container *ngIf="applyFilters === false"
            >Status
            <mat-icon class="icon-color filtericon" style="cursor: pointer; height: auto !important;" (click)="showFilters()">
              filter_list_alt</mat-icon
            >
          </ng-container>

          <!-- <mat-icon style="color: #2e9ccc;" (click)="showFilters()">filter_list</mat-icon> -->
        </mat-header-cell>

        <mat-cell class="col-1" *matCellDef="let element" style="padding-left: 0px !important;">
          <span
            style="white-space: nowrap;"
            [ngClass]="{
              'not-published-color': element.status.toLowerCase() === 'not published',
              'live-color': element.status.toLowerCase() === 'live',
              'completed-color': element.status.toLowerCase() === 'completed',
              'expired-color': element.status.toLowerCase() === 'expired',
              'published-color': element.status.toLowerCase() === 'published'
            }"
          >
            <span
              class="divider-border"
              [ngClass]="{
                'divider-border-un-published': element.status.toLowerCase() === 'not published',
                'divider-border-live': element.status.toLowerCase() === 'live',
                'divider-border-completed': element.status.toLowerCase() === 'completed',
                'divider-border-expired': element.status.toLowerCase() === 'expired',
                'divider-border-published': element.status.toLowerCase() === 'published'
              }"
            ></span
            >{{ element.status }}</span
          >
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="response">
        <mat-header-cell class="col-1" *matHeaderCellDef>Response <br />Received</mat-header-cell>
        <mat-cell class="col-1" *matCellDef="let element">
          <span *ngIf="element.status.toLowerCase() != 'not published'">
            {{ element.response }} <span *ngIf="element.employeeCount">&nbsp;/&nbsp; {{ element.employeeCount }}</span>
          </span>
          <span *ngIf="element.status.toLowerCase() === 'not published'">-</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="accessDenied">
        <mat-header-cell class="col-1" *matHeaderCellDef>Access <br />Denied</mat-header-cell>
        <mat-cell class="col-1" *matCellDef="let element">
          <span *ngIf="element.status.toLowerCase() != 'not published'">
            <span *ngIf="element.accessDenied > 0" class="col-link" (click)="deniedEmployees(element.surveyId)">{{
              element.accessDenied
            }}</span>
            <span *ngIf="element.accessDenied == 0">{{ element.accessDenied }}</span>
          </span>
          <span *ngIf="element.status.toLowerCase() === 'not published'">-</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell class="col-2.5" *matHeaderCellDef> Action </mat-header-cell>
        <mat-cell class="col-2.5" *matCellDef="let element">
          <div class="action" *ngIf="element.action == 'editdelete'">
            <div (click)="edit(element, 'edit')">
              <img *ngIf="!isLightTheme" class="icon" src="assets/Group 648.png" /><img
                *ngIf="isLightTheme"
                class="icon"
                src="assets/Light-Theme/Group 372.png"
              />Edit
            </div>
            <div class="divider"></div>
            <div (click)="delete(element)">
              <img *ngIf="!isLightTheme" class="icon delete" src="assets/Group 649.png" /><img
                *ngIf="isLightTheme"
                class="icon"
                src="assets/Light-Theme/Group 373.png"
              />Delete
            </div>
          </div>
          <div class="action" *ngIf="element.action == 'copytemplate'">
            <div (click)="edit(element, 'copy')">
              <img *ngIf="!isLightTheme" class="icon" src="assets/Group 769.png" /><img
                *ngIf="isLightTheme"
                class="icon"
                src="assets/Light-Theme/Group 769.png"
              />Copy Template
            </div>
          </div>
          <div class="action" *ngIf="element.action == 'withdraw'">
            <div (click)="edit(element, 'copy')">
              <img *ngIf="!isLightTheme" class="icon" src="assets/Group 769.png" /><img
                *ngIf="isLightTheme"
                class="icon"
                src="assets/Light-Theme/Group 769.png"
              />Copy Template
            </div>
            <div class="divider"></div>
            <div *ngIf="!element.disableStopPublishButton" (click)="stoppublish(element)">
              <img *ngIf="!isLightTheme" class="icon" src="assets/Group 1400.png" /><img
                *ngIf="isLightTheme"
                class="icon"
                src="assets/Light-Theme/Group 1400.png"
              />Stop Publish
            </div>
            <div class="withdraw" *ngIf="element.disableStopPublishButton">
              <img *ngIf="!isLightTheme" class="icon disable" src="assets/Group 1400.png" /><img
                *ngIf="isLightTheme"
                class="icon disable"
                src="assets/Light-Theme/Group 1400.png"
              />Stop Publish
            </div>
          </div>
        </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
  <mat-paginator *ngIf=isMobileView #paginator [pageSize]="10" >
  </mat-paginator>
  
  <div class="mobile-add-icon" (click)="createForm()">
    <img src="assets/Group 4471.svg" alt="add" />
  </div>
    <div *ngIf="surveyData.length == 0 && showNoData" class="nodata">
      <span class="circle">!</span> No forms has been created yet . Click on <strong> Create New Form </strong> to
      create a form
      <hr class="" />
    </div>
  </mat-card>
  <!--<mat-paginator [hidden]="!showDshboard" #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]">
</mat-paginator> -->
</div>
<div class="" *ngIf="!showFormlist">
  <app-create-form
    [surveryObj]="masterSurveyData"
    [actionType]="actionType"
    [editSurveyId]="editSurveyId"
    (toggleComponent)="showComponent($event)"
  >
  </app-create-form>
</div>
