import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { CredentialsService, Empdetails } from './credentials.service';

export interface LoginContext {
  username: string;
  password: string;
  remember?: boolean;
}

/**
 * Provides a base for authentication workflow.
 * The login/logout methods should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService {
  constructor(private credentialsService: CredentialsService, private http: HttpClient) {}

  /**
   * Authenticates the user.
   * @param context The login parameters.
   * @return The user credentials.
   */
  login(context: LoginContext): Observable<Empdetails> {
    // Replace by proper authentication call
    let data = {
      username: context.username,
      isEmployee: 0,
      isFacility: 0,
      token: '123456',
    };
    // if (context['role'].length > 0) {
    //   context['role'].forEach((element: any) => {
    //     if (element.toLowerCase() === 'employee') data.isEmployee = 1;
    //     else if (element.toLowerCase() === 'facility') data.isFacility = 1;
    //   });
    // }
    this.credentialsService.setEmployeeDetails(data, context.remember);
    return of(data);
  }

  /**
   * Logs out the user and clear credentials.
   * @return True if the user was logged out successfully.
   */
  logout(): Observable<boolean> {
    // Customize credentials invalidation here
    this.credentialsService.setEmployeeDetails();
    return of(true);
  }

  getRoles(data: any) {
    return this.http.post(environment.TS_baseURL + 'user-login/api/v1/user', data, { responseType: 'text' });
  }
}
