import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { OrganizationConfigService } from './organization-config.service';

@Component({
  selector: 'app-organization-config',
  templateUrl: './organization-config.component.html',
  styleUrls: ['./organization-config.component.scss']
})
export class OrganizationConfigComponent implements OnInit {

  orgConfigForm: FormGroup;
  measurementsList: any;
  currencyList: any;
  KPIList: any;
  languageList: any;
  dateFormatList: any = ['DD/MM/YYYY', 'MM/DD/YYYY'];
  selectedFacilityTypes: any = ['Laboratory', 'Head Quarters'];
  selectedRegionsList: any = ['JAPA', 'APAC'];
  selectedFile: File;
  organizationId: any = 1; //static
  parentOrganizationId = 0; //static
  organizationType = {
    "organizationTypeId": 1,
    "type": "IT",
    "description": "Information Technology",
    "isRoot": true
  } //static
  disableUploadButton: boolean = true;

  constructor(private fb: FormBuilder, private service : OrganizationConfigService) { }

  ngOnInit(): void {
    this.createConfigForm();
    this.getLanguageList();
    this.getMeasurementsList();
    this.getKPIList();
    this.getCurrencyList();
    this.getOrganizationDetails();
  }

  createConfigForm() {
    this.orgConfigForm = this.fb.group({
      organizationName: ['', Validators.required],
      logo: ['', Validators.required],
      regions: ['', Validators.required],
      facilityTypes: ['', Validators.required],
      measurement: ['', Validators.required],
      kpi: ['', Validators.required],
      language: ['', Validators.required],
      currency: ['', Validators.required],
      dateFormat: ['', Validators.required]
    });
  }
  removeRegion(id: number){
    this.selectedRegionsList.splice(id,1);
  }
  addRegion(event: any){
    this.selectedRegionsList.push(event.value);
    if (event.input) {
      event.input.value = '';
    }
  }

  getOrganizationDetails(){
    this.service.getOrganizationDetailsById(this.organizationId).subscribe((data: any)=>{
      // console.log(data);
      let obj = {}
      data.organizationProperties.forEach(ele => {
        if(ele.propertyKey == 'name')
          obj['name'] = ele.propertyValue
        if(ele.propertyKey == 'logo')
          obj['logo'] = this.extractLogoName(ele.propertyValue)
        if(ele.propertyKey == 'defineRegions')
          obj['defineRegions'] = JSON.parse(ele.propertyValue)
        if(ele.propertyKey == 'facilityTypes')
          obj['facilityTypes'] = JSON.parse(ele.propertyValue)
        if(ele.propertyKey == 'measurement')
          obj['measurement'] = parseInt(ele.propertyValue)
        if(ele.propertyKey == 'kpi')
          obj['kpi'] = JSON.parse(ele.propertyValue)
        if(ele.propertyKey == 'language')
          obj['language'] = parseInt(ele.propertyValue)
        if(ele.propertyKey == 'currency')
          obj['currency'] = parseInt(ele.propertyValue)
        if(ele.propertyKey == 'dateFormat')
          obj['dateFormat'] = ele.propertyValue
      });
      // console.log(obj);
      this.orgConfigForm.patchValue({
        organizationName: obj['name'],
        logo: obj['logo'],
        measurement: obj['measurement'],
        kpi: obj['kpi'],
        language: obj['language'],
        currency: obj['currency'],
        dateFormat: obj['dateFormat']
      });
      this.selectedRegionsList = obj['defineRegions'];
      this.selectedFacilityTypes = obj['facilityTypes'];
    });
  }

  //getting jargon string with 5 hyphens before actual logoname from api so name needs to be extracted
  extractLogoName(logoName: string){
    let hyphenCounter = 0;
    let i;
    for(i=0;i<logoName.length;i++){
      if(logoName[i]=='-')
        hyphenCounter++;
      if(hyphenCounter == 5)
        break;
    }
    if(hyphenCounter == 5)
      return logoName.substring(i+1);
    return logoName;
  }

  getCurrencyList(){
    this.service.getCurrencyList().subscribe((data: any)=>{
      this.currencyList = data;
    });
  }

  getKPIList(){
    this.service.getKPIList().subscribe((data: any)=>{
      this.KPIList = data;
    });
  }

  getLanguageList(){
    this.service.getLanguageList().subscribe((data: any)=>{
      this.languageList = data;
    });
  }

  getMeasurementsList(){
    this.service.getMeasurementsList().subscribe((data: any)=>{
      this.measurementsList = data;
    });
  }
  removeFacilityType(id: number){
    this.selectedFacilityTypes.splice(id,1);
  }
  addFacilityType(event: any){
    this.selectedFacilityTypes.push(event.value);
    if (event.input) {
      event.input.value = '';
    }
  }
  onFileChanged(files: FileList) {
    let fileName = files[0].name;
    this.orgConfigForm.patchValue({
      logo: fileName
    });
    this.selectedFile = files[0];
    this.disableUploadButton = false;
  }
  uploadLogo(){
    const formData: FormData = new FormData();
    formData.append('file', this.selectedFile, this.selectedFile.name);
    this.service.uploadLogoImage(this.organizationId, formData).subscribe((data: any) => {
      // console.log(data,data.status);
      if(data.status === 200){
        this.disableUploadButton = true;
      }
    });
  }
  createOrganizationProperty(propertyId: any, key: any, value: any){
    let property = {
      organizationPropertyId: propertyId,
      organizationId: null,
      propertyKey: key,
      propertyValue: value,
      propertyType: 'string'
    }
    return property;
  }

  //Method to extract organization data from form
  getOrganizationFormData(){ 
    let reqObj = {
      organizationId: this.organizationId,
      parentOrganizationId: this.parentOrganizationId,
      organizationType: this.organizationType,
      organizationProperties: []
    }
    let regions = JSON.stringify(this.selectedRegionsList)
    let facilities = JSON.stringify(this.selectedFacilityTypes)
    let kpis = JSON.stringify(this.orgConfigForm.value.kpi)
    let propertiesArray = [
      this.createOrganizationProperty(1,'name',this.orgConfigForm.value.organizationName),
      this.createOrganizationProperty(2,'defineRegions', regions),
      this.createOrganizationProperty(4,'facilityTypes', facilities),
      this.createOrganizationProperty(5,'measurement', this.orgConfigForm.value.measurement),
      this.createOrganizationProperty(6,'kpi', kpis),
      this.createOrganizationProperty(7,'language', this.orgConfigForm.value.language),
      this.createOrganizationProperty(8,'currency', this.orgConfigForm.value.currency),
      this.createOrganizationProperty(9,'dateFormat', this.orgConfigForm.value.dateFormat)
    ];
    reqObj.organizationProperties = propertiesArray;
    // console.log(JSON.stringify(reqObj))
    return reqObj;
  }

  saveOrganizationData(){
    let requestObj = this.getOrganizationFormData();
    this.service.updateOrganizationData(this.organizationId, requestObj).subscribe((data)=>{
      ;
    });
  }
}
