<div class="facility hadn-hygiene" id="scrollstyle">
    <div class="row">
      <div class="col-12">
        <mat-card class="hh-alerts extent">
          <div class="facilityHeader">
            <div class="title" style="display: flex; flex-wrap: wrap;">
              <span id="back" (click)="navigateToDashboard()">
                <i class="material-icons">keyboard_arrow_left</i>
                <span class="dashboard">DASHBOARD <span style="font-size: 16px; font-weight: 500;">| </span></span>
              </span>
              <span>HAND HYGIENE ALERTS</span>
            </div>
          </div>
          <div style="display: flex; flex-direction: column; justify-content: space-between; border-bottom: 1px solid var(--horizontal-line-divider);position: relative;
      bottom: 10px;">
            <div class="filter">
              <div class="filter-option" [ngClass]="{ active: filter == 'today' }" (click)="filterBy('today')">Today</div>
              <div class="filter-option" [ngClass]="{ active: filter == 'week' }" (click)="filterBy('week')">
                Last 7 days
              </div>
              <div class="filter-option" [ngClass]="{ active: filter == 'month' }" (click)="filterBy('month')">
                Last 30 days
              </div>
              <!-- <div class="filter-option custom" [ngClass]="{ active: filter == 'custom' }"
                (click)="filterBy('custom'); openDialog()">
                Custom Range
              </div> -->
              <div *ngIf="showCustomRange">
                <div style="height: 24px; margin-top: 17px; border-left: 1px solid #5e5e72; text-transform: uppercase;">
                  <span class="dateRange" style="margin-left: 10px;">{{ fromMon }}-{{ toMon }}
                    <span style="padding-left: 5px;" (click)="openDialog()"><img style="margin-bottom: 5px;"
                        src="assets/calendar (1).svg" /></span>
                  </span>
                </div>
              </div>
            </div>
            <div class="toggle-style" style="display: flex; justify-content: space-between; width: 12%;">
              <!-- <div class="report">Auto Reporting</div>
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input" id="customSwitch1" checked />
                <label class="custom-control-label" for="customSwitch1"></label>
              </div> -->
            </div>
          </div>
          <div class="row justify-content-center"
            style="border-bottom: 1px solid var(--horizontal-line-divider); padding-top: 1%; padding-bottom: 1%;">
            <div class="col-md-3 col-xl-3 text-left facilityStyles">
              <span>Facility-{{ facility }}</span>
            </div>

            <div class="col-md-9 col-xl-9 badge_styles"
              style="display: flex; justify-content: space-between; align-items: center; justify-content: center;">
              <div class="container">
                <div class="row scrollStyle">
                  <div class="col-md-2 col-xl-1 my-auto">
                    <div class="buildingStyles">Building</div>
                  </div>
                  <div class="col-md-10 col-xl-11 my-auto" style="display: flex;">
                    <div class="horizontalArrows">
                      <mat-icon (click)="scrollPrev()">keyboard_arrow_left</mat-icon>
                    </div>
                    <div *ngIf="buildingList" id="buildinglist" class="buildingListStyles">
                      <div *ngFor="let building of buildingList; let i = index"
                        (click)="selectBuilding(building.spaceId, i)"
                        [ngClass]="activeBadge === i ? 'badge-pill highlightedBuilding' : ''">
                        <span class="badge badge-pill" style="height: 24px; padding-top: 4%;">
                          {{ building.spaceName }}
                          <span class="badge badge-pill buildingAlert">{{ building.noncompliantCount }}</span></span>
                      </div>
                    </div>
                    <div style="padding-left: 2%; margin-left: auto;">
                      <mat-icon class="horizontalArrows" (click)="scrollNext()">keyboard_arrow_right</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 col-xl-4 buildingOverview">{{ buildingOverviewSelected }} BUILDING OVERVIEW:</div>

            <div class="col-md-12 col-xl-8">
              <div class="section">
                <div class="row">
                  <div class="col-xl-4">
                    <div class="overview fever">
                      <div>
                        <div class="count" style="padding-top: 20px; padding-bottom: 10px;">
                          {{ buildingSelectedAlerts }}
                        </div>
                        <div class="divider">
                          <div class="line"></div>
                          <!-- <div class="point"></div> -->
                        </div>
                        <div class="summryText">Alerts</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="overview normal">
                      <div>
                        <div class="count" style="padding-top: 20px; padding-bottom: 10px;">
                          {{ buildingSelectedOccupancy }}
                        </div>
                        <div class="divider">
                          <div class="line"></div>
                          <!-- <div class="point"></div> -->
                        </div>
                        <div class="summryText">Capacity</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xl-4">
                    <div class="overview">
                      <div>
                        <div class="count" style="padding-top: 20px; padding-bottom: 10px;">
                          {{ buildingSelectedNotifiedAsc }}
                        </div>
                        <div class="divider">
                          <div class="line"></div>
                          <!-- <div class="point"></div> -->
                        </div>
                        <div class="summryText">Associates Notified</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
    <div class="row" style="margin-right: 0px; margin-bottom: 2%;">
      <div class="col-xl-2 floor-styles" *ngIf="!isMobileView" style="
          height: 70vh;
          display: flex;
          flex-direction: column;
          min-height: 453px;
          background-clip: content-box !important;
        ">
        <div class="arrow-styles">
          <mat-card>
            <mat-icon class="iconClass" (click)="scrollUp()">keyboard_arrow_up </mat-icon>
          </mat-card>
        </div>
        <div>
          <mat-card style="padding-bottom: 0px !important;">
            <span class="allText">ALL
              <span class="badge badge-pill floorAlert" style="margin-left: 8px;">{{ buildingSelectedAlerts }}</span>
            </span>
          </mat-card>
        </div>
        <div id="floorList" class="card-styles"
          style="overflow-y: hidden; display: flex; flex-direction: column; cursor: pointer;">
          <mat-card *ngFor="let floor of selectedFloorArray; let j = index" (click)="selectFloor(j)"
            [ngClass]="activeFloorBadge === j ? 'activeFloor' : ''">
            <span [ngClass]="activeFloorBadge === j ? 'activeFloorColor' : 'selectedFloor'">{{ floor.spaceName }}
              <span class="badge badge-pill floorAlert" style="margin-left: 8px;">{{ floor.noncompliantCount }}</span>
            </span>
          </mat-card>
        </div>
        <div class="card-styles" style="margin-top: auto;">
          <mat-card>
            <mat-icon class="iconClass" (click)="scrollDown()"> keyboard_arrow_down</mat-icon>
          </mat-card>
        </div>
      </div>
      <div class="categories dropdown" *ngIf="isMobileView">
        <mat-form-field appearance="outline" class="align">
           <!-- <mat-label style="color: white;" class="float-label"> Floor &nbsp;</mat-label> -->
           <mat-select style="color: white;"  (selectionChange)="selectFloor($event.value)"
           [value]="activeFloorBadge">
             <mat-option *ngFor="let floor of selectedFloorArray; let i = index" [value]="i"> {{ floor.spaceName }} </mat-option>
           </mat-select>
         </mat-form-field>
       </div>
      <div class="col-xl-10" style="padding: 0px 0px 0px 10px;">
        <mat-card class="extent summary-card-size">
          <div class="chart">
            <div class="chart-title-bar">
              <div class="chart-title">
                HAND HYGIENE ALERTS SUMMARY
                <div *ngIf="history.length > 1" class="current-display">
                  <span>D {{ history[history.length - 1]?.label }}</span>
                </div>
              </div>
              <div class="legends">
                <div class="hyper-display" *ngIf="history.length > 1" (click)="navigateback(history[history.length - 2])">
                  <span *ngIf="history[history.length - 2].view == 'week'">W - </span>
                  <span *ngIf="history[history.length - 2].view == 'month'">M - </span>
                  {{ history[history.length - 2]?.label }}
                </div>
              </div>
            </div>
            <span *ngIf="isMobileView && !isLandscape">Please rotate your screen to get a better visual of the graph</span>
            <div class="chart-drill" [ngStyle]="isLandscape ? { display: 'block' } : { display: 'none' }">
              <canvas id="myChart" #chart height="100" (click)="chartClick($event)"></canvas>
            </div>
          </div>
        </mat-card>
      </div>
      <!-- <div class="col-xl-3 d-flex justify-content-center" style="padding-right: 0px;">
        <mat-card style="height: 70vh; min-width: 299px; min-height: 453px; min-width: 300px; padding-top: 0px;">
          <div class="mapStyles" style="padding-bottom: 3%;">{{ selectedFloor }} LAYOUT</div>
          <div *ngIf="!floorData" class="nofloor"></div>
          <app-hand-hygiene-heatmap *ngIf="floorData" [floorData]="floorData"> </app-hand-hygiene-heatmap>
        </mat-card>
      </div> -->
    </div>

    <div class="summry row">
      <div class="col-xl-3">
        <mat-card class="extent floor-card-size">
          <div class="section" style="height: 100%;">
            <div class="title" style="display: flex; justify-content: center;">{{ selectedFloor }} OVERVIEW</div>
            <div style="display: flex; flex-direction: column; justify-content: space-between; height: 100%;">
              <div class="overview fever">
                <div>
                  <div class="count">{{ floorAlerts }}</div>
                  <div class="divider">
                    <div class="line"></div>
                    <!-- <div class="point"></div> -->
                  </div>
                  <div class="summryText">Alerts</div>
                </div>
              </div>

              <div class="overview normal">
                <div>
                  <div class="count">{{ floorOccupancy }}</div>
                  <div class="divider">
                    <div class="line"></div>
                    <!-- <div class="point"></div> -->
                  </div>
                  <div class="summryText">Capacity</div>
                </div>
              </div>

              <div class="overview">
                <div>
                  <div class="count">{{ floorNotifiedAsc }}</div>
                  <div class="divider">
                    <div class="line"></div>
                    <!-- <div class="point"></div> -->
                  </div>
                  <div class="summryText">Associates Notified</div>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <div class="col-xl-9">
        <mat-card class="extent table-card-size">
          <div class="section people">
            <div class="row" style="width: 100%;">
              <div class="col-xl-8 title">{{ selectedFloor }} : NON COMPLIANT ASSOCIATES</div>
              <div class="col-xl-1"></div>
              <div class="col-xl-3 dropdown">
                <mat-form-field appearance="outline" class="align">
                  <mat-select [(value)]="fiterValue" (selectionChange)="filterChange(fiterValue)">
                    <mat-option *ngFor="let filter of filters" [value]="filter.value">
                      {{ filter.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <!-- <div class="title">{{ selectedFloor }} : NON COMPLIANT ASSOCIATES</div> -->
          </div>
          <div [hidden]="!hideDiscrete" class="peopleTable" style="height: 82vh;">
            <div class="table-container">
              <table mat-table [dataSource]="AGGREGATE_TABLE_DATA" #aggregateSort="matSort" matSort
                class="mat-custom-table">
                <ng-container matColumnDef="employeeName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Associate Name</th>
                  <td class="personStyles" mat-cell *matCellDef="let element">
                    {{ element.employeeName }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="numberOfAlerts">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Alerts</th>
                  <td mat-cell *matCellDef="let element">{{ element.numberOfAlerts }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="discrete_displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: discrete_displayedColumns"></tr>
              </table>
              <div *ngIf="dataNotFound" style="padding-top: 20px; text-align: center;">Table has no records to display
              </div>
            </div>
          </div>
          <div [hidden]="hideDiscrete" class="peopleTable" style="height: 88vh; overflow-y: auto;">
            <div class="table-container">
              <table mat-table [dataSource]="TABLE_DATA" matSort #discreteSort="matSort" class="mat-custom-table">
                <ng-container matColumnDef="personName">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Associate Name</th>
                  <td class="personStyles" mat-cell *matCellDef="let element">
                    {{ element.personName }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="spaceId">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Alerts</th>
                  <td mat-cell *matCellDef="let element">{{ element.spaceId }}</td>
                </ng-container>

                <ng-container matColumnDef="timeStamp">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Last Timestamp</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.timeStamp | date: 'dd MMM, hh:mm' }}
                    {{ element.timeStamp | date: 'a':'+0000' | lowercase }}
                  </td>
                </ng-container>
                <ng-container matColumnDef="complianceFlag">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header class="w-20">Action</th>
                  <td mat-cell *matCellDef="let element">{{ element.complianceFlag === '1' ? 'Email Sent' : '-' }}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
              <div *ngIf="dataNotFound" style="padding-top: 20px; text-align: center;">Table has no records to display
              </div>
            </div>
          </div>
        </mat-card>
      </div>
      <mat-paginator [hidden]="TABLE_DATA.filteredData.length === 0" [pageSize]="10"
        [ngClass]="{hidePaginator:hideDiscrete}" [pageSizeOptions]="[5, 10, 25, 100]"
        (page)="getDiscretePageData($event)">
      </mat-paginator>
      <mat-paginator [hidden]="AGGREGATE_TABLE_DATA.filteredData.length === 0" [ngClass]="{hidePaginator:!hideDiscrete}"
        [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]" (page)="getAggregatePageData($event)">
      </mat-paginator>
    </div>
  </div>
