<mat-card>
  <!--<div class="title">{{formTitle}}</div>-->
  <div class="title">
    <div>Stop Publish</div>
    <div>
      <img *ngIf="!isLightTheme" (click)="cancel('close')" src="assets/Group 99.png" alt="" />
      <img *ngIf="isLightTheme" (click)="cancel('close')" src="assets/Light-Theme/Group 99.png" alt="" />
    </div>
  </div>
  <div class="seperator-space"></div>
  <mat-divider></mat-divider>
  <div class="seperator-space"></div>
  <div class="message">
    There will not be any active form in future. Do you want to stop this form?
  </div>
  <div class="cancel-btn">
    <button (click)="cancel('close')" mat-button>Cancel</button>
  </div>
  <div class="pub-btn">
    <button mat-button [mat-dialog-close]="data">Stop Publish</button>
  </div>
</mat-card>
<!--<div class="root">
  <div class="title">
    <div>Stop Publish</div>
    <div><img (click)="cancel('close')" src="assets/Group 99.png" alt="" /></div>
  </div>
  <div class="seperator-space"></div>
  <div class="message">
    There will not be any active form in future. Do you want to stop this form?
  </div>
  <div class="btn">
    <button mat-button class="btn-reset" color="primary" (click)="cancel('close')">CANCEL</button>
    <button mat-button class="btn-save" color="primary" [mat-dialog-close]="data">Stop Publish</button>
  </div>
</div>-->