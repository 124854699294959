import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class IotServiceService {
  sensorbaseUrl = environment.apiUrl + 'sensor/';
  masterApiBaseUrl = environment.apiUrl + 'master/';
  deviceEnrollmentApiBaseUrl = environment.apiUrl + 'device/enrollment/';
  meetingOverviewBase = environment.apiUrl + 'report/';
  meetingRoomAlerts = environment.apiUrl + 'alert/';
  searchQuery = new BehaviorSubject(null);
  spaceId = new BehaviorSubject(null);
  // private meetinRoomData = new BehaviorSubject({});
  // currentMeetingData = this.meetinRoomData.asObservable();
  constructor(private http: HttpClient) {}

  // changeMeetingData(data: any) {
  //   this.meetinRoomData.next(data);
  // }

  getSpaceTypes(forSensor: boolean, categoryId: any = '') {
    let url = this.masterApiBaseUrl + 'SpaceType?';
    if (categoryId) {
      url += 'CategoryId=' + categoryId + '&';
    }
    if (forSensor) {
      return this.http.get(url + 'ForSensor=' + forSensor);
    } else {
      return this.http.get(url + 'ForDevice=' + !forSensor);
    }
  }
  getMeetingRoomOverview(spaceItem: any, spaceType: any) {
    return this.http.get(this.meetingOverviewBase + `?SpaceItemId=${spaceItem}&SpaceTypeItemId=${spaceType}`);
  }
  getMeetingRoomAlerts(id: any, offset: number, limit: number, spaceType: any) {
    return this.http.get(
      this.meetingRoomAlerts +
        `?SpaceItemId=${id}&SpaceitemTypeId=${spaceType}&OrderBy=DESC&SortBy=AlertDateTime&Offset=${offset}&Limit=${limit}`
    );
  }
  getZoneRoomOverview(spaceItem: any, spaceType: any) {
    return this.http.get(this.meetingOverviewBase + `?SpaceItemId=${spaceItem}&SpaceTypeItemId=${spaceType}`);
  }

  getSensorList(
    searchString: any,
    id: any,
    spaceTypeId: any,
    categoryId: any,
    gatewayId: any,
    spaceTypeItemId: any,
    offset: any,
    limit: any
  ) {
    if (id === 'all') {
      return this.http.get(
        `${this.sensorbaseUrl}?SensorSerialNumber=${searchString}&GatewayId=${gatewayId}&SpaceTypeId=${spaceTypeId}&SpaceTypeItemId=${spaceTypeItemId}&CategoryId=${categoryId}&Offset=${offset}&Limit=${limit}`
      );
    } else {
      return this.http.get(
        `${this.sensorbaseUrl}?SensorSerialNumber=${searchString}&spaceId=${id}&GatewayId=${gatewayId}&SpaceTypeId=${spaceTypeId}&SpaceTypeItemId=${spaceTypeItemId}&CategoryId=${categoryId}&Offset=${offset}&Limit=${limit}`
      );
    }
  }
  enablesensor(json: any) {
    return this.http.put(this.sensorbaseUrl + `EnableSensor`, json);
  }
  addSensor(json: any) {
    return this.http.post(this.sensorbaseUrl, json);
  }
  updateSensor(json: any) {
    return this.http.put(this.sensorbaseUrl, json);
  }
  deleteSensor(id: any) {
    return this.http.delete(this.sensorbaseUrl + `?sensorId=${id}`);
  }

  getVendors() {
    return this.http.get(this.masterApiBaseUrl + 'Vendor');
  }

  getCategories(vendorId: any = '') {
    let url = this.masterApiBaseUrl + 'DeviceSensorCategories';
    if (vendorId) url += '?VendorId=' + vendorId;
    return this.http.get(url);
  }

  getGatewayList(deviceTypeId: number) {
    return this.http.get(this.deviceEnrollmentApiBaseUrl + 'devices?deviceTypeId=' + deviceTypeId);
  }

  getGatewayListByCategory(category: any, deviceTypeId: number) {
    return this.http.get(
      this.deviceEnrollmentApiBaseUrl + 'devices?categoryId=' + category + '&deviceTypeId=' + deviceTypeId
    );
  }

  getProducts(vendorId: any = '', categoryId: any = '') {
    return this.http.get(
      environment.apiUrl + 'assetmodel/assetModel?VendorId=' + vendorId + '&CategoryId=' + categoryId
    );
  }

  getDeviceTypes() {
    return this.http.get(this.masterApiBaseUrl + 'IotDeviceType');
  }

  sensorBulkUpload(file: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(this.sensorbaseUrl + 'BulkUploadSensor', file);
  }

  getSensorBulkTemplate() {
    return this.http.get(`${this.sensorbaseUrl}SensorTemplate`, { responseType: 'blob' });
  }
}
