import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { finalize } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';
import {CredentialsService} from './credentials.service';
import {environment} from '../../environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy  {

  version: string | null = '';
  error: string | undefined;
  loginForm!: FormGroup;
  isLoading = false;
  isIncorrect: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private credentialsService: CredentialsService,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    authenticationService.logout();    
    this.createForm();
  }
  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    this.authenticationService.logout();
    localStorage.setItem('headerText','OPERATIONAL EFFICIENCY');
    localStorage.setItem('subHeaderText','OVERVIEW');
  }

  login() {
    let user = {
      username: this.loginForm.value.username,
      password: this.loginForm.value.password,
    };
    this.authenticationService.getRoles(user).subscribe(
      (res: any) => {
        res = JSON.parse(res);
        if (res.employeeId) {
          this.credentialsService.role = res.authRole;
          this.credentialsService.userObj = res;
          localStorage.setItem('role', res.authRole);
          localStorage.setItem('userdetails', JSON.stringify(res));
          console.log('has role of ', res.authRole);
          this.loginForm.patchValue({
            role: res.authRole,
          });
          this.isLoading = true;
          const login$ = this.authenticationService.login(this.loginForm.value);
          login$
            .pipe(
              finalize(() => {
                this.loginForm.markAsPristine();
                this.isLoading = false;
              }),
              // untilDestroyed(this)
            )
            .subscribe(
              (credentials) => {
                this.credentialsService.Authenticated = true;
                // log.debug(`${credentials.username} successfully logged in`);
                if (!!res.employeeId) {
                  this.credentialsService.role = res.authRole;
                  localStorage.setItem('role', res.authRole);
                  localStorage.setItem('userdetails', JSON.stringify(res));
                  console.log(this.credentialsService.role);
                  this.router.navigate(['/overview']);
                  // if (this.credentialsService.role === 'employee') {
                  //   this.router.navigate(['/employee'], { replaceUrl: true });
                  // } else if (this.credentialsService.role === 'facility') {
                  //   this.router.navigate(['/racetrac'], {
                  //     replaceUrl: true,
                  //   });
                  // } else if (this.credentialsService.role === 'admin') {
                  //   this.router.navigate(['/admin'], {
                  //     replaceUrl: true,
                  //   });
                  // } else if (this.credentialsService.role === 'security') {
                  //   if (environment.TS_enable) {
                  //     this.router.navigate(['/security'], {
                  //       replaceUrl: true,
                  //     });
                  //   }
                  // } else if (this.credentialsService.role === 'hr') {
                  //   this.router.navigate(['/hrView'], {
                  //     replaceUrl: true,
                  //   });
                  // }
                }
              },
              (error) => {
                // log.debug(`Login error: ${error}`);
                this.error = error;
              }
            );
        } else {
          this.error = 'no role';
          console.log(this.error);
          this.isIncorrect = true;
        }
      },
      (err) => {
        console.log('API Failed ', err.status);
        err.status === 401 ? (this.error = 'Invalid credentials') : undefined;
      }
    );
  }

  private createForm(){
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      role: [''],
      remember: true,
    });
  }

}
