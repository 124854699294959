<mat-card class="facility-list row">
  <div class="onbord col-12">
    <div class="list-header">
      Facilities List
    </div>
    <div class="add-icons">
      <div class="search" #search>
        <input type="text" matInput class="input-field" placeholder="Search" (keyup)="applyFilter($event)" #input />
        <span class="material-icons search-icon" (click)="toggleClass()">
          search
        </span>
      </div>
      <button mat-icon-button aria-label="" class="add-new" (click)="onBoard()">
        <img src="assets/Add.svg" alt="" />
        <span style="margin-left: 10px;">ADD NEW</span>
      </button>
      <button mat-icon-button aria-label="" class="add-new upload-div" (click)="bulkUpload()">
        <img src="assets/Group 1780.svg" alt="" />
        <!-- <img src="assets/Group 983.png" class="upload" alt="" /> -->
        <span style="margin-left: 10px;">BULK UPLOAD</span>
        <!-- <input type="file" class="file" (change)="upload($event.target.files)" /> -->
      </button>
    </div>
  </div>
  <hr class="border-line" />
  <div class="table filter">
    <mat-table [dataSource]="dataSource" class="temperaturetable" matSort>
      <ng-container matColumnDef="facilityName">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Facility Name </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.facilityName }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="facilityType">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Facility Type </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.facilityType }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="faciitySqFeet">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Gross Total Area (sq.ft) </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.faciitySqFeet }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="noOfBuildings">
        <mat-header-cell *matHeaderCellDef mat-sort-header> No. of Buildings </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.noOfBuildings }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="region">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Region </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.region }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="country">
        <mat-header-cell *matHeaderCellDef mat-sort-header> Country </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.country }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="city">
        <mat-header-cell *matHeaderCellDef mat-sort-header> City </mat-header-cell>
        <mat-cell *matCellDef="let element"> {{ element.city }} </mat-cell>
      </ng-container>

      <ng-container matColumnDef="action">
        <mat-header-cell *matHeaderCellDef> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Action </mat-header-cell>
        <mat-cell *matCellDef="let element">
          <div class="action">
            <div (click)="edit(element)"><img class="icon" src="assets/Group 372.svg" />View & Edit</div>
            <div class="divider"></div>
            <div (click)="delete(element)"><img class="icon" src="assets/Group 373.svg" />Delete</div>
          </div>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
  </div>
</mat-card>
<mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25, 100]"> </mat-paginator>
