<mat-card class="employee-survey" *ngIf="isFromDescription && !isFeedback" style="width: 540px;">
    <!--<div class="title">{{ formTitle }}</div>-->
    <div class="title">
      <div>{{ formTitle }}</div>
      <div>
        <img *ngIf="!isLightTheme" (click)="cancelSurvey()" src="assets/Group 99.png" alt="" />
        <div class="close-btn" *ngIf="isLightTheme" (click)="cancelSurvey()">
          <div>x</div>
        </div>
      </div>
    </div>
    <div class="seperator-space"></div>
    <mat-divider></mat-divider>
    <div class="seperator-space"></div>
    <p class="">
      {{ formDescription }}
      <!-- The safety of our employees, supplier partners, customers, families and visitors remain our priority. As the
      coronavirus disease 2019 (COVID-19) outbreak continues to evolve and spreads globally, Our Medical and Security are
      monitoring the situation closely and will periodically update company guidance based on current recommendations from
      the Centers for Disease Control and Prevention and the World Health Organization.Only business critical visitors are
      permitted at any of our facility at this time.
    </p>
    <p>
      To prevent the spread of COVID-19 and reduce the potential risk of exposure to our workforce and visitors, we are
      conducting a simple screening questionnaire. Your participation is important to help us take precautionary measures
      to protect you and everyone in this building. Thank you for your time.
    </p> -->
    </p>
  
    <div class="start-btn">
      <button (click)="surveyForm()" mat-button>Start Survey</button>
    </div>
    <div class="mob-start-btn" style="margin-bottom: 10px;">
      <button (click)="surveyForm()" mat-button>Start Survey</button>
    </div>
  </mat-card>
  
  <mat-card *ngIf="!isFromDescription && !isFeedback">
    <!--<div class="title">{{ formTitle }}</div>-->
    <div class="title">
      <div>{{ formTitle }}</div>
      <div>
        <img *ngIf="!isLightTheme" (click)="cancelSurvey()" src="assets/Group 99.png" alt="" />
        <div class="close-btn" *ngIf="isLightTheme" (click)="cancelSurvey()">
          <div>x</div>
        </div>
      </div>
    </div>
    <div class="seperator-space"></div>
    <mat-divider></mat-divider>
    <div class="seperator-space"></div>
    <form [formGroup]="employeeSurveyForm">
      <div class="" *ngFor="let question of questionSet; let index = index">
        <div class="" *ngIf="question.responseType.toLowerCase() === 'radio'">
          <p style="margin-bottom: 6px;">{{ index + 1 }}. {{ question.question }}</p>
          <mat-radio-group formControlName="{{ question.questionId }}">
            <mat-radio-button *ngFor="let option of question.questionOptions" value="{{ option }}"
              >{{ option }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
  
        <div class="" *ngIf="question.responseType.toLowerCase() === 'checkbox'">
          <p>{{ index + 1 }}. {{ question.question }}</p>
          <div style="display: flex;">
            <div class="" *ngFor="let option of question.questionOptions" style="margin-right: 10px;">
              <mat-checkbox (change)="changeCheckbox($event, question.questionId)" value="{{ option }}">
                {{ option }}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div class="submit-btn" *ngIf="questionSet.length > 0">
      <button (click)="submitDeclaration()" [disabled]="!employeeSurveyForm.valid" mat-button>SUBMIT</button>
    </div>
    <div class="mob-submit-btn" style="margin-bottom: 10px;" *ngIf="questionSet.length > 0">
      <button (click)="submitDeclaration()" [disabled]="!employeeSurveyForm.valid" mat-button>SUBMIT</button>
    </div>
  </mat-card>
  
  <div class="feedback" *ngIf="isFeedback && !isAccessProvided">
    <mat-card>
      <div class="title">FEEDBACK</div>
      <div class="seperator-space"></div>
      <mat-divider></mat-divider>
      <div class="seperator-space"></div>
      <p>Thank you for taking time to complete survey.</p>
      <div class="info-box">
        <div class="circle-red">X</div>
        <p>
          Based on your response, your access is <strong>Disabled</strong>. <br />
          Please reach out to HR for more information.
        </p>
      </div>
      <div class="feedback-btn">
        <button mat-button (click)="complianceHistory()">
          DONE
        </button>
      </div>
      <div class="mobile-feedback-btn">
        <button mat-button (click)="complianceHistory()">
          DONE
        </button>
      </div>
    </mat-card>
  </div>
  
  <div class="feedback" *ngIf="isFeedback && isAccessProvided">
    <mat-card>
      <div class="title">FEEDBACK</div>
      <div class="seperator-space"></div>
      <mat-divider></mat-divider>
      <div class="seperator-space"></div>
      <p>Thank you for taking time to complete survey.</p>
      <div class="info-box">
        <div class="circle-green">&#10003;</div>
        <p style="padding-top: 10px;">
          Use your QR code to get access to facility
        </p>
      </div>
      <div class="feedback-btn">
        <button mat-button (click)="complianceHistory()">
          DONE
        </button>
      </div>
      <div class="mobile-feedback-btn">
        <button mat-button (click)="complianceHistory()">
          DONE
        </button>
      </div>
    </mat-card>
  </div>
  