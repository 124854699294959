import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from '@env/environment';
import { globalConstant } from '@app/safe-building/safebuilding-global-constants';
import { FormGroup, FormControl } from '@angular/forms';
import { ThermalService } from '../service/thermal.service';
import { AppService } from '@app/app.service';
import { HTTPStatus } from '@app/workspace-experience/@core/services/auth-interceptor';

@Component({
  selector: 'app-security-scan',
  templateUrl: './security-scan.component.html',
  styleUrls: ['./security-scan.component.scss'],
})
export class SecurityScanComponent implements OnInit {
  timer: any;
  latestScannedEmp: any;
  facilities: any = [];
  isLightTheme: boolean = environment.isLightTheme;
  timeZone: any = globalConstant.timeZone;
  selectedFacility: any = localStorage.getItem('selectedFacility');
  selectedBuilding: any = localStorage.getItem('selectedBuilding');
  selectedLocation: any = localStorage.getItem('selectedLocation');
  selectedSpaceId: any;
  securityLocation = new FormGroup({
    facility: new FormControl(''),
    building: new FormControl(''),
    floor: new FormControl(''),
  });
  buildings: any;
  floors: any;
  floorValue: any;
  waitingScan: boolean;
  position: string;
  initialSetFloorValue: any;
  @Output() error: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() floorChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  loader: boolean;

  constructor(
    private service: ThermalService,
    private appservice: AppService,
    private httpStatus: HTTPStatus
  ) {}
  ngOnDestroy(): void {
    // clearInterval(this.timer);
  }
  ngOnInit(): void {
    this.service.getFacilityByRegion().subscribe(
      (data: any) => {
        if (data) {
          const facility: any = [];
          let count = 0;
          data.map((value: any, spaceIndex: number) => {
            let facName = '';
            value.spaceProperties.map((property: any, index: number) => {
              if (property.propertyKey === 'name') {
                count++;
                facName = property.propertyValue;
              }
            });
            if (!!facName) {
              facility.push({
                spaceId: value.spaceId,
                faciityName: facName,
              });
            }
          });
          this.facilities = facility;
          console.log(facility[0]);
          if (localStorage.getItem('selectedFacility') == null) {
            localStorage.setItem('selectedFacility', facility[0].faciityName);
            this.selectedFacility = localStorage.getItem('selectedFacility');
          }
          this.selectedFacility = localStorage.getItem('selectedFacility');
          //inside facility we are passing default first record space id to get first building value
          this.service
            .getSpaceDetailsByParentSpace(facility[0].spaceId)
            .subscribe(
              (buildings: any) => {
                //  console.log(buildings)
                const buildingsDrilldown: any = [];
                buildings.map((value: any) => {
                  let buildingName = '';
                  value.spaceProperties.map((property: any) => {
                    if (property.propertyKey === 'name') {
                      buildingName = property.propertyValue;
                    }
                  });
                  if (!!buildingName) {
                    buildingsDrilldown.push({
                      spaceId: value.spaceId,
                      buildingName: buildingName,
                    });
                  }
                });
                this.buildings = buildingsDrilldown;
                this.floors = [];
                console.log(buildingsDrilldown[0]);
                if (localStorage.getItem('selectedBuilding') == null) {
                  localStorage.setItem(
                    'selectedBuilding',
                    buildingsDrilldown[0].buildingName
                  );
                  this.selectedBuilding =
                    localStorage.getItem('selectedBuilding');
                }
                this.service
                  .getSpaceDetailsByParentSpace(buildingsDrilldown[0].spaceId)
                  .subscribe(
                    (floors: any) => {
                      //  console.log(floors);

                      const floorsDrilldown: any = [];

                      floors.map((value: any) => {
                        let floorName = '';
                        value.spaceProperties.map(
                          (property: any, index: number) => {
                            if (property.propertyKey === 'name') {
                              floorName = property.propertyValue;
                            }
                          }
                        );
                        if (!!floorName) {
                          floorsDrilldown.push({
                            spaceId: value.spaceId,
                            floorNumber: floorName,
                          });
                        }
                      });

                      this.floors = floorsDrilldown;
                      console.log(floorsDrilldown[0]);
                      if (localStorage.getItem('selectedLocation') == null) {
                        localStorage.setItem(
                          'selectedLocation',
                          floorsDrilldown[4].floorNumber
                        );
                        this.selectedLocation =
                          localStorage.getItem('selectedLocation');
                        this.initialSetFloorValue = floorsDrilldown[4].spaceId;
                      } else {
                        this.initialSetFloorValue = floorsDrilldown[4].spaceId;
                      }
                      this.every2Seconds();
                    },
                    (err) => console.log(err)
                  );
              },
              (err) => console.log(err)
            );
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
    // this.every2Seconds();
    this.position = 'above';
    this.appservice.currentTheme.subscribe(
      (theme) => (this.isLightTheme = theme == 'theme-light' ? true : false)
    );
    /* this.timer = setInterval(() => {
       this.every2Seconds();
     }, 2000); // 2 Seconds */
  }
  every2Seconds() {
    if (this.securityLocation.value.floor) {
      this.floorValue = this.securityLocation.value.floor;
    } else if (localStorage.getItem('selectedSpaceId') != null) {
      this.floorValue = localStorage.getItem('selectedSpaceId');
    } else {
      this.floorValue = this.initialSetFloorValue;
    }
    this.loader = true;
    this.service
      .getEmployeeNotification(this.floorValue, this.timeZone)
      .subscribe(
        (empData: any) => {
          this.loader = false;
          if (empData.employeeID != 0) {
            //  console.log(empData);
            this.waitingScan = false;
            var temp_value = empData.temperature;
            var roundedTemp = Math.round(temp_value * 10) / 10;
            this.latestScannedEmp = {
              employeeID: empData.employeeID,
              isElevatedTemperature: empData.isElevatedTemperature,
              isMaskDetected: empData.isMaskDetected,
              isPermitted: empData.isPermitted,
              isR2OPermitted: empData.isR2OPermitted,
              timeStamp: empData.timeStamp,
              temperature: roundedTemp,
            };
          } else {
            this.waitingScan = true;
            this.latestScannedEmp = { isPermitted: true, timeStamp: '' };
          }
        },
        (err: any) => {
          console.log(err);
          this.loader = false;
          this.httpStatus.setHttpStatus(false);
          this.error.emit(true);
        }
      );
  }

  getFacilityTooltip() {
    return this.selectedFacility;
  }
  getBuildingTooltip() {
    return this.selectedBuilding;
  }
  getFloorTooltip() {
    return this.selectedLocation;
  }

  facilitySelection(fac: any) {
    let target = fac.source.selected._element.nativeElement;
    let selectedData = {
      text: target.innerText.trim(),
    };
    this.selectedFacility = selectedData.text;
    localStorage.setItem('selectedFacility', selectedData.text);
    this.selectedSpaceId = this.securityLocation.value.facility;
    //  console.log(this.securityLocation.value);

    this.service.getSpaceDetailsByParentSpace(this.selectedSpaceId).subscribe(
      (buildings: any) => {
        //  console.log(buildings)
        const buildingsDrilldown: any = [];
        buildings.map((value: any) => {
          let buildingName = '';
          value.spaceProperties.map((property: any) => {
            if (property.propertyKey === 'name') {
              buildingName = property.propertyValue;
            }
          });
          if (!!buildingName) {
            buildingsDrilldown.push({
              spaceId: value.spaceId,
              buildingName: buildingName,
            });
          }
        });
        this.buildings = buildingsDrilldown;
        this.floors = [];
      },
      (err) => console.log(err)
    );
  }

  buildingSelection(event: any) {
    let target = event.source.selected._element.nativeElement;
    let selectedData = {
      text: target.innerText.trim(),
    };
    this.selectedBuilding = selectedData.text;
    localStorage.setItem('selectedBuilding', selectedData.text);
    this.selectedSpaceId = this.securityLocation.value.building;
    console.log(this.securityLocation);
    this.service.getSpaceDetailsByParentSpace(this.selectedSpaceId).subscribe(
      (floors: any) => {
        //  console.log(floors);

        const floorsDrilldown: any = [];

        floors.map((value: any) => {
          let floorName = '';
          value.spaceProperties.map((property: any, index: number) => {
            if (property.propertyKey === 'name') {
              floorName = property.propertyValue;
            }
          });
          if (!!floorName) {
            floorsDrilldown.push({
              spaceId: value.spaceId,
              floorNumber: floorName,
            });
          }
        });

        this.floors = floorsDrilldown;

        //  console.log(floorsDrilldown);
      },
      (err) => console.log(err)
    );
  }

  floorSelection(event: any) {
    let target = event.source.selected._element.nativeElement;
    let selectedData = {
      text: target.innerText.trim(),
    };
    console.log(this.securityLocation.value);
    this.selectedLocation = selectedData.text;
    localStorage.setItem('selectedLocation', selectedData.text);
    localStorage.setItem('selectedSpaceId', this.securityLocation.value.floor);
    this.floorChanged.emit(true);
  }
}
