<div class="qrdiv">
    <div>
      <span class="qrtitle">QR code</span>
      <span (click)="onCloseClick()" class="qrclose">x</span>
      <!-- <span (click)="onCloseClick()" class="material-icons" style="color: brown; background-color: #fff; float: right;"
        >highlight_off</span
      > -->
  
      <div *ngIf="qrCodeDetails">
        <qrcode [qrdata]="qrCodeDetails" [width]="240"></qrcode>
        <div class="qraccesstest">Scan this qr code for access</div>
      </div>
    </div>
  </div>