import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
// import { MsAdalAngular6Service } from 'microsoft-adal-angular6';
import { BehaviorSubject, Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
// import { AdalConfigService } from './adal-config.service';
// import { AdalService } from './adal.service';
// import { AppConfig, APP_CONFIG } from './app.config';
@Injectable()
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<boolean>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }

  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  activeRequests: number = 0;

  constructor(
    private router: Router,
    // private adalService: AdalService,
    // @Inject(APP_CONFIG) private config: AppConfig,
    private status: HTTPStatus,
    // private adalSvc: MsAdalAngular6Service,
    // private adalConfigService: AdalConfigService,
    private malauthService: MsalService
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.activeRequests === 0 &&
      this.router.url != '/safe-buildings/security'
    ) {
      this.status.setHttpStatus(true);
    }
    this.activeRequests++;

    let token = localStorage.getItem('azureToken');
    if (token) {
      req = req.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }
    return next.handle(req).pipe(
      finalize(() => {
        this.activeRequests--;
        if (this.activeRequests === 0) {
          this.status.setHttpStatus(false);
        }
      })
    );
    // const tokenRequest: any = {
    //   scopes: [this.config.resources.resourceScope],
    // };

    /* return this.adalSvc.acquireToken('token').pipe(
      mergeMap((token) => {
        console.log('token', token);
        // if (token) {
        //   req = req.clone({
        //     setHeaders: {
        //       Authorization: 'Bearer ' + token,
        //     },
        //   });
        // }
        return next.handle(req).pipe(
          finalize(() => {
            this.activeRequests--;
            if (this.activeRequests === 0) {
              this.status.setHttpStatus(false);
            }
          })
        );
      })
    ); */
  }
}
