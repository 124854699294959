import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HrViewComponent } from './hr-view/hr-view.component';
import { NonCompliantAssociateComponent } from './non-compliant-associate/non-compliant-associate.component';
import { ModifyAssociateDetailsComponent } from './modify-associate-details/modify-associate-details.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatTableFilterModule } from 'mat-table-filter';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { SuccessmodalComponent } from './successmodal/successmodal.component';
import { QuarantineTrackerComponent } from './quarantine-tracker/quarantine-tracker.component';
import { AddDayPipe } from './quarantine-tracker/pipe/add-day.pipe';
import { CountDayPipe } from './quarantine-tracker/pipe/count-day.pipe';
import { CreateFormComponent } from './questionnaire/create-form/create-form.component';
import { DeleteSurveyComponent } from './questionnaire/delete-survey/delete-survey.component';
import { DeniedListComponent } from './questionnaire/denied-list/denied-list.component';
import { EditFormComponent } from './questionnaire/edit-form/edit-form.component';
import { FormListComponent } from './questionnaire/form-list/form-list.component';
import { PreviewFormComponent } from './questionnaire/preview-form/preview-form.component';
import { PublishFormComponent } from './questionnaire/publish-form/publish-form.component';
import { StopPublishComponent } from './questionnaire/stop-publish/stop-publish.component';
import { NgxPanZoomModule } from 'ngx-panzoom';
import { HrAccessViewRoutingModule } from './hraccess-view.routing.module';

@NgModule({
  declarations: [
    HrViewComponent,
    NonCompliantAssociateComponent,
    ModifyAssociateDetailsComponent,
    SuccessmodalComponent,
    QuarantineTrackerComponent,
    AddDayPipe,
    CountDayPipe,
    CreateFormComponent,
    DeleteSurveyComponent,
    DeniedListComponent,
    EditFormComponent,
    FormListComponent,
    PreviewFormComponent,
    PublishFormComponent,
    StopPublishComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    FlexLayoutModule,
    MatTableFilterModule,
    NgxPanZoomModule,
    HrAccessViewRoutingModule,
    BsDatepickerModule.forRoot(),
  ]
})
export class HraccessViewModule { }
