import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrganizationConfigService {
  baseUrl = "https://connectedstore-ingress-dev.eastus.cloudapp.azure.com/space-management";
  
  constructor(private http: HttpClient) { }

  getKPIList() {
    return this.http.get(this.baseUrl + `/kpi`);
  }
  getMeasurementsList() {
    return this.http.get(this.baseUrl + `/measurment`);
  }
  getLanguageList() {
    return this.http.get(this.baseUrl + `/language`);
  }
  getCurrencyList() {
    return this.http.get(this.baseUrl + `/currency`);
  }
  getOrganizationDetailsById(organizationId: any){
    return this.http.get(this.baseUrl + `/organization/${organizationId}`);
  }
  uploadLogoImage(organizationId: any, file: any) {
    return this.http.post(this.baseUrl + `/organization/${organizationId}/logo`, file, {observe: 'response'});
  }
  updateOrganizationData(organizationId: any, body: any){
    return this.http.post(this.baseUrl + `/organization`, body);
  }
}
