import { environment } from '@env/environment';

const baseUrl = environment.apiUrl + 'astellasmeet/'; // for getMeetingRoom, my bookings(meeting room)
const baseUrl_1 = environment.apiUrl + 'meet/'; // for bookMeetingRoom
const baseUrl_2 = environment.apiUrl + 'deskbooking/'; // my bookings (desk)
const baseUrl_3 = environment.apiUrl + 'visitor/'; // visitor management
const baseUrl_5 = environment.apiUrl + '/graph/'; // graph api
const baseUrl_6 = environment.apiUrl + '/workspace/space-management/'; //space management api for location
// const baseUrl_4 = environment.apiUrl + 'deskbooking/'; // desk booking
const baseUrl_7 = environment.apiUrl + '/meeting/';

export const apiPath = {
  myMeetingRoomBooking: environment.meetingRoomUrl + 'BookingList',
  bookMeetingRoom: environment.meetingRoomUrl,
  updateMeetingRoom: environment.meetingRoomUrl,
  peakHours: environment.meetingRoomUrl,
  nearestMeetingRoom: environment.meetingRoomUrl + 'GetMeetingRoomDetails?',
  cancelMeeting: environment.meetingRoomUrl + '?',
  getMeetingRoomFeatures: environment.meetingRoomUrl + 'GetMeetingRoomFeatures',
  getMeetingRoomServices: environment.meetingRoomUrl + 'GetMeetingServices',
  updateUserLocation: environment.meetingRoomUrl + 'UpdateLocation',

  myDeskBooking: baseUrl_2 + 'GetDeskDetailsByUserId?',
  getUser: baseUrl_5 + 'GetUserByname?name=',
  sendInvites: baseUrl_5 + 'SendEmail',
  blockCalendar: baseUrl_5 + 'PostMeetingRequest',
  getVisitorIdProof: baseUrl_3 + 'GetProofTypes',
  getVisitorDetails: baseUrl_3 + 'VisitorDetails?',
  visitorEntry: baseUrl_3 + 'VisitorEntry',
  visitorEntryDeclined: baseUrl_3 + 'VisitorEntryDeclined?',
  visitorExit: baseUrl_3 + 'VisitorExit?',
  cancelDesk: baseUrl_2 + 'CancelDeskBooking?bookingId=',

  getCityValue: baseUrl_6 + 'region/regionType/3',
  getFacilityValue: baseUrl_6 + 'space/root/region/',
  getBuildingValue: baseUrl_6 + 'space/parent/',
  getCategoryList: baseUrl_7 + 'Categories',
};
