<mat-card>
  <div class="form-title">
    <div class="title">
      <img *ngIf= isMobileView class="back" src="../../../../../assets/Path 7285.svg" (click)="cancleSurvey()">
      create new form <span class="or">| or</span>
      <div *ngIf= isMobileView class="mob-preview">
        <span
          *ngIf="
            formCollection.valid &&
              formTitle &&
              formDescription &&
              checkSelected &&
              !titleFlag;
            else nopreview
          "
          class="preview"
          (click)="previewForm()"
        >
          <img *ngIf="!isLightTheme" src="assets/Group 767.png" alt="" /><img
            *ngIf="isLightTheme"
            src="assets/Light-Theme/Group 767.png"
            alt=""
          />Preview Form</span
        >
        <ng-template #nopreview>
          <span class="nopreview">
            <img *ngIf="!isLightTheme" src="assets/Group 767.png" alt="" /><img
              *ngIf="isLightTheme"
              src="assets/Light-Theme/Group 767.png"
              alt=""
            />Preview Form</span
          >
        </ng-template>
        </div>
    </div>
    <div class="temp-selector">
      <div class="selector">
        <mat-label class="template-label pos-select-label">Template</mat-label>
        <mat-form-field appearance="outline">
          <mat-select
            id="survey"
            class="selectorStyle"
            [disableOptionCentering]="true"
            style="font-size: 14px; text-transform: none"
            [(ngModel)]="surveySelectedValue"
            (selectionChange)="changeSurvey($event)"
          >
            <mat-option [value]="ele" *ngFor="let ele of masterSurveryTitles">
              <div class="option-wrapper">
                <div class="value">{{ ele.formTitle }}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  </div>

  <hr class="" />
  <div class="row">
    <div class="col-12 form-select" style="display: flex; justify-content: space-between">
      <div class="col-4 custom-col">
        <mat-label class="label-input">Creating Form at</mat-label>
        <mat-radio-group
          aria-label="Select an option"
          style="padding-top: 10px; display: flex"
          [(ngModel)]="selection"
          (change)="onSelectionChange($event)"
        >
          <mat-radio-button value="1">Facility Level</mat-radio-button>
          <mat-radio-button value="2">Country Level</mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="col-4 selector custom-col pad-col">
        <mat-label
          *ngIf="formCreateAt === 'facility'"
          class="label-input pos-select-label"
          style="padding-left: 10px; padding-bottom: 4px"
          >Select Facility
          <sup class="mandatory">*</sup>
        </mat-label>
        <mat-label
          *ngIf="formCreateAt === 'country'"
          class="label-input pos-select-label"
          style="padding-left: 10px; padding-bottom: 4px"
        >Select Country
        <sup class="mandatory">*</sup>
        </mat-label>
        <mat-form-field
          *ngIf="formCreateAt === 'facility'"
          appearance="outline"
          style="width: 100% !important; padding-left: 0px !important"
        >
          <mat-select
            [disableOptionCentering]="true"
            style="font-size: 14px; text-transform: none"
            [value]="facilityLevel"
            (selectionChange)="facilitySelection($event)"
          >
            <mat-option [value]="val.viewValue" *ngFor="let val of facilities">
              <div class="option-wrapper">
                <div class="value">{{ val.viewValue }}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          *ngIf="formCreateAt === 'country'"
          appearance="outline"
          style="width: 100% !important; padding-left: 0px !important"
        >
          <mat-select
            [disableOptionCentering]="true"
            style="font-size: 14px; text-transform: none"
            [value]="countryLevel"
            (selectionChange)="countrySelection($event)"
          >
            <mat-option [value]="val.countryName" *ngFor="let val of countries">
              <div class="option-wrapper">
                <div class="value">{{ val.countryName }}</div>
              </div>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="col-4 custom-col pad-col" style="padding-right: 0">
        <mat-label class="label-input pos-label"
          >Form Title
          <span class="red" *ngIf="titleFlag"
            >&nbsp;&nbsp;Form already Exist</span
          >
          <sup class="mandatory">*</sup>
        </mat-label>
        <input
          matInput
          (blur)="titleCheck()"
          type="text"
          autocomplete="off"
          class="input-field"
          [(ngModel)]="formTitle"
          placeholder="Enter Form Title"
        />
      </div>
    </div>
  </div>

  <div class="col-12 p-0 pad-col">
    <mat-label class="label-input pos-label">Form Description <sup class="mandatory">*</sup></mat-label>
    <!--<input matInput type="text" autocomplete="off" class="input-field" [(ngModel)]="formDescription"
      placeholder="Enter Form Description" />-->
    <textarea
      class="description"
      id="scrollstyle"
      [ngClass]="{ edit: editSurveyId }"
      [(ngModel)]="formDescription"
      placeholder="Enter Form Description"
    ></textarea>
  </div>
  <hr class="" />
  <div class="form-title questionnaire" style="margin-bottom: 20px; font-size: 24px">
    <span>Add Questionnaire</span>
  </div>
  <div class="question-des">
    Note - If the answer is “Yes” to any of the questions, access to the
    facility will be denied.
  </div>
  <hr class="" />
  <div class="ques">
  <div class="fields row ques-heading">
    <div class="col-4 q-question">
      <mat-label class="label-input">Questions<sup class="mandatory">*</sup></mat-label>
    </div>
    <div class="col-3 q-response-type">
      <div>
        <mat-label class="label-input">Response Type</mat-label>
      </div>
    </div>
    <div class="col-4 q-response-values">
      <div>
        <mat-label class="label-input">Response Values</mat-label>
      </div>
    </div>
    <div class="col-1 q-action">
      <div>
        <mat-label class="label-input">Action</mat-label>
      </div>
    </div>
  </div>
  <hr class="no-line" />
  <div class="ques-form" *ngFor="let form of formCollection.controls; index as i">
    <form [formGroup]="form">
      <div class="fields row">
        <div class="col-4 f-question">
          <!-- <input matInput autocomplete="off" class="input-field" type="text" formControlName="question" required /> -->
          <textarea
            id="scrollstyle"
            class="edit-question"
            formControlName="question"
            placeholder="Enter Question"
            required
          ></textarea>
        </div>
        <div class="col-3 f-response-type">
          <div class="res-options">
            <mat-radio-group formControlName="radioSelection">
              <mat-radio-button
                class="font-14"
                (change)="selectResponse($event, i, 'radio')"
                value="radio"
                >Radio Button(Single Select)
              </mat-radio-button>
            </mat-radio-group>
            <br />
            <!--<mat-checkbox class="font-14" formControlName="checkboxBoxselection"
              (change)="selectResponse($event,i,'checkbox')" value="checkbox">
              Check
              Box(Multiple Select)</mat-checkbox>-->
          </div>
        </div>
        <!--<div class="col-4">
          <input matInput autocomplete="off" class="input-field" type="text" formControlName="response" required />
        </div>-->
        <div class="col-4 f-response-value">
          <div class="response-placeholder">
            <div>
              <!--<div *ngIf="chipListEnable[i]">-->
              <mat-chip-list #chipList>
                <!--style="color: #ffff;"-->
                <mat-chip
                  *ngFor="let res of responseTypeArr[i]"
                  (removed)="removeRes(res, i)"
                  class="chipcolor"
                >
                  {{ res }}
                  <!--style="color: #2e9ccc;"-->
                  <mat-icon matChipRemove>cancel</mat-icon>
                </mat-chip>
                <input
                  [placeholder]="
                    responseTypeArr[i].length === 0
                      ? 'Enter Response Values'
                      : ''
                  "
                  [matChipInputFor]="chipList"
                  [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                  (matChipInputTokenEnd)="add($event, i)"
                />
              </mat-chip-list>
            </div>
          </div>
        </div>
        <div class="col-1 f-action">
          <div class="action">
            <div (click)="delete(i)">
              <img
                *ngIf="!isLightTheme"
                class="icon"
                src="assets/Group 649.png"
              /><img
                *ngIf="isLightTheme"
                class="icon"
                src="assets/Light-Theme/Group 373.png"
              />Delete
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  </div>
  <hr class="" />
  <div class="addPrview" style="display: flex">
    <span class="add" (click)="addQuestion()">
      <img *ngIf="!isLightTheme" src="assets/Add.png" alt="" />
      <img *ngIf="isLightTheme" src="assets/Light-Theme/Add.png" alt="" /> Add
      Question</span
    >
    <div class="mob-save" *ngIf= isMobileView (click)="saveSurvery()" [disabled]="
    !(
      formCollection.valid &&
      formTitle &&
      formDescription &&
      checkSelected &&
      !titleFlag
    )
  ">
      <img src="../../../../../assets/save.svg"> SAVE
    </div>
    <div *ngIf= !isMobileView>
    <span
      *ngIf="
        formCollection.valid &&
          formTitle &&
          formDescription &&
          checkSelected &&
          !titleFlag;
        else nopreview
      "
      class="preview"
      (click)="previewForm()"
    >
      <img *ngIf="!isLightTheme" src="assets/Group 767.png" alt="" /><img
        *ngIf="isLightTheme"
        src="assets/Light-Theme/Group 767.png"
        alt=""
      />Preview Form</span
    >
    <ng-template #nopreview>
      <span class="nopreview">
        <img *ngIf="!isLightTheme" src="assets/Group 767.png" alt="" /><img
          *ngIf="isLightTheme"
          src="assets/Light-Theme/Group 767.png"
          alt=""
        />Preview Form</span
      >
    </ng-template>
    </div>
  </div>
  <hr class="" />
  <div class="button">
    <div>
      <button 
        mat-button
        class="btn-cancel"
        color="primary"
        (click)="cancleSurvey()"
      >
        CANCEL
      </button>
    </div>
    <div >
      <button *ngIf= !isMobileView
        [disabled]="
          !(
            formCollection.valid &&
            formTitle &&
            formDescription &&
            checkSelected &&
            !titleFlag
          )
        "
        mat-button
        class="btn-save"
        color="primary"
        (click)="saveSurvery()"
      >
        SAVE
      </button>
      <button
        [disabled]="
          !(
            formCollection.valid &&
            formTitle &&
            formDescription &&
            checkSelected &&
            !titleFlag
          )
        "
        mat-button
        class="btn-save-publish"
        color="primary"
        (click)="savePublishSurvery()"
      >
        SAVE & PUBLISH
      </button>
    </div>
  </div>
</mat-card>
<!--<div *ngIf="!isShowCreateForm">
   <app-form-list></app-form-list>
</div>-->
