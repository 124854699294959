<div class="fields row">
  <div class="col-4">
    <mat-label class="label">Facility</mat-label>
    <div class="info-icon">
      <input matInput autocomplete="off" class="input-field" [(ngModel)]="facilityName" type="text" disabled />
      <div (click)="showFacilityData()" class="info"><span class="exclamatory">!</span></div>
    </div>
  </div>
  <div class="col-4">
    <mat-label class="label">Building</mat-label>
    <div class="info-icon">
      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-select (selectionChange)="buildingSelected($event)" [(ngModel)]="buildingSelectedvalue">
          <mat-option [value]="f" *ngFor="let f of buildings">
            <div class="option-wrapper">
              <div class="value">{{ f.buildingName }}</div>
              <div class="img" *ngIf="f.buildingValid"><img src="assets/Group 370.png" alt="" /></div>
            </div>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <div (click)="showBuildingData()" class="info"><span class="exclamatory">!</span></div>
    </div>
  </div>
</div>
<hr class="border-line" />

<div class="add-icons">
  <div class=""></div>
  <div class="btn-add">
    <button mat-icon-button aria-label="" class="add-new" (click)="newFloor()" style="width: 150px;">
      <img src="assets/Add.svg" alt="" />
      <span style="margin-left: 10px;">ADD NEW</span>
    </button>
    <button mat-icon-button aria-label="" class="add-new upload-div" (click)="bulkupload()" style="width: 150px;">
      <img src="assets/Group 1780.svg" alt="" />
        <!-- <img src="assets/Group 983.png" class="upload" alt="" /> -->
        <span style="margin-left: 10px;">BULK UPLOAD</span>
    </button>
  </div>
</div>
<hr class="border-line" />

<div class="table filter">
  <!--   <mat-form-field>
    <mat-label>Search</mat-label>
    <input matInput (keyup)="applyFilter($event)" #input />
  </mat-form-field> -->
  <mat-table [dataSource]="dataSource" class="temperaturetable" matSort>
    <ng-container matColumnDef="floorName">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Floor Name </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.floorName }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="floorNo">
      <mat-header-cell *matHeaderCellDef mat-sort-header> Floor No. </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.floorNo }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="capetArea">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Total Carpet Area (sq.ft)
        <!-- {{ buildingSqFeetTotal }}/{{ facilitysquareFeetArea }} -->
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.capetArea }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="seatingCapacity">
      <mat-header-cell *matHeaderCellDef mat-sort-header>
        Seating Capacity
        <!-- {{ buildingCapacityTotal }}/{{ facilityCapacity }} -->
      </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{ element.seatingCapacity }} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="action">
      <mat-header-cell *matHeaderCellDef> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Action </mat-header-cell>
      <mat-cell *matCellDef="let element">
        <div class="action">
          <div (click)="edit(element)"><img class="icon" src="assets/Group 372.svg" /> Edit</div>
          <div class="divider"></div>
          <div (click)="deleteRow(element)"><img class="icon" src="assets/Group 373.svg" />Delete</div>
        </div>
      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>

<div class="button">
  <div>
    <button mat-button class="btn-reset" color="primary" (click)="back()">back</button>
  </div>
  <div>
    <button mat-button class="btn-save" color="primary" (click)="next()">
      continue
    </button>
  </div>
</div>
