import { Component, OnInit, ViewChild } from '@angular/core';
import { ThermalScreenComponent } from '../thermal-screen/thermal-screen.component';
import { SecurityScanComponent } from '../security-scan/security-scan.component';

@Component({
  selector: 'app-security-tab',
  templateUrl: './security-tab.component.html',
  styleUrls: ['./security-tab.component.scss'],
})
export class SecurityTabComponent implements OnInit {
  selectedSecurityTab: any = 0;

  @ViewChild(ThermalScreenComponent)
  private ThermalScreenComponent: ThermalScreenComponent;
  @ViewChild(SecurityScanComponent)
  private SecurityScanComponent: SecurityScanComponent;

  @ViewChild('tabGroup') tabGroup: any;
  tabTimer: any;
  tabTimerEverySecond: any;
  tab2Timer: any;

  constructor() {}
  ngOnDestroy(): void {
    clearInterval(this.tab2Timer);
    clearInterval(this.tabTimer);
    clearInterval(this.tabTimerEverySecond);
  }
  securityTabClick(tab: any) {
    if (tab.index == 0) {
      clearInterval(this.tab2Timer);
      // this.ThermalScreenComponent.getFacilityDetails();
      this.ThermalScreenComponent.getThershHold();
      this.thermalscreenComponentFunctions();
    } else if (tab.index == 1) {
      clearInterval(this.tabTimer);
      clearInterval(this.tabTimerEverySecond);
      if (this.tab2Timer) clearInterval(this.tab2Timer);
      this.tab2Timer = setInterval(() => {
        this.SecurityScanComponent.every2Seconds();
      }, 3000); // 2 Seconds
    }
  }
  ngOnInit(): void {}
  ngAfterViewInit() {
    console.log('afterViewInit => ', this.tabGroup.selectedIndex);
    // if (this.tabGroup.selectedIndex == 0) {
    //   this.thermalscreenComponentFunctions();
    // }
  }

  thermalscreenComponentFunctions() {
    if (this.tabTimer) clearInterval(this.tabTimer);
    this.tabTimer = setInterval(() => {
      this.ThermalScreenComponent.every5Seconds();
    }, 5000); // 5 Seconds

    if (this.tabTimerEverySecond) clearInterval(this.tabTimerEverySecond);
    this.tabTimerEverySecond = setInterval(() => {
      this.ThermalScreenComponent.everySecond();
    }, 2000); // 1 Seconds
  }
  // Method to get the active tab
  ngAfterContentChecked() {
    let selectedTab: any = localStorage.getItem('subHeaderText');
    if (selectedTab == 'Security') {
      this.selectedSecurityTab = 0;
    } else if (selectedTab == 'Thermal Scan') {
      this.selectedSecurityTab = 1;
    }
  }

  clearTimer(flag: boolean) {
    if (flag) {
      if (this.tabTimer) {
        clearInterval(this.tabTimer);
        clearInterval(this.tabTimerEverySecond);
      }
      if (this.tabTimerEverySecond) {
        clearInterval(this.tabTimer);
        clearInterval(this.tabTimerEverySecond);
      }
      if (this.tab2Timer) {
        clearInterval(this.tab2Timer);
      }
    }
  }

  setTimer(flag: boolean) {
    if (flag) {
      if (this.tabGroup.selectedIndex == 0) {
        this.thermalscreenComponentFunctions();
      } else {
        if (this.tab2Timer) clearInterval(this.tab2Timer);
        this.tab2Timer = setInterval(() => {
          this.SecurityScanComponent.every2Seconds();
        }, 3000);
      }
    }
  }
}
