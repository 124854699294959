import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-asset-list',
  templateUrl: './asset-list.component.html',
  styleUrls: ['./asset-list.component.scss']
})
export class AssetListComponent implements OnInit {

  //Variable declaration
  columnDisplayOrder: any = [];
  assetDataSource:any;
  @ViewChild('search') search: ElementRef;
  @Output() addNewAsset = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
    this.getColumnOrder();
    this.getTableValue();
  }


  // Method to display the column order
  getColumnOrder() {
    this.columnDisplayOrder = ['system', 'asset', 'manufacturer', 'space', 'onboardDate', 'actions'];
  }

  // Method to get the table value
  getTableValue() {
    let assets:any = [
      {
        system:'HVAC',
        asset:'Filters',
        manufacturer:'Lorem Ipsum',
        space:'Building 2',
        date:'26 Jan 2021'
      }
    ]

    this.assetDataSource = new MatTableDataSource(assets);;
  }

  // Method called on search
  toggleClass() {
    if (this.search.nativeElement.classList.contains('show')) {
      this.search.nativeElement.classList.remove('show');
    } else {
      this.search.nativeElement.classList.add('show');
    }
  }


  // Method for search
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assetDataSource.filter = filterValue.trim().toLowerCase();
  }

  // Method called on clik of add new
  onAddNew() {
    this.addNewAsset.emit();
  }
  
}
