import {
  Component,
  OnInit,
  AfterContentChecked,
  Input,
  ViewChild,
} from '@angular/core';
import { CredentialsService } from '../login/credentials.service';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationService } from '@app/login/authentication.service';
import { environment } from '../../environments/environment';
import { AppService } from '@app/app.service';
// import { IotService } from '@app/race-trac/iot.service';
import { BookmeetingService } from '@app/workspace-experience/home/bookmeeting.service';
import { HotDeskingService } from '@app/workspace-experience/hot-desking/hot-desking.service';
import { MsalService } from '@azure/msal-angular';
import { globalConstant } from '@app/safe-building/safebuilding-global-constants';
import { SharedService } from '@app/@theme/components/shared.service';
import { MatSidenav } from '@angular/material/sidenav';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, AfterContentChecked {
  @Input() authenticated;
  isAuthenticated: any = false;
  employeeId: any;
  name: string;
  role: string;
  authRole: string;
  headerText: any;
  subHeaderText: any;
  isLightTheme: boolean = environment.isLightTheme;
  alarmscount: number = 0;
  alarms: any[];
  currentUrl: any;
  rolesList: any;
  locationDetails = '';
  locationCity: any;
  showLocation: any;
  hotdeskPage: any;
  energySpaceId: string;
  showBack: boolean;
  showOverlay: boolean;
  building_spaceId: any = globalConstant.building_spaceId;
  headerLocationRefreshed: boolean = false;
  @ViewChild('sidenav') sidenav: MatSidenav;
  @ViewChild('accordion', { static: true }) Accordion: MatAccordion;
  subPanels = [];
  roleRoutes = {
    FacilityManager: {
      default: '/energy/map',
      'ENERGY MANAGEMENT': '/energy/overview',
      'SAFE BUILDINGS': '/safe-buildings/dashboard',
      'WORKSPACE EXPERIENCE': '/workspace/admin/space',
      'OPERATIONAL EFFICIENCY': '/racetrac',
    },
    Employee: {
      default: '/overview',
      'SAFE BUILDINGS': '/safe-buildings/employee',
      'WORKSPACE EXPERIENCE': '/workspace/home',
    },
    Security: {
      default: '/overview',
      'SAFE BUILDINGS': '/safe-buildings/security',
      'WORKSPACE EXPERIENCE': '/workspace/visitor-scan',
    },
    HR: {
      default: '/overview',
      'SAFE BUILDINGS': '/safe-buildings/hrView',
    },
    PortfolioManager: {
      default: '/energy/map',
      'ENERGY MANAGEMENT': '/energy/dashboard',
    },
    Admin: {
      default: '/overview',
      'ENERGY MANAGEMENT': '/energy/admin',
      'SAFE BUILDINGS': 'safe-buildings/admin',
      'WORKSPACE EXPERIENCE': '/workspace/admin',
      'OPERATIONAL EFFICIENCY': '/racetrac/admin',
    },
  };
  breadcrumb: string;

  constructor(
    private credentialService: CredentialsService,
    private router: Router,
    private authenticationService: AuthenticationService,
    private appService: AppService,
    // private iotService: IotService,
    private bookmeetingService: BookmeetingService,
    private hdservice: HotDeskingService,
    private malauthService: MsalService,
    private sharedService: SharedService
  ) {}

  ngOnInit(): void {
    this.headerText = localStorage.getItem('headerText');
    this.subHeaderText = localStorage.getItem('subHeaderText');
    this.currentUrl = this.router.url;
    this.appService.breadcrumbs$.subscribe((value: string) => {
      this.breadcrumb = value;
    });
    this.role = localStorage.getItem('defaultRole');

    // this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //     this.currentUrl = event.url;
    //     this.isAuthenticated = this.credentialService.empdetails; //returns null if not authenticated
    //     console.log('isAuthenticated', this.isAuthenticated);
    //     let userDetails = this.credentialService.userObj;
    //     this.role = userDetails.role;
    //     this.name = userDetails.name;
    //     this.employeeId = userDetails.employeeId;
    //     this.authRole = userDetails.authRole;
    //   }
    //   // console.log(event);
    //   // console.log(this.credentialService.empdetails)
    // });
    // this.getcountofAlarms();
    this.appService.roles.subscribe((data: any) => {
      this.rolesList = data;
    });
    this.appService.loggedUser.subscribe((data: any) => {
      this.name = data;
      sessionStorage.setItem('name', this.name);
    });
    this.appService.hotdeskpage.subscribe((data: any) => {
      this.hotdeskPage = data;
    });
    this.appService.showLocation.subscribe((data: any) => {
      if (
        this.role == 'Admin' ||
        this.role == 'FacilityManager'
        // || this.role == 'PortfolioManager'
      ) {
        this.showLocation = false;
      } else {
        this.showLocation = data;
      }
    });
    //this.name = sessionStorage.getItem('name');
    // this.role = sessionStorage.getItem('role');
    this.appService.currentTheme.subscribe(
      (theme) => (this.isLightTheme = theme == 'theme-light' ? true : false)
    );
    this.getLocationDetails();
  }

  clearHeading(): void {
    localStorage.removeItem('headerText');
    localStorage.removeItem('subHeaderText');
    this.headerText = '';
    this.subHeaderText = '';
  }

  switchRole(role: any) {
    // if (role == 'PortfolioManager' || role == 'FacilityManager') {
    //   this.router.navigate(['/energy/map']);
    // } else {
    //   this.router.navigate(['overview']);
    // }
    console.log(this.breadcrumb);
    let moduleName = this.headerText?.toUpperCase();
    if (moduleName == 'SMART WORKSPACE') moduleName = 'WORKSPACE EXPERIENCE';
    if (
      moduleName === 'ENERGY MANAGEMENT' &&
      (this.router.url.split('/')[2] === 'map' ||
        (this.router.url.split('/')[2] === 'overview' &&
          this.router.url.split('/')[3]))
    ) {
      this.clearHeading();
      moduleName = 'default';
    }
    let navigateRoute = this.roleRoutes[role][moduleName]
      ? this.roleRoutes[role][moduleName]
      : this.roleRoutes[role]['default'];
    this.router.navigate([navigateRoute]);
    localStorage.setItem('defaultRole', role);
    this.role = role;
    this.appService.showLocation.next(true);
    this.locationDetails = '';
    // if (role == 'Employee') {
    //   this.router.navigate(['home'], {
    //     replaceUrl: true,
    //   });
    // } else if (role === 'Admin') {
    //   this.router.navigate(['/admin'], {
    //     replaceUrl: true,
    //   });
    // } else if (role === 'Security') {
    //   this.router.navigate(['/visitor/visitor-scan'], {
    //     replaceUrl: true,
    //   });
    // }
  }

  ngAfterContentChecked() {
    this.headerText = localStorage.getItem('headerText');
    this.subHeaderText = localStorage.getItem('subHeaderText');
    this.currentUrl = this.router.url;
    if (this.currentUrl == '/energy/overview') {
      this.showBack = true;
    } else if (this.currentUrl == '/workspace/admin/space') {
      this.showBack = true;
    } else if (this.currentUrl == '/safe-buildings/dashboard') {
      this.showBack = true;
    } else if (this.currentUrl == '/energy/dashboard') {
      this.showBack = true;
    } else {
      this.showBack = false;
    }
    this.role = localStorage.getItem('defaultRole');
    // this.appService.hotdeskpage.subscribe((data: any) => {
    //   this.hotdeskPage = data;
    // });
    if (this.router.url == '/workspace/home') {
      if (!this.headerLocationRefreshed) {
        this.headerLocationRefreshed = true; // boolean to call the getlocationDetails function once
        this.getLocationDetails();
      }
    } else {
      this.headerLocationRefreshed = false;
    }
    // this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //     this.currentUrl = event.url;
    //     this.isAuthenticated = this.credentialService.empdetails; //returns null if not authenticated
    //     console.log('isAuthenticated', this.isAuthenticated);
    //     let userDetails = this.credentialService.userObj;
    //     this.role = userDetails.role;
    //     this.name = userDetails.name;
    //     this.employeeId = userDetails.employeeId;
    //     this.authRole = userDetails.authRole;
    //   }
    //   // console.log(event);
    //   // console.log(this.credentialService.empdetails)
    // });
  }

  onOpenedChange(event: any) {
    if (event === true) {
      this.showOverlay = true;
    } else if (event === false) {
      this.showOverlay = false;
    }
  }

  logout() {
    this.malauthService.logout();
    // this.authenticationService
    //   .logout()
    //   .subscribe(() => this.router.navigate(['/login'], { replaceUrl: true }));
  }

  // getcountofAlarms() {
  //   this.iotService.getAlarmList().subscribe((res) => {
  //     this.alarms = res;
  //     this.alarmscount = this.alarms.length;
  //   });
  // }

  onLogoNavigate() {
    this.clearHeading();
    let role = localStorage.getItem('defaultRole');
    if (role == 'PortfolioManager' || role == 'FacilityManager') {
      this.router.navigate(['/energy/map']);
    } else {
      this.router.navigate(['overview']);
    }
  }
  switchTheme() {
    if (this.isLightTheme) {
      this.isLightTheme = false;
    } else {
      this.isLightTheme = true;
    }
    //this.isLightTheme = !this.isLightTheme;
    environment.isLightTheme = this.isLightTheme;
    let themeName = environment.isLightTheme ? 'theme-light' : 'theme-dark';
    document.documentElement.className = themeName;
    this.appService.changeTheme(themeName);
    window.location.reload();
  }

  changeRole(role: any) {
    this.credentialService.role = role;
    this.credentialService.userObj = JSON.parse(
      localStorage.getItem('userdetails')
    );
    this.credentialService.userObj.authRole = role;
    // console.log(this.credentialService.userObj)

    if (role === 'employee') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Employee Compliance History');
      this.credentialService.userObj.role = 'Employee';
      this.router.navigate(['/employee'], { replaceUrl: true });
    } else if (role === 'facility') {
      if (this.headerText.toLowerCase() == 'operational efficiency') {
        this.router.navigate(['/racetrac'], {
          replaceUrl: true,
        });
        localStorage.setItem('headerText', 'operational efficiency');
        localStorage.setItem('subHeaderText', 'overview');
        this.credentialService.userObj.role = 'Sales Manager';
      } else {
        this.router.navigate(['/dashboard'], {
          replaceUrl: true,
        });
        localStorage.setItem('headerText', 'safe building');
        localStorage.setItem('subHeaderText', 'overview');
        this.credentialService.userObj.role = 'Sales Manager';
      }
    } else if (role === 'admin') {
      if (this.headerText.toLowerCase() == 'operational efficiency') {
        this.router.navigate(['/racetrac/admin'], {
          replaceUrl: true,
        });
        localStorage.setItem('headerText', 'operational efficiency');
        localStorage.setItem('subHeaderText', 'Store');
        this.credentialService.userObj.role = 'Admin';
      } else {
        this.router.navigate(['/admin'], {
          replaceUrl: true,
        });
        localStorage.setItem('headerText', 'safe buildings');
        localStorage.setItem('subHeaderText', 'facility onboard');
        this.credentialService.userObj.role = 'Admin';
      }
    } else if (role === 'security') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Security');
      if (environment.TS_enable) {
        this.credentialService.userObj.role = 'Security';
        this.router.navigate(['/security'], {
          replaceUrl: true,
        });
      }
    } else if (role === 'hr') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Dashboard');
      this.credentialService.userObj.role = 'HR';
      this.router.navigate(['/hrView'], {
        replaceUrl: true,
      });
    }
    localStorage.setItem('role', role);
    localStorage.setItem(
      'userdetails',
      JSON.stringify(this.credentialService.userObj)
    );
  }
  back() {
    this.energySpaceId = localStorage.getItem('energySpaceID');
    localStorage.setItem('headerText', '');
    localStorage.setItem('subHeaderText', '');
    this.router.navigate([`/energy/overview/${this.energySpaceId}`]);
  }
  getLocationDetails() {
    this.appService.getUserLocation().subscribe((response: any) => {
      if (response && response['locationName']) {
        this.locationDetails =
          response['locationName'] + ' (' + response['timeZone'] + ')';
        this.locationCity = response['timeZone'];
        localStorage.setItem('regionId', response['regionId']);
        localStorage.setItem('timeZone', this.locationCity);
        // sessionStorage.setItem('timeZone', this.locationCity);
        this.bookmeetingService.storeTimeZone.next(this.locationCity);
        this.hdservice.bookingTimeZone.next(this.locationCity);
      }
    });
  }
  goToOverride() {
    this.router.navigate(['/workspace/override'], {
      replaceUrl: true,
    });
  }
  getLocation() {
    const city = this.locationDetails['city']
      ? this.locationDetails['city']
      : localStorage.getItem('city')
      ? localStorage.getItem('city')
      : '';
    const facility = this.locationDetails['city']
      ? this.locationDetails['city']
      : localStorage.getItem('facility')
      ? localStorage.getItem('facility')
      : '';
    const building = this.locationDetails['city']
      ? this.locationDetails['city']
      : localStorage.getItem('building')
      ? localStorage.getItem('building')
      : '';
    const floor = this.locationDetails['city']
      ? this.locationDetails['city']
      : localStorage.getItem('floor')
      ? localStorage.getItem('floor')
      : '';
    const location = this.locationDetails['zone']
      ? this.locationDetails['zone']
      : localStorage.getItem('timeZone')
      ? localStorage.getItem('timeZone')
      : '';
    if (this.role !== 'Security') {
      return this.locationDetails !== ''
        ? this.locationDetails
        : city !== ''
        ? city +
          ' ,' +
          facility +
          ' ,' +
          building +
          ' ,' +
          floor +
          '(' +
          location +
          ')'
        : '';
    } else {
      return (this.locationDetails = city + ' ,' + facility);
    }
  }
  closePanels() {
    this.Accordion.closeAll();
    this.subPanels.forEach((panel: any) => panel.close());
    this.subPanels = [];
  }
  subPanelOpen(event: any) {
    this.subPanels.push(event);
  }
  close(): void {
    setTimeout(() => {
      this.closePanels();
    }, 1);
    this.sidenav.close();
  }

  energyRedirect(val: string) {
    this.close();
    if (val == 'overview') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'overview');
      this.router.navigate(['/energy/overview']);
    }
    if (val == 'consumption') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Consumption Trend');
      this.router.navigate(['/energy/consumption-trend']);
    }
    if (val == 'peak-profile') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Peak Profile');
      this.router.navigate(['/energy/peak-profile']);
    }
    if (val == 'sourcing-breakup') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Sourcing Breakup');
      this.router.navigate(['/energy/sourcing-breakup']);
    }
    if (val == 'conservation') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Conservation Measures');
      this.router.navigate(['/energy/conservation']);
    }
    if (val == 'data-point-correlation') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Flexible Data Point Correlation');
      this.router.navigate(['/energy/data-point-correlation']);
    }
    if (val == 'target-setting') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Target Setting and Tracking');
      this.router.navigate(['/energy/target-setting']);
    }
    if (val == 'electricity-tariff-definition') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Electricity Tariff Definition');
      this.router.navigate(['/energy/electricity-tariff-definition']);
    }
    if (val == 'asset-view') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'Asset View');
      this.router.navigate(['/energy/asset-view']);
    }
    // if (val == 'map') {
    //   localStorage.setItem('headerText', 'Energy Management');
    //   localStorage.setItem('subHeaderText', 'Map');
    //   this.router.navigate(['/energy/map']);
    // }
    if (val == 'admin') {
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'admin');
      this.router.navigate(['/energy/admin']);
    }
  }

  raceTracRedirect(val: string) {
    this.close();
    // this.selectedSubMenu = val;
    if (val == 'dashboard') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'overview');
      this.router.navigate(['/racetrac']);
    }
    if (val == 'alertList') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'alert list');
      this.router.navigate(['/racetrac/alertList']);
    }
    if (val == 'workorderList') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'work order list');
      this.router.navigate(['/racetrac/WorkOrder']);
    }
    if (val == 'simulator') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'simulator');
      this.router.navigate(['/racetrac/simulator']);
    }
    if (val == 'admin') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'Store');
      this.sharedService.updateMenuText('Stores');
      this.router.navigate(['/racetrac/admin']);
    }
    if (val == 'assets') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'Assets');
      this.router.navigate(['racetrac/admin']);
      this.sharedService.updateMenuText('Assets');
    }
    if (val == 'assetModel') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'Assets Model Form');
      this.router.navigate(['racetrac/admin']);
      this.sharedService.updateMenuText('Assets Model Form');
    }
    if (val == 'alertRule') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'Configure Alert Rules');
      //this.router.navigate(['/racetrac/admin']);
      this.router.navigateByUrl('racetrac/admin');
      this.sharedService.updateMenuText('Configure Alert Rules');
    }
  }

  redirect(val: string) {
    this.close();
    console.log(val);
    if (val == 'physicalDistancing') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'physical distancing');
      this.router.navigateByUrl(
        '/physicaldistance' + '/' + this.building_spaceId
      );
    } else if (val == 'handHygiene') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'hand hygiene');
      this.router.navigateByUrl('/handhygiene' + '/' + this.building_spaceId);
    } else if (val == 'dashboard') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'overview');
      this.router.navigate(['/safe-buildings/dashboard']);
    } else if (val == 'facilityview') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'thermal screening');
      this.router.navigateByUrl('/facilityview' + '/' + this.building_spaceId);
    } else if (val == 'airquality') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'air quality');
      this.router.navigateByUrl('/airquality');
    } else if (val == 'peopleDensity') {
      this.router.navigateByUrl('/peopleDensity');
    } else if (val == 'facilityOnboard') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'facility onboard');
      this.router.navigateByUrl('safe-buildings/admin');
    } else if (val == 'deviceOnboard') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'device onboard');
      this.router.navigateByUrl('safe-buildings/admin');
    } else if (val == 'employeeManagement') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Employee and Band Management');
      this.router.navigateByUrl('safe-buildings/admin');
    } else if (val == 'ruleConfig') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Rules and Configuration');
      this.router.navigateByUrl('safe-buildings/admin');
    } else if (val == 'security') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Security');
      this.router.navigateByUrl('/safe-buildings/security');
    } else if (val == 'thermalScan') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Thermal Scan');
      this.router.navigateByUrl('/safe-buildings/security');
    } else if (val == 'hrDashboard') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Dashboard');
      this.router.navigateByUrl('/safe-buildings/hrView');
    } else if (val == 'modifyAssociate') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Modify Associate Details');
      this.router.navigateByUrl('/safe-buildings/hrView');
    } else if (val == 'quarantine') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Quarantine Tracker');
      this.router.navigateByUrl('/safe-buildings/hrView');
    } else if (val == 'forms') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Forms');
      this.router.navigateByUrl('/safe-buildings/hrView');
    } else if (val == 'employee') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'Employee Compliance History');
      this.router.navigate(['safe-buildings/employee'], {
        replaceUrl: true,
      });
    }
  }

  workSpaceRedirect(val: string) {
    this.close();
    this.getLocationDetails();
    this.appService.showLocation.next(true);
    if (val == 'visitor') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Visitor Dashboard');
      this.router.navigate(['/workspace/visitor-dashbaord'], {
        replaceUrl: true,
      });
    } else if (val == 'book-meeting') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Book Meeting Room');
      this.router.navigate(['/workspace/home'], {
        replaceUrl: true,
      });
    } else if (val == 'my-bookings') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'My Bookings');
      this.router.navigate(['/workspace/my-bookings'], {
        replaceUrl: true,
      });
    } else if (val == 'hot-desking') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Book Hot Desk');
      this.router.navigate(['/workspace/hot-desking'], {
        replaceUrl: true,
      });
    } else if (val == 'admin') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Organization Configuration');
      this.appService.showLocation.next(false);
      this.router.navigate(['/workspace/admin'], {
        replaceUrl: true,
      });
    } else if (val == 'facility-list') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Facility Onboarding');
      this.router.navigate(['/workspace/admin/facility-list'], {
        replaceUrl: true,
      });
    } else if (val == 'user-groups') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'User Groups');
      this.router.navigate(['/workspace/admin/user-groups'], {
        replaceUrl: true,
      });
    } else if (val == 'desk-groups') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Desk Groups');
      this.router.navigate(['/workspace/admin/desk-groups'], {
        replaceUrl: true,
      });
    } else if (val == 'meeting-room-onboard') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Meeting Room Onboard');
      this.router.navigate(['/workspace/admin/meeting-room-onboard'], {
        replaceUrl: true,
      });
    } else if (val == 'hotdesk-onboard') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Hotdesk Onboard');
      this.router.navigate(['/workspace/admin/hotdesk-onboard'], {
        replaceUrl: true,
      });
    } else if (val == 'devices') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Devices');
      this.router.navigate(['/workspace/admin/devices'], {
        replaceUrl: true,
      });
    } else if (val == 'sensors') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Sensors');
      this.router.navigate(['/workspace/admin/sensors'], {
        replaceUrl: true,
      });
    } else if (val == 'rules-configuration') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Rules Configuration');
      this.router.navigate(['/workspace/admin/rules-configuration'], {
        replaceUrl: true,
      });
    }
    else if (val == 'cost-center-reports') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Cost Center Reports');
      this.router.navigate(['/workspace/admin/cost-reports'], {
        replaceUrl: true,
      });
    }
     else if (val == 'inferences') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Inferences');
      this.router.navigate(['/workspace/admin/inferences'], {
        replaceUrl: true,
      });
    } else if (val == 'parking-groups') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Parking Groups');
      this.router.navigate(['/workspace/admin/parking-groups'], {
        replaceUrl: true,
      });
    } else if (val == 'parking-spot') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Parking Spot');
      this.router.navigate(['/workspace/admin/parking-spot'], {
        replaceUrl: true,
      });
    } else if (val == 'zone-space') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Zone Space Utilization');
      this.router.navigate(['/workspace/admin/zone-space'], {
        replaceUrl: true,
      });
    } else if (val == 'space') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Spaces');
      this.router.navigate(['/workspace/admin/space'], {
        replaceUrl: true,
      });
    } else if (val == 'visitor-scan') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Visitor Scan');
      this.router.navigate(['/workspace/visitor-scan'], {
        replaceUrl: true,
      });
    } else if (val == 'visitor-list') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Visitor List');
      this.router.navigate(['/workspace/visitor-list'], {
        replaceUrl: true,
      });
    } else if (val == 'meeting-list') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Meeting List');
      this.router.navigate(['/workspace/admin/meetingroomlist'], {
        replaceUrl: true,
      });
    } else if (val == 'zone-list') {
      localStorage.setItem('headerText', 'Smart WorkSpace');
      localStorage.setItem('subHeaderText', 'Zone List');
      this.router.navigate(['/workspace/admin/zoneroomlist'], {
        replaceUrl: true,
      });
    }
  }
  goToFacilityView(): void {
    this.energySpaceId = localStorage.getItem('energySpaceID');
    localStorage.setItem('headerText', '');
    localStorage.setItem('subHeaderText', '');
    this.router.navigate([
      `/energy/landing-page/overview/${this.energySpaceId}`,
    ]);
  }
}
