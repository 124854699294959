import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BuildingOnboardComponent } from '../building-onboard/building-onboard.component';
import { BulkUploadBuildingComponent } from '../bulk-upload-building/bulk-upload-building.component';
import { DeleteBuildingComponent } from '../delete-building/delete-building.component';
import { FacilityService } from '../facility.service';
import { ViewFacilityComponent } from '../view-facility/view-facility.component';

@Component({
  selector: 'app-building',
  templateUrl: './building.component.html',
  styleUrls: ['./building.component.scss']
})
export class BuildingComponent implements OnInit {

  @Output() toggleComponent = new EventEmitter();
  @Output() saveInfo = new EventEmitter();

  @Input() masterObj: any;
  @Input() isCompleted: any;

  buildingDetailsArray = new FormGroup({
    buildingName: new FormControl(''),
    squareFeetArea: new FormControl(''),
    buildingCapacity: new FormControl(''),
  });

  buildingDetails = new FormGroup({
    builidngArray1: this.buildingDetailsArray,
  });

  buildings: any = [];
  buildingCountArray: any = [];

  formCollection = new FormArray([]);

  buildingSelectedvalue: any;

  facilityName: any;
  facilityCapacity: any;
  facilitysquareFeetArea: any;
  buildingCapacityTotal = 0;
  buildingCapacityTotalFlag = false;
  buildingSqFeetTotal = 0;
  buildingSqFeetTotalFlag = false;
  displayedColumns: any[] = ['buildingName', 'capetArea', 'seatingCapacity', 'childSpaces', 'action'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;

  buildingsList: any[] = [];

  existingBuildings: any[] = [];
  isBulkUploaded = false;

  constructor(public dialog: MatDialog, private service:FacilityService) {}

  ngOnInit(): void {
    console.log('master obj', this.masterObj);
    this.facilityName = this.masterObj.facility.facilityName;
    this.facilityCapacity = this.masterObj.facility.facilityCapacity;
    this.facilitysquareFeetArea = this.masterObj.facility.squareFeetArea;
    this.service.getSpaceDetailsByParentSpace(this.masterObj.facility.spaceId).subscribe((buildigns: any) => {
      if (buildigns.length > 0) {
        this.buildingsList.length = 0;
        this.existingBuildings.length = 0;
        this.dataSource = new MatTableDataSource([]);
        this.isCompleted.building = true;
        this.saveInfo.emit({
          type: 'building',
          data: this.isCompleted.building,
        });
        buildigns.map((building: any) => {
          const buildingObj: any = {};
          buildingObj.spaceId = building.spaceId;
          buildingObj.latLong = this.masterObj.facility.latlong;
          const formCollection: any = [];
          building.spaceProperties.map((property: any) => {
            if (property.propertyKey === 'name') {
              buildingObj.buildingName = property.propertyValue;
              this.existingBuildings.push(property.propertyValue.toLowerCase());
            }

            if (property.propertyKey === 'latitudeLongitude') {
              buildingObj.latLong = property.propertyValue;
            }

            if (property.propertyKey === 'squareFeetArea' && property.propertyValue != null) {
              buildingObj.capetArea = parseInt(property.propertyValue, 10);
              this.buildingSqFeetTotal = this.buildingSqFeetTotal + parseInt(property.propertyValue, 10);
            }

            if (property.propertyKey === 'capacity' && property.propertyValue != null) {
              buildingObj.seatingCapacity = parseInt(property.propertyValue, 10);
              this.buildingCapacityTotal = this.buildingCapacityTotal + parseInt(property.propertyValue, 10);
            }
            if (property.propertyKey === 'childSpaces') {
              if (!!property.propertyValue) {
                buildingObj.childSpaces = parseInt(property.propertyValue, 10);
              }
            }
            if (property.propertyKey.indexOf('_Add') >= 0) {
              formCollection.push({
                id: property.spacePropertyId,
                key: property.propertyKey,
                value: property.propertyValue,
                type: property.propertyType,
              });
            }
          });
          buildingObj.addProperty = formCollection;
          this.buildingsList.push(buildingObj);

          /* if (!!buildingFormObj.buildingName) {
            this.formCollection.push(
              new FormGroup({
                spaceId: new FormControl(building.spaceId),
                buildingName: new FormControl(buildingFormObj.buildingName, Validators.required),
                squareFeetArea: new FormControl(buildingFormObj.squareFeetArea, Validators.required),
                buildingCapacity: new FormControl(buildingFormObj.buildingCapacity, Validators.required),
                noOfFloors: new FormControl(buildingFormObj.noOfFloors),
              })
            );
          } */
        });
        console.log('building list', this.buildingsList);
        this.dataSource = new MatTableDataSource(this.buildingsList.reverse());
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if (this.isBulkUploaded) {
          this.updateNoOfBuildigToFacility(this.buildingsList.length);
        }
        /* this.sumCapacity();
        this.sumSqFeet(); */
      } else {
        this.isCompleted.building = false;
        this.saveInfo.emit({
          type: 'building',
          data: this.isCompleted.building,
        });
        /* this.formCollection.push(
          new FormGroup({
            spaceId: new FormControl(''),
            buildingName: new FormControl('', Validators.required),
            squareFeetArea: new FormControl('', Validators.required),
            buildingCapacity: new FormControl('', Validators.required),
          })
        ); */
      }
    });
  }

  addBuildings() {
    this.isCompleted.building = false;
    this.saveInfo.emit({
      type: 'building',
      data: this.isCompleted.building,
    });
    this.formCollection.push(
      new FormGroup({
        spaceId: new FormControl(''),
        buildingName: new FormControl('', Validators.required),
        squareFeetArea: new FormControl('', Validators.required),
        buildingCapacity: new FormControl('', Validators.required),
      })
    );
  }

  /*   delete(index: any) {
    const form = this.formCollection.at(index);
    if (!!form.value.spaceId) {
      this.service.deleteSpace(form.value.spaceId).subscribe((value: any) => {
        this.formCollection.removeAt(index);
        this.sumSqFeet();
        this.sumCapacity();
        this.updateNoOfBuildigToFacility(this.formCollection.length);
        if (this.formCollection.length === 0) {
          this.addBuildings();
        }
      });
    } else {
      this.formCollection.removeAt(index);
      this.sumSqFeet();
      this.sumCapacity();
      if (this.formCollection.length === 0) {
        this.addBuildings();
      }
    }
  } */

  sumCapacity() {
    this.buildingCapacityTotal = 0;
    for (const form of this.formCollection.controls) {
      if (form.value.buildingCapacity) {
        this.buildingCapacityTotal = this.buildingCapacityTotal + form.value.buildingCapacity;
      }
    }

    if (this.buildingCapacityTotal <= this.facilityCapacity) {
      this.buildingCapacityTotalFlag = true;
    } else {
      this.buildingCapacityTotalFlag = false;
    }
  }

  sumSqFeet() {
    this.buildingSqFeetTotal = 0;
    for (const form of this.formCollection.controls) {
      if (form.value.squareFeetArea) {
        this.buildingSqFeetTotal = this.buildingSqFeetTotal + form.value.squareFeetArea;
      }
    }

    if (this.buildingSqFeetTotal <= this.facilitysquareFeetArea) {
      this.buildingSqFeetTotalFlag = true;
    } else {
      this.buildingSqFeetTotalFlag = false;
    }
  }

  saveBuilding() {
    for (const [i, form] of this.formCollection.controls.entries()) {
      console.log(form.value);
      const json = {
        organizationId: 1,
        parentSpaceId: this.masterObj.facility.spaceId,
        regionId: 3,
        spaceId: form.value.spaceId,
        spaceProperties: [
          {
            propertyKey: 'string',
            propertyType: 'string',
            propertyValue: 'string',
          },
        ],
        spaceType: {
          spaceTypeId: 2, // Building space type
        },
      };

      const spaceProrty: any = [];

      if (form.value.buildingName) {
        spaceProrty.push({
          propertyKey: 'name',
          propertyType: 'string',
          propertyValue: form.value.buildingName,
        });
      }
      if (form.value.squareFeetArea >= 0) {
        spaceProrty.push({
          propertyKey: 'squareFeetArea',
          propertyType: 'string',
          propertyValue: form.value.squareFeetArea,
        });
      }
      if (form.value.buildingCapacity >= 0) {
        spaceProrty.push({
          propertyKey: 'capacity',
          propertyType: 'string',
          propertyValue: form.value.buildingCapacity,
        });
      }
      if (form.value.noOfFloors) {
        spaceProrty.push({
          propertyKey: 'childSpaces',
          propertyType: 'string',
          propertyValue: form.value.noOfFloors,
        });
      }

      if (json.spaceId) {
        this.service.deleteSpaceProperty(json).subscribe((e: any) => {
          json.spaceProperties = spaceProrty;
          this.service.saveSpaceDetails(json).subscribe(
            (buidling: any) => {},
            (err: any) => console.log(err)
          );
        });
      } else {
        json.spaceProperties = spaceProrty;
        this.service.saveSpaceDetails(json).subscribe(
          (buidling: any) => {
            console.log(buidling.spaceId);
            this.formCollection.controls[i].patchValue({
              spaceId: buidling.spaceId,
            });
          },
          (err: any) => console.log(err)
        );
      }
    }
    this.isCompleted.building = true;
    this.saveInfo.emit({
      type: 'building',
      data: this.isCompleted.building,
    });
    this.updateNoOfBuildigToFacility(this.formCollection.length);
  }

  updateNoOfBuildigToFacility(noOfBuildings: number) {
    const json = {
      organizationId: 1,
      parentSpaceId: 0,
      regionId: 3,
      spaceId: this.masterObj.facility.spaceId,
      spaceProperties: [
        {
          propertyKey: 'childSpaces',
          propertyType: 'string',
          propertyValue: noOfBuildings,
        },
      ],
      spaceType: {
        spaceTypeId: 1, // Facility space type
      },
    };

    const spaceProperties: any = [];
    this.service.getSpaceDetailsBySpaceId(this.masterObj.facility.spaceId).subscribe((space: any) => {
      space.spaceProperties.map((property: any) => {
        if (property.propertyKey === 'name') {
          spaceProperties.push({
            propertyKey: 'name',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'squareFeetArea') {
          spaceProperties.push({
            propertyKey: 'squareFeetArea',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }

        if (property.propertyKey === 'capacity') {
          spaceProperties.push({
            propertyKey: 'capacity',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }

        if (property.propertyKey === 'type') {
          spaceProperties.push({
            propertyKey: 'type',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'pincode') {
          spaceProperties.push({
            propertyKey: 'pincode',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'city') {
          spaceProperties.push({
            propertyKey: 'city',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
          json.regionId = property.propertyValue;
        }
        if (property.propertyKey === 'province') {
          spaceProperties.push({
            propertyKey: 'province',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'country') {
          spaceProperties.push({
            propertyKey: 'country',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'addressline2') {
          spaceProperties.push({
            propertyKey: 'addressline2',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'addressline1') {
          spaceProperties.push({
            propertyKey: 'addressline1',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'latlong') {
          spaceProperties.push({
            propertyKey: 'latlong',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'facilityLayout') {
          spaceProperties.push({
            propertyKey: 'facilityLayout',
            propertyType: 'file',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'region') {
          spaceProperties.push({
            propertyKey: 'region',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'Location') {
          spaceProperties.push({
            propertyKey: 'Location',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'countryname') {
          spaceProperties.push({
            propertyKey: 'countryname',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'cityname') {
          spaceProperties.push({
            propertyKey: 'cityname',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'regionname') {
          spaceProperties.push({
            propertyKey: 'regionname',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'countryregion') {
          spaceProperties.push({
            propertyKey: 'countryregion',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey.indexOf('_Add') >= 0) {
          spaceProperties.push({
            propertyKey: property.propertyKey,
            propertyType: property.propertyType,
            propertyValue: property.propertyValue,
          });
        }
      });
      this.service.deleteSpaceProperty(json).subscribe((e: any) => {
        json.spaceProperties = json.spaceProperties.concat(spaceProperties);
        console.log('son.spaceProperties ', json.spaceProperties);
        this.service.saveSpaceDetails(json).subscribe(
          (facility: any) => {},
          (err: any) => console.log(err)
        );
      });
    });
  }

  buildingSelected() {
    this.reset();
    this.buildingSelectedvalue = this.buildingDetails.value.buildingSelection;

    if (!!this.buildingDetails.value.buildingSelection.data) {
      this.buildingDetails.patchValue({
        buildingName: this.buildingDetails.value.buildingSelection.data.buildingName,
        squareFeetArea: this.buildingDetails.value.buildingSelection.data.squareFeetArea,
        buildingCapacity: this.buildingDetails.value.buildingSelection.data.buildingCapacity,
        noOffloors: this.buildingDetails.value.buildingSelection.data.noOffloors,
      });
    }
  }
  reset() {
    this.buildingDetails.patchValue({
      buildingName: '',
      squareFeetArea: '',
      buildingCapacity: '',
      noOffloors: '',
    });
  }

  next() {
    this.toggleComponent.emit('floor');
  }

  back() {
    this.toggleComponent.emit('facility');
  }

  newBuilding() {
    const obj = {
      latLong: this.masterObj.facility.latlong,
      totalArea: this.facilitysquareFeetArea,
      totalCapacity: this.facilityCapacity,
      buildingOccupiedArea: this.buildingSqFeetTotal,
      buildingOccupiedCapacity: this.buildingCapacityTotal,
      existingBuilding: this.existingBuildings,
    };
    const dialogRef = this.dialog.open(BuildingOnboardComponent, {
      width: '90%',
      data: obj,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result.value);
      this.existingBuildings.push(result.value.buildingName);
      const Json = this.setData(result);
      console.log('json', Json);
      this.service.saveSpaceDetails(Json).subscribe((resp: any) => {
        result.value.spaceId = resp.spaceId;
        this.addToDataSource(result);
        this.isCompleted.building = true;
        this.saveInfo.emit({
          type: 'building',
          data: this.isCompleted.building,
        });
        this.updateNoOfBuildigToFacility(this.buildingsList.length);
      });
    });
  }

  addToDataSource(result: any) {
    this.buildingsList.push({
      spaceId: result.value.spaceId,
      buildingName: result.value.buildingName,
      capetArea: result.value.squareFeetArea,
      seatingCapacity: result.value.buildingCapacity,
      childSpaces: result.value.childSpaces,
      addProperty: result.value.addProperty,
      latLong: result.value?.latLong,
    });
    this.dataSource = new MatTableDataSource(this.buildingsList.reverse());
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  edit(element: any) {
    console.log('element', element);
    element.totalArea = this.facilitysquareFeetArea;
    element.totalCapacity = this.facilityCapacity;
    element.buildingOccupiedArea = this.buildingSqFeetTotal;
    element.buildingOccupiedCapacity = this.buildingCapacityTotal;
    element.existingBuilding = this.existingBuildings;
    const dialogRef = this.dialog.open(BuildingOnboardComponent, {
      width: '90%',
      data: element,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (!!result.value) {
        const index = this.buildingsList.findIndex((building: any) => building.spaceId === result.value.spaceId);

        this.buildingsList.splice(index, 1);
        this.addToDataSource(result);
        this.service.deleteSpaceProperty(result.value).subscribe((resp) => {
          const Json = this.setData(result);
          // console.log('json', Json);
          this.service.saveSpaceDetails(Json).subscribe((building) => {
            // console.log('building', building);
            this.updateNoOfBuildigToFacility(this.buildingsList.length);
          });
        });
      } else if (result === 'new') {
        this.newBuilding();
      }
    });
  }

  setData(result: any) {
    const spaceProperties = [];
    spaceProperties.push({
      propertyKey: 'name',
      propertyType: 'string',
      propertyValue: result.value.buildingName,
    });
    spaceProperties.push({
      propertyKey: 'squareFeetArea',
      propertyType: 'string',
      propertyValue: result.value.squareFeetArea,
    });
    spaceProperties.push({
      propertyKey: 'capacity',
      propertyType: 'string',
      propertyValue: result.value.buildingCapacity,
    });
    spaceProperties.push({
      propertyKey: 'childSpaces',
      propertyType: 'string',
      propertyValue: result.value.childSpaces,
    });

    if (!!result.value.latLong) {
      spaceProperties.push({
        propertyKey: 'latitudeLongitude',
        propertyType: 'string',
        propertyValue: result.value.latLong,
      });
    }
    result.value.addProperty.map((props: any) => {
      spaceProperties.push({
        spacePropertyId: props.id,
        propertyKey: props.key + '_Add',
        propertyType: props.type ? props.type : 'string',
        propertyValue: props.value,
      });
    });
    console.log('space prop', spaceProperties);

    return {
      organizationId: 1,
      parentSpaceId: this.masterObj.facility.spaceId,
      regionId: this.masterObj.facility.city,
      spaceId: result.value.spaceId,
      spaceProperties,
      spaceType: {
        spaceTypeId: 2, // Building space type
      },
    };
  }

  deleteRow(element: any) {
    const dialogRef = this.dialog.open(DeleteBuildingComponent, {
      width: '540px',
      panelClass: 'success-container',
      data: {
        message: element.buildingName,
        spaceId: element.spaceId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      this.service.deleteSpace(result.spaceId).subscribe((deleted: any) => {
        const index = this.buildingsList.findIndex((element1: any) => element1.spaceId === result.spaceId);
        this.existingBuildings.splice(this.existingBuildings.indexOf(element.buildingName.toLowerCase()), 1);
        this.buildingsList.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.buildingsList.reverse());
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.service.deleteSpace(result.spaceId).subscribe((resp) => {
          this.updateNoOfBuildigToFacility(this.buildingsList.length);
        });
      });
    });
  }

  showFacilityData() {
    const dialogRef = this.dialog.open(ViewFacilityComponent, {
      width: '90%',
      panelClass: 'facility-container',
      data: { spaceId: this.masterObj.facility.spaceId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
    });
  }
  download() {
    this.service.getBuildingTemplate().subscribe((template: any) => {
      const blob = new Blob([template], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
  upload(files: FileList) {
    const formData: FormData = new FormData();
    formData.append('file', files.item(0));
    this.service
      .buildingBulkUpload(
        formData,
        1,
        this.masterObj.facility.spaceId,
        2,
        this.masterObj.facility.city,
        this.buildingsList.length
      )
      .subscribe(() => {
        this.ngOnInit();
      });
  }

  bulkUpload() {
    const dialogRef = this.dialog.open(BulkUploadBuildingComponent, {
      width: '780px',
      panelClass: 'bulkupload',
      data: {
        spaceId: this.masterObj.facility.spaceId,
        region: this.masterObj.facility.city,
        length: this.buildingsList.length,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.refresh) {
        this.isBulkUploaded = true;
      }
      this.ngOnInit();
    });
  }
}
