<div class="dashboard root">
  <!-- <span class="back" (click)="back()">&lt;&nbsp; Back &nbsp;</span> -->
  <mat-card class="title">
    <mat-card-title class="covid custom-row">
      <div class="col-lg-6 col-xl-6 heading">
        <span> DASHBOARD </span>
        <!-- <span id="date">as of {{ todaysDate }}</span> -->
      </div>
      <div class="col-lg-6 col-xl-6 dropdown move-right">
        <div class="align">
          <span> Select Facility </span>
          <mat-form-field class="selector custom-arrow">
            <mat-select [(ngModel)]="facilitySelected" (selectionChange)="facilitySelectionChange(facilitySelected)">
              <mat-option *ngFor="let facility of facilities" [value]="facility.value">
                {{ facility.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </mat-card-title>
    <mat-card-content class="custom-row">
      <mat-card *ngIf="TS" class="col-12 col-lg-6 col-xl-6 background" style="padding: 0 10px 32px 0px;">
        <div class="foreground thermal-screening" style="cursor: pointer;">
          <mat-card-title class="thermal custom-row tile_heading" style="margin-bottom: 15px;">
            <div class="col-lg-7 col-xl-7 card-title">THERMAL SCREENING</div>
            <div class="col-lg-5 col-xl-5 yesterday">
              <div class="text custom-row" style="float: right;">
                <small> Since Yesterday </small>

                <span *ngIf="hasIncreased; else minus"> {{ comparison }} + </span>
                <ng-template #minus>
                  <span>{{ comparison }} -</span>
                </ng-template>
                <span class="inc-dec">
                  <i *ngIf="hasIncreased; else decreased" class="material-icons decreased">arrow_upward</i>
                  <ng-template #decreased>
                    <i class="material-icons increased">arrow_downward </i>
                  </ng-template>
                </span>
              </div>
            </div>
          </mat-card-title>
          <mat-card-subtitle class="custom-row" style="margin-bottom: 0px;">
            <div class="col-lg-6 col-xl-6 overview normal">
              <div>
                <div *ngIf="totaCount" class="count">{{ totaCount }}</div>
                <div *ngIf="!totaCount" class="count">0</div>
                <div class="divider">
                  <div class="line"></div>
                  <!-- <div class="point"></div> -->
                </div>
                <div class="summryText">Total screened</div>
              </div>
            </div>
            <div class="col-lg-6 col-xl-6 overview fever">
              <div>
                <div *ngIf="nonCompliant" class="count">{{ nonCompliant }}</div>
                <div *ngIf="!nonCompliant" class="count">0</div>
                <div class="divider">
                  <div class="line"></div>
                  <!-- <div class="point" [style.right.%]="plotLine"></div> -->
                </div>
                <div class="summryText">Non Compliant</div>
              </div>
            </div>
          </mat-card-subtitle>
          <mat-card-content style="display: block; margin-bottom: 0px; width: 566px; height: 204px;">
            <canvas id="columnChart" #chart height="100"></canvas>
          </mat-card-content>
        </div>
      </mat-card>
      <mat-card *ngIf="PD" class="col-12 col-lg-6 col-xl-6 background" style="padding: 0 0px 32px 10px;">
        <div class="foreground thermal-screening" style="cursor: pointer;">
          <mat-card-title class="thermal custom-row tile_heading" style="margin-bottom: 15px;">
            <div class="col-lg-7 col-xl-7 card-title">PHYSICAL DISTANCING</div>
            <div class="col-lg-5 col-xl-5 yesterday">
              <div class="text custom-row" style="float: right;">
                <small> Since Yesterday </small>
                <span *ngIf="physicalDataDiff > 0; else minus1">{{ physicalDataDiffVal }} + </span>
                <ng-template #minus1>
                  <span>{{ physicalDataDiffVal }} -</span>
                </ng-template>
                <span class="inc-dec">
                  <i *ngIf="physicalDataDiff > 0; else decreasedgreen" class="material-icons increased-red">
                    arrow_upward
                  </i>
                  <ng-template #decreasedgreen>
                    <i class="material-icons decreased-green">arrow_downward </i>
                  </ng-template>
                </span>
              </div>
            </div>
          </mat-card-title>
          <mat-card-subtitle class="custom-row" style="margin-bottom: 0px;">
            <div class="col-lg-12 col-xl-12 overview complaint_count fever">
              <div>
                <div *ngIf="physicalScreenData" class="count">
                  {{ physicalScreenData.totalNonCompliantCount }}
                </div>
                <div *ngIf="!physicalScreenData" class="count">
                  0
                </div>
                <div class="divider" style="width: 100%;">
                  <div class="line" [ngStyle]="{ width: 100 - pdPlotLine + '%' }"></div>
                  <!-- <div class="point" [style.right.%]="pdPlotLine"></div> -->
                </div>
                <div class="summryText">Alerts</div>
              </div>
            </div>
          </mat-card-subtitle>
          <mat-card-content style="display: block; margin: 0px auto; width: 566px; height: 204px;">
            <!-- <canvas id="columnChart" #chart height="100"></canvas> -->
            <canvas id="myDonut" #donut height="100"></canvas>
          </mat-card-content>
        </div>
      </mat-card>
      <!-- <mat-card class="background custom-row"> -->
      <mat-card *ngIf="HH" class="col-12 col-lg-6 col-xl-6 background" style="padding: 0 10px 32px 0px;">
        <div class="foreground thermal-screening " style="cursor: pointer;">
          <mat-card-title class="thermal tile_heading custom-row" style="margin-bottom: 15px;">
            <div class="col-lg-7 col-xl-7 card-title">HAND HYGIENE</div>
            <div class="col-lg-5 col-xl-5 yesterday">
              <div class="text custom-row" style="float: right;">
                <small> Since Yesterday </small>
                <span *ngIf="hygieneDataDiff > 0; else minus2"> {{ hygieneDataDiffVal }} + </span>
                <ng-template #minus2>
                  <span>{{ hygieneDataDiffVal }} -</span>
                </ng-template>
                <span class="inc-dec">
                  <i *ngIf="hygieneDataDiff > 0; else decreasedgreen"
                    class="material-icons increased-red">arrow_upward</i>
                  <ng-template #decreasedgreen>
                    <i class="material-icons decreased-green">arrow_downward </i>
                  </ng-template>
                </span>
              </div>
            </div>
          </mat-card-title>
          <mat-card-subtitle class="custom-row" style="margin-bottom: 0px;">
            <div class="col-lg-12 col-xl-12 overview fever complaint_count">
              <div>
                <div *ngIf="hygieneScreenData" class="count">{{ hygieneScreenData.totalNonCompliantCount }}</div>
                <div *ngIf="!hygieneScreenData" class="count">0</div>
                <div class="divider" style="width: 100%;">
                  <div class="line" [ngStyle]="{ width: 100 - hhPlotLine + '%' }"></div>
                  <!-- <div class="point" [style.right.%]="hhPlotLine"></div> -->
                </div>
                <div class="summryText">Alerts</div>
              </div>
            </div>
          </mat-card-subtitle>
          <mat-card-content style="display: block; margin: 0px auto; width: 566px; height: 204px;">
            <canvas id="myDonut1" #donut1 height="100"></canvas>
          </mat-card-content>
        </div>
      </mat-card>
      <mat-card *ngIf="PY" class="col-12 col-lg-6 col-xl-6 background" style="padding: 0 0px 32px 10px;">
        <div class="foreground thermal-screening " style="cursor: pointer;">
          <mat-card-title class="thermal tile_heading custom-row" style="margin-bottom: 15px;">
            <div class="col-lg-6 col-xl-6 card-title">PEOPLE DENSITY</div>
            <div class="col-lg-6 col-xl-6 yesterday">
              <div class="text custom-row" style="float: right;">
                <small> Since Yesterday </small>
                <span *ngIf="peopleDensityDiff > 0; else minus2"> {{ peopleDensityDiffVal }} + </span>
                <ng-template #minus2>
                  <span>{{ peopleDensityDiffVal }} -</span>
                </ng-template>
                <span class="inc-dec">
                  <i *ngIf="peopleDensityDiff > 0; else decreasedgreen"
                    class="material-icons increased-red">arrow_upward</i>
                  <ng-template #decreasedgreen>
                    <i class="material-icons decreased-green">arrow_downward </i>
                  </ng-template>
                </span>
              </div>
            </div>
          </mat-card-title>
          <mat-card-subtitle class="custom-row" style="margin-bottom: 0px;">
            <div class="col-lg-12 col-xl-12 overview fever complaint_count">
              <div>
                <div *ngIf="peopleScreenData" class="count">{{ peopleScreenData.totalNoticiationCount }}</div>
                <div *ngIf="!peopleScreenData" class="count">0</div>
                <div class="divider" style="width: 100%;">
                  <div class="line" [ngStyle]="{ width: 100 - ppPlotLine + '%' }"></div>
                  <!-- <div class="point" [style.right.%]="hhPlotLine"></div> -->
                </div>
                <div class="summryText">Notifications</div>
              </div>
            </div>
          </mat-card-subtitle>
          <mat-card-content class="PY_chart">
            <canvas id="columnChart1" #chart1 height="100"></canvas>
          </mat-card-content>
          <!-- <mat-card-content style="display: block; margin-bottom: 0px;">
            <canvas id="myDonut2" #donut2 height="100"></canvas>
          </mat-card-content> -->
        </div>
      </mat-card>
      <mat-card *ngIf="AQ" class="col-12 col-lg-6 col-xl-6 background" style="padding: 0 10px 32px 0px;">
        <div class="foreground thermal-screening  air-quality-container" style="cursor: pointer;"
          [ngStyle]="{'border-color':complianceStatusId==1?'var(--airquality-good);':''}"
          [ngStyle]="{'border-color':complianceStatusId==2?'var(--airquality-moderate);':''}"
          [ngStyle]="{'border-color':complianceStatusId==3?'var(--airquality-unhealthyforsome);':''}"
          [ngStyle]="{'border-color':complianceStatusId==4?'var(--airquality-unhealthy);':''}"
          [ngStyle]="{'border-color':complianceStatusId==5?'var(--airquality-veryunhealthy);':''}"
          [ngStyle]="{'border-color':complianceStatusId==6?'var(--airquality-hazardous);':''}"
          >
          <mat-card-title class="thermal tile_heading  custom-row" style="margin-bottom: 15px; font-weight: 500;">
            <div class="col-lg-6 col-xl-6 AQ_heading">AIR QUALITY</div>
            <div class="col-lg-6 col-xl-6 yesterday AQ_status">
              <div class="text custom-row align-middle">
                <small> Risk </small>
                <button class="mat-button airquality-button" [ngClass]="{
                    good: complianceStatusId == '1',
                    moderate: complianceStatusId == '2',
                    unhealthyforsome: complianceStatusId == '3',
                    unhealthy: complianceStatusId == '4',
                    veryunhealthy: complianceStatusId == '5',
                    hazardous: complianceStatusId == '6'
                  }">
                  {{ complianceStatusName }}
                </button>
              </div>
            </div>
          </mat-card-title>
          <hr />
          <mat-card-content style="display: block; margin: 0px; width: 100%;">
            <div class="row text-center air-quality">
              <div class="col vertical-border">
                <div>
                  <h5>Temperature</h5>

                  <img *ngIf="!isLightTheme" src="assets/thermometer.svg" />
                  <img *ngIf="isLightTheme" src="assets/thermometer_light.svg" />
                  <p [ngClass]="{
                      good_txt: iaqtempStatusCode == '1',
                      moderate_txt: iaqtempStatusCode == '2',
                      unhealthyforsome_txt: iaqtempStatusCode == '3',
                      unhealthy_txt: iaqtempStatusCode == '4',
                      veryunhealthy_txt: iaqtempStatusCode == '5',
                      hazardous_txt: iaqtempStatusCode == '6'
                    }">
                    {{ iaqtemp }}&#8457;
                  </p>
                </div>
              </div>
              <div class="col vertical-border">
                <div>
                  <h5>Humidity</h5>
                  <img *ngIf="!isLightTheme" src="assets/Path 256.svg" />
                  <img *ngIf="isLightTheme" src="assets/humidity.svg" />
                  <p [ngClass]="{
                      good_txt: iaqhumidStatusCode == '1',
                      moderate_txt: iaqhumidStatusCode == '2',
                      unhealthyforsome_txt: iaqhumidStatusCode == '3',
                      unhealthy_txt: iaqhumidStatusCode == '4',
                      veryunhealthy_txt: iaqhumidStatusCode == '5',
                      hazardous_txt: iaqhumidStatusCode == '6'
                    }">
                    {{ iaqhumid }}%
                  </p>
                </div>
              </div>
              <div class="col">
                <div>
                  <h5>{{ iarairflowText }}</h5>
                  <img *ngIf="!isLightTheme" src="assets/Group 1311.svg" />
                  <img *ngIf="isLightTheme" src="assets/airflow.svg" />
                  <p [ngClass]="{
                      good_txt: iaqairflowStatusCode == '1',
                      moderate_txt: iaqairflowStatusCode == '2',
                      unhealthyforsome_txt: iaqairflowStatusCode == '3',
                      unhealthy_txt: iaqairflowStatusCode == '4',
                      veryunhealthy_txt: iaqairflowStatusCode == '5',
                      hazardous_txt: iaqairflowStatusCode == '6'
                    }">
                    {{ iaqairflow }} ppm
                  </p>
                </div>
              </div>
            </div>
            <hr />
            <div class="particulate">PARTICULATE MATTER</div>
            <div class="row text-center air-quality">
              <div class="col vertical-border">
                <div>
                  <h5>PM 10</h5>
                  <img *ngIf="!isLightTheme" src="assets/Group 1312.svg" />
                  <img *ngIf="isLightTheme" src="assets/PM_1.svg" />
                  <p [ngClass]="{
                      good_txt: iaqPM110StatusCode == '1',
                      moderate_txt: iaqPM110StatusCode == '2',
                      unhealthyforsome_txt: iaqPM110StatusCode == '3',
                      unhealthy_txt: iaqPM110StatusCode == '4',
                      veryunhealthy_txt: iaqPM110StatusCode == '5',
                      hazardous_txt: iaqPM110StatusCode == '6'
                    }">
                    {{ iaqPM10 }} μg/m³
                  </p>
                </div>
              </div>
              <div class="col vertical-border">
                <div>
                  <h5>PM 2.5</h5>
                  <img *ngIf="!isLightTheme" src="assets/Group 1313.svg" />
                  <img *ngIf="isLightTheme" src="assets/PM_1.svg" />
                  <p [ngClass]="{
                      good_txt: iaqPM25StatusCode == '1',
                      moderate_txt: iaqPM25StatusCode == '2',
                      unhealthyforsome_txt: iaqPM25StatusCode == '3',
                      unhealthy_txt: iaqPM25StatusCode == '4',
                      veryunhealthy_txt: iaqPM25StatusCode == '5',
                      hazardous_txt: iaqPM25StatusCode == '6'
                    }">
                    {{ iaqPM25 }} μg/m³
                  </p>
                </div>
              </div>
              <div class="col">
                <div>
                  <h5>TVoC</h5>
                  <img *ngIf="!isLightTheme" src="assets/molecule.svg" />
                  <img *ngIf="isLightTheme" src="assets/molecule_light.svg" />
                  <p [ngClass]="{
                      good_txt: iaqtvocStatusCode == '1',
                      moderate_txt: iaqtvocStatusCode == '2',
                      unhealthyforsome_txt: iaqtvocStatusCode == '3',
                      unhealthy_txt: iaqtvocStatusCode == '4',
                      veryunhealthy_txt: iaqtvocStatusCode == '5',
                      hazardous_txt: iaqtvocStatusCode == '6'
                    }">
                    {{ iaqtvoc }}
                    mg/m³
                  </p>
                </div>
              </div>
            </div>
          </mat-card-content>
        </div>
      </mat-card>
      <!-- </mat-card> -->
    </mat-card-content>
  </mat-card>
</div>