import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { apiPath } from '../api.path';
import { blockCalendarRequest } from './models/blockCalendar-request.model';
import { bookMeetingRoomRequest } from './models/bookMeetingRoom-request.model';
import { getMeetingRoomRequest } from './models/getMeetingRoom-request.model';
import { MeetingRoom } from './models/meeting-room.model';
import { sendInvitesRequest } from './models/sendInvites-request.model';

@Injectable({
  providedIn: 'root',
})
export class BookmeetingService {
  baseUrl = environment.meetingRoomUrl;
  graphUrl = environment.graphUrl;
  sensorbaseUrl = environment.apiUrl + 'sensor/';
  spaceManagementBaseUrl = environment.apiUrl + 'workspace/space-management/';
  private options = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };

  meetingFilter = new BehaviorSubject(null);
  meetingDateTime = new BehaviorSubject(null);
  showDateTime = new BehaviorSubject(null);
  bookedMeeting = new BehaviorSubject(null);
  showLogo = new BehaviorSubject(true);
  location = new BehaviorSubject(null);
  setError = new BehaviorSubject(null);
  updatemeetingDetails = new BehaviorSubject(null);
  storeTimeZone = new BehaviorSubject(null);
  meetingId: any;

  constructor(private http: HttpClient) {}

  getMeetingList(request: getMeetingRoomRequest): Observable<MeetingRoom[]> {
    let params = new HttpParams({
      fromObject: {
        StartDate: request.startTime || undefined,
        EndDate: request.endTime || undefined,
        SpaceId: request.spaceId || undefined,
        recurrencceType: request.recurrenceType,
      },
    });
    if (request.capacity !== null) {
      params = params.append('Capacity', request.capacity);
    }
    if (request.features !== null) {
      params = params.append('MeetingFeatures', request.features);
    }
    if (request.services !== null) {
      params = params.append('MeetingServices', request.services);
    }
    return this.http.get<MeetingRoom[]>(this.baseUrl, { params });
  }

  bookMeetingRoom(request: bookMeetingRoomRequest): any {
    return this.http
      .post<MeetingRoom[]>(apiPath.bookMeetingRoom, request, {
        observe: 'response',
        responseType: 'json',
      })
      .pipe(map((res) => res));
  }

  updateMeetingRoom(request: any) {
    return this.http
      .put(apiPath.bookMeetingRoom, request)
      .pipe(map((res) => res));
  }

  getMeetingRoomFeatures() {
    return this.http.get(environment.meetingRoomUrl + `MeetingFeatures`);
  }
  getCityList() {
    return this.http.get(apiPath.getCityValue);
  }

  getFacilityByCity(city: any) {
    return this.http.get(apiPath.getFacilityValue + `${city}`);
  }

  getBuildingByFacility(facility: any) {
    return this.http.get(apiPath.getBuildingValue + `${facility}`);
  }

  getFloorByBuilding(building: any) {
    return this.http.get(apiPath.getBuildingValue + `${building}`);
  }
  getMeetingRoomServices() {
    return this.http.get(environment.meetingRoomUrl + `MeetingServices`);
  }

  updateUserLocation(request: any) {
    console.log(request);
    return this.http.put(
      apiPath.updateUserLocation + `?SpaceId=${request}`,
      request
    );
  }

  getUserLocation() {
    let azureToken = localStorage.getItem('azureToken');
    var header = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${azureToken}`,
        // 'Access-Control-Allow-Origin': '*',
      }),
    };
    console.log(azureToken);
    console.log(header);
    let url = this.baseUrl + `Location`;
    return this.http.get(url, header);
  }

  getAllCity() {
    return this.http.get(this.baseUrl + 'GetCityNames');
  }

  // getFacilityByCity(city: any) {
  //   return this.http.get(this.baseUrl + `GetFacilityByCity?city=${city}`);
  // }

  // getBuildingByFacility(city: any, facility: any) {
  //   return this.http.get(this.baseUrl + `GetBuildingByFacility?city=${city}&facility=${facility}`);
  // }

  getMeetingRoomByQrcode(qrCodeRef: any) {
    return this.http.get(this.baseUrl + `getMeetingRoomByQrcode/${qrCodeRef}`);
  }

  getUserName(name: any) {
    return this.http.get(apiPath.getUser + name);
  }
  getUserNamebySearch(name: any, startTime: any, endTime: any, timeZone: any) {
    return this.http.get(
      this.graphUrl +
        `User?Name=${name}&StartTime=${startTime}&EndTime=${endTime}&TimeZone=${timeZone}`
    );
  }
  sendInvites(param: sendInvitesRequest) {
    return this.http.post(apiPath.sendInvites, param).pipe(map((res) => res));
  }

  blockCalendar(param: blockCalendarRequest) {
    return this.http.post(apiPath.blockCalendar, param).pipe(map((res) => res));
  }
  getCategory() {
    return this.http.get(apiPath.getCategoryList);
  }

  getAttendeeStatus(list: any, startTime: any, endTime: any, timeZone: any) {
    return this.http.get(
      environment.apiUrl +
        `Graph/Users?userList=${list}&StartTime=${startTime}&EndTime=${endTime}&TimeZone=${timeZone}`
    );
  }

  setBookingRoomId(id: any) {
    this.meetingId = id;
  }

  getBookingRoomId() {
    return this.meetingId;
  }

  getParentDataFacilitytoFloor(spaceId: any) {
    return this.http.get(this.spaceManagementBaseUrl + 'space/' + spaceId);
  }
}
