<mat-card class="employee-survey" *ngIf="isMainScreen">
  <!--<div class="title">{{formTitle}}</div>-->
  <div class="title">
    <div>{{ formTitle }}</div>
    <div>
      <img *ngIf="!isLightTheme" (click)="submitDeclaration()" src="assets/Group 99.png" alt="" />
      <img *ngIf="isLightTheme" (click)="submitDeclaration()" src="assets/Light-Theme/Group 99.png" alt="" />
    </div>
  </div>
  <div class="seperator-space"></div>
  <mat-divider></mat-divider>
  <div class="seperator-space"></div>
  <p class="">
    {{ formDescription }}
  </p>

  <div class="start-btn">
    <button (click)="surveyForm()" mat-button>Start Survey</button>
  </div>
</mat-card>

<mat-card *ngIf="!isMainScreen">
  <!--<div class="title">{{ formTitle }}</div>-->
  <div class="title">
    <div>{{ formTitle }}</div>
    <div>
      <img *ngIf="!isLightTheme" (click)="submitDeclaration()" src="assets/Group 99.png" alt="" />
      <img *ngIf="isLightTheme" (click)="submitDeclaration()" src="assets/Light-Theme/Group 99.png" alt="" />
    </div>
  </div>
  <div class="seperator-space"></div>
  <mat-divider></mat-divider>
  <div class="seperator-space"></div>
  <form>
    <div class="" *ngFor="let question of questionSet; let index = index">
      <div class="" *ngIf="question.responseType.toLowerCase() === 'radio'">
        <p style="margin-bottom: 6px;">{{ index + 1 }}. {{ question.question }}</p>
        <mat-radio-group>
          <mat-radio-button *ngFor="let option of question.questionOptions" value="{{ option }}">{{ option }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="" *ngIf="question.responseType.toLowerCase() === 'checkbox'">
        <p>{{ index + 1 }}. {{ question.question }}</p>
        <div style="display: flex;">
          <div class="radio-option" *ngFor="let option of question.questionOptions" style="margin-right: 10px;">
            <mat-checkbox value="{{ option }}">
              {{ option }}
            </mat-checkbox>
          </div>
        </div>
      </div>
    </div>
  </form>
  <div class="submit-btn" *ngIf="questionSet.length > 0">
    <button (click)="submitDeclaration()" mat-button>SUBMIT</button>
  </div>
</mat-card>