import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class ThermalService {

  constructor(private http: HttpClient) { }

  getEmployeeDoctorsNote(employeeId: number) {
    return this.http.get(environment.TS_baseURL + 'ebtd/' + `doctor-note/employee/${employeeId}`);
  }

  getSecurityScanningDetils(spaceId: number) {
    return this.http.get(environment.TS_baseURL + `ebtd/security-view/temperature?spaceId=${spaceId}`);
  }

  getSecurityScanningDetilsLatest(spaceId: number) {
    return this.http.get(environment.TS_baseURL + `ebtd/security-view/temperature/latest?spaceId=${spaceId}`);
  }

  getEmployeeNotification(spaceId: number,timeZone:any) {
    return this.http.get(environment.TS_baseURL + `EmployeeNotification/?SpaceId=${spaceId}&timeZone=${timeZone}`);
  }
  getFacilityByRegion() {
    return this.http.get(environment.PY_baseURL + `/space-management/space/root/region/3`);
  }
  getSpaceDetailsByParentSpace(parentSpaceId: number) {
    return this.http.get(environment.PY_baseURL + `/space-management/space/parent/${parentSpaceId}`);
  }

  getThresholdTempBySpaceId(spaceId: any) {
    return this.http.get(
      environment.TS_baseURL + `/FacilitySettingConfig/SettingsTypeId?spaceId=${spaceId}&settingsTypeId=2`
    );
  }

  postAdmitData(employeeId: any, overrideReason: any, overrideValue: any) {
    return this.http.put(
      environment.TS_baseURL +
      `/ebtd/security-view/secondary-screening?employeeId=${employeeId}&overrideReason=${overrideReason}&overrideValue=${overrideValue}`,
      {}
    );
  }
}
