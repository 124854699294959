import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
@Pipe({
  name: 'countDay',
})
export class CountDayPipe implements PipeTransform {
  transform(reportedDate: any): any {
    // let reportDate = new Date(reportedDate);
    // let todaysDate = new Date();
    // const diffTime = Math.abs(todaysDate - reportDate),
    //   dateGap = Math.ceil(diffTime / (1000 * 60 * 60 * 24)),

    // let reportDate: any = new Date(reportedDate),
    //   todaysDate: any = new Date(),
    //   diffTime = Math.abs(todaysDate - reportDate),
    //   dateGap = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    //  dateGap = todaysDate.getDate() - reportDate.getDate();
    let reportDate: any = moment(reportedDate),
      todaysDate: any = moment(new Date()),
      dateGap = todaysDate.diff(reportDate, 'days');

    return dateGap;
  }
}
