<div class="root">
  <div class="title">
    <div>Delete Building</div>
    <!-- <div><img (click)="cancel()" src="assets/Group 99.png" alt="" /></div> -->
    <!-- <div><img (click)="cancel()" class="close-btn" src="assets/Add.png" alt="" /></div> -->
    <div class="remove-icon" (click)="cancel()"><i class="material-icons" style="font-size: 30px;"> cancel</i></div>
  </div>
  <hr class="border-line" />
  <div class="message">
    Selected Building <b>{{ data.message }}</b> will be deleted.Are you sure you want to delete?
  </div>
  <div class="btn">
    <button class="btn-reset" color="primary" (click)="close()">Delete</button>
  </div>
</div>
