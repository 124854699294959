<div class="location-header row">
  <p>Add New Floor</p>
  <button mat-dialog-close mat-icon-button>
    <span class="material-icons">cancel</span>
  </button>
</div>

<hr class="border-line" />
<mat-dialog-content>
  <form [formGroup]="floorDetailsArray">
    <div class="row">
      <div class="col-6">
        <mat-label>Floor Name &nbsp;<span class="red"> * </span></mat-label>
        <span *ngIf="isExistingBuilding" class="exceeding-area"
          >Floor Name already exists</span
        >
        <input
          class="input-field"
          autocomplete="off"
          matInput
          type="text"
          placeholder=""
          formControlName="floorName"
          (input)="nameCheck($event)"
          required
        />
      </div>
      <div class="col-6">
        <mat-label>Floor No </mat-label>
        <input
          class="input-field"
          autocomplete="off"
          matInput
          type="number"
          placeholder=""
          formControlName="floorNo"
        />
      </div>
      <div class="col-6">
        <mat-label>Gross Total Area (sq.ft)</mat-label>
        <span *ngIf="isExceedingArea" class="exceeding-area"
          >Carpet Area exceeds total area
        </span>
        <input
          class="input-field"
          autocomplete="off"
          matInput
          type="number"
          min="0"
          maxlength="10"
          placeholder=""
          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
          formControlName="squareFeetArea"
          (input)="onArea($event)"
        />
      </div>
      <div class="col-6">
        <mat-label>Seating Capacity</mat-label>
        <span *ngIf="isExceedingCapacity" class="exceeding-area"
          >Seating Capacity exceeds total capacity
        </span>
        <input
          class="input-field"
          autocomplete="off"
          matInput
          type="number"
          min="0"
          onkeypress="return (event.charCode == 8 || event.charCode == 0 || event.charCode == 13) ? null : event.charCode >= 48 && event.charCode <= 57"
          maxlength="10"
          placeholder=""
          formControlName="floorCapacity"
          (keyup)="onCapacity($event)"
        />
      </div>

      <div class="col-6 facility-upload">
        <mat-label class="label-input">
          Upload Floor Layout &nbsp;<span class="red"> {{ fileError }}</span>
        </mat-label>
        <button mat-stroked-button (click)="fileInput.click()" class="file-btn">
          <span
            class="file-name"
            [ngClass]="{ 'align-filename': fileName != undefined }"
            title="{{ fileName }}"
          >
            filename
          </span>
          <span
            [ngClass]="{ 'align-browse': fileName != undefined }"
            class="browse"
            >BROWSE</span
          >
          <input
            class=""
            #fileInput
            type="file"
            accept="image/*"
            (change)="onFileChange($event)"
            style="display: none"
          />
        </button>
        <button mat-raised-button class="btn-save" (click)="upload()">
          <img src="assets/Group 983.svg" alt="" /> <span> UPLOAD </span>
        </button>
        <button
          mat-icon-button
          *ngIf="fileNameFromDb != undefined"
          (click)="downloadMoreInfoFile(-1)"
          class="view"
          style="width: 14px; margin-right: 3px"
        >
          <span class="material-icons">visibility</span>
        </button>
        <span class="or-span">OR</span>
      </div>

      <div class="col-6">
        <mat-label class="label-input"> Select from Previous Layouts</mat-label>
        <mat-form-field appearance="outline" style="width: 100%">
          <mat-select
            panelClass="mat-select-panel-light"
            (selectionChange)="floorselection($event.value)"
            [disabled]="existingFloorsList.length > 0 ? false : true"
          >
            <mat-option [value]="f" *ngFor="let f of existingFloorsList">{{
              f.floorName
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <div class="customElement">
      <ng-container *ngFor="let form of formCollection.controls; index as i">
        <form class="m-t-15" [formGroup]="form">
          <div class="fields row">
            <div class="col-5">
              <mat-label class="label-input"> Key </mat-label>
              <input
                class="input-field"
                autocomplete="off"
                matInput
                type="text"
                formControlName="key"
              />
            </div>
            <div class="col-4">
              <mat-label class="label-input"> Value </mat-label>
              <input
                class="input-field"
                autocomplete="off"
                matInput
                type="text"
                formControlName="value"
                (input)="onValueChange($event, i)"
              />
            </div>
            <div class="bgicon delete" (click)="deleteForm(i)">
              <img src="assets/Group 1381.svg" alt="" />
            </div>
            <div class="bgicon upload">
              <input type="file" (change)="onKeyValueFileChange($event, i)" />
              <img src="assets/Group 1422.svg" alt="" />
              <!--  <span
                class="material-icons"
                *ngIf="addedInfoFileType[i] == 'file'"
                (click)="downloadMoreInfoFile(i)"
                class="view"
                >visibility</span
              > -->
            </div>
            <div
              *ngIf="addedInfoFileType[i] == 'file'"
              (click)="downloadMoreInfoFile(i)"
              class="view"
            >
              <span class="material-icons">visibility</span>
            </div>
          </div>
        </form>
      </ng-container>
      <div class="addfield" (click)="addRow()">
        <div>
          <img src="assets/Add.svg" alt="" />
        </div>
        <div>Add Additional Field</div>
      </div>
    </div>
  </form>

  <div class="select-location">
    <button
      mat-stroked-button
      class="stroked-btn"
      [disabled]="!floorDetailsArray.value.spaceId"
      [mat-dialog-close]="addNew"
    >
      ADD NEW
    </button>
    <button
      mat-raised-button
      class="raised-btn"
      [disabled]="
        floorDetailsArray.invalid ||
        isExceedingCapacity ||
        isExistingBuilding ||
        isExceedingArea
      "
      (click)="close()"
    >
      SAVE
    </button>
  </div>
</mat-dialog-content>
