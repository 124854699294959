import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FacilityService } from '../facility.service';

@Component({
  selector: 'app-facility-onboard',
  templateUrl: './facility-onboard.component.html',
  styleUrls: ['./facility-onboard.component.scss']
})
export class FacilityOnboardComponent implements OnInit {

  showFacility = false;
  showBuilding = false;
  showFloor = false;
  showZone = false;
  showConfigureZone = false;
  masterObj: any = {
    facility: {},
    building: {},
    floor: {},
    zone: {},
  };

  isCompleted = {
    facility: false,
    building: false,
    floor: false,
    zone: false,
  };

  @Input() facilitySpaceId: any;

  @Output() toogleView = new EventEmitter<any>();

  constructor(private service:FacilityService) {}

  reset() {
    this.masterObj = {
      facility: {},
      building: {},
      floor: {},
      zone: {},
    };

    this.isCompleted = {
      facility: false,
      building: false,
      floor: false,
      zone: false,
    };
  }

  ngOnInit(): void {
    console.log("onboard", this.facilitySpaceId, !!this.facilitySpaceId)
    if (!!this.facilitySpaceId) {
      this.service.getSpaceDetailsBySpaceId(this.facilitySpaceId).subscribe((facility: any) => {
        console.log(facility);
        const formCollection: any = [];
        facility.spaceProperties.map((property: any) => {
          if (property.propertyKey === 'name') {
            this.masterObj.facility.spaceId = facility.spaceId;
            this.masterObj.facility.facilityName = property.propertyValue;
          }
          if (property.propertyKey === 'type') {
            this.masterObj.facility.facilityType = property.propertyValue;
          }
          if (property.propertyKey === 'Location') {
            this.masterObj.facility.facilityLocation = property.propertyValue;
          }
          if (property.propertyKey === 'capacity') {
            this.masterObj.facility.facilityCapacity = parseInt(property.propertyValue, 10);
          }
          if (property.propertyKey === 'squareFeetArea') {
            this.masterObj.facility.squareFeetArea = parseInt(property.propertyValue, 10);
          }
          if (property.propertyKey === 'childSpaces') {
            this.masterObj.facility.noOfBuildings = parseInt(property.propertyValue, 10);
          }
          if (property.propertyKey === 'region') {
            this.masterObj.facility.region = property.propertyValue;
          }
          if (property.propertyKey === 'facilityLayout') {
            this.masterObj.facility.facilityLayout = property.propertyValue;
          }
          if (property.propertyKey === 'latlong') {
            this.masterObj.facility.latlong = property.propertyValue;
          }

          if (property.propertyKey === 'addressline1') {
            this.masterObj.facility.addressline1 = property.propertyValue;
          }
          if (property.propertyKey === 'addressline2') {
            this.masterObj.facility.addressline2 = property.propertyValue;
          }
          if (property.propertyKey === 'country') {
            this.masterObj.facility.country = property.propertyValue;
          }
          if (property.propertyKey === 'province') {
            this.masterObj.facility.province = property.propertyValue;
          }
          if (property.propertyKey === 'city') {
            this.masterObj.facility.city = property.propertyValue;
          }
          if (property.propertyKey === 'pincode') {
            this.masterObj.facility.pincode = property.propertyValue;
          }
          if (property.propertyKey === 'countryregion') {
            this.masterObj.facility.countryregion = property.propertyValue;
          }
          if (property.propertyKey === 'layoutName') {
            this.masterObj.facility.layoutName = property.propertyValue;
          }
          if (property.propertyKey.indexOf('_Add') >= 0) {
            formCollection.push({
              id: property.spacePropertyId,
              key: property.propertyKey,
              value: property.propertyValue,
              type: property.propertyType,
            });
          }
        });
        this.masterObj.facility.addProperty = formCollection;
        this.showFacility = true;
      });
    } else {
      this.showFacility = true;
    }
    console.log("facility", this.showFacility)
  }

  close() {
    this.toogleView.emit('faciityList');
  }

  completed(data: any) {
    if (data.type === 'building') {
      this.isCompleted.building = data.data;
    }
    if (data.type === 'floor') {
      this.isCompleted.floor = data.data;
    }
    if (data.type === 'zone') {
      this.isCompleted.zone = data.data;
    }
  }

  saveChildData(data: any) {
    if (data.type === 'facility') {
      this.masterObj.facility = data.data;
      this.isCompleted.facility = true;
    }

    if (data.type === 'building') {
      this.masterObj.building = data.data;
      const count = parseInt(this.masterObj.facility.noOfBuildings, 10);
      let counter = 0;
      this.masterObj.building.buildingCountArray.map((building: any) => {
        if (building.spaceId) {
          counter++;
        }
      });

      if (count === counter) {
        this.isCompleted.building = true;
      } else {
        this.isCompleted.building = false;
      }
    }

    if (data.type === 'floor') {
      this.masterObj.floor = data.data;
      let countFloors = 0;
      this.masterObj.building.buildingCountArray.map((building: any) => {
        countFloors = countFloors + parseInt(building.data.noOffloors, 10);
      });
      let counter = 0;
      this.masterObj.floor.floors.map((floor: any) => {
        if (floor.spaceId) {
          counter++;
        }
      });
      if (countFloors === counter) {
        this.isCompleted.floor = true;
      } else {
        this.isCompleted.floor = false;
      }
    }
    if (data.type === 'zone') {
      this.masterObj.zone = data.data;
      let countZones = 0;
      this.masterObj.floor.floors.map((floor: any) => {
        countZones = countZones + parseInt(floor.data.noOfZones, 10);
      });
      let counter = 0;
      this.masterObj.zone.zones.map((zone: any) => {
        if (zone.spaceId) {
          counter++;
        }
      });
      if (countZones === counter) {
        this.isCompleted.zone = true;
      } else {
        this.isCompleted.zone = false;
      }
    }
  }

  showComponent(component: string) {
    if (component === 'facility') {
      console.log("bool1", this.showFacility)
      this.showFacility = true;
      this.showBuilding = false;
      this.showFloor = false;
      this.showZone = false;
      this.showConfigureZone = false;
    }

    if (component === 'building') {
      this.showFacility = false;
      this.showBuilding = true;
      this.showConfigureZone = false;
      this.showFloor = false;
      this.showZone = false;
    }

    if (component === 'floor') {
      this.showFacility = false;
      this.showConfigureZone = false;
      this.showBuilding = false;
      this.showFloor = true;
      this.showZone = false;
    }

    if (component === 'zone') {
      this.showFacility = false;
      this.showConfigureZone = false;
      this.showBuilding = false;
      this.showFloor = false;
      this.showZone = true;
    }

    if (component === 'configureZone') {
      this.showFacility = false;
      this.showConfigureZone = false;
      this.showBuilding = false;
      this.showConfigureZone = true;
      this.showFloor = false;
      this.showZone = false;
    }
    console.log("bool", this.showFacility)
  }

  goBack() {
    this.toogleView.emit('faciityList');
  }
}
