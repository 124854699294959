import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacilityService } from '../facility.service';


@Component({
  selector: 'app-floor-view',
  templateUrl: './floor-view.component.html',
  styleUrls: ['./floor-view.component.scss']
})
export class FloorViewComponent implements OnInit {
  floorDetailsArray = new FormGroup({
    spaceId: new FormControl(''),
    floorName: new FormControl('', Validators.required),
    squareFeetArea: new FormControl('', Validators.required),
    floorCapacity: new FormControl('', Validators.required),
    childSpaces: new FormControl(''),
    parentSpaceId: new FormControl(''),
    floorPlan: new FormControl(''),
  });
  image: any;
  constructor(
    public dialogRef: MatDialogRef<FloorViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: FacilityService
  ) {}

  ngOnInit(): void {
    this.service.getSpaceDetailsBySpaceId(this.data.spaceId).subscribe((floor: any) => {
      floor.spaceProperties.map((property: any) => {
        if (property.propertyKey === 'name') {
          this.floorDetailsArray.patchValue({
            floorName: property.propertyValue,
          });
        }
        if (property.propertyKey === 'squareFeetArea') {
          this.floorDetailsArray.patchValue({
            squareFeetArea: property.propertyValue,
          });
        }
        if (property.propertyKey === 'capacity') {
          this.floorDetailsArray.patchValue({
            floorCapacity: property.propertyValue,
          });
        }
        if (property.propertyKey === 'image') {
          this.service.getFloorImage(this.data.spaceId).subscribe((image: any) => {
            this.image = 'data:image/png;base64,' + image.base64String;
          });
        }
      });
    });
  }
}
