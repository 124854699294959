import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { pluck } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class HotDeskingService {
  private graphUrl = environment.graphUrl;
  private baseURL = environment.apiUrl + 'deskbooking/';
  dateTimeObj = new BehaviorSubject(null);
  constructor(private http: HttpClient) {}

  private bookDeskData = new BehaviorSubject({});
  currentBookDeskData = this.bookDeskData.asObservable();

  private filterCount = new BehaviorSubject(0);
  currentfilterCount = this.filterCount.asObservable();

  private filters = new BehaviorSubject({});
  currentFilters = this.filters.asObservable();

  private previousFilters = new BehaviorSubject({});
  previousFiltersObs = this.filters.asObservable();

  private book_time = new BehaviorSubject(false);
  is_bookTime_changed = this.book_time.asObservable();

  private successPopupStatus$ = new BehaviorSubject(false);

  bookingTimeZone = new BehaviorSubject(null);

  changeBookTime(data: boolean) {
    this.book_time.next(data);
  }

  changePreviousFilters(data: any) {
    this.previousFilters.next(data);
  }

  changeBookDeskData(data: any) {
    this.bookDeskData.next(data);
  }

  changefilterCount(data: any) {
    this.filterCount.next(data);
  }

  changeFilters(data: any) {
    this.filters.next(data);
  }

  setPopupStatus(status: boolean) {
    this.successPopupStatus$.next(status);
  }

  getPopupStatus(): Observable<boolean> {
    return this.successPopupStatus$.asObservable();
  }

  getDesksCoordinates() {
    return this.http.get('../../assets/data/seats.json');
  }

  getDeskTypes() {
    return this.http.get(`${this.baseURL}GetDeskTypes`);
  }

  getDeskFeatures() {
    return this.http.get(`${this.baseURL}GetDeskFeatures`);
  }

  getDeskDetailsByUser(userId: any) {
    return this.http.get(
      `${this.baseURL}GetDeskDetailsByUserId?userId=${userId}`
    );
  }

  getDeskDetails(
    city: any,
    facility: any,
    building: any,
    floor: any,
    from: any,
    to: any,
    deskType: any,
    features: any
  ) {
    if (
      (deskType == undefined || deskType == '') &&
      (features == undefined || features == '')
    ) {
      return this.http.get(
        `${this.baseURL}GetDeskDetails?City=${city}&Facility=${facility}&Building=${building}&Floor=${floor}&StartTime=${from}&EndTime=${to}`
      );
    } else if (
      (deskType != undefined || deskType != '') &&
      (features == undefined || features == '')
    ) {
      return this.http.get(
        `${this.baseURL}GetDeskDetails?City=${city}&Facility=${facility}&Building=${building}&Floor=${floor}&StartTime=${from}&EndTime=${to}&DeskType=${deskType}`
      );
    } else if (
      (deskType == undefined || deskType == '') &&
      (features != undefined || features != '')
    ) {
      return this.http.get(
        `${this.baseURL}GetDeskDetails?City=${city}&Facility=${facility}&Building=${building}&Floor=${floor}&StartTime=${from}&EndTime=${to}&DeskFeature=${features}`
      );
    } else if (
      (deskType != undefined || deskType != '') &&
      (features != undefined || features != '')
    ) {
      return this.http.get(
        `${this.baseURL}GetDeskDetails?City=${city}&Facility=${facility}&Building=${building}&Floor=${floor}&StartTime=${from}&EndTime=${to}&DeskType=${deskType}&DeskFeature=${features}`
      );
    }
    // GetDeskDetails?City=Pune&Facility=CDC&Building=Building%201&Floor=Floor%201&StartTime=2021-04-08T08%3A40%3A13&EndTime=2021-04-08T09%3A40%3A13&DeskType=premium%3Bstandard&DeskFeature=single%20monitor%3Bdouble%20monitor
  }

  addNewDesk(Json: any) {
    return this.http.post(`${this.baseURL}PostDeskBooking`, Json);
  }

  getUserNamebySearch(name: any, startTime: any, endTime: any, timeZone: any) {
    return this.http.get(
      this.graphUrl +
        `User?Name=${name}&StartTime=${startTime}&EndTime=${endTime}&TimeZone=${timeZone}`
    );
  }

  findColleagues(
    email: string,
    startTime: string,
    endTime: string,
    spaceId: number
  ): Observable<any> {
    return this.http.get(
      this.baseURL +
        `GetColleagueBooking?StartTime=${startTime}&EndTime=${endTime}&SearchedEmailId=${email}&FloorSpaceId=${spaceId}`
    );
  }

  bookHotDesk(reqObj: any): Observable<any> {
    return this.http.post(`${this.baseURL}DeskRequestPost`, reqObj, {
      observe: 'response',
      responseType: 'json',
    });
  }

  getZoneSpaceUtilizationDetails(
    spaceId: number,
    date: any,
    startTime: any,
    endTime: any
  ): Observable<any> {
    if (date && startTime && endTime) {
      return this.http
        .get(
          `${environment.apiUrl}report/zoneUtilization?SpaceId=${spaceId}&RequestDate=${date}&&RequestStartTime=${startTime}&RequestEndTime=${endTime}`
        )
        .pipe(pluck('zoneUtilizationList'));
    } else {
      return this.http
        .get(`${environment.apiUrl}report/zoneUtilization?SpaceId=${spaceId}`)
        .pipe(pluck('zoneUtilizationList'));
    }
  }
}