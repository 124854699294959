import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { globalConstant } from '@app/safe-building/safebuilding-global-constants';
import { environment } from '@env/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import moment from 'moment';
import { EmployeeService } from '../service/employee.service';
@Component({
  selector: 'app-employee-qa',
  templateUrl: './employee-qa.component.html',
  styleUrls: ['./employee-qa.component.scss']
})
export class EmployeeQaComponent implements OnInit {

  isFeedback = false;
  isAccessProvided: boolean;
  healthSurveyData: any;
  isFromDescription = true;
  formDescription: string;
  employeeSurveyForm: FormGroup;
  questionSet: any[];
  formTitle: any;
  suerveyId: string;
  checkListQuestions: any[] = [];
  radioQuestions: any[] = [];
  isShowQrcode: boolean = false;
  healthQuestionnaire: any;
  expiryTime: string;
  timeZone: string = globalConstant.timeZone;
  isLightTheme: boolean = environment.isLightTheme;
  constructor(
    private service: EmployeeService,
    private formbuilder: FormBuilder,
    public dialogRef: MatDialogRef<EmployeeQaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    //console.log('RAM Questionnaire API data')
    //console.log(this.healthQuestionnaire)
    //console.log('RAM dialog data');
    //console.log(this.data.surveryQuestionnaire);
    this.healthSurveyData = this.data.surveryQuestionnaire;
    this.formTitle = this.healthSurveyData.surveyTitle;
    this.formDescription = this.healthSurveyData.serveyDescription;
    this.suerveyId = this.healthSurveyData.surveyId;
    this.createForm();
  }

  surveyForm(): void {
    this.isFromDescription = !this.isFromDescription;
  }

  submitDeclaration(): void {
    let questionId;
    let chosenResponse: any[] = [];
    this.checkListQuestions.forEach((question) => {
      if (question.response.length > 0) {
        questionId = question.questionId;
        if (questionId in this.employeeSurveyForm.value)
          this.employeeSurveyForm.controls[questionId].setValue(question.response);
      }
    });
    if (this.employeeSurveyForm.valid) {
      let formResponse = this.employeeSurveyForm.value;
      for (const questionID in this.radioQuestions) {
        if (this.radioQuestions[questionID] in this.employeeSurveyForm.value) {
          chosenResponse.push(this.employeeSurveyForm.value[this.radioQuestions[questionID]].toLowerCase());
        }
      }
      if (chosenResponse.includes('yes')) {
        this.isAccessProvided = false;
        this.isFeedback = !this.isFeedback;
        this.isShowQrcode = false;
      } else {
        this.isAccessProvided = true;
        this.isFeedback = !this.isFeedback;
        this.isShowQrcode = true;
      }
      this.prepareResponseJson(formResponse);
    }
  }

  createForm() {
    this.employeeSurveyForm = this.formbuilder.group({});
    //this.healthSurveyData.employeehealthsurveyQuestions.forEach((element) => {
    let element = this.healthSurveyData;
    if ('surveyQuestions' in element) {
      this.questionSet = element.surveyQuestions;
      element.surveyQuestions.forEach((question: any) => {
        if (question.responseType.toLowerCase() === 'radio') {
          this.employeeSurveyForm.addControl(question.questionId, this.formbuilder.control('', Validators.required));
          this.radioQuestions.push(question.questionId.toString());
        } else if (question.responseType.toLowerCase() === 'checkbox') {
          this.employeeSurveyForm.addControl(
            question.questionId.toString(),
            this.formbuilder.control([], Validators.required)
          );
          this.checkListQuestions.push({
            questionId: question.questionId,
            options: question.questionOptions,
            response: [],
          });
        }
      });
    }
    //});
    /* console.log(this.employeeSurveyForm.value);
    console.log(this.checkListQuestions); */
  }

  changeCheckbox(event: any, questionId: string): void {
    let index = this.checkListQuestions.findIndex((data) => data.questionId === questionId);
    let optionIndex;
    if (index != -1) {
      if (this.checkListQuestions[index].options.indexOf(event.source.value) != -1) {
        if (event.checked) {
          this.checkListQuestions[index].response.push(event.source.value);
        } else {
          if (
            this.checkListQuestions[index].response.length > 0 &&
            this.checkListQuestions[index].response.indexOf(event.source.value) != -1
          ) {
            optionIndex = this.checkListQuestions[index].response.indexOf(event.source.value);
            this.checkListQuestions[index].response.splice(optionIndex, 1);
          }
        }
      }
    }
    console.log('event', event.checked, event.source.value, questionId, index, this.checkListQuestions[index]);
  }

  complianceHistory(): void {
    this.dialogRef.close({ data: { isShowQrcode: this.isShowQrcode, expiryTime: this.expiryTime } });
  }
  prepareResponseJson(formResponse: any) {
    let surveyJson: any = [];
    this.questionSet.forEach((question) => {
      let questionResonse;
      for (let snumber in formResponse) {
        if (snumber == question.questionId) {
          questionResonse = formResponse[snumber];
        }
      }
      surveyJson.push({
        SurveyId: question.surveyId,
        QuestionId: question.questionId,
        ResponseType: question.responseType,
        QuestionOptions: question.questionOptions,
        Responses: questionResonse.split(','),
      });
    });
    console.log('utc', new Date().toUTCString(), moment().utc());
    let json = {
      SurveyId: this.suerveyId,
      EmployeeId: this.data.empId.toString(),
      AccessFlag: this.isShowQrcode,
      Frequency: this.healthSurveyData.frequency,
      GuestFlag: true,
      submitDate: moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss'),
      timeZone: this.timeZone,
      surveyQuestions: surveyJson,
    };
    this.service.saveHealthQuestionnaireData(json).subscribe(
      (questionnaire: any) => {
        console.log(questionnaire);
        this.expiryTime = questionnaire.expiryDate;
      },
      (err: any) => {
        console.log(err);
      }
    );
    //console.log(JSON.stringify(json))
  }
  cancelSurvey() {
    this.dialogRef.close();
  }
}
