import { Component, OnInit, ViewChild } from '@angular/core';
import { globalConstant } from '../../safebuilding-global-constants';
import { BsDatepickerDirective } from 'ngx-bootstrap/datepicker/public_api';
import { MatDialog } from '@angular/material/dialog';
import { QuarantineService } from '../quarantine.service';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { AppService } from '@app/app.service';
import { SuccessmodalComponent } from '../successmodal/successmodal.component';
@Component({
  selector: 'app-modify-associate-details',
  templateUrl: './modify-associate-details.component.html',
  styleUrls: ['./modify-associate-details.component.scss']
})
export class ModifyAssociateDetailsComponent implements OnInit {

  @ViewChild('fromDate') fromdatePicker: BsDatepickerDirective;
  @ViewChild('toDate') todatePicker: BsDatepickerDirective;
  @ViewChild('reportDate') reportedDatePicker: BsDatepickerDirective;
  @ViewChild('fromExposeDate') exposedDatePicker: BsDatepickerDirective;

  timeZone: string = globalConstant.timeZone;
  status: boolean = true;
  isEnable: boolean = true;
  isDisable: boolean = false;
  textEntered: any;
  searchedPerson: any;
  fromQuarantineDate: string;
  toQuarantineDate: string;
  searchLength: number;
  reportDate: any;
  fromDate: any;
  toDate: any;
  namesList = ['Alex Smith', 'Micheal Johnson', 'Tom Cruise', 'Alex John'];
  searchString: any;
  showresult: boolean = false;
  statusSelected: string;
  selectedReportedDate: any;
  toggleFlag: boolean = true;
  resultList: {
    name: string;
    employeeId: string;
    role: string;
    email: string;
    contact: string;
    location: string;
    reportDate: string;
    status: string;
    fromDate: string;
    toDate: string;
    access: boolean;
    note: string;
  }[];
  displayResult: any;
  statusflag: boolean;
  temperatureCurent: any;
  temperatureCurentComplaint: boolean;
  physicalAlert: any;
  physicalAlertComplianceFlag: any = true;
  lastHandwash: any;
  lastHandwashComplianceFlag: any = true;
  employee: any;
  empReportedDate: any;
  empQuarantineStartDate: any;
  isStatusDisable: boolean = false;
  position: string;
  selectedName: string;
  showStatus: string;
  remarks: any;
  reportError: boolean = false;
  tempReportedDate: any;
  tempQuarantineStartDate: any;
  tempQuarantineEndDate: any;
  tempExposedDate: any;
  quarantineError: boolean = false;
  empQuarantineEndDate: any;
  tempRemarks: any;
  empRemarks: string = '';
  checkedFlag: boolean = false;
  minReportedDate: any;
  selectedExposedDate: any;
  exposedError: boolean = false;
  repDate: any;
  minExposedDate: any;
  maxExposedDate: Date;
  selectedEmail: string;
  minQuarantineStartDate: any;
  empExposedDate: any;
  noResult: boolean = false;
  selectedNavBar: any;
  selectedIndex: any;
  disabledFlag: boolean = false;

  constructor(private dialog: MatDialog, private service: QuarantineService, private appservice: AppService) {}
  today: Date;
  TS: boolean = environment.TS_enable;
  PD: boolean = environment.PD_enable;
  HH: boolean = environment.HH_enable;
  isLightTheme: boolean = environment.isLightTheme;

  ngOnInit(): void {
    this.appservice.currentTheme.subscribe((theme) => (this.isLightTheme = theme == 'theme-light' ? true : false));
    this.today = new Date();
    this.position = 'above';
  }

  selectStatus(event: any) {
    if (this.displayResult.status == event.value) {
      this.disabledFlag = true;
    }

    if (event.value === 'In Quarantine') {
      document.getElementById('toggleButton')['checked'] = true;
      this.isStatusDisable = true;
      this.statusflag = true;
      this.fromQuarantineDate = moment().format('DD MMM YYYY');
      this.toQuarantineDate = moment(this.fromQuarantineDate).add(13, 'days').format('DD MMM YYYY');
    } else if (event.value === 'Normal') {
      document.getElementById('toggleButton')['checked'] = false;
      this.isStatusDisable = true;
      this.statusflag = false;
    } else if (event.value === 'Exposed') {
      document.getElementById('toggleButton')['checked'] = true;
      this.isStatusDisable = true;
      this.statusflag = false;
    }
  }

  enterMember(value: KeyboardEvent) {
    this.searchedPerson = value;
    this.searchMember(value);
  }
  searchMember(value: any) {
    console.log(value);

    if (value === '') {
      this.showresult = false;
    } else {
      this.service.getSearchresult(value).subscribe(
        (data: any) => {
          if (data.employeeList.length !== 0) {
            this.showresult = true;
            this.resultList = data.employeeList;
            console.log(this.resultList);
            this.searchLength = this.resultList.length;
            if (this.searchLength === 0) {
              this.showresult = false;
              this.noResult = true;
            }
            this.showSelectedAssociate(this.resultList[0].employeeId, 0);
          } else {
            this.service.getSearchById(value).subscribe(
              (data: any) => {
                this.resultList = data.employeeList;
                this.showresult = true;
                this.searchLength = this.resultList.length;
                if (this.searchLength === 0) {
                  this.showresult = false;
                  this.noResult = true;
                }
                this.showSelectedAssociate(this.resultList[0].employeeId, 0);
              },
              (err: any) => {
                console.log(err);
              }
            );
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }

  getName() {
    return this.displayResult.personName;
  }

  getEmail() {
    return this.employee.email;
  }

  onSuccess(selectedStatus: any, reportDate: any, fromDate: any, toDate: any, Remarks: any) {
    if (this.selectedReportedDate === null || this.selectedReportedDate === '') {
      this.reportError = true;
    }
    this.remarks = Remarks;
    if (this.displayResult.status === 'In Quarantine') {
      if (this.fromQuarantineDate === null || this.toQuarantineDate === '') {
        this.quarantineError = true;
      }
      this.tempExposedDate = '';
      this.tempQuarantineStartDate = moment(this.fromQuarantineDate).format('YYYY-MM-DD');
      this.tempQuarantineEndDate = moment(this.toQuarantineDate).format('YYYY-MM-DD');
    } else if (this.displayResult.status === 'Normal') {
      this.tempQuarantineStartDate = '';
      this.tempQuarantineEndDate = '';
      this.tempExposedDate = '';
    } else if (this.displayResult.status === 'Exposed') {
      if (this.selectedExposedDate === null || this.selectedExposedDate === '') {
        this.tempExposedDate = '';
        this.exposedError = true;
      } else {
        this.tempExposedDate = moment(this.selectedExposedDate).format('YYYY-MM-DD');
        this.exposedError = false;
      }
      this.tempQuarantineStartDate = '';
      this.tempQuarantineEndDate = '';
    }

    var obj = {
      personId: this.displayResult.personId,
      status: this.displayResult.status === 'In Quarantine' ? 'InQuarantine' : this.displayResult.status,
      reportedDate: moment(this.selectedReportedDate).format('YYYY-MM-DD'),
      quarantineStartDate: this.tempQuarantineStartDate,
      quarantineEndDate: this.tempQuarantineEndDate,
      isAccessEnabled: !document.getElementById('toggleButton')['checked'],
      accessChangeDate: moment(new Date()).format('YYYY-MM-DD'),
      remarks: Remarks,
      exposedDate: this.tempExposedDate,
    };

    console.log(obj);
    this.showStatus = 'success';
    if (this.reportError === false && this.quarantineError === false && this.exposedError === false) {
      this.service.modifyAssociate(obj).subscribe(
        (data) => {
          let dialogRef = this.dialog.open(SuccessmodalComponent, {
            disableClose: true,
            height: '200px',
            width: '540px',
          });

          dialogRef.afterClosed().subscribe((result) => {
            console.log(this.displayResult.personId, 'after close');
            this.resultList.forEach((data: any, index: any) => {
              if (data.employeeId == this.displayResult.personId) {
                this.selectedIndex = index;
                return;
              }
            });
            console.log(this.selectedIndex);
            this.disabledFlag = false;
            this.showSelectedAssociate(this.displayResult.personId, this.selectedIndex);
            this.remarks = '';
          });
        },
        (err: any) => {
          console.log(err);
        }
      );
    }
  }
  showSelectedAssociate(id: any, index: any) {
    this.selectedNavBar = index;
    this.reportError = false;
    this.quarantineError = false;
    this.exposedError = false;
    this.isStatusDisable = true;
    this.remarks = '';
    this.tempRemarks = [];
    this.empRemarks = '';
    this.empReportedDate = null;
    this.empExposedDate = null;
    this.empQuarantineStartDate = null;
    // this.service.getEmployeeDetailsById(id, this.timeZone).subscribe(
    //   (employee: any) => {
    //     if (employee) {
    //       this.employee = employee;

    //       if (this.employee.email.length > 15) {
    //         this.selectedEmail = this.employee.email.substring(0, 15) + '...';
    //       } else {
    //         this.selectedEmail = this.employee.email;
    //       }
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );

    this.resultList.forEach((data: any) => {
      if (data.employeeId === id) {
        this.employee = data;
        if (this.employee) {
          if (this.employee.email.length > 15) {
            this.selectedEmail = this.employee.email.substring(0, 15) + '...';
          } else {
            this.selectedEmail = this.employee.email;
          }
        }
        return;
      }
    });
    if (this.TS == true) {
      this.service.getEmployeeTemperatureById(id, this.timeZone).subscribe(
        (temp: any) => {
          console.log(temp);
          if (temp?.data) {
            this.temperatureCurent = temp.data.toFixed(1);

            this.temperatureCurentComplaint = this.temperatureCurent > 99.3 ? false : true;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
    if (this.PD == true) {
      this.service.getPhysicalComplianceDurationById(id, this.timeZone).subscribe(
        (data: any) => {
          if (data) {
            this.physicalAlert = data.numberOfAlerts;
            if (this.physicalAlert === 0) {
              this.physicalAlertComplianceFlag = true;
            } else {
              this.physicalAlertComplianceFlag = data.complianceFlag;
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }

    if (this.HH == true) {
      this.service.getHygieneComplianceDurationById(id, this.timeZone).subscribe(
        (data: any) => {
          if (data) {
            console.log('hrs', data.duration);
            if (!!data.duration) {
              this.lastHandwash = data.duration.replace('Hours', '');
              if (this.lastHandwash === 0) {
                this.lastHandwashComplianceFlag = true;
              } else {
                this.lastHandwashComplianceFlag = data.complianceFlag;
              }
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }

    this.service.getSelectedPerson(id, this.timeZone).subscribe(
      (data: any) => {
        this.displayResult = data.contactTraces[0];
        console.log(this.displayResult);

        // this.reportedDatePicker._bsValue = new Date(this.displayResult.reportedDate);

        /// if (this.displayResult.status === 'Exposed') {
        //  console.log(this.displayResult.exposedDate);
        // this.exposedDatePicker._bsValue = new Date(this.displayResult.exposedDate);
        // }
        //this.displayResult.personName = 'alex smith Johnson Danny Williams';

        if (
          this.displayResult.remark === null ||
          this.displayResult.remark === '' ||
          this.displayResult.remark.length === 0
        ) {
          this.empRemarks = '-';
        } else {
          this.tempRemarks = this.displayResult.remark.split('\n');
        }

        if (this.displayResult.personName.length > 25) {
          this.selectedName = this.displayResult.personName.substring(0, 25) + '...';
        } else {
          this.selectedName = this.displayResult.personName;
        }
        if (this.displayResult.status === 'In Quarantine' && this.displayResult.isAccessEnabled === 'False') {
          this.isStatusDisable = true;
        }

        if (this.displayResult.isAccessEnabled === 'False') {
          this.displayResult.isAccessEnabled = false;
          this.checkedFlag = true;
        } else {
          this.displayResult.isAccessEnabled = true;
          this.checkedFlag = false;
        }

        if (this.displayResult.reportedDate === null) {
          this.selectedReportedDate = '';
          this.minReportedDate = moment(this.today).subtract(13, 'days');
          this.minReportedDate = new Date(this.minReportedDate);
          console.log(this.minReportedDate);
          if (this.displayResult.exposedDate === null) {
            this.minExposedDate = this.minReportedDate;
            this.maxExposedDate = this.today;
          }
        } else {
          this.selectedReportedDate = moment(this.displayResult.reportedDate).format('DD MMM YYYY');
          this.empReportedDate = new Date(this.displayResult.reportedDate);
          this.minQuarantineStartDate = new Date(this.displayResult.reportedDate);
          this.minReportedDate = moment(this.today).subtract(13, 'days');
          this.minReportedDate = new Date(this.minReportedDate);
          console.log(this.minReportedDate);
          if (this.displayResult.exposedDate === null) {
            this.minExposedDate = this.minReportedDate;
            this.maxExposedDate = this.empReportedDate;
          } else {
            this.maxExposedDate = this.empReportedDate;
            this.minExposedDate = this.minReportedDate;
          }
        }
        if (this.displayResult.quarantineStartDate === null) {
          this.fromQuarantineDate = '';
          if (this.displayResult.reportedDate === null) {
            this.minQuarantineStartDate = moment(this.today).subtract(13, 'days');
            this.minQuarantineStartDate = new Date(this.minQuarantineStartDate);
          }
        } else {
          this.fromQuarantineDate = moment(this.displayResult.quarantineStartDate).format('DD MMM YYYY');
          this.empQuarantineStartDate = new Date(this.displayResult.quarantineStartDate);
        }
        if (this.displayResult.quarantineEndDate === null) {
          this.toQuarantineDate = '';
        } else {
          this.toQuarantineDate = moment(this.displayResult.quarantineEndDate).format('DD MMM YYYY');
        }
        if (this.displayResult.exposedDate === null) {
          if (this.displayResult.reportedDate === null) {
            this.selectedExposedDate = '';
            let x: any = moment(this.today).subtract(13, 'days');
            this.minExposedDate = new Date(x);
            this.maxExposedDate = this.today;
          } else {
            this.maxExposedDate = new Date(this.displayResult.reportedDate);
            let x: any = moment(this.today).subtract(13, 'days');
            this.minExposedDate = new Date(x);
          }
        } else {
          this.selectedExposedDate = moment(this.displayResult.exposedDate).format('DD MMM YYYY');
          this.empExposedDate = new Date(this.selectedExposedDate);
          // this.minReportedDate = new Date(this.selectedExposedDate);
          // console.log(this.minReportedDate);
          let x: any = moment(this.today).subtract(13, 'days');
          let y: any = new Date(this.selectedExposedDate);
          let z = moment();
          let difference = z.diff(y, 'days');
          console.log(difference);
          if (difference > 14) {
            this.minReportedDate = moment(this.today).subtract(13, 'days');
            this.minReportedDate = new Date(this.minReportedDate);
          } else {
            this.minReportedDate = new Date(this.selectedExposedDate);
          }
          this.minExposedDate = new Date(x);
          this.maxExposedDate = new Date(this.selectedExposedDate);
          if (this.displayResult.reportedDate === null) {
            let x: any = moment(this.today).subtract(13, 'days');
            let y: any = new Date(this.selectedExposedDate);
            let z = moment();
            let difference = z.diff(y, 'days');
            console.log(difference);
            if (difference > 14) {
              this.minReportedDate = moment(this.today).subtract(13, 'days');
              this.minReportedDate = new Date(this.minReportedDate);
            } else {
              this.minReportedDate = new Date(this.selectedExposedDate);
            }
          } else {
            let x: any = moment(this.today).subtract(13, 'days');
            this.minExposedDate = new Date(x);
            this.maxExposedDate = new Date(this.selectedExposedDate);
          }
        }

        if (document.getElementById('toggleButton') != undefined || document.getElementById('toggleButton') != null) {
          document.getElementById('toggleButton')['checked'] = !this.displayResult.isAccessEnabled;
        }
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  onValueChangeReportDate(e: any) {
    if (this.selectedReportedDate != moment(e).format('DD MMM YYYY')) {
      this.disabledFlag = true;
    }
    this.selectedReportedDate = moment(e).format('DD MMM YYYY');
    this.reportError = false;
    this.quarantineError = false;
    this.exposedError = false;
    this.empReportedDate = e;
    this.minQuarantineStartDate = e;
    let x: any = moment(this.today).subtract(13, 'days');
    this.minExposedDate = new Date(x);
    this.maxExposedDate = e;
    this.fromQuarantineDate = moment().format('DD MMM YYYY');
    this.toQuarantineDate = moment(this.fromQuarantineDate).add(13, 'days').format('DD MMM YYYY');
    if (this.displayResult.reportedDate === null) {
      this.reportedDatePicker._bsValue = e;
    }
  }
  onValueChangeFromDate(e: any) {
    console.log(this.fromQuarantineDate, moment(e).format('DD MMM YYYY'));
    if (this.fromQuarantineDate != moment(e).format('DD MMM YYYY')) {
      this.disabledFlag = true;
    }
    this.empQuarantineStartDate = e;
    this.empQuarantineEndDate = moment(this.empQuarantineStartDate).add(13, 'days');
    this.empQuarantineEndDate = new Date(this.empQuarantineEndDate);
    this.quarantineError = false;
    this.fromQuarantineDate = moment(e).format('DD MMM YYYY');
    this.toQuarantineDate = moment(this.fromQuarantineDate).add(13, 'days').format('DD MMM YYYY');
    if (this.displayResult.fromDate === null) {
      this.fromdatePicker._bsValue = e;
    }
  }
  onValueChangeToDate(e: any) {
    this.toQuarantineDate = moment(e).format('DD MMM YYYY');
  }
  onValueChangeExposedDate(e: any) {
    console.log(this.selectedExposedDate, moment(e).format('DD MMM YYYY'));
    if (this.selectedExposedDate != moment(e).format('DD MMM YYYY')) {
      this.disabledFlag = true;
    }
    this.selectedExposedDate = moment(e).format('DD MMM YYYY');
    this.exposedError = false;
    this.minReportedDate = e;
    this.empExposedDate = e;
    if (this.displayResult.exposedDate === null) {
      this.exposedDatePicker._bsValue = e;
    }
  }
  reportedDateValue() {
    if (this.displayResult.reportedDate === null && this.empReportedDate === null) {
      this.reportedDatePicker._bsValue = new Date();
    } else if (this.empReportedDate !== null) {
      this.reportedDatePicker._bsValue = new Date(this.empReportedDate);
    } else {
      this.reportedDatePicker._bsValue = new Date(this.displayResult.reportedDate);
    }
  }

  exposedDateValue() {
    if (this.displayResult.exposedDate === null && this.empExposedDate === null) {
      this.exposedDatePicker._bsValue = new Date();
    } else if (this.empExposedDate !== null) {
      this.exposedDatePicker._bsValue = new Date(this.empExposedDate);
    } else {
      this.exposedDatePicker._bsValue = new Date(this.displayResult.exposedDate);
    }
  }
  quarantineDateValue() {
    if (this.displayResult.quarantineStartDate === null && this.empQuarantineStartDate === null) {
      this.fromdatePicker._bsValue = new Date();
    } else if (this.empQuarantineStartDate !== null) {
      this.fromdatePicker._bsValue = new Date(this.empQuarantineStartDate);
    } else {
      this.fromdatePicker._bsValue = new Date(this.displayResult.quarantineStartDate);
    }
  }

  remarksChange(e: any) {
    this.disabledFlag = true;
  }
}
