<div class="container-fluid">
  <div class="row">
    <div class="col-3 search-container" style="margin-top: 15px; padding-right: 0px;">
      <mat-card style="min-height: 98vh; max-height: 126vh; overflow: auto;">
        <div class="label-style"><span class="search-label">Search</span> Employee</div>
        <div class="row search-field" style="margin-left: 0px; margin-right: 0px;">
          <div class="col-12 dateSelection" style="border: 1px solid #5e5e72; border-radius: 8px;">
            <div class="input-group">
              <input
                type="text"
                class="form-control search-input"
                placeholder="Search by Name or ID"
                #searchString
                [(ngModel)]="searchedPerson"
                (keydown.enter)="enterMember($event.target.value)"
              />
              <div class="input-group-append" style="margin-top: 7px;">
                <!-- color: #2e9ccc; -->
                <mat-icon matSuffix style="opacity: 1; cursor: pointer;" (click)="searchMember(searchString.value)">
                  search</mat-icon
                >
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="showresult" class="search-res">{{ searchLength }} Results found</div>
        <div *ngIf="noResult && !showresult" class="search-res">0 Results found</div>
        <div *ngIf="showresult">
          <div *ngFor="let search of resultList; let i = index" class="short-res">
            <div
              class="row"
              style="padding-top: 10px; cursor: pointer;"
              (click)="showSelectedAssociate(search.employeeId, i)"
            >
              <div class="col-12">
                <div class="employee-selection" [class.selectedBar]="selectedNavBar == i">
                  <!-- <div class="img-fluid image-small"> -->
                  <!-- <img class="img-fluid image-small" src="assets/Mask Group 4.png" alt="User Image" /> -->

                  <span class="material-icons user-icon" style="font-size: 72px;">account_circle</span>
                  <!-- </div> -->
                  <div style="padding-left: 20px;">
                    <div class="name-style"><!-- {{ employee?.role }} -->{{ search.name }}</div>
                    <div class="id-style">
                      <div>
                        <!-- {{ employee?.email }} -->
                        {{ search.employeeId }}
                      </div>
                    </div>
                    <div class="designation-style">
                      <div>
                        <!-- {{ employee?.contact }} -->
                        {{ search.role }}
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <hr color="#2f3d4e" /> -->
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-9 employee" style="padding-left: 0px;">
      <div class="summary">
        <mat-card
          *ngIf="!showresult"
          class="d-flex justify-content-center align-items-center"
          style="min-height: 98vh;"
        >
          <div class="no-result" style="color: #5e5e72;">
            <div style="text-align: center;">
              <mat-icon>error_outline</mat-icon>
            </div>
            <p>Search and select employees to view details</p>
          </div>
        </mat-card>
        <mat-card *ngIf="showresult && displayResult" style="min-height: 98vh;">
          <div>
            <div class="flex">
              <div class="col-xl-7 flex">
                <!-- <div class="currentDate">{{ date }}</div> -->
              </div>
              <div class="col-xl-5">
                <!-- <div class="access-status">
                  <span>STATUS: Access Denied </span>
                </div> -->
              </div>
            </div>

            <div class="row details">
              <div class="col-xl-5">
                <div class="row">
                  <div class="col-12 employee-details">
                    <div class="col-5 col-image" style="padding-left: 0px !important;">
                      <div class="image">
                        <span class="material-icons user-icon">account_circle</span>
                      </div>
                    </div>
                    <div class="col-7 col-emp" style="padding-left: 0px !important;">
                      <div class="employee-contact">
                        <div
                          *ngIf="displayResult.personName.length > 25"
                          [matTooltip]="getName()"
                          [matTooltipPosition]="position"
                          class="name"
                        >
                          {{ selectedName }}<span style="white-space: nowrap;">({{ displayResult.personId }})</span>
                        </div>
                        <div *ngIf="displayResult.personName.length <= 25" class="name">
                          {{ displayResult.personName
                          }}<span style="white-space: nowrap;">({{ displayResult.personId }})</span>
                        </div>
                        <div class="desgination"><!-- {{ employee?.role }} -->{{ displayResult.designation }}</div>
                        <div class= "emp-contact" style="margin-top: 1em;">
                          <div class="mail" style="display: flex;">
                            <div>
                              <div class="box">
                                <img *ngIf="!isLightTheme" src="assets/Group 53.png" alt="email" />
                                <img *ngIf="isLightTheme" src="assets/Light-Theme/Group 53.png" alt="email" />
                              </div>
                            </div>
                            <div
                              *ngIf="employee.email.length > 15"
                              [matTooltip]="getEmail()"
                              [matTooltipPosition]="position"
                              class="emp-email"
                            >
                              {{ this.selectedEmail }}
                            </div>
                            <div *ngIf="employee.email.length <= 15" class="emp-email">
                              {{ employee?.email }}
                            </div>
                          </div>
                          <div class="phone" style="display: flex;">
                            <div>
                              <div class="box">
                                <img *ngIf="!isLightTheme" src="assets/Group 54.png" alt="phone" />
                                <img *ngIf="isLightTheme" src="assets/Light-Theme/Group 54.png" alt="phone" />
                              </div>
                            </div>
                            <div class="emp-phone">
                              {{ employee?.contact }}
                            </div>
                          </div>
                          <div class="location" style="display: flex;">
                            <div>
                              <div class="box">
                                <img *ngIf="!isLightTheme" src="assets/Group 55.png" alt="location" />
                                <img *ngIf="isLightTheme" src="assets/Light-Theme/Group 55.png" alt="location" />
                              </div>
                            </div>
                            <!-- <div>{{ displayResult.location === null ? 'SDB 3' : displayResult.location }}</div> -->
                            <div class="emp-location">{{ employee?.spaceName }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-xl-7 com-data">
                <div class="row justify-content-end f-row">
                  <div *ngIf="TS" class="col-xl-4 col-4-custom">
                    <div class="compliance">
                      <div class="complianceCount">{{ temperatureCurent }} °F</div>
                      <div class="complianceType">Current Temperature</div>
                      <div
                        class="complianceDiv"
                        [ngClass]="{
                          'compliant-border': temperatureCurentComplaint == true,
                          'non-compliant-border': temperatureCurentComplaint == false
                        }"
                      ></div>
                      <div class="complianceStatus compliant" *ngIf="temperatureCurentComplaint">
                        Compliant
                      </div>
                      <div class="complianceStatus non-compliant" *ngIf="!temperatureCurentComplaint">
                        Non Compliant
                      </div>
                    </div>
                  </div>
                  <div *ngIf="PD" class="col-xl-4 col-4-custom PD">
                    <div class="compliance">
                      <div class="complianceCount">
                        <span *ngIf="physicalAlert">{{ physicalAlert }}</span>
                        <span *ngIf="!physicalAlert"> 0 </span>
                        <!-- <span *ngIf="!physicalAlert"> 0 </span> -->
                        <!-- <span class="hour">Hrs Ago</span> -->
                      </div>
                      <div class="complianceType">Physical Distancing Alerts</div>
                      <div
                        class="complianceDiv"
                        [ngClass]="{
                          'compliant-border': physicalAlertComplianceFlag == true,
                          'non-compliant-border': physicalAlertComplianceFlag == false
                        }"
                      ></div>
                      <div class="complianceStatus compliant" *ngIf="physicalAlertComplianceFlag">
                        Compliant
                      </div>
                      <div class="complianceStatus non-compliant" *ngIf="!physicalAlertComplianceFlag">
                        Non Compliant
                      </div>
                    </div>
                  </div>
                  <div *ngIf="HH" class="col-xl-4 col-4-custom">
                    <div class="compliance">
                      <div class="complianceCount">
                        <span *ngIf="lastHandwash"> {{ lastHandwash }}</span>
                        <span *ngIf="!lastHandwash">0</span>
                        <span class="hour">Hrs Ago</span>
                      </div>
                      <div class="complianceType">
                        Last Handwash
                      </div>
                      <div
                        class="complianceDiv"
                        [ngClass]="{
                          'compliant-border': lastHandwashComplianceFlag == true,
                          'non-compliant-border': lastHandwashComplianceFlag == false
                        }"
                      ></div>
                      <div class="complianceStatus compliant" *ngIf="lastHandwashComplianceFlag">
                        Compliant
                      </div>
                      <div class="complianceStatus non-compliant" *ngIf="!lastHandwashComplianceFlag">
                        Non Compliant
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr color="#2f3d4e" />

            <div class="row report-row">
              <div class="col-xl-4 dateSelection">
                <div class="date">
                  <div class="label">Associate Reported On</div>
                  <div
                    class="display"
                    bsDatepicker
                    [minDate]="minReportedDate"
                    [maxDate]="today"
                    [bsConfig]="{ minMode: 'day', placement: 'top', showWeekNumbers: false, adaptivePosition: true }"
                    #reportDate="bsDatepicker"
                    (bsValueChange)="onValueChangeReportDate($event)"
                  >
                    <div (click)="reportedDateValue()" class="date-placeholder">
                      <div style="padding-top: 5px;">{{ selectedReportedDate }}</div>
                      <div><i class="material-icons primary-icon">date_range</i></div>
                    </div>
                  </div>
                  <div class="label error-msg" *ngIf="reportError">* Report Date must not be empty</div>
                </div>
              </div>
            </div>
            <div class="row status-row" style="padding-top: 30px;">
              <div class="col-xl-4 dateSelection">
                <div class="date">
                  <div class="label-style" style="margin-bottom: 8px !important;">Status</div>
                  <div class="display-search">
                    <!-- <mat-form-field appearance="outline" style="width: 100%;"> -->
                    <mat-select
                      style="font-size: 14px; text-transform: none;"
                      [(ngModel)]="displayResult.status"
                      (selectionChange)="selectStatus($event)"
                      #selectedStatus
                      value="{{ displayResult.status }}"
                      disableOptionCentering
                    >
                      <mat-option value="In Quarantine">
                        In Quarantine
                      </mat-option>
                      <mat-option value="Normal">Normal</mat-option>
                      <mat-option value="Exposed">Exposed</mat-option>
                    </mat-select>
                    <!-- </mat-form-field> -->
                  </div>
                </div>
              </div>
              <div
                *ngIf="displayResult.status !== 'Normal' && displayResult.status !== 'In Quarantine'"
                class="col-xl-4 dateSelection"
              >
                <div class="date">
                  <div class="label">Exposed On</div>
                  <div
                    class="display"
                    bsDatepicker
                    [minDate]="minExposedDate"
                    [maxDate]="maxExposedDate"
                    [bsConfig]="{ minMode: 'day', placement: 'top', showWeekNumbers: false, adaptivePosition: true }"
                    #fromExposeDate="bsDatepicker"
                    (bsValueChange)="onValueChangeExposedDate($event)"
                  >
                    <div class="date-placeholder" (click)="exposedDateValue()">
                      <div style="padding-top: 5px;">{{ selectedExposedDate }}</div>
                      <div>
                        <i class="material-icons primary-icon">date_range</i>
                      </div>
                    </div>
                  </div>
                  <div class="label error-msg" *ngIf="exposedError">* Exposed Date must not be empty</div>
                </div>
              </div>
              <div
                *ngIf="displayResult.status !== 'Normal' && displayResult.status !== 'Exposed'"
                class="col-xl-4 dateSelection"
              >
                <div class="date">
                  <div class="label">From</div>
                  <div
                    class="display"
                    bsDatepicker
                    [minDate]="minQuarantineStartDate"
                    [maxDate]="today"
                    [bsConfig]="{ minMode: 'day', placement: 'top', showWeekNumbers: false, adaptivePosition: true }"
                    #fromDate="bsDatepicker"
                    (bsValueChange)="onValueChangeFromDate($event)"
                  >
                    <div class="date-placeholder" (click)="quarantineDateValue()">
                      <div style="padding-top: 5px;">{{ fromQuarantineDate }}</div>
                      <div>
                        <i class="material-icons primary-icon">date_range</i>
                      </div>
                    </div>
                  </div>
                  <div class="label error-msg" *ngIf="quarantineError">* Quarantine Start Date must not be empty</div>
                </div>
              </div>
              <div
                *ngIf="displayResult.status !== 'Normal' && displayResult.status !== 'Exposed'"
                class="col-xl-4 dateSelection"
              >
                <div class="date">
                  <div class="label">Until</div>
                  <div class="display" style="cursor: auto; opacity: 40%;">
                    <div class="date-placeholder" style="opacity: 40%;">
                      {{ toQuarantineDate }}
                    </div>
                    <div>
                      <i class="material-icons primary-icon" style="opacity: 40%;">date_range</i>
                    </div>
                  </div>
                  <!-- <div class="label error-msg" *ngIf="quarantineError">* Quarantine End Date must not be empty</div> -->
                </div>
              </div>
            </div>
            <div class="row access-row" style="padding-top: 30px;">
              <div class="col-xl-4 custom-col">
                <div class="label">Access</div>
                <div class="switch6" style="opacity: 0.6;">
                  <label class="switch6-light">
                    <input id="toggleButton" type="checkbox" [disabled]="isStatusDisable" [checked]="checkedFlag" />
                    <span>
                      <span>ENABLE</span>
                      <span>DISABLE</span>
                    </span>
                    <a class="btn bluebg"></a>
                  </label>
                </div>
              </div>
              <div class="col-xl-4 custom-col notes">
                <div class="notes">Notes</div>
                <div
                  *ngIf="displayResult.remark === null || displayResult.remark === ''"
                  class="note-content"
                  style="max-height: 30vh; overflow: auto; word-break: break-all;"
                >
                  <div>{{ empRemarks }}</div>
                </div>
                <div
                  *ngIf="displayResult.remark !== null || displayResult.remark !== ''"
                  class="note-content"
                  style="max-height: 30vh; overflow: auto; word-break: break-all;"
                >
                  <div *ngFor="let tempRemark of tempRemarks">{{ tempRemark }}</div>
                </div>
              </div>
              <div class="col-xl-4"></div>
            </div>
            <div class="row remark-row" style="padding-top: 30px;">
              <div class="col-12 custom-col">
                <div class="label">Remarks</div>

                <textarea class="text" value="remarks" rows="5" maxlength="1500" [(ngModel)]="remarks" (ngModelChange)="remarksChange($event)"></textarea>
              </div>
            </div>
            <div style="display: flex; justify-content: flex-end; margin-top: 15px;">
              <div>
                <button type="button" class="btn cancel-button" (click)="showresult = false; searchString.value = ''">
                  CANCEL
                </button>
              </div>
              <div>
                <button
                  type="button"
                  class="btn btn-primary save-button"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                  [disabled] = "disabledFlag == false"
                  [ngClass]="disabledFlag == false ? 'disabled-Save' : ''"
                  (click)="onSuccess(selectedStatus.value, reportDate, fromDate, toDate, remarks)"
                >
                  SAVE
                </button>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
