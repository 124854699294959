<div class="container-fluid thermal-screen">
    <div class="row video-alerts-panel">
      <div class="col-xl-12 grey-panel">
        <div class="alerts">
          <div class="alerts-group">
            <div class="alert-header">
              Thermal Screening
            </div>
    
            <div *ngIf="!noData" class="alert-section" [ngClass]="{ greenborder: isComplaint, redborder: !isComplaint }">
              <div class="alert-details">
                <div class="name">
                  <div class="name-label">Name &nbsp;&nbsp;</div>
                  <div class="name_text" *ngIf="latestScannedPerson" [matTooltip]="getScannedTooltip()"
                    [matTooltipPosition]="position" [ngClass]="{ greenText: isComplaint, redText: !isComplaint }">
                    {{
                      !!latestScannedPerson && !!latestScannedPerson.name
                        ? latestScannedPerson.name.length < 9
                          ? latestScannedPerson.name
                          : latestScannedPerson.name.slice(0, 8).concat('...')
                        : ''
                    }}
                  </div>
                </div>
                <div class="name-div"></div>
                <div class="name">
                  <div class="name-label">Temperature &nbsp;&nbsp;</div>
                  <div class="name_text" [ngClass]="{ greenText: isComplaint, redText: !isComplaint }">
                    {{ latestScannedPerson?.temperature }}&#176;F
                  </div>
                </div>
                <div *ngIf="!isLightTheme" class="complaint-img">
                  <img *ngIf="isComplaint" src="assets/Group 369.png" alt="" />
                  <img *ngIf="!isComplaint" src="assets/red.png" alt="" />
                </div>
                <div *ngIf="isLightTheme" class="complaint-img">
                  <img *ngIf="isComplaint" src="assets/Light-Theme/Group 369.png" alt="" />
                  <img *ngIf="!isComplaint" src="assets/Light-Theme/red.png" alt="" />
                </div>
              </div>
            </div>
          </div>
  
          <div class="alert-location">
  
            <mat-accordion>
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <img *ngIf="isLightTheme" class="location_img" src="assets/Light-Theme/Group 55.png" />
                    <img *ngIf="!isLightTheme" class="location_img" src="assets/maps-and-flags.png" />
                    <span class="loc_details" [matTooltip]="getFacilityTooltip()" [matTooltipPosition]="position">
                      {{ selectedFacility }}
                    </span> | <span class="loc_details" [matTooltip]="getBuildingTooltip()"
                      [matTooltipPosition]="position">
                      {{selectedBuilding}}</span> |
                    <span class="loc_details" [matTooltip]="getFloorTooltip()"
                      [matTooltipPosition]="position">{{selectedLocation}}</span>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div>
                  <form [formGroup]="securityLocation">
                    <div class="border-top" *ngIf="facilities">
                      <mat-label class="label">Facility</mat-label>
                      <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-select (selectionChange)="thermalfacilitySelection($event)" formControlName="facility">
                          <mat-option [value]="f.spaceId" *ngFor="let f of facilities">{{ f.faciityName }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-label class="label">Building</mat-label>
                      <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-select (selectionChange)="thermalbuildingSelection($event)" formControlName="building">
                          <mat-option [value]="f.spaceId" *ngFor="let f of buildings">{{ f.buildingName }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div>
                      <mat-label class="label">Location</mat-label>
                      <mat-form-field appearance="outline" style="width: 100%;">
                        <mat-select (selectionChange)="thermalfloorSelection($event)" formControlName="floor">
                          <mat-option [value]="f.spaceId" *ngFor="let f of floors">{{ f.floorNumber }}</mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </form>
                </div>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
  
        <!-- <div [ngClass]="{
            'video-panel': alertScreen,
            'normal-panal': !alertScreen && empTemperatur,
            'grey-panel': !empTemperatur
          }">
          <div class="row screeing-header">
            <div class="col-md-4 header-color">THERMAL SCREENING</div>
            <div class="col-md-4">
              <div *ngIf="alertScreen === true && !!empTemperatur" class="warning-color">
                <span style="font-family: TTNorms, Bold;">{{
                  empTemperatur + "F"
                }}</span><span><img class="alert-icon" src="assets/Alert.svg" width="40px" height="40px" /></span>
              </div>
              <div *ngIf="alertScreen === false && !!empTemperatur" class="warning-color">
                <span style="color: #5cc272; font-family: TTNorms, Bold;">{{ empTemperatur + "F" }} </span><span><img
                    class="alert-icon" src="assets/Success.svg" width="40px" height="40px" /></span>
              </div>
            </div>
            <div class="col-md-4 alert-color">
              <span><img style="padding-right: 2px; font-family: TTNorms, Medium;"
                  src="assets/maps-and-flags.svg" /></span>
              CDC - BUIDING 2 - Entry GATE-1
            </div>
          </div>
        </div> -->
      </div>
    </div>
    <div class="row pad-row" style="padding-top: 10px; padding-right: 5px;">
      <div class="col-9 custom-col">
        <mat-card>
          <div class="peoplescan-div">
            <div class="people-scanning" style="font-family: TTNorms, Medium; font-size: 24px;">
              PEOPLE SCANNING DETAILS
            </div>
            <div style="display: flex; align-items: center;">
              <div style="margin-right: 20px; display: flex; align-items: center;">
                <div class="compliant"></div>
                <div class="complaint-text">
                  Compliant
                </div>
              </div>
              <div style="margin-right: 4px; display: flex; align-items: center;">
                <div class="non-compliant"></div>
                <div class="complaint-text">
                  Non Compliant
                </div>
              </div>
            </div>
          </div>
  
          <div class="no-result" *ngIf="noData">
            <div style="text-align: center;">
              <mat-icon>error_outline</mat-icon>
            </div>
            <p>Waiting for the thermal scan data</p>
          </div>
          
          <div class="table-container">
          <table mat-table [dataSource]="dataSource" class="temperaturetable" *ngIf="!noData">
  
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef>Name</th>
  
              <td mat-cell class="name" style="font-family: TTNorms, Medium !important; font-size: 18px !important;"
                *matCellDef="let element">
                <div class="mob-status">
                <div *ngIf="!element.isMaskDetected  || !element.status" class="non-compliant">
                </div>
                <div *ngIf="element.isMaskDetected && element.status" class="compliant"></div>
                </div>
                {{ element.name }}<span class="star" *ngIf="element.config">*</span>
              </td>
            </ng-container>
  
            <ng-container matColumnDef="employeeId">
              <th mat-header-cell *matHeaderCellDef>Employee ID</th>
              <td mat-cell *matCellDef="let element">{{ element.employeeId == -1 ? '-' : element.employeeId }}</td>
            </ng-container>
  
  
            <!-- <ng-container matColumnDef="isR2oPermission">
              <th mat-header-cell *matHeaderCellDef>RTO Status</th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.isR2oPermission == 'true'">YES</div>
                <div *ngIf="element.isR2oPermission == 'false'">NO</div>
              </td>
            </ng-container> -->
  
            <ng-container matColumnDef="temperature">
              <th mat-header-cell *matHeaderCellDef>Temperature</th>
              <td mat-cell *matCellDef="let element">
                {{ element.temperature + '  &#176;F' }}
                <span *ngIf="element.config">
                  <span class="arrow">
                    <img src="assets/Group 251.png" alt="" />
                  </span>
                  {{ element.newTemp + '  &#176;F' }}
                  <span class="doc" *ngIf="!element.isNormal">
                    <img src="assets/Group 250.png" alt="" />
                  </span>
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="faceMask">
              <th mat-header-cell *matHeaderCellDef>Face Mask</th>
              <td mat-cell *matCellDef="let element">
                <div *ngIf="element.isMaskDetected == true">YES</div>
                <div *ngIf="element.isMaskDetected == false">NO</div>
              </td>
            </ng-container>
            <ng-container matColumnDef="time">
              <th mat-header-cell *matHeaderCellDef>Timestamps</th>
  
              <td mat-cell *matCellDef="let element">
                {{ element.time | date: 'dd MMM' }}, {{ element.time | date: 'hh:mm a' | lowercase }}
              </td>
            </ng-container>
            <ng-container matColumnDef="status" >
              <th mat-header-cell *matHeaderCellDef class="no-status">Status</th>
              <td mat-cell *matCellDef="let element" class="no-status">
                <div *ngIf="!element.isMaskDetected  || !element.status" class="non-compliant">
                </div>
                <div *ngIf="element.isMaskDetected && element.status" class="compliant"></div>
              </td>
            </ng-container>
  
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
          <div *ngIf="isTableEmpty" style="padding-top: 20px; text-align: center;">
            Table has no records to display
          </div>
        </mat-card>
        <mat-paginator class="page" style="background-color: #24233f;" [pageSizeOptions]="[5, 10, 25, 100]"
          [length]="resultLength" [pageSize]="10"></mat-paginator>
          <div *ngIf="loader" class="loading-spinner-div">
            <img src="assets/loading_bar.svg" class="loading-spinner" />
          </div>
      </div>
  
      <div class="overflow-auto col-xl-3" style="border-radius: 10px; max-width: 400px;">
        <div class="alerts-panel" id="scrollstyle">
          <div class="row" style="margin-top: 6px; margin-bottom: 6px; padding-left: 5%;">
            <div class="col">
              <span class="alert-panent-complaint">NON COMPLIANCE </span>
              <span class="badge badge-danger badge-pill">{{ alertsData.length }}</span>
            </div>
          </div>
          <div class="note">
            Facility access disabled
          </div>
  
          <div class="row row-styles" *ngFor="let i = index; let alert; of: alertsData">
            <div class="col-md-4">
              <!-- {{alert | json}} -->
              <div class="image-user" [ngClass]="{ light: isLightTheme }">
                <img *ngIf="alert.employeeId == 13502" src="assets/Image 5.png" class="img-rounded" alt="User" />
                <img *ngIf="alert.employeeId == 13508" src="assets/Image 6.png" class="img-rounded" alt="User" />
                <img *ngIf="alert.employeeId != 13508 && alert.employeeId != 13502" src="assets/user.svg"
                  class="img-rounded svg" alt="User" />
              </div>
            </div>
            <div class="col-md-8 alert-detail">
              <div class="row no-gutters">
                <div *ngIf="alert.name" class="col-md-5" style="font-size: 11px; font-family: TTNorms, Bold;">
                  {{ alert.name.length > 9 ? alert.name.slice(0, 8).concat('...') : alert.name }}
                </div>
                <div class="col-md-6" style="font-size: 12px; font-family: TTNorms, Regular;">
                  Temperature
                </div>
              </div>
              <div class="row no-gutters">
                <div *ngIf="alert.employeeId" class="col-md-5" style="font-size: 14px; font-family: TTNorms, Regular;">
                  {{ alert.employeeId == -1 ? '-' : alert.employeeId }}
                </div>
                <div *ngIf="alert.temperature" class="col-md-6" style="font-size: 14px; font-family: TTNorms, Medium;">
                  {{ alert.temperature + '  &#176;F' }}
                </div>
              </div>
              <div class="row no-gutters" style="margin-top: 8px;">
                <div class="col-md-6" style="font-size: 12px; font-family: TTNorms, Regular;">
                  Entry Time
                </div>
                <div class="col-md-5" style="font-size: 12px; font-family: TTNorms, Regular;">
                  Entry point
                </div>
              </div>
              <div class="row no-gutters">
                <div class="col-md-6" style="font-size: 14px; font-family: TTNorms, Regular;">
                  {{ alert.time | date: 'hh:mm a' | lowercase }}
                </div>
                <div class="col-md-5" style="font-size: 14px; font-family: TTNorms, Regular;">
                  {{ alert.spaceName }}
                </div>
              </div>
              <div class="row pd-10 no-gutters">
                <div class="col-md-12">
                  <button type="button" class="btn btn-admint" (click)="admitEmployee(i, alert.employeeId)">
                    ADMIT
                  </button>
                </div>
              </div>
            </div>
            <hr style="background-color: #2f3d4e; margin-right: 26px; width: 280px !important;" />
          </div>
        </div>
      </div>
    </div>
  </div>