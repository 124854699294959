<div style="display: flex; justify-content: space-between; border-bottom: 1px solid #e6e6e6;">
  <div mat-dialog-title class="success" style="margin-bottom: 10px !important;">SUCCESS</div>
  <!-- <div class="close">X</div> -->
  <mat-icon class="close" [mat-dialog-close]="" cdkFocusInitial>add_circle</mat-icon>
</div>

<div mat-dialog-content style="margin-top: 10px;">
  <p>• Associate information has been successfully updated.</p>
  <!-- <p>
    • Mail has been sent to notify Facility Manager, Exposed Associates & Reported Associate along with guidelines to
    follow.
  </p> -->
</div>
<div mat-dialog-actions style="justify-content: center;">
  <!-- <button mat-button [mat-dialog-close]="" cdkFocusInitial>Ok</button> -->
  <button type="button" class="btn btn-primary save-button" [mat-dialog-close]="" cdkFocusInitial>
    OK
  </button>
</div>
