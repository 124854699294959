<mat-card class="add-asset">
    <div class="add-asset-top-section">
        <div class="asset-title">Add New Asset</div>
    </div>
    <div class="add-asset-accordion">
        <mat-accordion>
            <mat-expansion-panel hideToggle class="add-asset-expansion" #expansion
                [ngClass]=" expansion.expanded ? 'expandedPanel' : 'notExpandedPanel' " [expanded]="step === 0"
                (opened)="setStep(0)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="asset-panel-title">
                            <div *ngIf="!expansion.expanded"
                                style="transform: rotate(180deg); display: inline-flex;cursor: pointer;">
                                <img src="/assets/images/Group 1399.svg" />
                            </div>
                            <div *ngIf="expansion.expanded" style="display: inline-flex;cursor: pointer">
                                <img src="/assets/images/Group 1399.svg" />
                            </div>
                            <div class="section-title">System Details </div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-desc">
                    <form [formGroup]="systemForm">
                        <div class="row">
                            <div class="col">
                                <mat-label>System <span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <mat-select formControlName="system" panelClass="add-asset-panel">
                                        <mat-option *ngFor="let system of systemList" [value]="system">
                                            {{ system }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-label>Asset Type <span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <mat-select formControlName="system" panelClass="add-asset-panel">
                                        <mat-option *ngFor="let asset of assetList" [value]="asset">
                                            {{ asset }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">

                            </div>
                        </div>
                        <div class="next-button">
                            <button (click)="nextStep()">Next</button>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle class="add-asset-expansion" #assetexpansion
                [ngClass]=" assetexpansion.expanded ? 'expandedPanel' : 'notExpandedPanel' " [expanded]="step === 1"
                (opened)="setStep(1)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="asset-panel-title">
                            <div *ngIf="!assetexpansion.expanded"
                                style="transform: rotate(180deg); display: inline-flex;cursor: pointer;">
                                <img src="/assets/images/Group 1399.svg" />
                            </div>
                            <div *ngIf="assetexpansion.expanded" style="display: inline-flex;cursor: pointer">
                                <img src="/assets/images/Group 1399.svg" />
                            </div>
                            <div class="section-title">Asset Details </div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-desc">
                    <form [formGroup]="assetForm">
                        <div class="row">
                            <div class="col">
                                <mat-label>Asset Name<span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <input matInput type="text" formControlName="assetName" />
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-label>Manufacturer Name<span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <input matInput type="text" formControlName="manufacterName" />
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-label>Serial Number<span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <input matInput type="text" formControlName="serialNo" />
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-label>Purchase Cost<span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <input matInput type="text" formControlName="purchaseCost" />
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-label>Purchase Date<span class="imp-note">*</span></mat-label>
                                <div>
                                    <div class="display" bsDatepicker
                                        [bsConfig]="{ minMode: 'date', placement: 'top',showWeekNumbers: false, adaptivePosition: true }"
                                        #purchase="bsDatepicker" (bsValueChange)="onPurchaseDate($event)">
                                        <div class="date-placeholder">
                                            <div style="padding-top: 5px;">{{ selectedPurchaseDate }}</div>
                                            <div><i class="material-icons primary-icon">date_range</i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <mat-label>Warranty End Date<span class="imp-note">*</span></mat-label>
                                <div>
                                    <div class="display" bsDatepicker
                                        [bsConfig]="{ minMode: 'date', placement: 'top',showWeekNumbers: false, adaptivePosition: true }"
                                        #warranty="bsDatepicker" (bsValueChange)="onWarrantyEndDate($event)">
                                        <div class="date-placeholder">
                                            <div style="padding-top: 5px;">{{ selectedWarrantyEndDate }}</div>
                                            <div><i class="material-icons primary-icon">date_range</i></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div formArrayName="additionalFieldDetails">
                            <div *ngFor="let fields of additionalFieldDetails.controls; let i=index">
                                <div class="additional-field" [formGroupName]="i">
                                    <div class="additional-col">
                                        <mat-label>Key</mat-label>
                                        <mat-form-field appearance="outline" class="add-asset-field">
                                            <input matInput type="text" formControlName="key" />
                                        </mat-form-field>
                                    </div>
                                    <div class="additional-col">
                                        <mat-label>Value</mat-label>
                                        <mat-form-field appearance="outline" class="add-asset-field">
                                            <input matInput type="text" formControlName="value" />
                                        </mat-form-field>
                                    </div>
                                    <i class="material-icons cancel-icon" (click)="onCancel(i)">cancel</i>

                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <div class="col">
                                <button mat-icon-button (click)="addNewField();">
                                    <img src="assets/Add.svg" alt="add-icon" />
                                    <span class="add-new-field">Add Additional Field</span>
                                </button>
                            </div>
                        </div>
                        <div class="next-button">
                            <button (click)="nextStep()">Next</button>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle class="add-asset-expansion" #spaceexpansion
                [ngClass]="spaceexpansion.expanded ? 'expandedPanel' : 'notExpandedPanel' " [expanded]="step === 2"
                (opened)="setStep(2)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="asset-panel-title">
                            <div *ngIf="!spaceexpansion.expanded"
                                style="transform: rotate(180deg); display: inline-flex;cursor: pointer;">
                                <img src="/assets/images/Group 1399.svg" />
                            </div>
                            <div *ngIf="spaceexpansion.expanded" style="display: inline-flex;cursor: pointer">
                                <img src="/assets/images/Group 1399.svg" />
                            </div>
                            <div class="section-title">Space Mapping</div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-desc">
                    <form [formGroup]="spaceForm">
                        <div class="row">
                            <div class="col">
                                <mat-label>Region <span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <mat-select formControlName="region" panelClass="add-asset-panel">
                                        <mat-option *ngFor="let region of regionList" [value]="region">
                                            {{ region }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-label>Country<span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <mat-select formControlName="country" panelClass="add-asset-panel">
                                        <mat-option *ngFor="let country of countryList" [value]="country">
                                            {{ country }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-label>Facility<span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <mat-select formControlName="facility" panelClass="add-asset-panel">
                                        <mat-option *ngFor="let facility of facilityList" [value]="facility">
                                            {{ facility }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col">
                                <mat-label>Building <span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <mat-select formControlName="building" panelClass="add-asset-panel">
                                        <mat-option *ngFor="let building of buildingList" [value]="building">
                                            {{ building }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-label>Floor<span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <mat-select formControlName="floor" panelClass="add-asset-panel">
                                        <mat-option *ngFor="let floor of floorList" [value]="floor">
                                            {{ floor }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col">
                                <mat-label>Zone<span class="imp-note">*</span></mat-label>
                                <mat-form-field appearance="outline" class="add-asset-field">
                                    <mat-select formControlName="zone" panelClass="add-asset-panel">
                                        <mat-option *ngFor="let zone of zoneList" [value]="zone">
                                            {{ zone }}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="next-button">
                            <button (click)="nextStep()">Next</button>
                        </div>
                    </form>
                </div>
            </mat-expansion-panel>
            <mat-expansion-panel hideToggle class="add-asset-expansion" #operationalexpansion
                [ngClass]="operationalexpansion.expanded ? 'expandedPanel' : 'notExpandedPanel' "
                [expanded]="step === 3" (opened)="setStep(3)">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        <div class="asset-panel-title">
                            <div *ngIf="!operationalexpansion.expanded"
                                style="transform: rotate(180deg); display: inline-flex;cursor: pointer;">
                                <img src="/assets/images/Group 1399.svg" />
                            </div>
                            <div *ngIf="operationalexpansion.expanded" style="display: inline-flex;cursor: pointer">
                                <img src="/assets/images/Group 1399.svg" />
                            </div>
                            <div class="section-title">Operational Datapoints </div>
                        </div>
                    </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="panel-desc">
                </div>
            </mat-expansion-panel>
        </mat-accordion>
    </div>
    <div class="asset-button-section">
        <button class="button-style back-button" (click)="onBack()">Back</button>
        <button class="button-style submit-button">Submit</button>
    </div>
</mat-card>