<div class="root">
  <div class="title">
    <div>Delete survey</div>
    <div>
      <img *ngIf="!isLightTheme" (click)="cancel('close')" src="assets/Group 99.png" alt="" />
      <img *ngIf="isLightTheme" (click)="cancel('close')" src="assets/Light-Theme/Group 99.png" alt="" />
    </div>
  </div>
  <hr class="border-line" />
  <div class="message">
    Selected survey <b>{{ data.message }}</b> will be deleted.Are you sure you want to delete?
  </div>
  <div class="btn">
    <button mat-button class="btn-reset" color="primary" (click)="cancel('close')">CANCEL</button>
    <button mat-button class="btn-save" color="primary" [mat-dialog-close]="data">Delete</button>
  </div>
</div>