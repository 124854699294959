import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable()
export class EmployeeService {
  baseUrl = environment.TS_baseURL + 'ebtd/';
  hygieneUrl = environment.HH_baseURL + 'HygieneCompliance';
  physicalUrl = environment.PD_baseURL + 'PhysicalDistanceCompliance';
  employeeUrl = environment.TS_baseURL + 'Employee/';

  //https://onefacility-covid-eus-ingress.eastus.cloudapp.azure.com/Employee/?EmployeeId=13501

  constructor(private http: HttpClient) {}

  getEmployeeDetailsById(id: number) {
    return this.http.get(this.employeeUrl + `?EmployeeId=${id}`);
  }

  getEmployeeTemperatureById(id: number) {
    return this.http.get(this.baseUrl + 'temperature-data/latest/employee/' + id);
  }

  getEmployeeTemperatureTrendById(id: number) {
    return this.http.get(this.baseUrl + 'temperature-data/employee/' + id);
  }

  getHygieneComplianceHistoryById(
    id: number,
    startTime: string,
    endTime: string,
    timeZone: string,
    offset: number,
    limit: number
  ) {
    return this.http.get(
      this.hygieneUrl +
        `/?PersonId=${id}&SpaceType=Zone&StartTime=${startTime}&EndTime=${endTime}&SortBy=TimeStamp&OrderBy=DESC&Offset=${offset}&Limit=${limit}&timeZone=${timeZone}`
    );
  }

  getHygieneComplianceDurationById(id: number, timeZone: string) {
    return this.http.get(this.hygieneUrl + `/LATEST?PersonId=${id}&timeZone=${timeZone}`);
  }

  getPhysicalComplianceHistoryById(id: number, startTime: string, endTime: string, limit: number, timeZone: string) {
    return this.http.get(
      this.physicalUrl +
        `/?PersonId=${id}&SpaceType=Zone&StartTime=${startTime}&EndTime=${endTime}&SortBy=TimeStamp&OrderBy=DESC&Offset=0&Limit=${limit}&timeZone=${timeZone}`
    );
  }

  getPhysicalComplianceDurationById(id: number, timeZone: string) {
    return this.http.get(this.physicalUrl + `/LATEST?PersonId=${id}&timeZone=${timeZone}`);
  }

  getThresholdTempBySpaceId(id: any) {
    return this.http.get(
      environment.TS_baseURL + `/FacilitySettingConfig/SettingsTypeId?spaceId=${localStorage.getItem('safebuildingSpaceId')}&settingsTypeId=2`
    );
  }

  getHealthQuestionnaire(empId: any, timeZone: string) {
    return this.http.get(environment.HQ_baseURL + `EmployeeHealthSurvey/?employeeId=${empId}&timeZone=${timeZone}`);
  }

  getEmployeeContactTracin(empId: any, timeZone: string) {
    // empId = 13594;
    // 13594 , 13533,  13534, 13537, 13554, 13574, 13634  - 13543
    return this.http.get(environment.TS_baseURL + `ContactTracing/?PersonId=${empId}&timeZone=${timeZone}`);
  }
  /* getHealthQuestionnaire(empId: any) {
    return this.http.get(environment.TS_baseURL + `EmployeeHealthSurvey/?employeeId=${empId}`);
  }*/
  saveHealthQuestionnaireData(json: any) {
    return this.http.post(environment.HQ_baseURL + `EmployeeHealthSurvey/`, json);
  }
}
