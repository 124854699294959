import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class RoleAuthGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    let roles = route.data['roles'] as Array<string>;
    console.log(roles);
    console.log(state.url);
    localStorage.setItem('returnUrl', state.url);
    if (localStorage.getItem('azureToken')) {
      //  this.router.navigate(['home'], { queryParams: { returnUrl: state.url } });
      // if (roles.indexOf(localStorage.getItem('defaultRole')) !== -1) {
      //   return true;
      // } else {
      //   return false;
      // }
      return true;
    } else {
      return false;
    }
  }
}
