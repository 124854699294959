import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { SecurityTabComponent } from './security-tab/security-tab.component';

const routes: Routes = [{ path: 'security', component: SecurityTabComponent }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class SecurityRoutingModule { }
