import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-screen',
  templateUrl: './home-screen.component.html',
  styleUrls: ['./home-screen.component.scss']
})
export class HomeScreenComponent implements OnInit {
  isViewable:boolean = true;

  constructor() { }

  ngOnInit(): void {
    this.isViewable = true
  }

  // Method trigered on click of add new asset 
  onAddNewAsset() {
    this.isViewable = false;
  }

  // Method called on click of back on add new assset
  onAssetList() {
    this.isViewable = true;
  }

}
