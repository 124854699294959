import { Component, OnInit, ViewChild, ElementRef, Inject, HostListener, AfterContentChecked, AfterContentInit, AfterViewChecked } from '@angular/core';
import { globalConstant } from '../../safebuilding-global-constants';
import { environment } from '../../../../environments/environment';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { QuarantineService } from '../quarantine.service';
import { PanZoomAPI, PanZoomConfig, PanZoomModel } from 'ngx-panzoom';
declare var google: any;
import { AppService } from '../../../app.service';
import { ViewportScroller} from '@angular/common';
import { SwitchToLandscapePopupComponent } from '@app/safe-building/switch-to-landscape-popup/switch-to-landscape-popup.component';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-quarantine-tracker',
  templateUrl: './quarantine-tracker.component.html',
  styleUrls: ['./quarantine-tracker.component.scss'],
})
export class QuarantineTrackerComponent implements OnInit {
  @ViewChild('orgChart') public orgChart: ElementRef;
  //chart: any;
  responseData: any;
  selectedIdData: any;
  quarantineData: any;
  exposedData: any;
  detailsDisplay: boolean = false;
  activeTab: any = 'REPORTED';
  tracedData: any;
  contactTraceCount = 0;
  nodeList: any[][];
  firstChildChildren: any[] = [];
  selectedId: any;

  sampleArray: any;
  responseDataRes: any;
  level2Data: any;
  level1Data: any;
  selectedIndex1: any = 0;
  selectedNavBar: any = 0;
  selectedTab: any = 0;
  totalData: any;
  selectedEmail: any;
  selectedName: string;
  timeZone: string = globalConstant.timeZone;
  isLightTheme: boolean = environment.isLightTheme;
  pageExpand: boolean = false;
  zoomMiddlePosition: number = 400;
  public panzoomConfig: PanZoomConfig = new PanZoomConfig();
  private panZoomAPI: PanZoomAPI;
  private modelChangedSubscription: Subscription;
  private apiSubscription: Subscription;
  collapseLength: any;
  rowNumber: any;
  searchedPerson: any;
  showSearch: boolean = false;
  noResult: boolean = false;
  counter: number = 0;
  childIndexes: any;
  selectedParentId: any;
  expandedNodesList: any[];
  collapseCounter: number = 1;

  selectedDuration: any;
  durationSelected: any;
  durationList: any = [
    { view: 'ALL', value: '0' },
    { view: '> 1 min', value: '1' },
    { view: '> 2 min', value: '2' },
    { view: '> 3 min', value: '3' },
  ];
  personalDetailById: any;
  startDate: string;
  endDate: string;
  lastNDays: string;
  date: string;
  quarantineDay: any;

  showarrow: boolean;

  listPersonalDetailById: any;
  listTracedData:any;
  listSelectedEmail : any;
  listSelectedName : any;
  isMobileView:boolean;
  display : boolean;
  displaycard : boolean = true;
  level1 : boolean;
  level2 : boolean;
  windowScrolled: boolean;
  lvl1data: boolean = false;
  expand: boolean;
  switchToLandscapeDialog: any;
  screenMode: string = '';
  isLandscape: boolean;
  countt:any = 1;


  constructor(
    private service: QuarantineService,
    private appservice: AppService,
    public dialog: MatDialog,
    private viewportScroller: ViewportScroller
  ) {}

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: any) {
    if (window.screen.orientation.type.includes('landscape')) {
      this.screenMode = 'landscape';
      this.isLandscape = true;
     // this.changeChartType(this.chartType);
    // document.getElementsByTagName('html')[0].classList.remove('landscape-style');
     if (this.switchToLandscapeDialog) {
       this.switchToLandscapeDialog.close();
     }
      console.log('Landscape oriented ' + this.isLandscape);
    } else {
      this.screenMode = 'portrait';
      this.isLandscape = false;
      console.log('Portrait oriented ' + this.isLandscape);
    }
  }

  ngOnInit(): void {
    //console.log("ngoninit called ");
    this.isMobileView = this.appservice.isMobileDevice();
    this.durationSelected = '0';
    this.selectedDuration = 0;
    this.appservice.currentTheme.subscribe(
      (theme) => (this.isLightTheme = theme == 'theme-light' ? true : false)
    );
    this.getExposedData(this.timeZone);
    this.getQuarantineData(this.timeZone);
    this.apiSubscription = this.panzoomConfig.api.subscribe(
      (api: PanZoomAPI) => (this.panZoomAPI = api)
    );
    this.panzoomConfig.zoomOnDoubleClick = true;
    this.panzoomConfig.zoomOnMouseWheel = false;
    //this.panzoomConfig.zoomButtonIncrement = 1;
    this.panzoomConfig.freeMouseWheel = false;
    this.panzoomConfig.freeMouseWheelFactor = 0.5;
    //this.panzoomConfig.initialPanX = -1000;
    this.panzoomConfig.invertMouseWheel = true;
    //this.panzoomConfig.neutralZoomLevel = 1;
    //this.panZoomAPI.zoomToFit({ "x": 0, "y": 100, "width": 100, "height": 100 })
    // this.activeTabVal('All');
    this.startDate = moment().subtract(13, 'days').format('YYYY-MM-DD');
    this.lastNDays = moment().subtract(13, 'days').format('D MMM YYYY');
    this.endDate = moment().format('YYYY-MM-DD');
    this.date = moment().format('D MMM YYYY');
    this.service.getFacilitySettings().subscribe((i: any) => {
      console.log(i, i.settingItems);
      i.settingItems.forEach((j: any) => {
        if (j.settingsKeysValues == 'PDContactTracePastDays') {
          this.quarantineDay = j.minValue;
          this.startDate = moment()
            .subtract(this.quarantineDay - 1, 'days')
            .format('YYYY-MM-DD');
          this.lastNDays = moment()
            .subtract(this.quarantineDay - 1, 'days')
            .format('D MMM YYYY');
          this.endDate = moment().format('YYYY-MM-DD');
          this.date = moment().format('D MMM YYYY');
        }
      });
    });
  }
  getQuarantineData(timeZone: any) {
    this.service
      .getQuarantineData('InQuarantine', this.selectedDuration, this.timeZone)
      .subscribe(
        (response) => {
          // console.log('response', response);
          this.quarantineData = response['contactTraces'];
          this.activeTabVal(this.activeTab);
        },
        (error) => {
          //console.log('error', error.statusText);
          // this._toastr.error('File contents mismatch', error.statusText);
        }
      );
  }
  getExposedData(timeZone: any) {
    console.log('time '+ timeZone)
    this.service
      .getQuarantineData('Exposed', this.selectedDuration, this.timeZone)
      .subscribe(
        (response) => {
          this.exposedData = response['contactTraces'];
          this.activeTabVal(this.activeTab);
          // this.responseData = this.exposedData;

          //this.showSelectedAssociate(0);
        },
        (error) => {
          //console.log('error', error.statusText);
          // this._toastr.error('File contents mismatch', error.statusText);
        }
      );
  }
  ngAfterViewInit(): void {
    google.charts.load('current', { packages: ['orgchart'] });
    // this.chart = new google.visualization.OrgChart(this.orgChart.nativeElement);
  }

  drawChart = function () {

    if(this.isMobileView && this.countt == 1){
      this.countt++;
      console.log('graph tab is clicked')
      if (window.screen.orientation.type.includes('landscape')) {
        this.screenMode = 'landscape';
        this.isLandscape = true;
        //  this.changeChartType(this.chartType);
        document.getElementsByTagName('html')[0].classList.remove('landscape-style');
        if (this.switchToLandscapeDialog) {
          this.switchToLandscapeDialog.close();
          this.dialog.closeAll();
        }
        console.log('Landscape oriented ' + this.isLandscape);
      } else {
        this.screenMode = 'portrait';
        this.isLandscape = false;
        this.switchToLandscapeDialog = this.dialog.open(SwitchToLandscapePopupComponent, {
          panelClass: 'switchLandscapePanel',
          width: '100%',
          maxWidth: '80vw',
        });
        console.log('Portrait oriented ' + this.isLandscape);
      }
    }
    const data: any = new google.visualization.DataTable();
    data.addColumn('string', 'Name');
    data.addColumn('string', 'Manager');
    // data.addColumn('string', 'ToolTip');
    // For each orgchart box, provide the name, manager, and tooltip to show.

    // let parentId = this.nodeList[0][0].v;
    console.log(this.nodeList.length);
    this.nodeList.forEach((e: any) => {
      if (e[1] == null) {
        this.parentId1 = e[0].v;
      }
    });
    console.log(this.parentId1);
    let firstLevelFilter = this.nodeList.filter((i: any, j: any) => {
      if (j === 0) return i;
      else {
        // console.log(i[j]);
        if (i[1] === this.parentId1) {
          return i;
        }
      }
    });
    console.log(firstLevelFilter.length);
    data.addRows(
      // this.service.getAssociateDetails()
      firstLevelFilter
    );
    if (data.getNumberOfRows() > 0) {
      data.setRowProperty(1, 'selectedStyle0', 'height:400px;width: 300px');
      data.setRowProperty(1, 'selectedStyle1', 'height:400px;width: 300px');
    }

    //  data.setRowProperty(2, 'selectedStyle2', 'height:400px;width: 300px');

    // Create the chart.
    //let fica: any = this.orgChart.nativeElement;
    //this.orgChart.nativeElement;
    const chart: any = new google.visualization.OrgChart(
      this.orgChart.nativeElement
    );
    var me = this;

    var container = document.getElementById('orgChart');
    if(this.isMobileView){
      container.addEventListener('touchend',myFunction,false);
    }else{
      container.addEventListener('click',myyFunction,false);
    }

    function myFunction(e) {
      e.preventDefault();
      if (
        e.target['className'] == 'dummy' ||
        e.target['className'] == 'collapseClass'
      ) {
        var regExp = /\(([^)]+)\)/;
        var matches = regExp.exec(e.path[2].innerText);
        var selectId = matches[1]
        console.log(selectId)
        me.collapseCounter++;
        var selection = chart.getSelection();
        console.log(chart)
        console.log(chart.Jr.uw[0].Ei)
        console.log(chart.Jr.uw[0].Wz.length)
        console.log(selection);
       if (chart.Jr.uw[0].Wz.length > 0){
         for (let i=0; i <= chart.Jr.uw[0].Wz.length; i++){
          if(selectId== chart.Jr.uw[i].Ei){
            var row = chart.Jr.uw[i].row
            console.log('Row ' + row)
           }
         }
          if (row <= me.collapseLength) {
            var collapse = chart.getCollapsedNodes().indexOf(row) == -1;

            var ele = document.getElementById('chartElement_' + row);
            if (ele != null) {
              ele.scrollIntoView({ block: 'center', inline: 'center' });
            }
            if (chart.getChildrenIndexes(row).length === 0 || row === 0) {
              if (ele != null) {
                if (ele.children[3] != undefined) {
                  var selectedEmpId = ele.children[3].lastElementChild[
                    'innerText'
                  ]
                    .trim()
                    .slice(1, -1);
                  me.selectedParentId = selectedEmpId;
                  for (let _i = 1; _i <= me.collapseLength; _i++) {
                    if (chart.getChildrenIndexes(_i).length > 0) {
                      let child1 = new Array();
                      child1 = chart.getChildrenIndexes(_i);
                      let counter = child1.length;

                      data.removeRows(child1[0], counter);

                      me.drawChart();

                      me.rowNumber = row;
                      ele.scrollIntoView({
                        block: 'center',
                        inline: 'center',
                      });
                    }
                  }
                  var secondLevelFilter: any[] = [];
                  secondLevelFilter = me.nodeList.filter((i: any, j: any) => {
                    if (j > 0) {
                      if (i[1] === selectedEmpId) {
                        return i;
                      }
                    }
                  });

                  data.addRows(secondLevelFilter);
                } else {
                  chart.collapse(row, collapse);
                }
              }
              me.rowNumber = row;
            } else if (
              chart.getChildrenIndexes(row).length > 0 &&
              ele != null
            ) {
              let child1 = new Array();
              child1 = chart.getChildrenIndexes(row);
              let counter = child1.length;

              data.removeRows(child1[0], counter);

              me.drawChart();

              me.rowNumber = row;
              ele.scrollIntoView({ block: 'center', inline: 'center' });
            }

            if (collapse == false) {
              me.displayGraph(chart, data, row);
            }
            if (collapse == true && row != 0) {
              me.displayGraph(chart, data, row);
            }

            if (chart != undefined && me.counter > 0) {
              me.childIndexes = chart.getChildrenIndexes(row);
            }
          }
          var ele = document.getElementById('chartElement_' + row);
          if (ele != null) {
            console.log(ele, row);
            ele.scrollIntoView({ block: 'center', inline: 'center' });
          }

          chart.setSelection([]);
          return false;
        }
      }
    }
    function myyFunction(e) {
      e.preventDefault();
      if (
        e.target['className'] == 'dummy' ||
        e.target['className'] == 'collapseClass'
      ) {
        me.collapseCounter++;
        var selection = chart.getSelection();
       if (selection.length > 0){
            var row = selection[0].row;
          if (row <= me.collapseLength) {
            var collapse = chart.getCollapsedNodes().indexOf(row) == -1;

            var ele = document.getElementById('chartElement_' + row);
            if (ele != null) {
              ele.scrollIntoView({ block: 'center', inline: 'center' });
            }
            if (chart.getChildrenIndexes(row).length === 0 || row === 0) {
              if (ele != null) {
                if (ele.children[3] != undefined) {
                  var selectedEmpId = ele.children[3].lastElementChild[
                    'innerText'
                  ]
                    .trim()
                    .slice(1, -1);
                  me.selectedParentId = selectedEmpId;
                  for (let _i = 1; _i <= me.collapseLength; _i++) {
                    if (chart.getChildrenIndexes(_i).length > 0) {
                      let child1 = new Array();
                      child1 = chart.getChildrenIndexes(_i);
                      let counter = child1.length;

                      data.removeRows(child1[0], counter);

                      me.drawChart();

                      me.rowNumber = row;
                      ele.scrollIntoView({
                        block: 'center',
                        inline: 'center',
                      });
                    }
                  }
                  var secondLevelFilter: any[] = [];
                  secondLevelFilter = me.nodeList.filter((i: any, j: any) => {
                    if (j > 0) {
                      if (i[1] === selectedEmpId) {
                        return i;
                      }
                    }
                  });

                  data.addRows(secondLevelFilter);
                } else {
                  chart.collapse(row, collapse);
                }
              }
              me.rowNumber = row;
            } else if (
              chart.getChildrenIndexes(row).length > 0 &&
              ele != null
            ) {
              let child1 = new Array();
              child1 = chart.getChildrenIndexes(row);
              let counter = child1.length;

              data.removeRows(child1[0], counter);

              me.drawChart();

              me.rowNumber = row;
              ele.scrollIntoView({ block: 'center', inline: 'center' });
            }

            if (collapse == false) {
              me.displayGraph(chart, data, row);
            }
            if (collapse == true && row != 0) {
              me.displayGraph(chart, data, row);
            }

            if (chart != undefined && me.counter > 0) {
              me.childIndexes = chart.getChildrenIndexes(row);
            }
          }
          var ele = document.getElementById('chartElement_' + row);
          if (ele != null) {
            console.log(ele, row);
            ele.scrollIntoView({ block: 'center', inline: 'center' });
          }

          chart.setSelection([]);
          return false;
        }
      }
    }

    this.displayGraph(chart, data, 0);
  };

  displayGraph(chart: any, data: any, row: any) {
    // Draw the chart, setting the allowHtml option to true for the tooltips.
    var options: any = {
      allowHtml: true,
      allowCollapse: false,
      cssClassNames: {
        headerRow: 'headerRow',
        size: 'small',
        tableRow: 'tableRow',
        oddTableRow: 'oddTableRow',
        selectedTableRow: 'selectedTableRow',
        // hoverTableRow: 'hoverTableRow',
        headerCell: 'headerCell',
        tableCell: 'tableCell',
        rowNumberCell: 'rowNumberCell',
      },
    };

    chart.draw(data, options);

    if (row > 0) {
      this.nodeList.forEach((element: any, index: any) => {
        if (index > this.collapseLength) {
          if (document.getElementById('imageElement_' + index) != null) {
            document.getElementById('imageElement_' + index).hidden = true;
          }
          if (document.getElementById('secondElement_' + index) != null) {
            document.getElementById('secondElement_' + index).hidden = true;
          }
        }
      });
    }

    if (row <= this.collapseLength) {
      for (let m = 0; m <= this.collapseLength; m++) {
        if (chart.getChildrenIndexes(m).length === 0) {
          if (document.getElementById('imageElement_' + m) != null) {
            document.getElementById('imageElement_' + m).hidden = false;
          }
          if (document.getElementById('secondElement_' + m) != null) {
            document.getElementById('secondElement_' + m).hidden = true;
          }
        } else {
          if (document.getElementById('imageElement_' + m) != null) {
            document.getElementById('imageElement_' + m).hidden = true;
          }
          if (document.getElementById('secondElement_' + m) != null) {
            document.getElementById('secondElement_' + m).hidden = false;
          }
        }
      }
    }
    if (this.collapseCounter < 2) {
      if (this.collapseLength > 10) {
        chart.collapse(0, true);
      }

      var ele = document.getElementById('chartElement_0');
      if (ele != null) {
        ele.scrollIntoView({ block: 'center', inline: 'center' });
      }
      this.collapseCounter++;
    }

    if (row == 0) {
      var ele = document.getElementById('chartElement_0');
      if (ele != null) {
        ele.scrollIntoView({ block: 'center', inline: 'center' });
      }
    }
    var ele = document.getElementById('chartElement_0');
    if (ele != null) ele.scrollIntoView({ block: 'center', inline: 'center' });

    // if (document.getElementById('graphCollapse') != undefined) {
    //   //var container = document.getElementById('graphCollapse');
    //   var ele = document.getElementById('chartElement_0');

    //   ele.scrollIntoView({ block: 'center', inline: 'center' });

    //   //this.sideScroll(container, 'right', 25, 26540, 1000);
    // }
  }
  activeTabVal(tabVal: any) {
    // console.log('tabVal', tabVal);
    this.activeTab = tabVal;
    if (this.activeTab === 'REPORTED') {
      if (this.selectedDuration === 0) {
        this.durationSelected = '0';
      }

      this.searchedPerson = '';
      this.noResult = false;
      this.responseData = this.quarantineData;
      this.responseData = this.responseData.sort(function (a: any, b: any) {
        return a.personName.localeCompare(b.personName, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
      // console.log('tabVal', this.responseData);
    } else if (this.activeTab === 'EXPOSED') {
      if (this.selectedDuration === 0) {
        this.durationSelected = '0';
      }
      this.searchedPerson = '';
      this.noResult = false;
      this.responseData = this.exposedData;
      this.responseData = this.responseData.sort(function (a: any, b: any) {
        return a.personName.localeCompare(b.personName, undefined, {
          numeric: true,
          sensitivity: 'base',
        });
      });
      // console.log('tabVal', this.responseData);owSe
    }
    console.log(this.quarantineData, this.exposedData, this.responseData);
    if (this.responseData !== undefined) {
      if (this.responseData.length > 0) {
        console.log('in response');
        console.log(this.selectedDuration);
        if(!this.isMobileView){
          this.showSelectedAssociate(0);
        }
      }
    }
    console.log(this.quarantineData, this.exposedData, this.responseData);
  }
  showSelectedAssociate(index: any) {
    console.log('showselectedass index valllllllll ' + index);
    if(this.isMobileView){
      this.display = true;
      this.displaycard = false;
      this.level2 = true;
      this.service.setShow(false);
    }
    this.counter = 0;
    this.collapseCounter = 0;
    this.expandedNodesList = [];
    if (
      this.responseData !== undefined ||
      this.responseData !== null ||
      this.responseData.length > 0
    ) {
      this.selectedNavBar = index;
      this.detailsDisplay = true;

      this.selectedIdData = this.responseData[index];
      if (this.selectedIdData != undefined || this.selectedIdData != null) {
        this.getPersonDetails(this.selectedIdData.personId);
        console.log('report '+ this.selectedIdData.reportedDate)
        console.log('personID ' +this.selectedIdData.personId)
        console.log(this.selectedDuration);
        let x = {...this.selectedIdData}
        console.log(x);
      }
    }
  }
  getListPersonDetails(id : any){
    if (id !== null || id !== undefined) {
      this.service.getPersonalDetailById(id).subscribe((data: any) => {
        this.listPersonalDetailById = data.employeeList[0];
        console.log(data);
        if (this.listPersonalDetailById.email.length > 30) {
          this.listSelectedEmail =
            this.listPersonalDetailById.email.substring(0, 30) + '...';
        } else {
          this.listSelectedEmail = this.listPersonalDetailById.email;
        }
      });

    }
    this.service
      .getPerson(
        id,
        this.selectedDuration,
        this.timeZone,
        this.startDate,
        this.endDate
      ).subscribe((data: any) => {
        console.log(data)
        if (data.contactTraces[0] !== null) {
          this.listTracedData = data.contactTraces[0];
          if (this.listTracedData.personName.length > 15) {
            this.listSelectedName =
              this.listTracedData.personName.substring(0, 15) + '...';
          }
        }
      });
  }


  getPersonDetails(id: any) {
    this.level1Data = null;
    this.level2Data = null;
    this.firstChildChildren = [];

    if (id !== null || id !== undefined) {
      this.service.getPersonalDetailById(id).subscribe((data: any) => {
        this.personalDetailById = data.employeeList[0];
        console.log(data);
        if (this.personalDetailById.email.length > 30) {
          this.selectedEmail =
            this.personalDetailById.email.substring(0, 30) + '...';
        } else {
          this.selectedEmail = this.personalDetailById.email;
        }
      });
    }

    console.log(this.selectedDuration);
    this.service
      .getPerson(
        id,
        this.selectedDuration,
        this.timeZone,
        this.startDate,
        this.endDate
      )
      .subscribe((data: any) => {
        // console.log('data', data, data.contactTraces[0].firstContactDetails);
        console.log(data)

        if (data.contactTraces[0] !== null) {
          this.responseDataRes = data;
          this.tracedData = data.contactTraces[0];
          //console.log(this.tracedData);
          if (this.tracedData.personName.length > 15) {
            this.selectedName =
              this.tracedData.personName.substring(0, 15) + '...';
          }
          if (data.contactTraces[0].firstContactDetails) {
            this.setLevel1(data.contactTraces[0]);
          }
          if (data.contactTraces[0].firstContactDetails === null) {
            this.sampleArray = data.contactTraces[0];
            this.contactTraceCount = 0;
            let chart = document.getElementById('orgChart');
            if (chart !== null) {
              chart.innerHTML = '';
            }
          } else {
            let a = data.contactTraces.map(function (i: any) {
              return i.firstContactDetails.map(function (j: any) {
                return [i, j];
              });
            });
            this.collapseLength = a[0].length;
            a[0].forEach((x: any) => {
              if (x !== undefined) {
                x[0].duplicateId = x[0].personId;
                x[1].duplicateId = x[1].personId;
              }
            });
            let b = data.contactTraces.map(function (i: any) {
              return i.firstContactDetails.map(function (j: any) {
                if (j.firstContactDetails === null) {
                } else {
                  return j.firstContactDetails.map(function (k: any) {
                    return [j, k];
                  });
                }
              });
            });

            b[0].forEach((element: any) => {
              if (element !== undefined) {
                element.forEach((data: any, index: any) => {
                  data[1].duplicateId = data[1].personId;
                  data[1].personId =
                    data[0].personId + '_' + data[1].personId + '_' + index;
                });
              }
            });

            var newArray = [].concat.apply([], b[0]);
            var finalArray = [].concat.apply([], newArray);

            let quarantineArray = [...a[0], ...finalArray];

            this.sampleArray = [].concat.apply([], quarantineArray);

            var obj = {};

            for (var i = 0, len = this.sampleArray.length; i < len; i++) {
              if (this.sampleArray[i] != undefined) {
                obj[this.sampleArray[i]['personId']] = this.sampleArray[i];
              }
            }
            //quarantineArray.reduce((i, j) => i.concat(j), []);
            this.sampleArray = new Array();
            for (var key in obj) this.sampleArray.push(obj[key]);
            let data1 = this.sampleArray;
            data1.forEach(function (item: any, i: any) {
              if (item.sourceId === null) {
                data1.splice(i, 1);
                data1.unshift(item);
              }
            });

            this.sampleArray = data1;
            this.nodeList = this.sampleArray.map((i: any, index: any) => {
              var x =
                typeof i.duration === 'string'
                  ? Number(i.duration.split(':')[0]) * 60 +
                    Number(i.duration.split(':')[1]) +
                    ' sec'
                  : i.duration + ' sec';
              return [
                {
                  v: i.personId,
                  f:
                    i.sourceId == null
                      ? '<div class="chart-node" id="chartElement_' +
                        index +
                        '">' +
                        this.statusNode(i.status, i.personName, i.duplicateId) +
                        '</div><p class="emp-location" >' +
                        i.location +
                        '</p><p style="padding-right: 10%;" id="imageElement_' +
                        index +
                        '"><img style="position: absolute;" class="dummy"   src="assets/Group 1809.svg" alt="" /><img style="position: absolute;padding-left:20px;padding-top:7px;" class="dummy" src="assets/downward-arrow.svg" alt="" /></p><p  style="padding-right: 10%;" hidden id="secondElement_' +
                        index +
                        '"><img style="position: absolute;" class="collapseClass" src="assets/Group 1809.svg" alt="" /><img style="position: absolute;padding-left:20px;padding-top:7px;" class="dummy" src="assets/upward-arrow.svg" alt="" /></p>'
                      : '<div class="chart-node" id="chartElement_' +
                        index +
                        '"><div class="time-spent">' +
                        x +
                        '</div>' +
                        this.statusNode(i.status, i.personName, i.duplicateId) +
                        '</div><p class="emp-location" > ' +
                        i.location +
                        '</p><p style="padding-right: 10%;" id="imageElement_' +
                        index +
                        '"><img style="position: absolute;"  class="dummy"  src="assets/Group 1809.svg" alt="" /><img style="position: absolute;padding-left:20px;padding-top:7px;" class="dummy" src="assets/downward-arrow.svg" alt="" /></p><p  style="padding-right: 10%;" hidden id="secondElement_' +
                        index +
                        '"><img style="position: absolute;" class="collapseClass" src="assets/Group 1809.svg" alt="" /><img style="position: absolute;padding-left:20px;padding-top:7px;" class="dummy" src="assets/upward-arrow.svg" alt="" /></p>',
                },
                i.sourceId === '0' ? '' : i.sourceId,
              ];
            });

            let arrList = {};
            for (var i = 0, len = this.sampleArray.length; i < len; i++)
              arrList[this.sampleArray[i]['duplicateId']] = this.sampleArray[i];

            this.sampleArray = new Array();
            for (var key in arrList) this.sampleArray.push(arrList[key]);

            this.contactTraceCount = this.sampleArray.length - 1;

            if (this.contactTraceCount > 100) this.panzoomConfig.zoomLevels = 4;
            else this.panzoomConfig.zoomLevels = 5;

            if (
              this.selectedTab == 1 &&
              this.responseDataRes.contactTraces[0].firstContactDetails
            ) {
              google.charts.setOnLoadCallback(this.drawChart());
              var ele = document.getElementById('chartElement_0');
              if (ele != null)
                ele.scrollIntoView({ block: 'center', inline: 'center' });
              setTimeout(() => {
                this.resetView();
              }, 200);
              setTimeout(() => {
                this.resetView();
              }, 200);
            }
          }
        }
      });
  }

  scrollDown() {
    var container = document.getElementById('floorList');

    if (container !== null || container !== undefined) {
      this.horizontalScroll(container, 'down', 25, 100, 10);
    }
  }

  scrollUp() {
    var container = document.getElementById('floorList');
    if (container !== null || container !== undefined) {
      this.horizontalScroll(container, 'up', 25, 100, 10);
    }
  }
  horizontalScroll(
    element: any,
    direction: any,
    speed: any,
    distance: any,
    step: any
  ) {
    if (element !== null || element !== undefined) {
      let scrollAmount = 0;
      var slideTimer = setInterval(function () {
        if (direction == 'up') {
          element.scrollTop -= step;
        } else {
          element.scrollTop += step;
        }
        scrollAmount += step;
        if (scrollAmount >= distance) {
          window.clearInterval(slideTimer);
        }
      }, speed);
    }
  }
  statusNode(status: any, personName: any, duplicateId: any) {
    // console.log(status);
    if (status == 'InQuarantine' || status == 'In Quarantine') {
      return (
        '<span class="material-icons user-icon person-red" style="font-size: 34px;">account_circle</span><span class="tooltiptext">' +
        personName +
        '</span><p  class= "person-danger" ><span style="display: inline-block;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 80px!important;">' +
        personName +
        '</span><span style="display:block;float:right;">(' +
        duplicateId +
        ') </span></p>'
      );
    } else if (status == 'Exposed') {
      return (
        '<span class="material-icons user-icon person-orange" style="font-size: 34px;">account_circle</span><span class="tooltiptext">' +
        personName +
        '</span><p  class= "person-check" ><span style="display: inline-block;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 80px!important;">' +
        personName +
        '</span><span style="display:block;float:right;">(' +
        duplicateId +
        ') </span></p>'
      );
    } else if (status == 'Normal') {
      return (
        '<span class="material-icons user-icon person-green" style="font-size: 34px;">account_circle</span><span class="tooltiptext">' +
        personName +
        '</span><p  class= "person-safe" ><span style="display: inline-block;text-overflow: ellipsis;overflow: hidden;white-space: nowrap;width: 80px!important;">' +
        personName +
        '</span><span style="display:block;float:right;">(' +
        duplicateId +
        ') </span></p>'
      );
    }
  }
  setLevel1(level: any) {
    this.level1Data = level.firstContactDetails;
    if (this.level1Data != null) {
      this.level1Data.forEach((i: any) => {
        i.duration =
          Number(i.duration.split(':')[0]) * 60 +
          Number(i.duration.split(':')[1]);
      });
    }
    if(!this.isMobileView){
      this.setLevel2(this.level1Data[0], 0);
    }

    this.getGraph();
  }
  setLevel2(level: any, index: any) {
    console.log(this.level1Data[index])
    this.goUp();
    if(this.isMobileView){
      this.getListPersonDetails(this.level1Data[index].personId);
      this.level1 = true;
      this.level2 = false;
      this.lvl1data = true;
      this.showarrow = true;
      this.expand = true;
    }

    this.selectedIndex1 = index;
    this.level2Data = level.firstContactDetails;
    if (this.level2Data != null) {
      this.level2Data.forEach((j: any) => {
        j.duration =
          Number(j.duration.split(':')[0]) * 60 +
          Number(j.duration.split(':')[1]);
      });
    }
  }
  getGraph() {
    let incOne = 0;
    let incTwo = 0;

    if (this.responseDataRes.contactTraces[0].firstContactDetails === null) {
      this.sampleArray = this.responseDataRes.contactTraces[0];
      this.contactTraceCount = 0;
      let chart = document.getElementById('orgChart');
      if (chart !== null) {
        chart.innerHTML = '';
      }
    } else {
      let a = this.responseDataRes.contactTraces.map(function (i: any) {
        return i.firstContactDetails.map(function (j: any) {
          return [i, j];
        });
      });

      let b = this.responseDataRes.contactTraces.map(function (i: any) {
        return i.firstContactDetails.map(function (j: any) {
          if (j.firstContactDetails === null) {
          } else {
            return j.firstContactDetails.map(function (k: any) {
              return [j, k];
            });
          }
        });
      });

      b[0].forEach((element: any) => {
        if (element !== undefined) {
          this.firstChildChildren.push(element);
        }
      });

      var newArray = [].concat.apply([], this.firstChildChildren);
      var finalArray = [].concat.apply([], newArray);
      // console.log(finalArray);
      let quarantineArray = [...a[0], ...finalArray];
      this.sampleArray = [...new Set([].concat.apply([], quarantineArray))];

      //quarantineArray.reduce((i, j) => i.concat(j), []);
      this.nodeList = this.sampleArray.map((i: any, index: any) => {
        var x =
          typeof i.duration === 'string'
            ? Number(i.duration.split(':')[0]) * 60 +
              Number(i.duration.split(':')[1]) +
              ' sec'
            : i.duration + ' sec';
        return [
          {
            v: i.personId,
            f:
              i.sourceId == null
                ? '<div class="chart-node" id="chartElement_' +
                  index +
                  '">' +
                  this.statusNode(i.status, i.personName, i.duplicateId) +
                  '</div><p class="emp-location">' +
                  i.location +
                  '</p><p  style="padding-right: 10%;" id="imageElement_' +
                  index +
                  '"><img style="position: absolute;" class="dummy" src="assets/Group 1809.svg" alt="" /><img style="position: absolute;padding-left:20px;padding-top:7px;" class="dummy" src="assets/downward-arrow.svg" alt="" /></p><p  style="padding-right: 10%;" hidden id="secondElement_' +
                  index +
                  '"><img style="position: absolute;" class="collapseClass" src="assets/Group 1809.svg" alt="" /><img style="position: absolute;padding-left:20px;padding-top:7px;" class="dummy" src="assets/upward-arrow.svg" alt="" /></p>'
                : '<div class="chart-node"id="chartElement_' +
                  index +
                  '" ><div class="time-spent">' +
                  x +
                  '</div>' +
                  this.statusNode(i.status, i.personName, i.duplicateId) +
                  '</div><p class="emp-location" >' +
                  i.location +
                  '</p><p style="padding-right: 10%;" id="imageElement_' +
                  index +
                  '"><img style="position: absolute;" class="dummy"  src="assets/Group 1809.svg" alt="" /><img style="position: absolute;padding-left:20px;padding-top:7px;" class="dummy" src="assets/downward-arrow.svg" alt="" /></p><p  style="padding-right: 10%;" hidden id="secondElement_' +
                  index +
                  '"><img style="position: absolute;" class="collapseClass"  src="assets/Group 1809.svg" alt="" /><img style="position: absolute;padding-left:20px;padding-top:7px;" class="dummy" src="assets/upward-arrow.svg" alt="" /></p>',
          },
          i.sourceId === '0' ? '' : i.sourceId,
          // i.personName,
        ];
      });
      let arrList = {};
      for (var i = 0, len = this.sampleArray.length; i < len; i++)
        arrList[this.sampleArray[i]['duplicateId']] = this.sampleArray[i];

      this.sampleArray = new Array();
      for (var key in arrList) this.sampleArray.push(arrList[key]);

      this.contactTraceCount = this.sampleArray.length - 1;

      if (this.selectedTab == 1 && this.orgChart) {
        google.charts.setOnLoadCallback(this.drawChart());
        var ele = document.getElementById('chartElement_0');
        if (ele != null)
          ele.scrollIntoView({ block: 'center', inline: 'center' });
        setTimeout(() => {
          this.resetView();
        }, 200);
        setTimeout(() => {
          this.resetView();
        }, 200);
      }
    }
  }

  changeTab(event: any) {
    this.selectedTab = event.index;

    if (event.index == 1) {
      this.panZoomAPI.resetView();
      this.getGraph();
      /*let divWidth = document.getElementById("orgChart").clientWidth
      console.log('RAM Graph width ' + divWidth)
      this.panzoomConfig.initialPanX = (divWidth / 2);*/
      // this.middlePos()
    }
  }

  getEmail() {
    return this.personalDetailById?.email;
  }
  getName() {
    return this.tracedData?.personName;
  }
  getListName() {
    return this.listTracedData?.personName;
  }
  ngOnDestroy(): void {
    this.apiSubscription.unsubscribe(); // don't forget to unsubscribe.  you don't want a memory leak!
  }
  onModelChanged(model: PanZoomModel): void {
    alert('RAM here');
    //this.panZoomAPI.zoomIn();
  }
  zoomIn() {
    this.panZoomAPI.zoomIn();
  }
  zoomOut() {
    this.panZoomAPI.zoomOut();
  }
  resetView() {
    this.collapseCounter = 0;
    if (this.responseDataRes.contactTraces[0].firstContactDetails != null) {
      google.charts.setOnLoadCallback(this.drawChart());
    }
    this.panZoomAPI.resetView();
    setTimeout(() => {
      var ele = document.getElementById('chartElement_0');
      if (ele != null)
        ele.scrollIntoView({ block: 'center', inline: 'center' });
    }, 100);
    setTimeout(() => {
      var ele = document.getElementById('chartElement_0');
      if (ele != null)
        ele.scrollIntoView({ block: 'center', inline: 'center' });
    }, 100);
    //this.panZoomAPI.panToPoint({ "x": this.zoomMiddlePosition, "y": 0 })
    //let viewPos = this.panZoomAPI.getViewPosition()
    //console.log('RAM View position ', viewPos)
    //this.panZoomAPI.panToPoint({ "x": 36000, "y": 0 })
    //this.panZoomAPI.panDeltaPercent({ "x": 50.0, "y": 0 })
    //this.panZoomAPI.panDelta({ "x": 1000, "y": 0 })
    // let divWidth = document.getElementById("orgChart").clientWidth;
    //console.log('RAM Div width ' + divWidth)

    //this.panZoomAPI.getSizes().realZoom;
    //this.panZoomAPI.zoomToFit({ "x": 100, "y": 0, "width": 0, "height": 0 })

    //this.panZoomAPI.zoomOnDoubleClick = false;
  }
  async middlePos() {
    //var thisTable = document.getElementsByTagName('table');
    //var thisTable = document.getElementsByClassName('google-visualization-orgchart-table');
    //thisTable[0].id = 'chartid';
    /*let element = document.getElementById('table');
    if (typeof (element) != 'undefined' && element != null) {
      // Exists.
      document.getElementsByClassName('google-visualization-orgchart-table')[0].setAttribute('id', 'orgTable');
      let chartdivWidth = document.getElementById("orgTable").clientWidth;
      let centerPosition = Math.round(chartdivWidth / 2);
      console.log('RAM Chart width ' + chartdivWidth)
      console.log('RAM chart pixel', + centerPosition)
      this.panZoomAPI.panToPoint({ "x": centerPosition, "y": 0 })
    }*/
    if (
      document.getElementsByClassName('google-visualization-orgchart-table')[0]
    ) {
      document
        .getElementsByClassName('google-visualization-orgchart-table')[0]
        .setAttribute('id', 'orgTable');
    }

    if (document.getElementById('orgTable')) {
      let chartdivWidth = document.getElementById('orgTable').clientWidth;
      let centerPosition = Math.round(chartdivWidth / 2);
      centerPosition = centerPosition + 800;
      this.zoomMiddlePosition = centerPosition;

      //d.style.top = centerPosition + 'px';
    }

    //this.panZoomAPI.panToPoint({ "x": this.zoomMiddlePosition, "y": 0 })
    //this.panzoomConfig.initialPanX = centerPosition;
  }
  zoomFit() {
    this.pageExpand = !this.pageExpand;
    //this.panZoomAPI.getSizes().width
    /*const dialogRef = this.dialog.open(QuarantineTrackerComponent, {
      width: '100%',
      height: '100%',
      panelClass: 'survey-form',
      data: {},
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });*/
    /*if (!this.pageExpand) {
      this.pageExpand = true;
      this.panZoomAPI.zoomToFit({ "x": 0, "y": 100, "width": 100, "height": 100 })
    } else {
      this.pageExpand = false;
      this.panZoomAPI.resetView();
    }*/
  }
  expandView() {
    this.pageExpand = !this.pageExpand;
  }

  enterMember(val: any) {
    this.searchedPerson = val;
    this.searchMember(val);
  }

  searchMember(val: any) {
    if (val === '') {
      this.noResult = false;

      if (this.activeTab === 'REPORTED') {
        this.responseData = this.quarantineData;
      } else {
        this.responseData = this.exposedData;
      }
      if(!this.isMobileView){
        this.showSelectedAssociate(0);
      }

    } else {
      var tempArr;
      this.noResult = false;

      if (this.activeTab === 'REPORTED') {
        tempArr = this.quarantineData;
      } else {
        tempArr = this.exposedData;
      }
      let arr1 = tempArr.filter((data: any) => {
        if (data.personName.toLowerCase().includes(val.toLowerCase())) {
          return data;
        }
      });
      if (arr1.length > 0) {
        this.responseData = arr1;
        if(!this.isMobileView){
          this.showSelectedAssociate(0);
        }
      } else {
        let arr2 = tempArr.filter((data: any) => {
          if (data.personId.toLowerCase().includes(val.toLowerCase())) {
            return data;
          }
        });
        if (arr2.length > 0) {
          this.responseData = arr2;
          if(!this.isMobileView){
            this.showSelectedAssociate(0);
          }
        } else {
          this.noResult = true;
          this.responseData = [];
          this.detailsDisplay = false;
          this.tracedData = [];
        }
      }
    }
  }
  zoomControlesStyle() {
    let style = {
      'margin-bottom': !this.pageExpand ? '-29px' : '',
      'margin-right': !this.pageExpand ? '26px' : '',
    };
    return style;
  }
  durationSelectionChange(e: any) {
    // console.log(this.durationSelected);
  }

  filterDuration() {
    if (this.activeTab === 'EXPOSED') {
      if (this.durationSelected == 1) {
        this.selectedDuration = 1;
        this.getExposedData(this.timeZone);
      } else if (this.durationSelected == 2) {
        this.selectedDuration = 2;
        this.getExposedData(this.timeZone);
      } else if (this.durationSelected == 3) {
        this.selectedDuration = 3;
        this.getExposedData(this.timeZone);
      } else if (this.durationSelected == 0) {
        this.selectedDuration = 0;
        this.getExposedData(this.timeZone);
      }
    } else if (this.activeTab === 'REPORTED') {
      if (this.durationSelected == 1) {
        this.selectedDuration = 1;
        this.getQuarantineData(this.timeZone);
      } else if (this.durationSelected == 2) {
        this.selectedDuration = 2;
        this.getQuarantineData(this.timeZone);
      } else if (this.durationSelected == 3) {
        this.selectedDuration = 3;
        this.getQuarantineData(this.timeZone);
      } else if (this.durationSelected == 0) {
        this.selectedDuration = 0;
        this.getQuarantineData(this.timeZone);
      }
    }
    // this.activeTabVal(this.activeTab);
  }
  back(){
    if(this.isMobileView){
      this.display = false;
      this.displaycard = true
      this.service.setShow(true);
      this.level1 =false;
      this.level2 = true;
      this.lvl1data = false;
      this.showarrow = false;
    }
  }
  goBack(){
    if(this.isMobileView){
      this.level1 = false;
      this.level2 = true;
      this.lvl1data = false;
      this.showarrow = false;
    }
  }
  goUp(){
    this.viewportScroller.scrollToPosition([0,0])
  }
  expandData(){
    this.lvl1data = !this.lvl1data;
    this.expand = !this.expand;
  }
  mobGraph(){
    console.log('Graph tab is clicked')
  }

}
