<div class="desk">
  <mat-tab-group
    [selectedIndex]="hrSelectedTabIndex"
    style="width: 100%; padding-left: 5px;"
    (selectedTabChange)="tabClick($event)"
    class="hrtab"
  >
    <mat-tab label="DASHBOARD">
      <app-non-compliant-associate></app-non-compliant-associate>
    </mat-tab>
    <mat-tab label="MODIFY ASSOCIATE DETAILS">
      <app-modify-associate-details></app-modify-associate-details>
    </mat-tab>
    <mat-tab *ngIf="PD" label="QUARANTINE TRACKER">
      <app-quarantine-tracker *ngIf="showQuarantine"></app-quarantine-tracker>
    </mat-tab>
    <mat-tab *ngIf="HQ_Enable" label="FORMS">
      <app-form-list *ngIf="showForms" ></app-form-list>
    </mat-tab>
  </mat-tab-group>
</div>

<div class="mobile">
  <div class="list" *ngIf="menu">
    <ul style="list-style-type: none;">
      <li (click)="onClick('dash')">DASHBOARD</li>
      <li (click)="onClick('mad')">MODIFY ASSOCIATE DETAILS</li>
      <li (click)="onClick('qt')">QUARANTINE TRACKER</li>
      <li (click)="onClick('form')">FORMS</li>
    </ul>
  </div>
  <div *ngIf="dash" label="DASHBOARD">
    <div class="back">
      <img src="../../../../assets/Path 7285.svg" (click)="back()"> DASHBOARD 
    </div>
    <app-non-compliant-associate></app-non-compliant-associate>
  </div>
  <div *ngIf="mad" label="MODIFY ASSOCIATE DETAILS">
    <div class="back">
      <img src="../../../../assets/Path 7285.svg" (click)="back()"> MODIFY ASSOCIATE DETAILS 
    </div>
    <app-modify-associate-details></app-modify-associate-details>
  </div>
  <div *ngIf="qt">
    <div *ngIf="PD" label="QUARANTINE TRACKER">
      <div class="back" *ngIf="display">
        <img src="../../../../assets/Path 7285.svg" (click)="back()"> QUARANTINE TRACKER
      </div>
      <app-quarantine-tracker ></app-quarantine-tracker>
    </div>
  </div>
  <div *ngIf="forms">
    <div *ngIf="HQ_Enable" label="FORMS">
      <div class="back" *ngIf="display">
        <img src="../../../../assets/Path 7285.svg" (click)="back()"> FORM LIST
      </div>
      <app-form-list ></app-form-list>
    </div>
  </div>
</div>