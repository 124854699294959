<div class="location-header row">
  <p>Building Details</p>
  <button mat-dialog-close mat-icon-button>
    <span class="material-icons">cancel</span>
  </button>
</div>

<mat-dialog-content>
  <hr class="border-line" />

  <div class="building-form">
    <form [formGroup]="buildingDetailsArray">
      <div class="fields row">
        <div class="col-4">
          <mat-label class="label-input">Building Name </mat-label>
          <input
            class="input-field"
            readonly
            autocomplete="off"
            matInput
            type="text"
            placeholder=""
            formControlName="buildingName"
            required
          />
        </div>
        <div class="col-4">
          <mat-label class="label-input">Total Carpet Area (sq ft)</mat-label>
          <input
            class="input-field"
            readonly
            autocomplete="off"
            matInput
            type="number"
            maxlength="10"
            placeholder=""
            formControlName="squareFeetArea"
            required
          />
        </div>
        <div class="col-4">
          <mat-label class="label-input">Seating Capacity</mat-label>
          <input
            class="input-field"
            readonly
            autocomplete="off"
            matInput
            type="number"
            maxlength="8"
            placeholder=""
            formControlName="buildingCapacity"
            required
          />
        </div>
      </div>
    </form>
  </div>

  <hr class="border-line" />

  <div class="location-building row">
    <p>Building Mapping</p>
    <form [formGroup]="buildingDetailsArray">
      <input
        class="input-field"
        readonly
        autocomplete="off"
        matInput
        type="text"
        placeholder=""
        formControlName="latLong"
      />
    </form>
  </div>

  <!-- <div class="map-block">
    <agm-map
      [latitude]="latitude"
      [longitude]="longitude"
      [zoom]="zoom"
      [zoomControl]="false"
      [mapTypeControl]="false"
      [gestureHandling]="'coopeative'"
      [scrollwheel]="false"
    >
      <agm-marker [latitude]="latitude" [longitude]="longitude" [iconUrl]="getimagename()"> </agm-marker>
    </agm-map>
  </div> -->
</mat-dialog-content>
