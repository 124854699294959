<div class="location-header row">
  <p>Floor Details</p>
  <button mat-dialog-close mat-icon-button>
    <span class="material-icons">cancel</span>
  </button>
</div>

<hr class="border-line" />
<mat-dialog-content>
  <form [formGroup]="floorDetailsArray">
    <div class="row">
      <div class="col-4">
        <mat-label class="label-input">Floor Name </mat-label>
        <input
          class="input-field"
          readonly
          autocomplete="off"
          matInput
          type="text"
          placeholder=""
          formControlName="floorName"
          required
        />
      </div>
      <div class="col-4">
        <mat-label class="label-input">Total Carpet Area (sq ft)</mat-label>
        <input
          class="input-field"
          readonly
          autocomplete="off"
          matInput
          type="text"
          placeholder=""
          formControlName="squareFeetArea"
          required
        />
      </div>
      <div class="col-4">
        <mat-label class="label-input">Seating Capacity</mat-label>
        <input
          class="input-field"
          readonly
          autocomplete="off"
          matInput
          type="text"
          placeholder=""
          formControlName="floorCapacity"
          required
        />
      </div>
    </div>

    <div class="floorPlan" *ngIf="image">
      <img [src]="image" alt="" />
    </div>
  </form>
</mat-dialog-content>
