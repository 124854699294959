import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-success-facility',
  templateUrl: './success-facility.component.html',
  styleUrls: ['./success-facility.component.scss']
})
export class SuccessFacilityComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<SuccessFacilityComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}
  close(){
    this.dialogRef.close({data:this.data});
  }
}
