<div class="root">
  <mat-card>
    <div class="facility">
      <div class="header">
        <div class="ttile">
          <span class="back" (click)="goBack()">
            <i class="material-icons">keyboard_arrow_left</i> BACK <span class="divider">| </span>
          </span>
          <span *ngIf="showFacility">FACILITY DETAILS</span>
          <span *ngIf="showBuilding">BUILDING DETAILS</span>
          <span *ngIf="showFloor">FLOOR DETAILS</span>
          <span *ngIf="showZone">ZONE DETAILS</span>
        </div>
        <div class="onborad-progress">
          <div class="progress-root">
            <img src="assets/Path 86.svg" alt="" />

            <div class="selector1" [ngClass]="{ active: showFacility, completed: isCompleted.facility }">
              Facility Details
            </div>

            <div class="selector1-value">
              <img *ngIf="showFacility && !isCompleted.facility" class="elicpse" src="assets/Group 325.svg" alt="" />
              <img *ngIf="isCompleted.facility" src="assets/Group 369.png" alt="" />
              <div *ngIf="!showFacility && !isCompleted.facility" class="textno">01</div>
            </div>
            <div class="selector2" [ngClass]="{ active: showBuilding, completed: isCompleted.building }">
              Building Details
            </div>
            <div class="selector2-value">
              <img *ngIf="showBuilding && !isCompleted.building" class="elicpse" src="assets/Group 325.svg" alt="" />
              <img *ngIf="isCompleted.building" src="assets/Group 369.png" alt="" />
              <div *ngIf="!showBuilding && !isCompleted.building" class="textno">02</div>
            </div>
            <div class="selector3" [ngClass]="{ active: showFloor, completed: isCompleted.floor }">
              Floor Details
            </div>
            <div class="selector3-value">
              <img *ngIf="showFloor && !isCompleted.floor" class="elicpse" src="assets/Group 325.svg" alt="" />
              <img *ngIf="isCompleted.floor" src="assets/Group 369.png" alt="" />
              <div *ngIf="!showFloor && !isCompleted.floor" class="textno">03</div>
            </div>
            <div class="selector4" [ngClass]="{ active: showZone, completed: isCompleted.zone }">
              Zone Details
            </div>
            <div class="selector4-value">
              <img *ngIf="showZone && !isCompleted.zone" class="elicpse" src="assets/Group 325.svg" alt="" />
              <img *ngIf="isCompleted.zone" src="assets/Group 369.png" alt="" />
              <div *ngIf="!showZone && !isCompleted.zone" class="textno">04</div>
            </div>
            <!-- <div class="selector4" [ngClass]="{ active: showZone, completed: isCompleted.zone }">
              Configure Zone Details
            </div>
            <div class="selector4-value">
              <img *ngIf="showZone && !isCompleted.zone" class="elicpse" src="assets/Group 325.png" alt="" />
              <img *ngIf="isCompleted.zone" src="assets/Group 369.png" alt="" />
              <div *ngIf="!showZone && !isCompleted.zone" class="textno">05</div>
            </div> -->
          </div>
        </div>
      </div>
      <hr class="border-line" />
      <div class="facility-component">
        <app-facility
          (toggleComponent)="showComponent($event)"
          (saveInfo)="saveChildData($event)"
          [masterObj]="masterObj"
          [facilitySpaceId]="facilitySpaceId"
          [isCompleted]="isCompleted"
          *ngIf="showFacility"
        >
        </app-facility>
        <app-building
          (toggleComponent)="showComponent($event)"
          (saveInfo)="completed($event)"
          [masterObj]="masterObj"
          [isCompleted]="isCompleted"
          *ngIf="showBuilding"
        ></app-building>
        <app-floor
          (toggleComponent)="showComponent($event)"
          (saveInfo)="completed($event)"
          [masterObj]="masterObj"
          [isCompleted]="isCompleted"
          *ngIf="showFloor"
        ></app-floor>
        <app-zone
          (toggleComponent)="showComponent($event)"
          (saveInfo)="completed($event)"
          [masterObj]="masterObj"
          [isCompleted]="isCompleted"
          (closeOnboard)="close()"
          *ngIf="showZone"
        >
        </app-zone>
        <app-configure-zone
          (toggleComponent)="showComponent($event)"
          (saveInfo)="completed($event)"
          [masterObj]="masterObj"
          [isCompleted]="isCompleted"
          (closeOnboard)="close()"
          *ngIf="showConfigureZone"
        >
        </app-configure-zone>
      </div>
    </div>
  </mat-card>
</div>
