import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-delete-facility',
  templateUrl: './delete-facility.component.html',
  styleUrls: ['./delete-facility.component.scss']
})
export class DeleteFacilityComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DeleteFacilityComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close();
  }

  delete(){
    console.log(this.data);
    this.dialogRef.close({data:this.data});
  }

}
