import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';

import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FacilityService } from '../facility.service';
import { LocationComponent } from '../location/location.component';
import { SuccessFacilityComponent } from '../success-facility/success-facility.component';

@Component({
  selector: 'app-facility',
  templateUrl: './facility.component.html',
  styleUrls: ['./facility.component.scss']
})
  export class FacilityComponent implements OnInit, AfterViewInit {
    @Output() toggleComponent = new EventEmitter();
    @Output() saveInfo = new EventEmitter();

    @Input() masterObj: any;
    @Input() isCompleted: any;
    @Input() facilitySpaceId: any;

    @ViewChild('facilityName') facilityName: ElementRef;
    facilityElement: any;
    formCollection = new FormArray([]);

    facilityDetailsForm = new FormGroup({
      facilityName: new FormControl('', Validators.required),
      facilityType: new FormControl(''),
      // facilityLocation: new FormControl('', Validators.required),
      facilityCapacity: new FormControl('', Validators.pattern('^[0-9]*$')),
      squareFeetArea: new FormControl('', [Validators.required, Validators.pattern('^[0-9]*$')]),
      facilityLayout: new FormControl(''),
      layoutName: new FormControl(''),
      region: new FormControl('', Validators.required),
      spaceId: new FormControl(''),

      noOfBuildings: new FormControl(''),
      countryregion: new FormControl(''),
      addressline1: new FormControl('', Validators.required),
      addressline2: new FormControl(''),
      pincode: new FormControl('', Validators.required),
      latlong: new FormControl(''),
      country: new FormControl('', Validators.required),
      city: new FormControl('', Validators.required),
      province: new FormControl('', Validators.required),
    });
    facilityTypes: any = [];
    facilityId = 0;

    nameFlag = true;
    fileName: any;
    countries: any = [];
    states: any = [];
    cities: any = [];
    regions: any;
    latLong: any;
    facilityLayout: any;

    addedInfoFileType: any[] = [];
    fileNameFromDb: any;
    fileError: string;
    stateFromGoogle: any = '';
    cityFromGoogle: any = '';
    constructor(public dialog: MatDialog, private service:FacilityService) {}

    ngOnInit() {
      console.log('master obj', this.masterObj);
      this.getCountries();
      this.getGlobalRegions();
      this.FacilityType();
    }

    FacilityType() {
      this.service.getFacilityTypes().subscribe((types: any) => {
        types.map((type: any) => {
          if (type.isRoot) {
            this.facilityTypes.push(type.type);
          }
        });
      });
    }
    ngAfterViewInit() {
      if (!!this.masterObj.facility && this.masterObj.facility.spaceId) {
        this.facilityDetailsForm.patchValue({
          spaceId: this.masterObj.facility.spaceId,
          facilityName: this.masterObj.facility.facilityName,
          facilityType: this.masterObj.facility.facilityType,
          facilityLocation: this.masterObj.facility.facilityLocation,
          facilityCapacity: this.masterObj.facility.facilityCapacity,
          noOfBuildings: this.masterObj.facility.noOfBuildings,
          squareFeetArea: this.masterObj.facility.squareFeetArea,
          facilityLayout: this.masterObj.facility.facilityLayout,
          layoutName: this.masterObj.facility.layoutName,
          region: Number(this.masterObj.facility.region),
          latlong: this.masterObj.facility.latlong,
          addressline1: this.masterObj.facility.addressline1,
          addressline2: this.masterObj.facility.addressline2,
          pincode: this.masterObj.facility.pincode,
          country: Number(this.masterObj.facility.country),
          countryregion: this.masterObj.facility.countryregion,
          province: Number(this.masterObj.facility.province),
          city: Number(this.masterObj.facility.city),
        });

        this.fileName = this.masterObj.facility.layoutName;
        this.fileNameFromDb = this.masterObj.facility.facilityLayout;
        if (!!this.masterObj.facility.addProperty) {
          this.masterObj.facility.addProperty.map((form: any) => {
            this.formCollection.push(
              new FormGroup({
                key: new FormControl(form.key.split('_Add')[0], Validators.required),
                value: new FormControl(form.value, Validators.required),
                id: new FormControl(form.id, Validators.required),
                type: new FormControl(form.type, Validators.required),
              })
            );
            this.addedInfoFileType.push(form.type);
          });
        }
        this.getStates();
        this.getCities();
      } else {
        this.formCollection.push(
          new FormGroup({
            key: new FormControl('', Validators.required),
            value: new FormControl('', Validators.required),
            id: new FormControl('', Validators.required),
            type: new FormControl('', Validators.required),
          })
        );
      }

      this.facilityElement = fromEvent(this.facilityName.nativeElement, 'keyup');

      this.facilityElement.pipe(debounceTime(1000)).subscribe((e: any) => {
        this.nameCheck();
      });
    }

    addRow() {
      this.formCollection.push(
        new FormGroup({
          key: new FormControl('', Validators.required),
          value: new FormControl('', Validators.required),
          id: new FormControl('', Validators.required),
          type: new FormControl('', Validators.required),
        })
      );
    }

    deleteForm(i: any) {
      if (!!this.formCollection.controls[i].value.id) {
        this.service
          .deleteSpacePropertybySpacePropertyId(this.formCollection.controls[i].value.id)
          .subscribe((deleted: any) => {
            this.formCollection.removeAt(i);
          });
      } else {
        this.formCollection.removeAt(i);
      }
    }

    saveFacility() {
      const json = {
        organizationId: 1,
        parentSpaceId: 0,
        regionId: 3,
        spaceId: this.facilityDetailsForm.value.spaceId,
        spaceProperties: [
          {
            assetPropertyId: 0,
            propertyKey: 'string',
            propertyType: 'string',
            propertyValue: 'string',
          },
        ],
        spaceType: {
          spaceTypeId: 1, // Facility space type
        },
      };

      const spaceProrty: any = [];

      if (this.facilityDetailsForm.value.facilityName) {
        spaceProrty.push({
          propertyKey: 'name',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.facilityName,
        });
      }

      console.log('fac name', this.facilityDetailsForm.value);
      if (this.facilityDetailsForm.value.layoutName) {
        spaceProrty.push({
          propertyKey: 'layoutName',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.layoutName,
        });
      }

      if (this.facilityDetailsForm.value.facilityType) {
        spaceProrty.push({
          propertyKey: 'type',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.facilityType,
        });
      }

      if (this.facilityDetailsForm.value.facilityLocation) {
        spaceProrty.push({
          propertyKey: 'Location',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.facilityLocation,
        });
      }

      if (this.facilityDetailsForm.value.facilityCapacity) {
        spaceProrty.push({
          propertyKey: 'capacity',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.facilityCapacity,
        });
      }

      if (this.facilityDetailsForm.value.squareFeetArea) {
        spaceProrty.push({
          propertyKey: 'squareFeetArea',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.squareFeetArea,
        });
      }

      if (this.facilityDetailsForm.value.noOfBuildings) {
        spaceProrty.push({
          propertyKey: 'childSpaces',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.noOfBuildings,
        });
      }

      if (this.facilityDetailsForm.value.region) {
        spaceProrty.push({
          propertyKey: 'region',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.region,
        });
      }

      this.regions.map((region: any) => {
        if (region.globalRegionId === Number(this.facilityDetailsForm.value.region)) {
          spaceProrty.push({
            propertyKey: 'regionname',
            propertyType: 'string',
            propertyValue: region.name,
          });
        }
      });

      if (this.facilityDetailsForm.value.facilityLayout) {
        spaceProrty.push({
          propertyKey: 'facilityLayout',
          propertyType: 'file',
          propertyValue: this.facilityDetailsForm.value.facilityLayout,
        });
      }

      if (this.facilityDetailsForm.value.latlong) {
        spaceProrty.push({
          propertyKey: 'latlong',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.latlong,
        });
      }

      if (this.facilityDetailsForm.value.addressline1) {
        spaceProrty.push({
          propertyKey: 'addressline1',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.addressline1,
        });
      }

      if (this.facilityDetailsForm.value.addressline2) {
        spaceProrty.push({
          propertyKey: 'addressline2',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.addressline2,
        });
      }
      if (this.facilityDetailsForm.value.country) {
        spaceProrty.push({
          propertyKey: 'country',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.country,
        });
      }

      this.countries.map((country: any) => {
        if (country.countryId === Number(this.facilityDetailsForm.value.country)) {
          spaceProrty.push({
            propertyKey: 'countryname',
            propertyType: 'string',
            propertyValue: country.countryName,
          });
        }
      });
      if (this.facilityDetailsForm.value.province) {
        spaceProrty.push({
          propertyKey: 'province',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.province,
        });
      }
      if (this.facilityDetailsForm.value.city) {
        spaceProrty.push({
          propertyKey: 'city',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.city,
        });

        json.regionId = this.facilityDetailsForm.value.city;
      }

      this.cities.map((city: any) => {
        if (city.regionId === Number(this.facilityDetailsForm.value.city)) {
          spaceProrty.push({
            propertyKey: 'cityname',
            propertyType: 'string',
            propertyValue: city.name,
          });
        }
      });
      if (this.facilityDetailsForm.value.pincode) {
        spaceProrty.push({
          propertyKey: 'pincode',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.pincode,
        });
      }
      if (this.facilityDetailsForm.value.countryregion) {
        spaceProrty.push({
          propertyKey: 'countryregion',
          propertyType: 'string',
          propertyValue: this.facilityDetailsForm.value.countryregion,
        });
      }

      const formCollection: any = [];
      this.formCollection.controls.map((form: any) => {
        if (!!form.value.key) {
          formCollection.push(form.value);
          spaceProrty.push({
            spacePropertyId: form.value.id,
            propertyKey: form.value.key + '_Add',
            propertyType: form.value.type ? form.value.type : 'string',
            propertyValue: form.value.value,
          });
        }
      });
      console.log(this.formCollection.controls);
      this.facilityDetailsForm.value.addProperty = formCollection;

      console.log('facility json', json, spaceProrty);
      if (!!json.spaceId) {
        this.service.deleteSpaceProperty(json).subscribe((e: any) => {
          json.spaceProperties = spaceProrty;
          console.log('form ', this.facilityDetailsForm.value);
          this.service.saveSpaceDetails(json).subscribe(
            (facility: any) => {
              this.saveInfo.emit({
                type: 'facility',
                data: this.facilityDetailsForm.value,
              });
              this.next();
            },
            (err: any) => console.log(err)
          );
        });
      } else {
        json.spaceProperties = spaceProrty;
        this.service.saveSpaceDetails(json).subscribe(
          (facility: any) => {
            console.log(facility.spaceId);
            this.facilityId = facility.spaceId;

            this.facilityDetailsForm.patchValue({
              spaceId: facility.spaceId,
            });

            this.saveInfo.emit({
              type: 'facility',
              data: this.facilityDetailsForm.value,
            });
            this.next();
          },
          (err: any) => console.log(err)
        );
      }
    }

    next() {
      this.toggleComponent.emit('building');
    }

    nameCheck() {
      this.service.faciityNameCheck(this.facilityDetailsForm.value.facilityName).subscribe((name: any) => {
        if (!!name && name.exists && name.spaceId !== this.facilityDetailsForm.value.spaceId) {
          this.nameFlag = false;
        } else {
          this.nameFlag = true;
        }
      });
    }

    reset() {
      this.facilityDetailsForm.reset();

      const dialogRef = this.dialog.open(SuccessFacilityComponent, {
        width: '540px',
        panelClass: 'success-container',
        data: {
          message: `Facility ${this.masterObj.facility.facilityName}has been successfully added.`,
        },
      });

      dialogRef.afterClosed().subscribe((result) => {});
    }

    addedInfo() {
      this.formCollection.push(
        new FormGroup({
          key: new FormControl('', Validators.required),
          value: new FormControl('', Validators.required),
        })
      );
    }

    delete(index: any) {
      console.log('index to delete', index);
      const form = this.formCollection.at(index);
      this.formCollection.removeAt(index);
      console.log('form at index', form);
    }

    onFileChange(event: any) {
      const validExts = new Array('.png', '.jpg', '.jpeg');
      let fileExt = event.target.files[0].name;
      fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
      if (validExts.indexOf(fileExt.toLowerCase()) < 0) {
        this.fileError = 'Invalid file selected';
        return false;
      } else {
        this.fileError = '';
        this.fileName = event.target.files[0].name;

        this.facilityLayout = event.target.files[0];
        this.fileNameFromDb = undefined;

        return true;
      }

    }

    upload() {
      const fomrdata: any = new FormData();
      fomrdata.append('file', this.facilityLayout);
      this.service.uploadFacilityLayout(fomrdata).subscribe((layout: any) => {
        this.facilityDetailsForm.patchValue({
          facilityLayout: layout.imageName,
        });

        this.fileNameFromDb = layout.imageName;
        this.facilityDetailsForm.patchValue({
          layoutName: this.facilityLayout.name,
        });
        console.log(layout, this.facilityDetailsForm.value.layoutName);
      });
    }

    onLatLong(event: any) {
      // 12.90626, 80.228172;
      console.log('lat long value', event.target.value);
      this.latLong = event.target.value;
    }

    openMap() {
      const dialogRef = this.dialog.open(LocationComponent, {
        data: this.facilityDetailsForm.value.latlong,
      });

      dialogRef.afterClosed().subscribe((result) => {
        if (!!result) {
          this.facilityDetailsForm.patchValue({
            latlong: result.value.latlong,
          });
          this.service.getGeoAddress(result.value.latlong).subscribe((addresses: any) => {
            console.log('address ', addresses);
            this.facilityDetailsForm.patchValue({
              addressline1: addresses.results[0].address_components[0].long_name,
              addressline2: addresses.results[0].address_components[1].long_name,
            });
            let countryFromGoogle = '';
            addresses.results[0].address_components.map((address: any) => {
              if (address.types.indexOf('postal_code') >= 0) {
                this.facilityDetailsForm.patchValue({
                  pincode: address.long_name,
                });
              }
              if (address.types.indexOf('country') >= 0) {
                countryFromGoogle = address.long_name;
              }
              if (address.types.indexOf('administrative_area_level_1') >= 0) {
                this.stateFromGoogle = address.long_name;
              }
              if (address.types.indexOf('locality') >= 0) {
                this.cityFromGoogle = address.long_name;
              }
              console.log('city name ', this.cityFromGoogle);
            });

            this.countries.map((country: any) => {
              if (country.countryName.indexOf(countryFromGoogle) >= 0) {
                this.facilityDetailsForm.patchValue({
                  country: country.countryId,
                });
                this.getStates();
              }
            });
          });
        }
      });
    }

    getCountries() {
      this.service.getAllCountries().subscribe((resp) => {
        this.countries = resp;
      });
    }

    getStates() {
      this.service.getAllState(this.facilityDetailsForm.value.country).subscribe((states: any) => {
        this.states = [];
        states.map((state: any) => {
          const temp = {
            regionId: state.regionId,
            name: '',
          };

          state.regionProperties.map((property: any) => {
            if (property.propertyKey === 'Name') {
              temp.name = property.propertyValue;
              if (property.propertyValue.toLowerCase() === this.stateFromGoogle.toLowerCase()) {
                this.facilityDetailsForm.patchValue({
                  province: state.regionId,
                });
                this.getCities();
              }
            }
          });
          this.states.push(temp);
        });
      });
    }

    getCities() {
      if (this.facilityDetailsForm.value.province) {
        this.service.getAllCities(this.facilityDetailsForm.value.province).subscribe((states: any) => {
          this.cities = [];
          states.map((state: any) => {
            const temp = {
              regionId: state.regionId,
              name: '',
            };

            state.regionProperties.map((property: any) => {
              if (property.propertyKey === 'Name') {
                temp.name = property.propertyValue;
                if (property.propertyValue.toLowerCase() === this.cityFromGoogle.toLowerCase()) {
                  this.facilityDetailsForm.patchValue({
                    city: state.regionId,
                  });
                }
              }
            });
            this.cities.push(temp);
          });
        });
      }
    }

    getGlobalRegions() {
      this.service.getGlobalRegions().subscribe((resp) => {
        this.regions = resp;
        console.log('regions ', this.regions);
        let regionIndex = this.regions.findIndex(
          (element: any) => element.globalRegionId === this.facilityDetailsForm.value.region
        );
        if (regionIndex == -1) {
          this.facilityDetailsForm.patchValue({
            region: '',
          });
        }
      });
    }

    onKeyValueFileChange(event: any, index: number) {
      const fomrdata: any = new FormData();
      fomrdata.append('file', event.target.files[0]);
      this.service.uploadSpacePropertyAsFile(fomrdata).subscribe((fileparams: any) => {
        this.formCollection.controls[index].patchValue({
          value: fileparams.imageName,
          type: 'file',
        });
        this.addedInfoFileType[index] = 'file';
      });
    }

    downloadMoreInfoFile(index: any) {
      let filename: any;
      if (index !== -1) {
        filename = this.formCollection.controls[index].value.value;
      } else {
        filename = this.fileNameFromDb;
      }
      let fileType = '';
      if (
        filename.toLowerCase().includes('png') ||
        filename.toLowerCase().includes('jpg') ||
        filename.toLowerCase().includes('jpeg') ||
        filename.toLowerCase().includes('ico')
      ) {
        fileType = 'image/' + filename.split('.')[1];
      } else if (
        filename.toLowerCase().includes('xlsx') ||
        filename.toLowerCase().includes('csv') ||
        filename.toLowerCase().includes('ods')
      ) {
        fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
      } else if (filename.toLowerCase().includes('doc') || filename.toLowerCase().includes('docx')) {
        fileType = 'application/msword';
      } else if (filename.toLowerCase().includes('pdf')) {
        fileType = 'application/pdf';
      } else {
        fileType = `application/${filename.toLowerCase().split('.')[1]}`;
      }

      console.log(fileType);
      this.service.getUploadedSpacePropertyFile(filename).subscribe((resp: any) => {
        console.log('file resp ', resp);
        const blob = new Blob([resp], { type: fileType });
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);

        link.setAttribute('visibility', 'hidden');
        link.download = filename.split('.')[0];

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    }

    onValueChange(event: any, index: any) {
      this.addedInfoFileType[index] = '';
    }

    clear(value: any) {
      if (value === 'country') {
        this.facilityDetailsForm.patchValue({
          country: '',
          province: '',
          city: '',
        });
        this.states = [];
        this.cities = [];
      } else if (value === 'province') {
        this.facilityDetailsForm.patchValue({
          province: '',
          city: '',
        });
        this.cities = [];
      } else if (value === 'city') {
        this.facilityDetailsForm.patchValue({
          city: '',
        });
      } else if (value === 'facilityType') {
        this.facilityDetailsForm.patchValue({
          facilityType: '',
        });
      } else if (value === 'region') {
        this.facilityDetailsForm.patchValue({
          region: '',
        });
      }
    }
  }
