import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class FacilityService {
  baseUrl = "https://connectedstore-ingress-dev.eastus.cloudapp.azure.com/space-management"
  constructor(private http: HttpClient) { }

  getFacilityByRegion() {
    return this.http.get(this.baseUrl + `/space/parent/9`);
  }

  deleteSpace(spaceId: any) {
    return this.http.delete(this.baseUrl + `/space/${spaceId}`);
  }
  facilityBulkUpload(
    file: any,
    organizationId: number,
    parentSpaceId: number,
    spaceTypeId: number,
    regionId: number,
    child: number
  ) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(
      this.baseUrl +
        `/facility/bulk-upload?organizationId=${organizationId}&parentSpaceId=${parentSpaceId}&regionId=${regionId}&spaceTypeId=${spaceTypeId}`,
      file
    );
  }

  getFacilityTemplate() {
    return this.http.get(`${this.baseUrl}/bulk-upload/template/facility`, { responseType: 'blob' });
  }

  getSpaceDetailsBySpaceId(spaceId: number) {
    return this.http.get(this.baseUrl + `/space/${spaceId}`);
  }

  getFloorImage(spaveId: any) {
    return this.http.get(`${this.baseUrl}/space/zone/image/base64/${spaveId}`);
  }

  getFacilityTypes() {
    return this.http.get(this.baseUrl + `/space/type`);
  }

  deleteSpacePropertybySpacePropertyId(SpacePropertyId: any) {
    return this.http.delete(this.baseUrl + `/space/property/${SpacePropertyId}`);
  }

  deleteSpaceProperty(json: any) {
    return this.http.delete(this.baseUrl + `/space/property?spaceId=${json.spaceId}`);
  }

  saveSpaceDetails(json: any) {
    return this.http.post(this.baseUrl + '/space', json);
  }

  faciityNameCheck(name: string) {
    return this.http.get(this.baseUrl + `/space/property/check/${name}`);
  }

  uploadFacilityLayout(file: any) {
    return this.http.post(`${this.baseUrl}/space/property/file`, file);
  }

  getGeoAddress(latlong: string) {
    return this.http.get(`${this.baseUrl}/space/latlong/${latlong}`);
  }

  getAllCountries() {
    return this.http.get(`${this.baseUrl}/country`);
  }

  getAllState(countryId: number) {
    return this.http.get(`${this.baseUrl}/region/root/country/${countryId}`);
  }

  getAllCities(stateID: number) {
    return this.http.get(`${this.baseUrl}/region/parent/${stateID}`);
  }

  getGlobalRegions() {
    return this.http.get(`${this.baseUrl}/global-region`);
  }

  uploadSpacePropertyAsFile(file: any) {
    return this.http.post(`${this.baseUrl}/space/property/file`, file);
  }

  getUploadedSpacePropertyFile(filename: any) {
    return this.http.get(`${this.baseUrl}/space/property/file?fileName=${filename}`, { responseType: 'blob' });
  }

  getSpaceDetailsByParentSpace(parentSpaceId: number) {
    return this.http.get(this.baseUrl + `/space/parent/${parentSpaceId}`);
  }

  getBuildingTemplate() {
    return this.http.get(`${this.baseUrl}/bulk-upload/template/building`, { responseType: 'blob' });
  }

  buildingBulkUpload(
    file: any,
    organizationId: number,
    parentSpaceId: number,
    spaceTypeId: number,
    regionId: number,
    child: number
  ) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(
      this.baseUrl +
        `/building/bulk-upload?organizationId=${organizationId}&parentSpaceId=${parentSpaceId}&regionId=${regionId}&spaceTypeId=${spaceTypeId}`,
      file
    );
  }

  uploadFloorLayout(file: any, spaceId: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.baseUrl}/space/zone/image/${spaceId}`, file);
  }

  getFloorTemplate() {
    return this.http.get(`${this.baseUrl}/bulk-upload/template/floor`, { responseType: 'blob' });
  }

  floorBulkUpload(
    file: any,
    organizationId: number,
    parentSpaceId: number,
    spaceTypeId: number,
    regionId: number,
    child: number
  ) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(
      this.baseUrl +
        `/floor/bulk-upload?organizationId=${organizationId}&parentSpaceId=${parentSpaceId}&regionId=${regionId}&spaceTypeId=${spaceTypeId}`,
      file
    );
  }
}
