import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeModule } from './home/home.module';
import { PhysicalDistanceRoutingModule } from './physical-distance-view/physical-distance.routing.module';
import { HandHygieneRoutingModule } from './hand-hygiene-view/hand-hygiene.routing.module';
import { HomeRoutingModule } from './home/home-routing.module';
import { HraccessViewModule } from './hraccess-view/hraccess-view.module';
import { EmployeeModule } from './employee/employee.module';
import { SecurityModule } from './security/security.module';
import { SwitchToLandscapePopupComponent } from './switch-to-landscape-popup/switch-to-landscape-popup.component';

@NgModule({
  declarations: [SwitchToLandscapePopupComponent],
  imports: [
    CommonModule,
    HomeModule,
    HomeRoutingModule,
    PhysicalDistanceRoutingModule,
    HandHygieneRoutingModule,
    HraccessViewModule,
    EmployeeModule,
    SecurityModule
  ]
})
export class SafeBuildingModule { }
