import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacilityService } from '../facility.service';

@Component({
  selector: 'app-building-view',
  templateUrl: './building-view.component.html',
  styleUrls: ['./building-view.component.scss']
})
export class BuildingViewComponent implements OnInit {

  buildingDetailsArray = new FormGroup({
    spaceId: new FormControl(''),
    buildingName: new FormControl('', Validators.required),
    squareFeetArea: new FormControl('', Validators.required),
    buildingCapacity: new FormControl('', Validators.required),
    childSpaces: new FormControl(''),
    latLong: new FormControl(''),
  });

  addNew = 'new';

  latitude = 0;
  longitude = 0;
  zoom = 12; // google maps zoom level

  constructor(
    public dialogRef: MatDialogRef<BuildingViewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: FacilityService
  ) {}

  ngOnInit(): void {
    // this.dialogRef.disableClose = true;
    // if (!!this.data) {
    //   this.buildingDetailsArray.patchValue({
    //     spaceId: this.data['spaceId'],
    //     buildingName: this.data['buildingName'],
    //     squareFeetArea: this.data['capetArea'],
    //     buildingCapacity: this.data['seatingCapacity'],
    //     childSpaces: this.data['childSpaces'],
    //   });
    // }

    // if (!!this.data.latLong) {
    //   this.buildingDetailsArray.patchValue({
    //     latLong: this.data['latLong'],
    //   });
    //   this.latitude = Number(this.data['latLong'].split(',')[0]);
    //   this.longitude = Number(this.data['latLong'].split(',')[1]);
    // }
    this.service.getSpaceDetailsBySpaceId(this.data.spaceId).subscribe((building: any) => {
      building.spaceProperties.map((property: any) => {
        if (property.propertyKey === 'name') {
          this.buildingDetailsArray.patchValue({
            buildingName: property.propertyValue,
          });
        }
        if (property.propertyKey === 'squareFeetArea') {
          this.buildingDetailsArray.patchValue({
            squareFeetArea: property.propertyValue,
          });
        }
        if (property.propertyKey === 'capacity') {
          this.buildingDetailsArray.patchValue({
            buildingCapacity: property.propertyValue,
          });
        }
        if (property.propertyKey === 'latitudeLongitude') {
          this.latitude = Number(property.propertyValue.split(',')[0]);
          this.longitude = Number(property.propertyValue.split(',')[1]);

          this.buildingDetailsArray.patchValue({
            latLong: property.propertyValue,
          });
        }
      });
    });
  }

  placeMarker(event: any) {
    console.log('marker position', event);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.buildingDetailsArray.patchValue({
      latLong: event.coords.lat + ',' + event.coords.lng,
    });

    console.log('form', this.buildingDetailsArray.value);
  }

  getimagename() {
    return '/assets/Group 1009.png';
  }

  selectedLocation() {
    this.dialogRef.close();
  }

  addNewBuilding() {
    this.dialogRef.close();
  }




}
