import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeQaComponent } from './employee-qa/employee-qa.component';
import { EmployeeQrcodeComponent } from './employee-qrcode/employee-qrcode.component';
import { EmployeeTableComponent } from './employee-table/employee-table.component';
import { EmployeeViewComponent } from './employee-view/employee-view.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/material.module';
import { QRCodeModule } from 'angularx-qrcode';
import { EmployeeRoutingModule } from './employee-routing.module';
import { EmployeeService } from './service/employee.service';


@NgModule({
  declarations: [EmployeeQaComponent, EmployeeQrcodeComponent, EmployeeTableComponent, EmployeeViewComponent],
  imports: [
    CommonModule,
    MaterialModule, EmployeeRoutingModule, QRCodeModule, ReactiveFormsModule
  ],
  providers: [EmployeeService],
})
export class EmployeeModule { }
