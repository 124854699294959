import { Component, OnInit, ViewChild } from '@angular/core';
import { NonCompliantAssociateComponent } from '../non-compliant-associate/non-compliant-associate.component';
import { environment } from '@env/environment';
import { globalConstant } from '../../../safe-building/safebuilding-global-constants';
import { AppService } from '../../../app.service';
import { QuarantineTrackerComponent } from '../quarantine-tracker/quarantine-tracker.component';
import { ModifyAssociateDetailsComponent } from '../modify-associate-details/modify-associate-details.component';
import { FormListComponent } from '../questionnaire/form-list/form-list.component';
import { QuarantineService } from '../quarantine.service';

@Component({
  selector: 'app-hr-view',
  templateUrl: './hr-view.component.html',
  styleUrls: ['./hr-view.component.scss']
})
export class HrViewComponent implements OnInit {

  @ViewChild(QuarantineTrackerComponent) private QuarantineComponent: QuarantineTrackerComponent;
  @ViewChild(ModifyAssociateDetailsComponent) private ModifyAssociateDetails: ModifyAssociateDetailsComponent;
  @ViewChild(FormListComponent) private FormListComponent: FormListComponent;
  @ViewChild(NonCompliantAssociateComponent) private nonCompliant: NonCompliantAssociateComponent;
  //@ViewChild(SurveyComponent) private surveyComponent: SurveyComponent;
  TS: boolean = environment.TS_enable;
  PD: boolean = environment.PD_enable;
  HH: boolean = environment.HH_enable;
  AQ: boolean = environment.AQ_enable;
  PY: boolean = environment.PY_enable;
  timeZone: string = globalConstant.timeZone;
  HQ_Enable: boolean = environment.HQ_enable;
  showQuarantine: boolean = false;
  showForms: boolean = false;
  selectedTab: number = 0;
  isViewLoaded: boolean;
  hrSelectedTabIndex:any = 0;
  mad : boolean = false;
  dash : boolean = false;
  qt : boolean = false;
  forms : boolean = false;
  menu : boolean = true;
  display : boolean;
  subscription;

  constructor(private appservice: AppService , private service: QuarantineService) { }
  ngOnDestroy(): void {
    if (this.TS == true) {
      this.nonCompliant.pollingData_TS.unsubscribe();
    }
    if (this.PD == true) {
      this.nonCompliant.pollingData_PD.unsubscribe();
    }
    if (this.HH == true) {
      this.nonCompliant.pollingData_HH.unsubscribe();
    }
    if (this.AQ == true) {
      this.nonCompliant.pollingData_AQ.unsubscribe();
    }
    if (this.PY == true) {
      this.nonCompliant.pollingData_PY.unsubscribe();
    }
    this.subscription.unsubscribe();
  }
  tabClick(tab: any) {
    console.log(tab)
    if (tab.index == 0) {
      //this.QuarantineComponent.getQuarantineData();//Or whatever name the method is called
      this.selectedTab = 0;
      this.nonCompliant.getFacility();
      if (this.PD == true) {
        this.nonCompliant.getPhysicalData();
      }
      if (this.HH == true) {
        this.nonCompliant.getHygieneData();
      }
      if (this.TS == true) {
        this.nonCompliant.getThermalData();
      }
      
    } else if (tab.index == 2) {
      if (this.TS == true) {
        this.nonCompliant.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.nonCompliant.pollingData_PD.unsubscribe();
      }
      if (this.HH == true) {
        this.nonCompliant.pollingData_HH.unsubscribe();
      }
      if (this.AQ == true) {
        this.nonCompliant.pollingData_AQ.unsubscribe();
      }
      if (this.PY == true) {
        this.nonCompliant.pollingData_PY.unsubscribe();
      }
      this.showQuarantine = true;
      this.selectedTab = 2;
      if (this.QuarantineComponent != undefined) {
        this.QuarantineComponent.pageExpand = false;
        this.QuarantineComponent.durationSelected = '0';
        this.QuarantineComponent.getExposedData(this.timeZone);
        this.QuarantineComponent.getQuarantineData(this.timeZone);
      }
    } else if (tab.index == 1) {
      if (this.TS == true) {
        this.nonCompliant.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.nonCompliant.pollingData_PD.unsubscribe();
      }
      if (this.HH == true) {
        this.nonCompliant.pollingData_HH.unsubscribe();
      }
      if (this.AQ == true) {
        this.nonCompliant.pollingData_AQ.unsubscribe();
      }
      if (this.PY == true) {
        this.nonCompliant.pollingData_PY.unsubscribe();
      }
      this.ModifyAssociateDetails.showresult = false;
      this.ModifyAssociateDetails.searchedPerson = '';
      this.ModifyAssociateDetails.displayResult = '';
      this.ModifyAssociateDetails.searchLength = null;
      this.ModifyAssociateDetails.resultList = null;
      this.ModifyAssociateDetails.noResult = false;
      this.selectedTab = 1;
    } else if (tab.index == 3) {
      if (this.TS == true) {
        this.nonCompliant.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.nonCompliant.pollingData_PD.unsubscribe();
      }
      if (this.HH == true) {
        this.nonCompliant.pollingData_HH.unsubscribe();
      }
      if (this.AQ == true) {
        this.nonCompliant.pollingData_AQ.unsubscribe();
      }
      if (this.PY == true) {
        this.nonCompliant.pollingData_PY.unsubscribe();
      }
      this.showForms = true;
      if (this.FormListComponent != undefined) {
        this.FormListComponent.applyFilters = false;
        this.FormListComponent.countryFilters = false;
      }
      this.selectedTab = 3;
    }
  }

  ngOnInit(): void {
    this.appservice.currentTheme.subscribe((theme) => {
      if (this.isViewLoaded && this.selectedTab == 0) {
        location.reload();
      }
    });
    this.isViewLoaded = true;

    this.subscription = this.service.getShow().subscribe((val)=>{
      this.display =val;
    })
  }

   // Method to get the active tab
   ngAfterContentChecked() {
    let selectedTab:any =localStorage.getItem('subHeaderText');
    if(selectedTab == 'Dashboard') {
      this.hrSelectedTabIndex = 0;
      this.menu = false;
      this.dash=true;
      this.mad=false;
      this.qt=false;
      this.forms = false;
    } 
    else if(selectedTab == 'Modify Associate Details') {
      this.hrSelectedTabIndex = 1;
      this.dash=false
      this.mad=true;
      this.qt=false;
      this.forms = false;
      this.menu = false;
    }
    else if(selectedTab == 'Quarantine Tracker') {
      this.hrSelectedTabIndex = 2;
      this.dash=false
      this.mad=false;
      this.qt=true;
      this.forms = false;
      this.menu = false;
    }
    else if(selectedTab == 'Forms') {
      this.hrSelectedTabIndex = 3;
      this.dash=false
      this.mad=false;
      this.qt=false;
      this.forms = true;
      this.menu = false;
    }
    else if(selectedTab == 'Forms') {
      this.dash=false
      this.mad=false;
      this.qt=false;
      this.forms = false;
      this.menu = true;
    }
  }

  //For mobile view navigation
  onClick(index : any){
    console.log(index)
    if (index == 'dash') {
      localStorage.setItem('subHeaderText', 'Dashboard');
      this.dash=true;
      this.mad=false;
      this.qt=false;
      this.forms = false;
      this.menu = false;
      //this.QuarantineComponent.getQuarantineData();//Or whatever name the method is called
      this.selectedTab = 0;
      this.nonCompliant.getFacility();
      if (this.PD == true) {
        this.nonCompliant.getPhysicalData();
      }
      if (this.HH == true) {
        this.nonCompliant.getHygieneData();
      }
      if (this.TS == true) {
        this.nonCompliant.getThermalData();
      }
      
    } else if (index == 'qt') {
      localStorage.setItem('subHeaderText', 'Quarantine Tracker');
      this.dash=false
      this.mad=false;
      this.qt=true;
      this.forms = false;
      this.menu = false;
      if (this.TS == true) {
        this.nonCompliant.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.nonCompliant.pollingData_PD.unsubscribe();
      }
      if (this.HH == true) {
        this.nonCompliant.pollingData_HH.unsubscribe();
      }
      if (this.AQ == true) {
        this.nonCompliant.pollingData_AQ.unsubscribe();
      }
      if (this.PY == true) {
        this.nonCompliant.pollingData_PY.unsubscribe();
      }
      this.showQuarantine = true;
      this.selectedTab = 2;
      if (this.QuarantineComponent != undefined) {
        this.QuarantineComponent.pageExpand = false;
        this.QuarantineComponent.durationSelected = '0';
        this.QuarantineComponent.getExposedData(this.timeZone);
        this.QuarantineComponent.getQuarantineData(this.timeZone);
      }
    } else if (index == 'mad') {
      localStorage.setItem('subHeaderText', 'Modify Associate Details');
      this.dash=false
      this.mad=true;
      this.qt=false;
      this.forms = false;
      this.menu = false;
      if (this.TS == true) {
        this.nonCompliant.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.nonCompliant.pollingData_PD.unsubscribe();
      }
      if (this.HH == true) {
        this.nonCompliant.pollingData_HH.unsubscribe();
      }
      if (this.AQ == true) {
        this.nonCompliant.pollingData_AQ.unsubscribe();
      }
      if (this.PY == true) {
        this.nonCompliant.pollingData_PY.unsubscribe();
      }
      this.ModifyAssociateDetails.showresult = false;
      this.ModifyAssociateDetails.searchedPerson = '';
      this.ModifyAssociateDetails.displayResult = '';
      this.ModifyAssociateDetails.searchLength = null;
      this.ModifyAssociateDetails.resultList = null;
      this.ModifyAssociateDetails.noResult = false;
      this.selectedTab = 1;
    } else if (index == 'form') {
      localStorage.setItem('subHeaderText', 'Forms');
      this.dash=false
      this.mad=false;
      this.qt=false;
      this.forms = true;
      this.menu = false;
      if (this.TS == true) {
        this.nonCompliant.pollingData_TS.unsubscribe();
      }
      if (this.PD == true) {
        this.nonCompliant.pollingData_PD.unsubscribe();
      }
      if (this.HH == true) {
        this.nonCompliant.pollingData_HH.unsubscribe();
      }
      if (this.AQ == true) {
        this.nonCompliant.pollingData_AQ.unsubscribe();
      }
      if (this.PY == true) {
        this.nonCompliant.pollingData_PY.unsubscribe();
      }
      this.showForms = true;
      if (this.FormListComponent != undefined) {
        this.FormListComponent.applyFilters = false;
        this.FormListComponent.countryFilters = false;
      }
      this.selectedTab = 3;
    }
  }
  back(){
    localStorage.setItem('subHeaderText', 'HRback');
    this.dash=false
    this.mad=false;
    this.qt=false;
    this.forms = false;
    this.menu = true;
  }
 
}
