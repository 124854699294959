import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { globalConstant } from '../../../safebuilding-global-constants';
import { environment } from '../../../../../environments/environment';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QuarantineService } from '../../quarantine.service';
import * as moment from 'moment';
@Component({
  selector: 'app-publish-form',
  templateUrl: './publish-form.component.html',
  styleUrls: ['./publish-form.component.scss']
})
export class PublishFormComponent implements OnInit {
  today: Date;
  selectedStartDate = 'Select date';
  surveyData: any;
  startDate: any;
  endDate: any;
  actionType: any;
  isDisablePublishButton: boolean = true;
  isDuplicate: boolean = false;
  isSameDate: boolean = false;
  isLiveSurveyExists: boolean = false;
  liveSurvey: any = [];
  notPublishedData: any = [];
  dateConflictSurvey: any = [];
  prevSurveyDateChangeArr: any = [];
  conflictSurveyObj: any = {};
  validLiveSurvey: any;
  liveConflictObj: any;
  nextDay: Date;
  sameDateExist: boolean = false;
  conflictSurveyIdArr: any = [];
  timeZone: string = globalConstant.timeZone;
  @Output() toggleComponent = new EventEmitter();
  publishDetails = new FormGroup({
    surveryFrequency: new FormControl('', Validators.required),
    includeWeekend: new FormControl(''),
  });
  isLightTheme: boolean = environment.isLightTheme;
  disableToday: boolean;
  constructor(
    public service: QuarantineService,
    public dialogRef: MatDialogRef<PublishFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    dialogRef.disableClose = true;
  }

  ngOnInit(): void {
    this.today = new Date();
    this.nextDay = new Date();
    this.nextDay.setDate(this.nextDay.getDate() + 1);
    console.log('RAM date ', this.today, this.nextDay);
    this.surveyData = this.data.surveyData;
    this.actionType = this.data.actionType;
    console.log(this.isLightTheme);
    this.startDate = this.actionType === 'edit' ? this.surveyData.startDate : '';
    this.endDate = this.actionType === 'edit' ? this.surveyData.endDate : '';
    //this.isLiveSurveyExists = this.data.liveSurveyExists
    this.liveSurvey = this.data.liveSurvey;
    //this.notPublishedData = this.data.notPublishedData;
    console.log('RAM live survey');
    console.log(this.liveSurvey);
    //console.log(this.notPublishedData)
    console.log(this.startDate, this.endDate);
    console.log(moment().utc().startOf('day'));

    if (this.data.liveSurveyExists) {
      for (let i = 0; i < this.data.liveSurvey.length; i++) {
        if (this.data.liveSurvey[i].locationTypeId === this.data.surveyData.locationType) {
          this.disableToday = true;
        } else {
          this.disableToday = false;
        }
      }
    }
    if (this.startDate && this.endDate && this.actionType === 'edit') {
      this.selectedStartDate =
        moment(this.startDate).format('DD MMM YYYY') + ' - ' + moment(this.endDate).format('DD MMM YYYY');
      this.isDisablePublishButton = false;
    }
    this.checkLiveSurveyDateRange(this.liveSurvey, this.startDate, this.endDate);
  }
  onStartDate(e: any) {
    this.isSameDate = false;
    this.startDate = e[0];
    this.endDate = e[1];
    console.log(e[0], e[1]);
    let dateOne = moment(this.startDate).format('YYYY-MM-DD');
    let dateTwo = moment(this.endDate).format('YYYY-MM-DD');
    console.log(dateOne, dateTwo);
    if (new Date(dateOne).getTime() === new Date(dateTwo).getTime()) {
      this.isSameDate = true;
    } else {
      this.isSameDate = false;
      this.selectedStartDate =
        moment(this.startDate).format('DD MMM YYYY') + ' - ' + moment(this.endDate).format('DD MMM YYYY');
      this.isDisablePublishButton = false;
      this.checkLiveSurveyDateRange(this.liveSurvey, this.startDate, this.endDate);
    }
  }
  publishLater() {
    this.surveyData = this.data.surveyData;
    if (this.surveyData) {
      this.surveyData.status = 'Not Published';
      this.service.postMasterHealthSurvey(this.surveyData).subscribe(
        (response: any) => {
          console.log(response);
          this.closePublishForm('publishlater');
        },
        (err: any) => {
          console.log(err);
          if (err.error.toLocaleLowerCase().includes('same name or id')) {
            this.isDuplicate = true;
          }
          this.closePublishForm('close');
        }
      );
      console.log(JSON.stringify(this.surveyData));
    } else {
      console.log('Invalid data');
    }
  }
  savePublish() {
    this.surveyData = this.data.surveyData;
    if (this.selectedStartDate && this.selectedStartDate != 'Select date' && this.surveyData) {
      console.log(JSON.stringify(this.surveyData));
      console.log(this.startDate, moment(this.startDate).format('YYYY-MM-DD HH:mm:ss'));
      console.log(this.endDate, moment(this.endDate).format('YYYY-MM-DD HH:mm:ss'));
      this.surveyData.startDate = moment(this.startDate).format('YYYY-MM-DD 00:00:00');
      this.surveyData.endDate = moment(this.endDate).format('YYYY-MM-DD 23:59:59');
      let compareStartDate = moment(this.startDate).format('YYYY-MM-DD');
      let compareEndDate = moment(this.endDate).format('YYYY-MM-DD');
      let compareToday = moment(this.today).format('YYYY-MM-DD');
      let prevSurveyEndDate = this.startDate;
      if (new Date(compareToday) < new Date(compareStartDate)) {
        this.surveyData.status = 'Published';
      } else {
        if (this.disableToday) {
          //this.data.liveSurveyExists
          this.surveyData.status = 'Published';
        } else {
          this.surveyData.status = 'Live';
          this.surveyData.startDate = moment(this.startDate).format('YYYY-MM-DD HH:mm:ss');
        }
      }
      if (this.isLiveSurveyExists) {
        console.log('RAM valid live survey ', prevSurveyEndDate);
        prevSurveyEndDate.setDate(prevSurveyEndDate.getDate() - 1);
        console.log(prevSurveyEndDate);
        console.log(this.validLiveSurvey.masterSurveyQuestions);
        this.validLiveSurvey.endDate = moment(prevSurveyEndDate).format('YYYY-MM-DD 23:59:59');
        this.validLiveSurvey.MasterSurveyQuestionsPostReq = this.validLiveSurvey.masterSurveyQuestions;
        this.validLiveSurvey.timeZone = this.timeZone;
        this.validLiveSurvey.locationType = this.validLiveSurvey.locationTypeId;
        delete this.validLiveSurvey.masterSurveyQuestions;
        console.log(this.validLiveSurvey);
        this.service.postMasterHealthSurvey(this.validLiveSurvey).subscribe(
          (response: any) => {
            console.log(response);
          },
          (err: any) => {
            console.log(err);
          }
        );
      }
      if (this.conflictSurveyIdArr.length > 0) {
        console.log('RAM duplicate date survey ', prevSurveyEndDate);
        this.conflictSurveyIdArr.forEach((surId: any) => {
          console.log('RAM sur arr ', surId);
          let prevPostJson = this.data.notPublishedData.find((data: any) => data.surveyId === surId);
          prevPostJson.startDate = null;
          prevPostJson.endDate = null;
          prevPostJson.status = 'Not Published';
          prevPostJson.timeZone = this.timeZone;
          prevPostJson.locationType = prevPostJson.locationTypeId;
          prevPostJson.locationId = prevPostJson.locationId;
          prevPostJson.MasterSurveyQuestionsPostReq = prevPostJson.masterSurveyQuestions;
          delete prevPostJson.masterSurveyQuestions;
          this.service.postMasterHealthSurvey(prevPostJson).subscribe(
            (response: any) => {
              console.log(response);
            },
            (err: any) => {
              console.log(err);
            }
          );
          console.log(prevPostJson);
        });
      }

      this.service.postMasterHealthSurvey(this.surveyData).subscribe(
        (response: any) => {
          console.log(response);
          this.closePublishForm('publish');
        },
        (err: any) => {
          console.log(err);
          if (err.error.toLocaleLowerCase().includes('same name or id')) {
            this.isDuplicate = true;
          }
          this.closePublishForm('close');
        }
      );
      console.log(JSON.stringify(this.surveyData));
    } else {
      console.log('Invalid data');
    }
  }
  closePublishForm(action: any): void {
    this.dialogRef.close({ data: { action: action, isDuplicate: this.isDuplicate } });
  }
  checkLiveSurveyDateRange(liveSurvey: any, selectedStartDate: any, selectedEndDate: any) {
    console.log('RAM Live Survey');
    console.log(liveSurvey);
    console.log(selectedStartDate, ' => ', selectedEndDate);
    if (liveSurvey.length > 0) {
      let liveSurveyData = liveSurvey[0];
      this.validLiveSurvey = liveSurvey[0];
      this.liveConflictObj = {
        formTitle: liveSurveyData.formTitle,
        startDate: liveSurveyData.startDate,
        endDate: liveSurveyData.endDate,
      };
      let liveSurveyEndDate = moment(liveSurveyData.endDate).format('YYYY-MM-DD');
      let newSurveyStartDate = moment(selectedStartDate).format('YYYY-MM-DD');
      this.conflictSurveyIdArr = [];
      if (selectedStartDate && new Date(liveSurveyEndDate) >= new Date(newSurveyStartDate)) {
        console.log('RAM coming live survey exist condition ', liveSurveyEndDate, newSurveyStartDate);
        this.isLiveSurveyExists = true;
      } else {
        console.log('RAM coming to not published survey data ', liveSurveyEndDate, newSurveyStartDate);
        this.isLiveSurveyExists = false;
        this.checkDateRange(selectedStartDate, selectedEndDate);
      }
    } else {
      this.checkDateRange(selectedStartDate, selectedEndDate);
    }
  }
  checkDateRange(selectedStartDate: any, selectedEndDate: any) {
    console.log('RAM date range not published');
    console.log(this.surveyData);
    console.log(this.data.notPublishedData);
    this.dateConflictSurvey = [];
    let newStartDate = moment(selectedStartDate).format('YYYY-MM-DD');
    let newEndDate = moment(selectedEndDate).format('YYYY-MM-DD');
    let oldStartDate = '';
    let oldEndDate = '';
    this.isLiveSurveyExists = false;
    //this.notPublishedData.forEach((survey: any) => {

    for (let i = 0; i < this.data.notPublishedData.length; i++) {
      let survey = this.data.notPublishedData[i];

      if (survey.startDate && survey.endDate) {
        oldStartDate = moment(survey.startDate).format('YYYY-MM-DD');
        oldEndDate = moment(survey.endDate).format('YYYY-MM-DD');
        if (
          (new Date(newStartDate) >= new Date(oldStartDate) && new Date(newStartDate) <= new Date(oldEndDate)) ||
          (new Date(newEndDate) >= new Date(oldStartDate) && new Date(newEndDate) <= new Date(oldEndDate))
        ) {
          console.log(
            'Matched => StDt :' +
              newStartDate +
              ', EdDt :' +
              newEndDate +
              ' is between StDt: ' +
              oldStartDate +
              ' and ' +
              oldEndDate +
              ' of suvey id ' +
              survey.surveyId
          );
          this.dateConflictSurvey = survey;
          this.conflictSurveyIdArr.push(survey.surveyId);
        } /*else if (new Date(newStartDate) < new Date(oldStartDate) && new Date(newEndDate) > new Date(oldEndDate)) {
          console.log('RAM coming to equl survey')
          this.dateConflictSurvey = survey;
          this.conflictSurveyIdArr.push(survey.surveyId)
        }*/ else {
          console.log(
            'Not Matched => StDt :' +
              newStartDate +
              ', EdDt :' +
              newEndDate +
              ' is not between StDt: ' +
              oldStartDate +
              ' and ' +
              oldEndDate +
              ' of suvey id ' +
              survey.surveyId
          );
        }
      }
    }
    this.sameDateExist = false;
    this.conflictSurveyObj = {};
    console.log('RAM this conflict survey arr ', this.conflictSurveyIdArr, this.conflictSurveyIdArr.length);
    if (this.dateConflictSurvey && this.dateConflictSurvey.startDate) {
      this.conflictSurveyObj = {
        formTitle: this.dateConflictSurvey.formTitle,
        startDate: this.dateConflictSurvey.startDate,
        endDate: this.dateConflictSurvey.endDate,
      };
    }
  }

}
