<div class="root">
  <div class="title">
    <div>Access Denied List</div>
    <div>
      <img *ngIf="!isLightTheme" (click)="close()" src="assets/Group 99.png" alt="" />
      <img *ngIf="isLightTheme" (click)="close()" src="assets/Light-Theme/Group 99.png" alt="" />
    </div>
  </div>
  <div class="seperator-space"></div>
  <mat-divider></mat-divider>

  <mat-table [dataSource]="dataSource" class="formlisttable">
    <ng-container matColumnDef="employeeName">
      <mat-header-cell *matHeaderCellDef class="col-4">Employee Name</mat-header-cell>
      <mat-cell *matCellDef="let element" class="col-4">{{ element.name }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="employeeId">
      <mat-header-cell *matHeaderCellDef class="col-2">Employee Id</mat-header-cell>
      <mat-cell *matCellDef="let element" class="col-2">{{ element.employeeId }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="emailId">
      <mat-header-cell *matHeaderCellDef class="col-4">Email ID</mat-header-cell>
      <mat-cell *matCellDef="let element" class="col-4">{{ element.email }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="deniedDate">
      <mat-header-cell *matHeaderCellDef class="col-2">Date & Time</mat-header-cell>
      <mat-cell *matCellDef="let element" class="col-2">{{ element.accessDeniedDate | date: 'dd MMM  hh:mm a' }}
      </mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
  <!--<div class="start-btn">
    <button (click)="surveyForm()" mat-button>Start Survey</button>
  </div>-->
</div>