<mat-tab-group>
    <mat-tab label="ORGANIZATION CONFIGURATION">
      <app-organization-config></app-organization-config>
    </mat-tab>
    <mat-tab label="FACILITY ONBOARDING">
      <!-- <ng-template matTabContent> -->
        <app-facility-wrapper></app-facility-wrapper>
      <!-- </ng-template> -->
    </mat-tab>
    <mat-tab label="ASSET ONBOARDING">
      <app-asset-list *ngIf="this.isViewable" (addNewAsset)="onAddNewAsset()"></app-asset-list>
     <app-add-asset *ngIf="!this.isViewable" (assetListView)="onAssetList()"></app-add-asset>
    </mat-tab>
  </mat-tab-group>
