import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuarantineService {
// baseUrl = environment.CT_baseURL + 'ContactTracing/?';
  // baseUrl1 = environment.CT_baseURL + 'Employee/?';
  // baseUrl2 = environment.CT_baseURL;
  // physicalUrl = environment.PD_baseURL + 'PhysicalDistanceCompliance/';
  // hygieneUrl = environment.HH_baseURL + 'HygieneCompliance/';
  // basePersonalDetail: string = environment.CT_baseURL + 'ebtd/employee/';

  CT_baseUrl = environment.CT_baseURL + 'ContactTracing/?';
  // Should be replaced with base URL of employee end point
  CM_baseUrl = environment.CT_baseURL + 'Employee/?';
  TS_baseUrl = environment.TS_baseURL + 'ebtd/';
  PD_baseUrl = environment.PD_baseURL + 'PhysicalDistanceCompliance/';
  HH_baseUrl = environment.HH_baseURL + 'HygieneCompliance/';

  private show = new BehaviorSubject<boolean>(true);

  constructor(private http: HttpClient) {}

  setShow(boolean){
    this.show.next(boolean);
  }
  getShow(){
    return this.show.asObservable();
  }

  getSearchresult(name: string) {
    return this.http.get(this.CM_baseUrl + `Name=${name}&OrderBy=ASC&SortBy=Name&Limit=100`);
  }

  getSearchById(id: any) {
    return this.http.get(this.CM_baseUrl + `EmployeeId=${id}&OrderBy=ASC&SortBy=Name&Limit=100`);
  }

  getSelectedPerson(id: number, timeZone: string) {
    return this.http.get(this.CT_baseUrl + `PersonId=${id}&timeZone=${timeZone}`);
  }

  getPerson(id: number, duration: any, timeZone: string, startDate: any, endDate: any) {
    return this.http.get(
      this.CT_baseUrl +
        `PersonId=${id}&ContactLevel=2&DurationOfFilter=${duration}&timeZone=${timeZone}&startDate=${startDate}&endDate=${endDate}`
    );
  }

  getQuarantineData(status: any, duration: any, timeZone: string) {
    return this.http.get(this.CT_baseUrl + `Status=${status}&DurationOfFilter=${duration}&timeZone=${timeZone}`);
  }

  modifyAssociate(obj: any) {
    return this.http.post(this.CT_baseUrl, obj);
  }

  getEmployeeTemperatureById(id: number, timeZone: string) {
    return this.http.get(this.TS_baseUrl + 'temperature-data/latest/employee/' + id);
  }

  getPhysicalComplianceDurationById(id: number, timeZone: string) {
    return this.http.get(this.PD_baseUrl + `Latest?PersonId=${id}&timeZone=${timeZone}`);
  }
  getHygieneComplianceDurationById(id: number, timeZone: string) {
    return this.http.get(this.HH_baseUrl + `Latest?PersonId=${id}&timeZone=${timeZone}`);
  }

  getEmployeeDetailsById(id: number, timeZone: string) {
    return this.http.get(this.CM_baseUrl + `EmployeeId=${id}`);
  }
  getPersonalDetailById(id: number) {
    return this.http.get(this.CM_baseUrl + `EmployeeId=${id}`);
  }
  getMasterHealthSurvey(timeZone: string, id: any) {
    return this.http.get(
      environment.HQ_baseURL + `MasterHealthSurvey/?SortBy=StartDate&OrderBy=DESC&timeZone=${timeZone}&employeeId=${id}`
    );
  }
  postMasterHealthSurvey(obj: any) {
    return this.http.post(environment.HQ_baseURL + `MasterHealthSurvey/`, obj);
  }
  deleteMasterHealthSurvery(surveyId: any, timeZone: any) {
    return this.http.delete(environment.HQ_baseURL + `MasterHealthSurvey/?surveyId=${surveyId}&timeZone=${timeZone}`);
  }
  deleteMasterHealthSurveryQuestion(surveyId: any, questionId: any) {
    return this.http.delete(
      environment.HQ_baseURL + `MasterHealthSurvey/DeleteQuestion/?surveyId=${surveyId}&questionId=${questionId}`
    );
  }
  withdrawLiveSurvey(obj: any) {
    console.log(obj);
    //return obj;
    return this.http.post(environment.HQ_baseURL + `MasterHealthSurvey/WithdrawSurvey/`, obj);
  }
  getAccessDeniedList(surveyId: any, timeZone: string) {
    ///MasterHealthSurvey/AccessDeniedEmployee/?surveyId=
    return this.http.get(
      environment.HQ_baseURL + `MasterHealthSurvey/AccessDeniedEmployee?SurveyId=${surveyId}&timeZone=${timeZone}`
    );
  }
  getCountry() {
    return this.http.get(`${environment.CT_baseURL}space-management/country`);
  }

  getOrganization() {
    return this.http.get(`${environment.CT_baseURL}space-management/space/root/region/3`);
  }
  getFacilitySettings() {
    return this.http.get(environment.TS_baseURL + `/FacilitySettingConfig/SettingsTypeId?spaceId=1&settingsTypeId=3`);
  }
}
