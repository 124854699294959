import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BuildingViewComponent } from '../building-view/building-view.component';
import { BulkUploadFloorComponent } from '../bulk-upload-floor/bulk-upload-floor.component';
import { DeleteFloorComponent } from '../delete-floor/delete-floor.component';
import { FacilityService } from '../facility.service';
import { FloorOnboardComponent } from '../floor-onboard/floor-onboard.component';
import { ViewFacilityComponent } from '../view-facility/view-facility.component';

@Component({
  selector: 'app-floor',
  templateUrl: './floor.component.html',
  styleUrls: ['./floor.component.scss']
})
export class FloorComponent implements OnInit {

  @Output() toggleComponent = new EventEmitter();
  @Output() saveInfo = new EventEmitter();

  @Input() masterObj: any;
  @Input() isCompleted: any;

  floorDetails = new FormGroup({
    building: new FormControl('', Validators.required),
    floor: new FormControl('', Validators.required),
    squareFeetArea: new FormControl('', Validators.required),
    floorCapacity: new FormControl('', Validators.required),
    noOfZones: new FormControl('', Validators.required),
  });

  buildings: any = [];
  floors: any = [];
  selectedBuildingValue: any;
  selectedFloor: any;

  floorsCache: any = [];

  facilityName: any;
  buildingCapacity: any;
  buildingsquareFeetArea: any;
  formCollection = new FormArray([]);

  floorCapacityTotal = 0;
  floorCapacityTotalFlag = false;
  floorSqFeetTotal = 0;
  floorSqFeetTotalFlag = false;

  buildingSelectedvalue: any;

  displayedColumns: any[] = ['floorName', 'floorNo', 'capetArea', 'seatingCapacity', 'action'];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  floorsList: any[] = [];
  parentSpaceId: any;

  existingFloors: any[] = [];
  isBulkUploaded = false;
  constructor(public dialog: MatDialog, private service:FacilityService) {}

  ngOnInit(): void {
    console.log('master obj', this.masterObj);
    this.isCompleted.floor = false;
    this.facilityName = this.masterObj.facility.facilityName;
    this.service.getSpaceDetailsByParentSpace(this.masterObj.facility.spaceId).subscribe((buildings: any) => {
      const buildingsArray: any = [];
      buildings.map((building: any) => {
        const buiding = {
          spaceId: '',
          buildingName: '',
          buildingsquareFeetArea: 0,
          buildingCapacity: 0,
          buildingValid: false,
          noOfFloors: 0,
        };
        building.spaceProperties.map((property: any) => {
          if (property.propertyKey === 'name') {
            buiding.spaceId = building.spaceId;
            buiding.buildingName = property.propertyValue;
          }
          if (property.propertyKey === 'squareFeetArea') {
            buiding.buildingsquareFeetArea = parseInt(property.propertyValue, 10);
          }

          if (property.propertyKey === 'capacity') {
            buiding.buildingCapacity = parseInt(property.propertyValue, 10);
          }
          if (property.propertyKey === 'childSpaces') {
            if (parseInt(property.propertyValue, 10) > 0) {
              buiding.noOfFloors = parseInt(property.propertyValue, 10);
              buiding.buildingValid = true;
            } else {
              buiding.buildingValid = false;
            }
          }
        });

        buildingsArray.push(buiding);
      });
      this.buildings = buildingsArray.reverse();
      this.validateFloor();
      this.buildingSelectedvalue = this.buildings[0];
      this.buildingSelected({
        s: '',
        value: this.buildings[0],
      });
    });
  }

  validateFloor() {
    let counter = 0;
    let buildingCapictyTotal = 0;
    let buildingsquareFeetAreaTotal = 0;

    const flagArray: boolean[] = [];
    const floorArayys: any = [];
    this.buildings.map(async (building: any, i: any) => {
      if (building.noOfFloors > 0) {
        setTimeout(async () => {
          floorArayys[i] = await this.service.getSpaceDetailsByParentSpace(building.spaceId).toPromise();
          console.log(floorArayys.length);
          counter++;
          if (floorArayys.length === this.buildings.length) {
            floorArayys.map((floorArayy: any, index: any) => {
              buildingCapictyTotal = this.buildings[index].buildingCapacity;
              buildingsquareFeetAreaTotal = this.buildings[index].buildingsquareFeetArea;
              let floorsCapicty = 0;
              let floorssquareFeetArea = 0;
              let floorsCapictyTotal = 0;
              let floorssquareFeetAreaTotal = 0;

              floorArayy.map((floor: any) => {
                floor.spaceProperties.map((property: any) => {
                  if (property.propertyKey === 'squareFeetArea') {
                    floorssquareFeetArea = parseInt(property.propertyValue, 10);
                  }
                  if (property.propertyKey === 'capacity') {
                    floorsCapicty = parseInt(property.propertyValue, 10);
                  }
                });
                floorsCapictyTotal = floorsCapictyTotal + floorsCapicty;
                floorssquareFeetAreaTotal = floorssquareFeetAreaTotal + floorssquareFeetArea;
              });

              if (
                floorssquareFeetAreaTotal <= buildingsquareFeetAreaTotal &&
                floorsCapictyTotal <= buildingCapictyTotal
              ) {
                flagArray.push(true);
                console.log('flagArray Value', true);

                if (counter === this.buildings.length && flagArray.every((e) => !!e)) {
                  setTimeout(() => {
                    this.isCompleted.floor = true;
                    console.log('Final Value', this.isCompleted.floor);
                  });

                  this.saveInfo.emit({
                    type: 'floor',
                    data: this.isCompleted.floor,
                  });
                } else {
                  setTimeout(() => {
                    this.isCompleted.floor = false;
                    console.log('Final Value', this.isCompleted.floor);
                    console.log('counter', counter);
                    console.log('this.buildings.length', this.buildings.length);
                  });
                  this.saveInfo.emit({
                    type: 'floor',
                    data: this.isCompleted.floor,
                  });
                }
              } else {
                flagArray.push(false);
                console.log('flagArray Value', false);

                if (
                  i === this.buildings.length - 1 &&
                  counter === this.buildings.length &&
                  flagArray.every((e) => !!e)
                ) {
                  this.isCompleted.floor = true;
                  console.log('Final Value', this.isCompleted.floor);

                  this.saveInfo.emit({
                    type: 'floor',
                    data: this.isCompleted.floor,
                  });
                } else {
                  this.isCompleted.floor = false;
                  console.log('Final Value', this.isCompleted.floor);
                  this.saveInfo.emit({
                    type: 'floor',
                    data: this.isCompleted.floor,
                  });
                }
              }
            });
          }
          //
        });
      }
    });
  }

  buildingSelected(e: any) {
    this.isCompleted.floor = false;
    this.formCollection = new FormArray([]);
    this.floorCapacityTotal = 0;
    this.floorSqFeetTotal = 0;
    this.selectedBuildingValue = e.value.spaceId;
    this.buildingsquareFeetArea = e.value.buildingsquareFeetArea;
    this.buildingCapacity = e.value.buildingCapacity;
    this.parentSpaceId = e.value.spaceId;
    this.floorsList.length = 0;
    this.dataSource = new MatTableDataSource([]);
    this.existingFloors.length = 0;
    this.service.getSpaceDetailsByParentSpace(e.value.spaceId).subscribe((floors: any) => {
      if (floors.length > 0) {
        this.isCompleted.floor = true;
        floors.map((floor: any) => {
          const floorFormObj: any = {};
          floorFormObj.spaceId = floor.spaceId;
          floorFormObj.parentSpaceId = floor.parentSpaceId;
          const formCollection: any = [];
          floor.spaceProperties.map((property: any) => {
            if (property.propertyKey === 'name') {
              floorFormObj.floorName = property.propertyValue;
              this.existingFloors.push(property.propertyValue.toLowerCase());
            }
            if (property.propertyKey === 'image') {
              floorFormObj.layoutName = property.propertyValue;
            }

            if (property.propertyKey === 'floorPlan') {
              floorFormObj.floorPlan = property.propertyValue;
            }

            if (property.propertyKey === 'squareFeetArea' && property.propertyValue != null) {
              floorFormObj.capetArea = parseInt(property.propertyValue, 10);
              this.floorSqFeetTotal = this.floorSqFeetTotal + parseInt(property.propertyValue, 10);
            }

            if (property.propertyKey === 'capacity' && property.propertyValue != null) {
              floorFormObj.seatingCapacity = parseInt(property.propertyValue, 10);
              this.floorCapacityTotal = this.floorCapacityTotal + parseInt(property.propertyValue, 10);
            }
            if (property.propertyKey === 'childSpaces') {
              floorFormObj.childSpaces = parseInt(property.propertyValue, 10);
            }
            if (property.propertyKey === 'floorNo') {
              floorFormObj.floorNo = parseInt(property.propertyValue, 10);
            }
            if (property.propertyKey.indexOf('_Add') >= 0) {
              formCollection.push({
                id: property.spacePropertyId,
                key: property.propertyKey,
                value: property.propertyValue,
                type: property.propertyType,
              });
            }
          });
          floorFormObj.addProperty = formCollection;
          this.floorsList.push(floorFormObj);

          /* if (!!floorFormObj.floorName) {
            this.formCollection.push(
              new FormGroup({
                spaceId: new FormControl(floor.spaceId),
                floorName: new FormControl(floorFormObj.floorName, Validators.required),
                squareFeetArea: new FormControl(floorFormObj.squareFeetArea, Validators.required),
                floorCapacity: new FormControl(floorFormObj.floorCapacity, Validators.required),
                noOfZones: new FormControl(floorFormObj.noOfZones),
              })
            );
          } */
        });

        console.log('building list', this.floorsList);
        this.dataSource = new MatTableDataSource(this.floorsList.reverse());
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        if (this.isBulkUploaded) {
          this.updateNoOfFloorsToBuilding(this.floorsList.length);
        }
        /* this.sumCapacity();
        this.sumSqFeet(); */
      } else {
        /* this.formCollection.push(
          new FormGroup({
            spaceId: new FormControl(''),
            floorName: new FormControl('', Validators.required),
            squareFeetArea: new FormControl('', Validators.required),
            floorCapacity: new FormControl('', Validators.required),
          })
        ); */
      }
    });
  }

  sumCapacity() {
    this.floorCapacityTotal = 0;
    for (const form of this.formCollection.controls) {
      if (form.value.floorCapacity) {
        this.floorCapacityTotal = this.floorCapacityTotal + form.value.floorCapacity;
      }
    }

    if (this.floorCapacityTotal <= this.buildingCapacity) {
      this.floorCapacityTotalFlag = true;
    } else {
      this.floorCapacityTotalFlag = false;
    }
  }

  sumSqFeet() {
    this.floorSqFeetTotal = 0;
    for (const form of this.formCollection.controls) {
      if (form.value.squareFeetArea) {
        this.floorSqFeetTotal = this.floorSqFeetTotal + form.value.squareFeetArea;
      }
    }

    if (this.floorSqFeetTotal <= this.buildingsquareFeetArea) {
      this.floorSqFeetTotalFlag = true;
    } else {
      this.floorSqFeetTotalFlag = false;
    }
  }

  saveFloor() {
    for (const [i, form] of this.formCollection.controls.entries()) {
      console.log(form.value);

      const json = {
        organizationId: 1,
        parentSpaceId: this.selectedBuildingValue,
        regionId: 3,
        spaceId: form.value.spaceId,
        spaceProperties: [
          {
            propertyKey: 'string',
            propertyType: 'string',
            propertyValue: 'string',
          },
        ],
        spaceType: {
          spaceTypeId: 4, // Floor space type
        },
      };

      const spaceProrty: any = [];

      if (form.value.floorName) {
        spaceProrty.push({
          propertyKey: 'name',
          propertyType: 'string',
          propertyValue: form.value.floorName,
        });
      }
      if (form.value.squareFeetArea >= 0) {
        spaceProrty.push({
          propertyKey: 'squareFeetArea',
          propertyType: 'string',
          propertyValue: form.value.squareFeetArea,
        });
      }
      if (form.value.floorCapacity >= 0) {
        spaceProrty.push({
          propertyKey: 'capacity',
          propertyType: 'string',
          propertyValue: form.value.floorCapacity,
        });
      }
      if (form.value.noOfZones) {
        spaceProrty.push({
          propertyKey: 'childSpaces',
          propertyType: 'string',
          propertyValue: form.value.noOfZones,
        });
      }

      if (json.spaceId) {
        this.service.deleteSpaceProperty(json).subscribe((e: any) => {
          json.spaceProperties = spaceProrty;
          this.service.saveSpaceDetails(json).subscribe(
            (floor: any) => {
              console.log(floor.spaceId);
              this.formCollection.controls[i].patchValue({
                spaceId: floor.spaceId,
              });
            },
            (err: any) => console.log(err)
          );
        });
      } else {
        json.spaceProperties = spaceProrty;
        this.service.saveSpaceDetails(json).subscribe(
          (floor: any) => {
            console.log(floor.spaceId);
            this.formCollection.controls[i].patchValue({
              spaceId: floor.spaceId,
            });
          },
          (err: any) => console.log(err)
        );
      }
    }
    this.buildings.map((building: any, i: any) => {
      if (this.buildingSelectedvalue.spaceId === building.spaceId) {
        this.buildings[i].buildingValid = true;
        this.buildings[i].noOfFloors = this.formCollection.length;
        this.buildingSelectedvalue.buildingValid = true;
      }
    });
    this.updateNoOfFloorsToBuilding(this.formCollection.length);
  }

  updateNoOfFloorsToBuilding(noOfFloors: number) {
    const json = {
      organizationId: 1,
      parentSpaceId: this.masterObj.facility.spaceId,
      regionId: 3,
      spaceId: this.buildingSelectedvalue.spaceId,
      spaceProperties: [
        {
          propertyKey: 'childSpaces',
          propertyType: 'string',
          propertyValue: noOfFloors,
        },
      ],
      spaceType: {
        spaceTypeId: 2, // Building space type
      },
    };

    const spaceProperties: any = [];
    this.service.getSpaceDetailsBySpaceId(this.buildingSelectedvalue.spaceId).subscribe((space: any) => {
      space.spaceProperties.map((property: any) => {
        if (property.propertyKey === 'name') {
          spaceProperties.push({
            propertyKey: 'name',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey === 'squareFeetArea') {
          spaceProperties.push({
            propertyKey: 'squareFeetArea',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }

        if (property.propertyKey === 'capacity') {
          spaceProperties.push({
            propertyKey: 'capacity',
            propertyType: 'string',
            propertyValue: property.propertyValue,
          });
        }
        if (property.propertyKey.indexOf('_Add') >= 0) {
          spaceProperties.push({
            propertyKey: property.propertyKey,
            propertyType: property.propertyType,
            propertyValue: property.propertyValue,
          });
        }
      });
      this.service.deleteSpaceProperty(json).subscribe((e: any) => {
        json.spaceProperties = json.spaceProperties.concat(spaceProperties);
        console.log('son.spaceProperties ', json.spaceProperties);
        this.service.saveSpaceDetails(json).subscribe(
          (facility: any) => {
            this.validateFloor();
          },
          (err: any) => console.log(err)
        );
      });
    });
  }

  next() {
    this.toggleComponent.emit('zone');
  }

  back() {
    this.toggleComponent.emit('building');
  }

  addFloor() {
    this.formCollection.push(
      new FormGroup({
        spaceId: new FormControl(''),
        floorName: new FormControl('', Validators.required),
        squareFeetArea: new FormControl('', Validators.required),
        floorCapacity: new FormControl('', Validators.required),
      })
    );
  }

  delete(index: any) {
    const form = this.formCollection.at(index);

    if (!!form.value.spaceId) {
      this.service.deleteSpace(form.value.spaceId).subscribe((value: any) => {
        this.formCollection.removeAt(index);
        this.sumSqFeet();
        this.sumCapacity();
        this.updateNoOfFloorsToBuilding(this.formCollection.length);
        this.validateFloor();
        if (this.formCollection.length === 0) {
          this.buildings.map((building: any, i: any) => {
            if (this.buildingSelectedvalue.spaceId === building.spaceId) {
              this.buildings[i].buildingValid = false;
              this.buildings[i].noOfFloors = this.formCollection.length;
            }
          });
          this.addFloor();
        }
      });
    } else {
      this.formCollection.removeAt(index);
      this.sumSqFeet();
      this.sumCapacity();
      if (this.formCollection.length === 0) {
        this.addFloor();
      }
    }
  }

  newFloor() {
    const dialogRef = this.dialog.open(FloorOnboardComponent, {
      width: '60%',
      data: {
        parentSpaceId: this.parentSpaceId,
        totalArea: this.buildingsquareFeetArea,
        totalCapacity: this.buildingCapacity,
        floorOccupiedArea: this.floorSqFeetTotal,
        floorOccupiedCapacity: this.floorCapacityTotal,
        existingBuilding: this.existingFloors,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      const Json = this.setData(result);
      console.log('json', Json);
      this.service.saveSpaceDetails(Json).subscribe((resp: any) => {
        console.log('posted floor ', resp);
        result.value.spaceId = resp.spaceId;
        this.addToDataSource(result);
        if (result.value.floorPlan) {
          const formData: FormData = new FormData();
          formData.append('file', result.value.floorPlan);
          this.service.uploadFloorLayout(formData, resp.spaceId).subscribe((image) => {
            console.log('rep', image);
          });
        }
        this.isCompleted.floor = true;
        this.saveInfo.emit({
          type: 'floor',
          data: this.isCompleted.floor,
        });
        this.updateNoOfFloorsToBuilding(this.floorsList.length);
      });
    });
  }

  edit(element: any) {
    console.log('element', element);
    element.totalArea = this.buildingsquareFeetArea;
    element.totalCapacity = this.buildingCapacity;
    element.floorOccupiedArea = this.floorSqFeetTotal;
    element.floorOccupiedCapacity = this.floorCapacityTotal;
    element.existingBuilding = this.existingFloors;
    const dialogRef = this.dialog.open(FloorOnboardComponent, {
      width: '60%',
      data: element,
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      if (result === 'new') {
        this.newFloor();
      } else if (result.value) {
        const index = this.floorsList.findIndex((element1) => element1.spaceId === result.value.spaceId);
        this.floorsList.splice(index, 1);
        this.addToDataSource(result);
        const Json = this.setData(result);
        this.service.deleteSpaceProperty(result.value).subscribe((resp: any) => {
          this.service.saveSpaceDetails(Json).subscribe((floor: any) => {
            if (result.value.floorPlan) {
              const formData: FormData = new FormData();
              formData.append('file', result.value.floorPlan);
              this.service.uploadFloorLayout(formData, floor.spaceId).subscribe((image) => {
                console.log('rep', image);
              });
            }
          });
        });
      }
    });
  }

  deleteRow(element: any) {
    const dialogRef = this.dialog.open(DeleteFloorComponent, {
      width: '540px',
      panelClass: 'success-container',
      data: {
        message: element.floorName,
        spaceId: element.spaceId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
      this.service.deleteSpace(result.spaceId).subscribe((deleted: any) => {
        const index = this.floorsList.findIndex((element1) => element1.spaceId === result.spaceId);
        this.existingFloors.splice(this.existingFloors.indexOf(element.floorName.toLowerCase()), 1);
        this.floorsList.splice(index, 1);
        this.dataSource = new MatTableDataSource(this.floorsList.reverse());
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        this.service.deleteSpace(result.spaceId).subscribe((resp) => {
          this.updateNoOfFloorsToBuilding(this.floorsList.length);
        });
      });
    });
  }

  addToDataSource(result: any) {
    const obj: any = {
      spaceId: result.value.spaceId,
      floorName: result.value.floorName,
      floorNo: result.value.floorNo,
      capetArea: result.value.squareFeetArea,
      seatingCapacity: result.value.floorCapacity,
      childSpaces: result.value.childSpaces,
      addProperty: result.value.addProperty,
      layoutName: result.value.LayoutName,
      floorPlan: '',
    };
    if (!!result.value.floorPlan) {
      obj.floorPlan = result.value.floorPlan?.name;
    } else if (!!result.value.fileName) {
      obj.floorPlan = result.value.fileName;
    }
    this.floorsList.push(obj);
    this.dataSource = new MatTableDataSource(this.floorsList.reverse());
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  setData(result: any) {
    const spaceProperties = [];
    spaceProperties.push({
      propertyKey: 'name',
      propertyType: 'string',
      propertyValue: result.value.floorName,
    });
    spaceProperties.push({
      propertyKey: 'squareFeetArea',
      propertyType: 'string',
      propertyValue: result.value.squareFeetArea,
    });
    spaceProperties.push({
      propertyKey: 'capacity',
      propertyType: 'string',
      propertyValue: result.value.floorCapacity,
    });

    if (!!result.value.childSpaces) {
      spaceProperties.push({
        propertyKey: 'childSpaces',
        propertyType: 'string',
        propertyValue: result.value.childSpaces,
      });
    }

    if (!!result.value.floorNo) {
      spaceProperties.push({
        propertyKey: 'floorNo',
        propertyType: 'string',
        propertyValue: result.value.floorNo,
      });
    }

    if (!!result.value.floorPlan) {
      spaceProperties.push({
        propertyKey: 'floorPlan',
        propertyType: 'string',
        propertyValue: result.value.floorPlan.name,
      });
    } else if (!!result.value.fileName) {
      spaceProperties.push({
        propertyKey: 'floorPlan',
        propertyType: 'string',
        propertyValue: result.value.fileName,
      });
    }

    if (!!result.value.LayoutName) {
      spaceProperties.push({
        propertyKey: 'layoutName',
        propertyType: 'string',
        propertyValue: result.value.LayoutName,
      });
    }
    result.value.addProperty.map((props: any) => {
      spaceProperties.push({
        spacePropertyId: props.id,
        propertyKey: props.key + '_Add',
        propertyType: props.type ? props.type : 'string',
        propertyValue: props.value,
      });
    });
    console.log('space prop', spaceProperties);

    return {
      organizationId: 1,
      parentSpaceId: this.parentSpaceId,
      regionId: this.masterObj.facility.city,
      spaceId: result.value.spaceId,
      spaceProperties,
      spaceType: {
        spaceTypeId: 4, // floor space type
      },
    };
  }

  showBuildingData() {
    const dialogRef = this.dialog.open(BuildingViewComponent, {
      width: '90%',
      data: { spaceId: this.parentSpaceId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
    });
  }
  showFacilityData() {
    const dialogRef = this.dialog.open(ViewFacilityComponent, {
      width: '90%',
      panelClass: 'facility-container',
      data: { spaceId: this.masterObj.facility.spaceId },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
    });
  }
  download() {
    this.service.getFloorTemplate().subscribe((template: any) => {
      const blob = new Blob([template], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
  upload(files: FileList) {
    const formData: FormData = new FormData();
    formData.append('file', files.item(0));
    this.service
      .floorBulkUpload(formData, 1, this.parentSpaceId, 4, this.masterObj.facility.city, this.floorsList.length)
      .subscribe(() => {
        this.ngOnInit();
      });
  }

  bulkupload() {
    const dialogRef = this.dialog.open(BulkUploadFloorComponent, {
      width: '780px',
      panelClass: 'bulkupload',
      data: {
        spaceId: this.parentSpaceId,
        region: this.masterObj.facility.city,
        length: this.floorsList.length,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.refresh) {
        this.isBulkUploaded = true;
      }
      this.ngOnInit();
    });
  }
}
