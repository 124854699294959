<div class="">
  <form [formGroup]="facilityDetailsForm">
    <div class="fields row">
      <div class="col-4">
        <mat-label class="label-input">
          Facility Name *
          <span class="red" *ngIf="!nameFlag">&nbsp;&nbsp;Facility Name already Exist</span>
        </mat-label>
        <input
          class="input-field"
          autocomplete="off"
          (blur)="nameCheck()"
          matInput
          type="text"
          formControlName="facilityName"
          required
          #facilityName
        />
      </div>
      <div class="col-4">
        <mat-label class="label">Facility Type</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select formControlName="facilityType">
            <mat-option [value]="f" *ngFor="let f of facilityTypes">{{ f }}</mat-option>
          </mat-select>
          <button
            mat-button
            *ngIf="facilityDetailsForm.value.facilityType"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clear('facilityType'); $event.stopPropagation()"
          >
            <mat-icon style="font-size: 15px; color: #fff;">close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-label class="label-input"> Capacity (Number of People)</mat-label>
        <input
          class="input-field"
          autocomplete="off"
          matInput
          type="number"
          maxlength="10"
          minlength="0"
          min="0"
          formControlName="facilityCapacity"
        />
      </div>
      <div class="col-4">
        <mat-label class="label-input"> Gross Total Area (sq.ft) *</mat-label>
        <input
          class="input-field"
          autocomplete="off"
          matInput
          type="number"
          maxlength="10"
          placeholder=""
          minlength="0"
          min="0"
          formControlName="squareFeetArea"
          required
        />
      </div>
      <div class="col-7 facility-upload">
        <mat-label class="label-input">
          Upload Facility Layout &nbsp;<span class="red"> {{ fileError }}</span></mat-label
        >

        <button mat-stroked-button (click)="fileInput.click()" class="file-search-btn" style="text-align: right;">
          <span [ngClass]="{ 'align-filename': fileName != undefined }" title="{{ fileName }}">
            <!-- {{ fileName | truncate: [10] }} -->
          </span>
          <span [ngClass]="{ 'align-browse': fileName != undefined }" class="browse">BROWSE</span>
          <input
            class=""
            #fileInput
            type="file"
            accept="image/*"
            (change)="onFileChange($event)"
            style="display: none;"
          />
        </button>
        <!-- <button mat-raised-button class="btn-save" color="primary" style="width: 17%; height: 43px;" (click)="upload()">
          <img src="assets/Group 983.png" class="upload" alt="" />
          <span>UPLOAD</span>
        </button> -->
        <button mat-button class="btn-save btn-upload" color="primary" (click)="upload()">
          <img src="assets/Group 983.svg" alt="" />
          <span style="margin-left: 5px;">Upload</span>
        </button>
        <button mat-icon-button *ngIf="fileNameFromDb != undefined" (click)="downloadMoreInfoFile(-1)" class="view">
          <span class="material-icons">visibility</span>
        </button>
      </div>
    </div>
    <div class="customElement">
      <ng-container *ngFor="let form of formCollection.controls; index as i">
        <form class="m-t-15" [formGroup]="form">
          <div class="fields row">
            <div class="col-4">
              <mat-label class="label-input">
                Key
              </mat-label>
              <input class="input-field" autocomplete="off" matInput type="text" formControlName="key" />
            </div>
            <div class="col-4">
              <mat-label class="label-input">
                Value
              </mat-label>
              <input
                class="input-field"
                autocomplete="off"
                matInput
                type="text"
                formControlName="value"
                (input)="onValueChange($event, i)"
              />
            </div>
            <div class="bgicon delete" (click)="deleteForm(i)">
              <img src="assets/Group 1381.svg" alt="" />
              <!-- <span style="color: #fff;">x</span> -->
            </div>
            <div class="bgicon upload">
              <input type="file" (change)="onKeyValueFileChange($event, i)" />
              <!-- <img src="assets/bg.png" alt="" /> -->
              <img src="assets/Group 1422.svg" alt="" />
            </div>
            <div *ngIf="addedInfoFileType[i] == 'file'" (click)="downloadMoreInfoFile(i)" class="view">
              <span class="material-icons">visibility</span>
            </div>
          </div>
        </form>
      </ng-container>
      <div class="addfield" (click)="addRow()">
        <div>
          <img src="assets/Add.svg" alt="" />
        </div>
        <div>
          Add Additional Field
        </div>
      </div>
    </div>
    <hr class="hrline" />
    <div class="sub-heading">Space Mapping</div>
    <div class="fields row">
      <div class="col-4">
        <mat-label class="label">Region *</mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select formControlName="region">
            <mat-option [value]="f.globalRegionId" *ngFor="let f of regions">{{ f.name }}</mat-option>
          </mat-select>
          <button
            mat-button
            *ngIf="facilityDetailsForm.value.region"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clear('region'); $event.stopPropagation()"
          >
            <mat-icon style="font-size: 15px; color: #fff;">close</mat-icon>
          </button>
        </mat-form-field>
      </div>

      <div class="col-4 p-b-25">
        <mat-label class="label-input"> Location </mat-label>
        <input
          class="input-field"
          autocomplete="off"
          matInput
          type="text"
          placeholder=""
          formControlName="latlong"
          (input)="onLatLong($event)"
          style="width: 92%; border-radius: 8px;"
        />
        <!-- <span
          style="
            border: 1px solid #5e5e72;

            padding-left: 0;
            height: 50px;
            border-left: 0;
            border-radius: 0px 8px 8px 0px;
            margin-left: -4px;
            top: -1px;
            padding: 13.5px;
            position: relative;
            padding-bottom: 16px;
          "
        >
          <img class="location" src="assets/Group 1009.png" (click)="openMap()" />
        </span> -->
        <!-- <span class="clear-icon" (click)="openMap()">
          <img src="assets/Group 1009.png" (click)="openMap()" />
        </span> -->
      </div>
      <div class="col-4">
        <mat-label class="label-input"> Address Line 1 * </mat-label>
        <input
          class="input-field"
          autocomplete="off"
          matInput
          type="text"
          placeholder=""
          formControlName="addressline1"
        />
      </div>
      <div class="col-4">
        <mat-label class="label-input"> Address Line 2</mat-label>
        <input
          class="input-field"
          autocomplete="off"
          matInput
          type="text"
          placeholder=""
          formControlName="addressline2"
        />
      </div>
      <div class="col-4">
        <mat-label class="label">Country * </mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select formControlName="country" (selectionChange)="getStates()" (change)="getStates()">
            <mat-option [value]="f.countryId" *ngFor="let f of countries">{{ f.countryName }}</mat-option>
          </mat-select>
          <button
            mat-button
            *ngIf="facilityDetailsForm.value.country"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clear('country'); $event.stopPropagation()"
          >
            <mat-icon style="font-size: 15px; color: #fff;">close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-label class="label-input">Country Region </mat-label>
        <input
          class="input-field"
          autocomplete="off"
          matInput
          type="text"
          maxlength="25"
          formControlName="countryregion"
        />
      </div>
      <div class="col-4">
        <mat-label class="label"> Province State * </mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select formControlName="province" (selectionChange)="getCities()">
            <mat-option [value]="f.regionId" *ngFor="let f of states">{{ f.name }}</mat-option>
          </mat-select>
          <button
            mat-button
            *ngIf="facilityDetailsForm.value.province"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clear('province'); $event.stopPropagation()"
          >
            <mat-icon style="font-size: 15px; color: #fff;">close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-label class="label-input">City * </mat-label>
        <mat-form-field appearance="outline" style="width: 100%;">
          <mat-select formControlName="city">
            <mat-option [value]="f.regionId" *ngFor="let f of cities">{{ f.name }}</mat-option>
          </mat-select>
          <button
            mat-button
            *ngIf="facilityDetailsForm.value.city"
            matSuffix
            mat-icon-button
            aria-label="Clear"
            (click)="clear('city'); $event.stopPropagation()"
          >
            <mat-icon style="font-size: 15px; color: #fff;">close</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-4">
        <mat-label class="label-input">Pincode * </mat-label>
        <input class="input-field" autocomplete="off" matInput type="number" maxlength="10" formControlName="pincode" />
      </div>
    </div>
  </form>
  <div class="button">
    <button
      mat-button
      class="btn-save btn-continue"
      color="primary"
      (click)="saveFacility()"
      [disabled]="!(facilityDetailsForm.valid && nameFlag)"
    >
      continue
    </button>
  </div>
</div>
