import { EmployeeViewComponent } from './employee-view/employee-view.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: 'employee', component: EmployeeViewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class EmployeeRoutingModule {}
