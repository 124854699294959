import { Injectable } from '@angular/core';
import { AppService } from '@app/app.service';
import { environment } from '@env/environment';
@Injectable({
  providedIn: 'root',
})
export class chartColors {
  colorCodes: any;
  themeType = environment.isLightTheme;
  //colorCodes:any();

  constructor(private appservice: AppService) {
    // this.getColorCodes()
    this.appservice.currentTheme.subscribe(theme => this.themeType = (theme == 'theme-light' ? true : false));
  }
  getColorCodes() {
    if (!this.themeType) {
      return {
        normal_font_color: '#ffffff',
        toolTipBackgroundColor: '#35354D',
        donutChartTextColor: '#ffffff',
        redColor: '#DB3145',
        greenColor: '#5CC272',
        amberColor: '#FCCF5A'
      };
    }
    if (this.themeType) {
      return {
        normal_font_color: '#000000', //'#fff'
        toolTipBackgroundColor: '#2C3E50',
        donutChartTextColor: '#000000',
        redColor: '#C4314B',
        greenColor: '#00B260',
        amberColor: '#EBA509'
      };
    }
  }
}
