import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacilityService } from '../facility.service';

@Component({
  selector: 'app-bulk-upload-building',
  templateUrl: './bulk-upload-building.component.html',
  styleUrls: ['./bulk-upload-building.component.scss']
})
export class BulkUploadBuildingComponent implements OnInit {

  fileName: any;
  file: any;
  fileError: any;
  uploadFailure: any;
  constructor(
    public dialogRef: MatDialogRef<BulkUploadBuildingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: FacilityService
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close();
  }

  download() {
    this.service.getBuildingTemplate().subscribe((template: any) => {
      const blob = new Blob([template], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
  upload() {
    const formData: FormData = new FormData();
    formData.append('file', this.file);
    this.service
      .buildingBulkUpload(formData, 1, this.data.spaceId, 2, this.data.region, this.data.length)
      .subscribe((resp: any) => {
        if (resp.length > 0) {
          this.uploadFailure = 'Upload Incomplete - ' + resp[0];
        } else {
          this.dialogRef.close({
            refresh: true,
          });
        }
      });
  }

  onFileChange(event: any) {
    const validExts = new Array('.xlsx');
    let fileExt = event.target.files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt) < 0) {
      this.fileError = 'Invalid file selected';
      return false;
    } else {
      this.fileError = '';
      this.fileName = event.target.files[0].name;
      this.file = event.target.files[0];

      return true;
    }
  }
}
