import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-switch-to-landscape-popup',
  templateUrl: './switch-to-landscape-popup.component.html',
  styleUrls: ['./switch-to-landscape-popup.component.scss']
})
export class SwitchToLandscapePopupComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<SwitchToLandscapePopupComponent>) { }

  ngOnInit(): void {
  }
 onClose(){
    this.dialogRef.close();
 }
}
