import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@env/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  public tokenValue = new BehaviorSubject<string>('');
  public roles = new BehaviorSubject(null);
  public department = new BehaviorSubject(null);
  public loggedUser = new BehaviorSubject(null);
  public showLocation = new BehaviorSubject(null);
  public hotdeskpage = new BehaviorSubject(null);
  baseUrl = environment.meetingRoomUrl;
  tokenObservable$ = this.tokenValue.asObservable();
  private theme: string =
    typeof Storage != 'undefined' && localStorage.getItem('theme')
      ? localStorage.getItem('theme')
      : environment.isLightTheme
      ? 'theme-light'
      : 'theme-dark';
  private defaultTheme = new BehaviorSubject(this.theme);
  currentTheme = this.defaultTheme.asObservable();
  private readonly _breadcrumbs$ = new BehaviorSubject(null);
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    this.defaultTheme.next(this.theme);
    this.hotdeskpage.next(true);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        // console.log(event);
        if (event.url.includes('landing-page')) {
          this._breadcrumbs$.next(null);
        } else if (event.url.includes('energy')) {
          this._breadcrumbs$.next('ENERGY MANAGEMENT');
        } else if (event.url.includes('workspace')) {
          this._breadcrumbs$.next('WORKSPACE EXPERIENCE');
        } else if (event.url.includes('racetrac')) {
          this._breadcrumbs$.next('OPERATIONAL EFFICIENCY');
        } else {
          this._breadcrumbs$.next('SAFE BUILDINGS');
        }
      });
  }

  setTokenMsg(msg: any) {
    this.tokenValue.next(msg);
  }

  changeTheme(theme: string) {
    localStorage.setItem('theme', theme);
    this.defaultTheme.next(theme);
  }
  getUserLocation() {
    let azureToken = localStorage.getItem('azureToken');
    var header = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${azureToken}`,
        // 'Access-Control-Allow-Origin': '*',
      }),
    };
    let url = this.baseUrl + `Location`;
    return this.http.get(url, header);
  }
  isMobileDevice(): boolean {
    const userAgent = window.navigator.userAgent;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        userAgent
      )
    ) {
      return true;
    } else {
      return false;
    }
  }
}
