<div class="root">
    <mat-card class="org-config-card">
      <div class="facility">
        <div class="header">
            <span>ORGANIZATION CONFIGURATION</span>
        </div>
        <hr class="border-line" />
        <div class="org-config-form-container">
            <form [formGroup]="orgConfigForm">
                <div class="row">
                  <div class="col">
                    <mat-label>Organization Name</mat-label>
                    <mat-form-field appearance="outline" class="org-config-field-design">
                      <input matInput type="text" formControlName="organizationName" />
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-label>Logo</mat-label>
                    <div class="logoFieldContainer">
                      <mat-form-field appearance="outline" class="org-config-field-design">
                        <input matInput type="text" formControlName="logo" />
                        <span class="browseButton">
                          BROWSE
                          <input type="file" id="imgupload" (change)="onFileChanged($event.target.files)"/> 
                        </span>
                      </mat-form-field>
                      <button [disabled]="disableUploadButton" (click)="uploadLogo()">
                        <img src="assets/Group 983.svg" class="uploadIcon" alt="" />
                        <span>UPLOAD</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="row">
                    <div class="col">
                      <mat-label>Define Regions</mat-label>
                      <mat-form-field appearance="outline" class="org-config-field-design" [ngClass]="{'org-config-field-design-chip': selectedRegionsList.length>0}">
                        <mat-chip-list #defineRegions>
                          <mat-chip *ngFor="let region of selectedRegionsList; let i = index" (removed)="removeRegion(i)">
                            {{region}}
                            <mat-icon matChipRemove>cancel</mat-icon>
                          </mat-chip>
                          <input [matChipInputFor]="defineRegions" (matChipInputTokenEnd)="addRegion($event)" formControlName="regions">
                        </mat-chip-list>
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-label>Facility Types</mat-label>
                      <mat-form-field appearance="outline" class="org-config-field-design" [ngClass]="{'org-config-field-design-chip': selectedFacilityTypes.length>0}">
                        <mat-chip-list #facilityTypes>
                            <mat-chip *ngFor="let type of selectedFacilityTypes; let i = index" (removed)="removeFacilityType(i)">
                              {{type}}
                              <mat-icon matChipRemove>cancel</mat-icon>
                            </mat-chip>
                            <input [matChipInputFor]="facilityTypes" (matChipInputTokenEnd)="addFacilityType($event)" formControlName="facilityTypes">
                        </mat-chip-list>
                      </mat-form-field>
                    </div>
                  </div>
                <div class="row">
                  <div class="col">
                    <mat-label>System of Measurement</mat-label>
                    <mat-form-field appearance="outline" class="org-config-field-design">
                      <mat-select formControlName="measurement" panelClass="org-config-panel">
                        <mat-option *ngFor="let measurement of measurementsList" [value]="measurement.measurmentId">
                          {{ measurement.measurmentName }} 
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <mat-label>Choose your KPIs/ Areas of Improvement</mat-label>
                    <mat-form-field appearance="outline" class="org-config-field-design">
                      <mat-select formControlName="kpi" multiple panelClass="org-config-panel">
                        <mat-option *ngFor="let kpi of KPIList" [value]="kpi.kpiId">
                          {{ kpi.kpiName }} 
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                    <div class="col">
                      <mat-label>Language</mat-label>
                      <mat-form-field appearance="outline" class="org-config-field-design">
                        <mat-select formControlName="language" panelClass="org-config-panel">
                          <mat-option *ngFor="let lang of languageList" [value]="lang.languageId">
                            {{ lang.languageName }} 
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div class="col">
                      <mat-label>Currency</mat-label>
                      <mat-form-field appearance="outline" class="org-config-field-design">
                        <mat-select formControlName="currency" panelClass="org-config-panel">
                          <mat-option *ngFor="let currency of currencyList" [value]="currency.currencyId">
                            {{ currency.currencySymbol }} 
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                </div>
                <div class="row">
                  <div class="col">
                    <mat-label>Date Format</mat-label>
                    <mat-form-field appearance="outline" class="org-config-field-design">
                        <mat-select formControlName="dateFormat" panelClass="org-config-panel">
                          <mat-option *ngFor="let format of dateFormatList" [value]="format">
                            {{ format }} 
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col"></div>
                </div>
            </form>
            <div class="button-container">
              <button (click)="saveOrganizationData()" [disabled]="orgConfigForm.valid">
                Save
              </button>
            </div>          
        </div>
      </div>
    </mat-card>
  </div>
