import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MaterialModule } from './material.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HomeScreenComponent } from './home/home-screen/home-screen.component';
import { FacilityListComponent } from './home/facility-list/facility-list.component';
import { OrganizationConfigComponent } from './home/organization-config/organization-config.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AssetListComponent } from './home/asset-list/asset-list.component';
import { FacilityWrapperComponent } from './home/facility-wrapper/facility-wrapper.component';
import { FacilityOnboardComponent } from './home/facility-onboard/facility-onboard.component';
import { FloorViewComponent } from './home/floor-view/floor-view.component';
import { AddAssetComponent } from './home/add-asset/add-asset.component';
import { FacilityComponent } from './home/facility/facility.component';
import { SuccessFacilityComponent } from './home/success-facility/success-facility.component';
import { BuildingComponent } from './home/building/building.component';
import { BuildingOnboardComponent } from './home/building-onboard/building-onboard.component';
import { ViewFacilityComponent } from './home/view-facility/view-facility.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { DeleteFloorComponent } from './home/delete-floor/delete-floor.component';
import { BuildingViewComponent } from './home/building-view/building-view.component';
import { BulkUploadFloorComponent } from './home/bulk-upload-floor/bulk-upload-floor.component';
import { BulkUploadBuildingComponent } from './home/bulk-upload-building/bulk-upload-building.component';
import { FloorComponent } from './home/floor/floor.component';
import { FloorOnboardComponent } from './home/floor-onboard/floor-onboard.component';
import { LoginComponent } from './login/login.component';
import { HeaderModule } from './header/header.module';
import { MenuComponent } from './menu/menu.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from './@shared';
import { SafeBuildingModule } from './safe-building/safe-building.module';
import {
  NbDatepickerModule,
  NbLayoutModule,
  NbCardModule,
  NbSelectModule,
  NbSidebarModule,
  NbMenuModule,
  NbWindowModule,
  NbToastrModule,
  NbChatModule,
  NbDialogModule,
} from '@nebular/theme';
import { SharedService } from './@theme/components/shared.service';
import { OverviewComponent } from './overview/overview.component';
import {
  MsalAngularConfiguration,
  MsalInterceptor,
  MsalModule,
  MsalService,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
} from '@azure/msal-angular';

import { Configuration } from 'msal';
import * as config from './@shared/services/msal.config';
import { MatDialogRef } from '@angular/material/dialog';
import {
  AuthInterceptor,
  HTTPStatus,
} from './workspace-experience/@core/services/auth-interceptor';
import { LandingpageComponent } from './landingpage/landingpage.component';

export const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
export const protectedResourceMap: [string, string[], string[]][] = [
  [
    config.azure_authConfig.resources.resourceUri,
    [config.azure_authConfig.resources.resourceScope],
    ['user.read'],
  ],
];

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: config.azure_authConfig.auth.clientId,
      authority: config.azure_authConfig.auth.authority,
      validateAuthority: true,
      redirectUri: config.azure_authConfig.auth.redirectUri,
      postLogoutRedirectUri: config.azure_authConfig.auth.postLogoutRedirectUri,
      navigateToLoginRequestUrl: true,
    },
    cache: {
      storeAuthStateInCookie: false,
    },
  };
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: false,
    consentScopes: [
      config.azure_authConfig.resources.resourceScope,
      ...config.azure_authConfig.scopes.loginRequest,
    ],
    unprotectedResources: [],
    protectedResourceMap: [],
    extraQueryParameters: {},
  };
}
const RxJS_Services = [AuthInterceptor, HTTPStatus];

@NgModule({
  declarations: [
    AppComponent,
    HomeScreenComponent,
    FacilityListComponent,
    OrganizationConfigComponent,
    AssetListComponent,
    FacilityWrapperComponent,
    FacilityOnboardComponent,
    FloorViewComponent,
    AddAssetComponent,
    FacilityComponent,
    BuildingComponent,
    BuildingOnboardComponent,
    ViewFacilityComponent,
    SuccessFacilityComponent,
    DeleteFloorComponent,
    BuildingViewComponent,
    BulkUploadFloorComponent,
    BulkUploadBuildingComponent,
    FloorComponent,
    FloorOnboardComponent,
    LoginComponent,
    MenuComponent,
    OverviewComponent,
    LandingpageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    FormsModule,
    MatFormFieldModule,
    MatInputModule,
    HttpClientModule,
    ReactiveFormsModule,
    NgbModule,
    MatSidenavModule,
    BsDatepickerModule.forRoot(),
    NbLayoutModule,
    NbCardModule,
    NbSelectModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDatepickerModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    NbChatModule.forRoot({
      messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
    }),
    NbDialogModule.forRoot(),
    HeaderModule,
    SharedModule,
    SafeBuildingModule,
    FlexLayoutModule,
    MsalModule,
  ],
  providers: [
    SharedService,
    HTTPStatus,
    ...RxJS_Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory,
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory,
    },
    MsalService,
    {
      provide: MatDialogRef,
      useValue: {},
    },
  ],
  entryComponents: [SuccessFacilityComponent],
  bootstrap: [AppComponent],
})
export class AppModule {}
