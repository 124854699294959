import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
//import { BehaviorSubject } from "rxjs/BehaviorSubject";
@Injectable()
export class SharedService {
    menuValue:boolean;
    _comp1ValueBS = new BehaviorSubject<boolean>(false);

    menuTextValue:string;
    _comp2ValueBS = new BehaviorSubject<string>('');
        user:any;
    // menuDiv:any;
    // _menuCompDiv = new BehaviorSubject<Element>(Element);
    private breadCrumb: BehaviorSubject<string> = new BehaviorSubject<string>('');
    roleval: any;
     enablePreheader: boolean=false;
     preHeader: string;

    constructor() {
        this.menuValue;
           // this.user = JSON.parse(localStorage.getItem('user'));
           // this.roleval = this.user["role"]
        this._comp1ValueBS = new BehaviorSubject<boolean>(false);
        if(localStorage.getItem('StoredMenuValue')){
            this.menuTextValue = localStorage.getItem('StoredMenuValue');
        }
        else{
            if(this.roleval == "Store Manager"){
                this.menuTextValue="Dashboard";
              }
              else if(this.roleval == "Admin"){
            
              this.menuTextValue = "Stores";
              }
        }
    }

    setBreadCrumbValue(value: string) {
        this.breadCrumb.next(value);
    }

    getBreadCrumbValue(): Observable<string> {
        return this.breadCrumb.asObservable();
    }

    updateMenuCompVal(val) {
        this.menuValue = val;
        this._comp1ValueBS.next(this.menuValue);
       
      }
    updateMenuText(text){
        this.menuTextValue = text;
        this._comp2ValueBS = new BehaviorSubject<string>('');
    }
    
}