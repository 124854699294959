export const globalConstant = {
    facility_spaceId: 1,
    facility_spaceTypeId: 1,
    building_spaceId: 1,
    building_spaceTypeId: 2,
    floor_spaceId: 2,
    floor_spaceTypeId: 4,
    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    intervalValue: 30000
  };
  