import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HrViewComponent } from './hr-view/hr-view.component';

const routes: Routes = [
  // Module is lazy loaded, see app-routing.module.ts
  { path: 'hrView', component: HrViewComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: [],
})
export class HrAccessViewRoutingModule {}
