import {
  Component,
  ElementRef,
  HostListener,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppService } from '@app/app.service';
import { environment } from '@env/environment';
import * as Chart from 'chart.js';
import * as moment from 'moment';
import { chartColors } from '../../../themes/chart-theme-variables';
import { globalConstant } from '../safebuilding-global-constants';
import { CustomDatePickerComponent } from '../custom-date-picker/custom-date-picker.component';
import { HandHygieneService } from './service/hand-hygiene.service';
import { SwitchToLandscapePopupComponent } from '../switch-to-landscape-popup/switch-to-landscape-popup.component';
export interface PEOPLE_DATA {
  personId: string;
  spaceId: string;
  spaceName: string;
  complianceFlag: string;
  timeStamp: string;
  personName: string;
}
const ELEMENT_DATA: PEOPLE_DATA[] = [];

const floorplanData = {
  parentSpaceId: '5',
  counts: [
    {
      spaceId: '21',
      spaceName: 'ODC 1',
      noncompliantCount: 25,
      capacityCount: 8,
      noncompliantPeopleCount: '0.8',
    },
    {
      spaceId: '22',
      spaceName: 'ODC 2',
      noncompliantCount: 50,
      capacityCount: 24,
      noncompliantPeopleCount: '1.2',
    },
    {
      spaceId: '23',
      spaceName: 'ODC 3',
      noncompliantCount: 100,
      capacityCount: 20,
      noncompliantPeopleCount: '1.6',
    },
  ],
};

const floorplanData1 = {
  TotalNonCompliantCount: '10',
  SpaceId: '23',
  SpaceName: 'Cafeteria.',
  SpaceTypeId: '1',
  HandHygieneAggregates: [
    {
      Label: '5 May',
      StartDate: '2020-05-05.',
      NoncompliantCount: 604,
      CapacityCount: 604,
      NoncompliantPeopleCount: 604,
    },
    {
      Label: '6 May',
      StartDate: '2020-05-06.',
      NoncompliantCount: 60,
      CapacityCount: 604,
      NoncompliantPeopleCount: 604,
    },
  ],
};

@Component({
  selector: 'app-hand-hygiene-view',
  templateUrl: './hand-hygiene-view.component.html',
  styleUrls: ['./hand-hygiene-view.component.scss'],
})
export class HandHygieneViewComponent implements OnInit {
  @ViewChild('aggregateSort') aggregateSort: MatSort;
  @ViewChild('discreteSort') discreteSort: MatSort;
  facility_spaceId: any = parseInt(localStorage.getItem('safebuildingSpaceId'));
  facility_spaceTypeId: any = parseInt(
    localStorage.getItem('safebuildingSpaceTypeId')
  );
  building_spaceId: any = globalConstant.building_spaceId;
  building_spaceTypeId: any = globalConstant.building_spaceTypeId;
  floor_spaceId: any = globalConstant.floor_spaceId;
  floor_spaceTypeId: any = globalConstant.floor_spaceTypeId;
  timeZone: string = globalConstant.timeZone;
  totalFloorAlerts: number;
  isMobileView:boolean;
  filter = 'today';
  facilities = ['CKC', 'KNC'];
  peoples = ['STAFF', 'CUSTOMER'];
  peopleSelected = 'STAFF';
  p: number = 1;
  selectedPoint: any;
  showDiscrete: boolean;
  filters: any[] = [
    {
      value: 1,
      viewValue: 'Discrete',
    },
    {
      value: 2,
      viewValue: 'Aggregate',
    },
  ];
  fiterValue: any;
  displayedColumns: string[] = [
    'personName',
    'spaceId',
    'timeStamp',
    'complianceFlag',
  ];
  discrete_displayedColumns: string[] = ['employeeName', 'numberOfAlerts'];

  myChart: any;
  dataSource = new MatTableDataSource();
  //@ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  // @ViewChildren(MatPaginator) paginator = new QueryList<MatPaginator>();
  @ViewChild('chart') chart: ElementRef;
  public context: CanvasRenderingContext2D;
  activeBadge: number;
  activeFloorBadge: number = 0;
  selectedFloorArray: any = [];
  selectedFloor: any = 1;
  selectedFloorId: any = 1;
  history: any = [];
  buildingSelected: any = 'SDB1';
  chartData: any;
  //chartData: any = [0, 0, 0, 0, 0, 0];
  label: any = ['ODC 1', 'ODC 2', 'ODC 3', 'Cafeteria', 'Pantry', 'Reception'];
  floorData: any;
  facilityList: any = [];
  buildingList: any = [];
  buildingOverviewSelected: string = '';
  SortedBuldingList: any;
  buildingAlertIndex: any;
  maxSpaceAlerts: any;
  buildingSelectedAlerts: any;
  buildingSelectedOccupancy: any;
  buildingSelectedNotifiedAsc: any;
  maxFloorAlerts: any[];
  facility: any;
  floorAlerts: any;
  floorOccupancy: any;
  floorNotifiedAsc: any;
  peopleList: any;
  AGGREGATE_TABLE_DATA = new MatTableDataSource();
  TABLE_DATA = new MatTableDataSource();
  customDate = {
    from: moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss'),
    to: moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss'),
  };
  customDate1 = {
    from: new Date(),
    to: new Date(),
  };
  fromMon: string;
  toMon: string;
  showCustomRange: boolean = false;
  currentPlottedType = '';
  customFrom: any;
  customTo: any;
  limit: any;
  slicedLength: any;
  hideDiscrete: boolean;
  chartColorCodes: any;
  isLightTheme: boolean = environment.isLightTheme;
  isViewLoaded: boolean;
  dataOffset: number = 0;
  dataLimit: number = 500;
  discreteDataOffset: number = 0;
  discreteDataLimit: number = 500;
  floorSpaceId: any;
  floorSpaceTypeId: any;
  tabledata2: any;
  dataNotFound: boolean;
  tabledata1: any;
  switchToLandscapeDialog: any;
  isLandscape: boolean;
  screenMode: string = '';
  constructor(
    public dialog: MatDialog,
    private service: HandHygieneService,
    private router: Router,
    private activeRoute: ActivatedRoute,
    private chartColors: chartColors,
    private appservice: AppService
  ) {}
  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event: any) {
    if (window.screen.orientation.type.includes('landscape')) {
      this.screenMode = 'landscape';
      this.isLandscape = true;
     // this.changeChartType(this.chartType);
     document.getElementsByTagName('html')[0].classList.remove('landscape-style');
     if (this.switchToLandscapeDialog) {
       this.switchToLandscapeDialog.close();
     }
      console.log('Landscape oriented ' + this.isLandscape);
    } else {
      this.screenMode = 'portrait';
      this.isLandscape = false;
      console.log('Portrait oriented ' + this.isLandscape);
    }
  }
  ngOnInit(): void {
    if (window.screen.orientation.type.includes('landscape')) {
      this.screenMode = 'landscape';
      this.isLandscape = true;
      //  this.changeChartType(this.chartType);
      document.getElementsByTagName('html')[0].classList.remove('landscape-style');
      if (this.switchToLandscapeDialog) {
        this.switchToLandscapeDialog.close();
      }
      console.log('Landscape oriented ' + this.isLandscape);
    } else {
      this.screenMode = 'portrait';
      this.isLandscape = false;
      this.switchToLandscapeDialog = this.dialog.open(SwitchToLandscapePopupComponent, {
        panelClass: 'switchLandscapePanel',
        width: '100%',
        maxWidth: '80vw',
      });
      console.log('Portrait oriented ' + this.isLandscape);
    }
    this.isMobileView = this.appservice.isMobileDevice();
    this.activeRoute.params.subscribe((params: Params) => {
      this.building_spaceId = params.id;
    });
    this.appservice.currentTheme.subscribe((theme) => {
      this.isLightTheme = theme == 'theme-light' ? true : false;
      if (
        this.isViewLoaded &&
        location.pathname.toLowerCase().includes('handhygiene')
      ) {
        location.reload();
      }
    });
    this.chartColorCodes = this.chartColors.getColorCodes();
    this.fiterValue = this.filters[1].value;
    this.showDiscrete = false;
    this.hideDiscrete = true;
    this.AGGREGATE_TABLE_DATA.sort = this.aggregateSort;
    this.TABLE_DATA.sort = this.discreteSort;
    //this.getHygieneComplianceCountById(5, '2020-04-28%2004:49:34', '2020-04-30%2009:51:14');
    this.getLocation(
      this.facility_spaceId,
      this.facility_spaceTypeId,
      this.customDate.from,
      this.customDate.to,
      this.timeZone
    );
    this.getBuilding(
      this.building_spaceId,
      this.building_spaceTypeId,
      this.customDate.from,
      this.customDate.to,
      this.timeZone
    );
    this.isViewLoaded = true;
  }

  getBuilding(
    id: number,
    spaceTypeId: number,
    startTime: string,
    endTime: string,
    timeZone: string
  ) {
    this.service
      .getHygieneComplianceCountById(
        id,
        spaceTypeId,
        startTime,
        endTime,
        this.timeZone
      )
      .subscribe(
        (data: any) => {
          if (data) {
            this.buildingList = data.counts;
            this.SortedBuldingList = [...this.buildingList];
            this.SortedBuldingList.sort(function (x: any, y: any) {
              return y['noncompliantCount'] - x['noncompliantCount'];
            });
            var maxSpaceAlertsIndex1 =
              this.SortedBuldingList[0].noncompliantCount;
            var z = this.buildingList
              .map((e: any) => e.noncompliantCount)
              .indexOf(maxSpaceAlertsIndex1);

            this.selectBuilding(this.SortedBuldingList[0].spaceId, z);
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }
  getLocation(
    id: number,
    spaceTypeId: number,
    startTime: string,
    endTime: string,
    timeZone: string
  ) {
    this.service
      .getLocation(id, spaceTypeId, startTime, endTime, this.timeZone)
      .subscribe(
        (data: any) => {
          if (data) {
            this.facilityList = data.counts;
            this.facility = this.facilityList[0].spaceName;
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  getHygieneComplianceCountById(
    id: number,
    spaceTypeId: number,
    startTime: string,
    endTime: string,
    timeZone: string
  ) {
    this.floorData = null;
    this.service
      .getHygieneComplianceCountById(
        id,
        spaceTypeId,
        startTime,
        endTime,
        this.timeZone
      )
      .subscribe(
        (data: any) => {
          if (data) {
            if (data.counts.length > 0) {
              this.label = [];
              this.chartData = [];
            }

            this.floorData = floorplanData1;
            for (
              var i = 0;
              i < this.floorData.HandHygieneAggregates.length;
              i++
            ) {
              this.label.push(this.floorData.HandHygieneAggregates[i].Label);
              this.chartData.push(
                this.floorData.HandHygieneAggregates[i].NoncompliantCount
              );
            }
            console.log(this.label);
            console.log(this.chartData);
            this.chartDraw(this.context, 'bar', this.label, this.chartData);
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  ngAfterViewInit() {
    this.context = this.chart.nativeElement.getContext('2d');

    //this.chartDraw(this.context, 'bar', this.label, this.chartData);
  }
  chartDraw = (context: any, type: string, label: any, data: any) => {
    if (this.myChart) {
      this.myChart.destroy();
    }
    const gradient = context.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, '#E65100');
    gradient.addColorStop(1, '#556270');

    this.myChart = new Chart(context, {
      type,
      data: {
        labels: label,
        datasets: [
          {
            label: 'Alerts',
            data: data,
            fill: false,
            backgroundColor: this.chartColorCodes.redColor,
            barThickness: 12,
            maxBarThickness: 12,
            yAxisID: 'YAxis1',
          },
          {
            label: 'Alerts',
            data: data,
            fill: false,
            backgroundColor: this.chartColorCodes.redColor,
            barThickness: 12,
            maxBarThickness: 12,
            type: 'line',
            pointRadius: 8,
            pointBackgroundColor: this.chartColorCodes.redColor,
            borderColor: this.chartColorCodes.redColor,
            lineTension: 0,
            yAxisID: 'YAxis1',
          },
        ],
      },
      options: {
        plugins: {
          datalabels: {
            display: false,
          },
        },
        tooltips: {
          mode: 'single',
          backgroundColor: this.chartColorCodes.toolTipBackgroundColor,
          callbacks: {
            afterBody: (data) => {
              if (data && data[0]) {
                this.selectedPoint = data[0];
              }
              return [''];
            },
          },
        },

        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              id: 'YAxis1',
              position: 'left',
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: this.chartColorCodes.normal_font_color, //'white',
                fontSize: 14,
                beginAtZero: true,
                stepSize: 0,
                fontFamily: 'TTNorms',
              },
              stacked: true,
            },
          ],
          xAxes: [
            {
              stacked: true,
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: this.chartColorCodes.normal_font_color, //'white',
                fontSize: 14,
                beginAtZero: true,
                fontFamily: 'TTNorms',
              },
            },
          ],
        },
      },
    });
  };

  // filterBy(value: string) {
  //   this.filter = value;
  //   if (this.filter === 'month') {
  //     this.customDate.from = moment().startOf('month').format('YYYY-MM-DD+HH:MM:ss');
  //     this.customDate.to = moment().endOf('month').format('YYYY-MM-DD+HH:MM:ss');
  //     this.getBuilding(1, this.customDate.from, this.customDate.to);
  //   }
  //   if (this.filter === 'week') {
  //     this.customDate.from = moment().startOf('week').format('YYYY-MM-DD+HH:MM:ss');
  //     this.customDate.to = moment().endOf('week').format('YYYY-MM-DD+HH:MM:ss');
  //     this.getBuilding(1, this.customDate.from, this.customDate.to);
  //   }
  //   if (this.filter === 'today') {
  //     this.customDate.from = moment().startOf('day').format('YYYY-MM-DD+HH:MM:ss');
  //     this.customDate.to = moment().endOf('day').format('YYYY-MM-DD+HH:MM:ss');
  //     this.getBuilding(1, this.customDate.from, this.customDate.to);
  //   }
  // }

  filterBy(value: string) {
    this.history.length = 0;
    this.filter = value;
    this.tabledata1 = [];
    this.tabledata2 = [];
    if (this.filter === 'month') {
      // this.customDate.from = moment().startOf('month').format('YYYY-MM-DD+HH:MM:ss');
      // this.customDate.to = moment().endOf('month').format('YYYY-MM-DD+HH:MM:ss');
      this.customDate.from = this.getLastThirty();
      this.customDate.to = this.getCurrentDateTime();
      // this.thisMonthlyView(this.spaceId);
      // const from = moment().startOf('month').valueOf();
      // const end = moment().endOf('month').valueOf();
      const from = moment().startOf('day').subtract(29, 'days').valueOf();
      const end = moment().valueOf();
      const label =
        moment().startOf('day').subtract(29, 'days').format('DD MMM') +
        '-' +
        moment().startOf('day').format('DD MMM');
      this.history.push({
        label,
        date: from,
        view: 'day-month',
      });
      this.getBuilding(
        this.building_spaceId,
        this.building_spaceTypeId,
        this.customDate.from,
        this.customDate.to,
        this.timeZone
      );
    }
    if (this.filter === 'week') {
      // this.customDate.from = moment().startOf('week').format('YYYY-MM-DD+HH:MM:ss');
      // this.customDate.to = moment().endOf('week').format('YYYY-MM-DD+HH:MM:ss');
      this.customDate.from = this.getLastSeven();
      this.customDate.to = this.getCurrentDateTime();
      this.getBuilding(
        this.building_spaceId,
        this.building_spaceTypeId,
        this.customDate.from,
        this.customDate.to,
        this.timeZone
      );
      const from = moment().startOf('day').subtract(6, 'days').valueOf();
      const end = moment().endOf('day').valueOf();
      const label =
        moment().startOf('day').subtract(6, 'days').format('DD MMM') +
        '-' +
        moment().endOf('day').format('DD MMM');
      this.history.push({
        label,
        date: from,
        view: 'week',
      });
    }
    if (this.filter === 'today') {
      this.customDate.from = moment()
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      this.customDate.to = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
      this.getBuilding(
        this.building_spaceId,
        this.building_spaceTypeId,
        this.customDate.from,
        this.customDate.to,
        this.timeZone
      );
    }
  }

  chartClick(e: any) {
    if (this.selectedPoint) {
      const data = this.myChart.getElementAtEvent(e);
      if (data.length > 0) {
        console.log(data[0]._view.label);
        if (this.currentPlottedType === 'day') {
          console.log(this.chartData);
          this.chartData.map((chartdata: any) => {
            if (chartdata.label === this.selectedPoint.xLabel) {
              const from = moment(new Date(chartdata.startDate))
                .startOf('day')
                .format('YYYY-MM-DDTHH:mm:ss');
              const end = moment(new Date(chartdata.startDate))
                .endOf('day')
                .format('YYYY-MM-DDTHH:mm:ss');
              console.log(from + ' ' + end);
              this.getHoursView(this.selectedFloorId, from, end);
              chartdata.view = 'day';
              console.log('bar click', chartdata, this.history);
              this.history.push(chartdata);
            }
          });
        }
      }
    }
  }

  today(spaceId: number) {
    // const from = moment().startOf('day').valueOf();
    // const end = moment().endOf('day').valueOf();
    const from = moment().startOf('day').format('YYYY-MM-DDTHH:mm:ss');
    const end = moment().endOf('day').format('YYYY-MM-DDTHH:mm:ss');
    this.getHoursView(spaceId, from, end);
  }

  getHoursView(spaceId: number, from: any, end: any) {
    // console.log(spaceId); console.log("hoursview");
    // const label: any = [];
    // const noncompliantCount: any = [];
    // floorplanData1.HandHygieneAggregates.map((data: any) => {
    //   label.push(data.Label);
    //   noncompliantCount.push(data.NoncompliantCount);
    // });
    // this.chartData = floorplanData1;
    // this.chartDraw(this.context, 'bar', label, noncompliantCount);
    // this.currentPlottedType = 'hour';
    this.service
      .getHourlyView(spaceId, from, end, this.timeZone)
      .subscribe((day: any) => {
        const label: any = [];
        const noncompliantCount: any = [];
        day.handHygieneAggregates.map((data: any) => {
          label.push(data.label);
          noncompliantCount.push(data.noncompliantCount);
        });
        this.chartData = day.handHygieneAggregates;
        this.chartDraw(this.context, 'bar', label, noncompliantCount);
        this.currentPlottedType = 'hour';
        console.log();
      });
  }

  getDailyView(spaceId: number, from: any, end: any, timeZone: string) {
    console.log(spaceId);
    console.log('dailyview');
    this.service
      .getDailyView(spaceId, from, end, this.timeZone)
      .subscribe((day: any) => {
        const label: any = [];
        const noncompliantCount: any = [];
        day.handHygieneAggregates.map((data: any) => {
          label.push(data.label);
          noncompliantCount.push(data.noncompliantCount);
        });
        this.chartData = day.handHygieneAggregates;
        this.chartDraw(this.context, 'bar', label, noncompliantCount);
        this.currentPlottedType = 'day';
      });
  }

  getWeekly(spaceId: number, from: any, end: any, timeZone: string) {
    console.log(spaceId);
    console.log('weeklyview');
    this.service
      .getweeklyView(spaceId, from, end, this.timeZone)
      .subscribe((day: any) => {
        const label: any = [];
        const noncompliantCount: any = [];
        day.handHygieneAggregates.map((data: any) => {
          label.push(data.label);
          noncompliantCount.push(data.noncompliantCount);
        });
        this.chartData = day.handHygieneAggregates;
        this.chartDraw(this.context, 'bar', label, noncompliantCount);
        this.currentPlottedType = 'week';
      });
  }

  getMonthlyView(spaceId: number, from: any, end: any, timeZone: string) {
    console.log(spaceId);
    console.log('montlyview');
    this.service
      .getMonthlyView(spaceId, from, end, this.timeZone)
      .subscribe((day: any) => {
        const label: any = [];
        const noncompliantCount: any = [];
        day.handHygieneAggregates.map((data: any) => {
          label.push(data.label);
          noncompliantCount.push(data.noncompliantCount);
        });
        this.chartData = day.handHygieneAggregates;
        this.chartDraw(this.context, 'bar', label, noncompliantCount);
        this.currentPlottedType = 'month';
      });
  }

  thisMonthlyView(spaceId: any) {
    // const from = moment().startOf('month').valueOf();
    // const end = moment().endOf('month').valueOf();
    const from = moment().startOf('month').format('YYYY-MM-DDTHH:mm:ss');
    const end = moment().endOf('month').format('YYYY-MM-DDTHH:mm:ss');
    this.getWeekly(spaceId, from, end, this.timeZone);
    const label = moment().startOf('month').format('MMMM');
    this.history.push({
      label,
      date: from,
      view: 'month',
    });
  }

  thisWeeklyView(spaceId: any) {
    // const from = moment().startOf('week').valueOf();
    // const end = moment().endOf('week').valueOf();
    const from = moment().startOf('week').format('YYYY-MM-DDTHH:mm:ss');
    const end = moment().endOf('week').format('YYYY-MM-DDTHH:mm:ss');
    const label =
      moment().startOf('week').format('DD MMM') +
      '-' +
      moment().endOf('week').format('DD MMM');
    this.history.push({
      label,
      date: from,
      view: 'week',
    });
    this.getWeekly(spaceId, from, end, this.timeZone);
  }

  scrollNext() {
    var container = document.getElementById('buildinglist');
    this.sideScroll(container, 'right', 25, 100, 10);
  }

  scrollPrev() {
    var container = document.getElementById('buildinglist');
    this.sideScroll(container, 'left', 25, 100, 10);
  }

  sideScroll(
    element: any,
    direction: any,
    speed: any,
    distance: any,
    step: any
  ) {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction == 'left') {
        element.scrollLeft -= step;
      } else {
        element.scrollLeft += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  selectBuilding(id: number, index: number) {
    this.dataOffset = 0;
    this.discreteDataOffset = 0;
    this.tabledata1 = [];
    this.tabledata2 = [];
    this.paginator.toArray()[0].firstPage();
    this.paginator.toArray()[1].firstPage();
    this.activeBadge = index;

    // this.selectedFloorArray = this.buildingInfo[0].Floors;
    this.buildingSelected = this.buildingList.filter(function (x: any) {
      return id == x.spaceId;
    });

    this.buildingOverviewSelected = this.buildingSelected[0].spaceName;
    this.buildingSelectedAlerts = this.buildingSelected[0].noncompliantCount;
    this.buildingSelectedOccupancy = this.buildingSelected[0].capacityCount;
    this.buildingSelectedNotifiedAsc =
      this.buildingSelected[0].noncompliantPeopleCount;

    this.service
      .getFloors(
        this.buildingSelected[0].spaceId,
        4,
        this.customDate.from,
        this.customDate.to,
        this.timeZone
      )
      .subscribe(
        (data: any) => {
          if (data) {
            this.selectedFloorArray = data.counts;
            this.selectHighAlertFloor();
            // this.progressBar();
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }
  selectFloor(index: number) {
    this.paginator.toArray()[0].firstPage();
    this.paginator.toArray()[1].firstPage();
    this.discreteDataOffset = 0;
    this.dataOffset = 0;
    this.tabledata1 = [];
    this.tabledata2 = [];
    this.activeFloorBadge = index;
    this.selectedFloor = this.selectedFloorArray[index].spaceName;

    this.floorAlerts = this.selectedFloorArray[index].noncompliantCount;
    this.floorOccupancy = this.selectedFloorArray[index].capacityCount;
    this.floorNotifiedAsc =
      this.selectedFloorArray[index].noncompliantPeopleCount;
    this.floorSpaceId = this.selectedFloorArray[index].spaceId;
    this.floorSpaceTypeId = this.selectedFloorArray[index].spaceTypeId;
    this.setPeopleData(
      this.selectedFloorArray[index].spaceId,
      this.selectedFloorArray[index].spaceTypeId,
      this.discreteDataOffset,
      this.discreteDataLimit
    );
    this.setAggregateData(
      this.selectedFloorArray[index].spaceId,
      this.selectedFloorArray[index].spaceTypeId,
      this.dataOffset,
      this.dataLimit
    );
    // this.getHygieneComplianceCountById(
    //   this.selectedFloorArray[index].spaceId,
    //   this.customDate.from,
    //   this.customDate.to
    // );
    this.selectedFloorId = this.selectedFloorArray[index].spaceId;
    this.getFloorsBasedByCategory(this.selectedFloorId);
  }
  selectHighAlertFloor() {
    this.dataOffset = 0;
    this.discreteDataOffset = 0;
    this.maxFloorAlerts = [...this.selectedFloorArray];
    this.maxFloorAlerts.sort(function (x: any, y: any) {
      return y['noncompliantCount'] - x['noncompliantCount'];
    });
    var z = this.selectedFloorArray
      .map((e: any) => e.noncompliantCount)
      .indexOf(this.maxFloorAlerts[0].noncompliantCount);

    this.activeFloorBadge = z;

    this.selectedFloor = this.selectedFloorArray[z].spaceName;
    this.floorAlerts = this.selectedFloorArray[z].noncompliantCount;
    this.floorOccupancy = this.selectedFloorArray[z].capacityCount;
    this.floorNotifiedAsc = this.selectedFloorArray[z].noncompliantPeopleCount;
    this.floorSpaceId = this.selectedFloorArray[z].spaceId;
    this.floorSpaceTypeId = this.selectedFloorArray[z].spaceTypeId;
    this.setPeopleData(
      this.selectedFloorArray[z].spaceId,
      this.selectedFloorArray[z].spaceTypeId,
      this.discreteDataOffset,
      this.discreteDataLimit
    );
    this.setAggregateData(
      this.selectedFloorArray[z].spaceId,
      this.selectedFloorArray[z].spaceTypeId,
      this.dataOffset,
      this.dataLimit
    );
    //this.getHygieneComplianceCountById(this.selectedFloorArray[z].spaceId, this.customDate.from, this.customDate.to);
    this.selectedFloorId = this.selectedFloorArray[z].spaceId;
    this.getFloorsBasedByCategory(this.selectedFloorId);
  }

  getFloorsBasedByCategory(id: any) {
    this.history.length = 0;
    if (this.filter == 'month') {
      const from = moment().startOf('day').subtract(29, 'days').valueOf();
      const end = moment().valueOf();
      const label =
        moment().startOf('day').subtract(29, 'days').format('DD MMM') +
        '-' +
        moment().startOf('day').format('DD MMM');
      this.history.push({
        label,
        date: from,
        view: 'day-month',
      });
      this.getDailyView(
        id,
        this.getLastThirty(),
        this.getCurrentDateTime(),
        this.timeZone
      );
    } else if (this.filter == 'week') {
      const from = moment().startOf('day').subtract(6, 'days').valueOf();
      const end = moment().endOf('day').valueOf();
      const label =
        moment().startOf('day').subtract(6, 'days').format('DD MMM') +
        '-' +
        moment().endOf('day').format('DD MMM');
      this.history.push({
        label,
        date: from,
        view: 'week',
      });
      this.getDailyView(
        id,
        this.getLastSeven(),
        this.getCurrentDateTime(),
        this.timeZone
      );
    } else if (this.filter == 'today') {
      this.today(id);
    } else if (this.filter == 'custom') {
      // this.getMonthlyView(id, this.customFrom, this.customTo);
    }
  }

  horizontalScroll(
    element: any,
    direction: any,
    speed: any,
    distance: any,
    step: any
  ) {
    let scrollAmount = 0;
    var slideTimer = setInterval(function () {
      if (direction == 'up') {
        element.scrollTop -= step;
      } else {
        element.scrollTop += step;
      }
      scrollAmount += step;
      if (scrollAmount >= distance) {
        window.clearInterval(slideTimer);
      }
    }, speed);
  }

  scrollDown() {
    var container = document.getElementById('floorList');
    this.horizontalScroll(container, 'down', 25, 100, 10);
  }

  scrollUp() {
    var container = document.getElementById('floorList');
    this.horizontalScroll(container, 'up', 25, 100, 10);
  }

  openDialog(): void {
    this.showCustomRange = false;
    const dialogRef = this.dialog.open(CustomDatePickerComponent, {
      width: '450px',
      data: {
        selectedRange: {
          ...this.customDate1,
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result);
      this.showCustomRange = true;
      this.fromMon = moment(result.from).format('MMM');
      this.toMon = moment(result.to).format('MMM');
      this.customDate.from = moment(result.from)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      this.customDate.to = moment(result.to)
        .endOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      // this.customFrom = moment(result.from).startOf('day').valueOf();
      // this.customTo = moment(result.to).startOf('day').valueOf();
      this.customFrom = moment(result.from)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      this.customTo = moment(result.to)
        .startOf('day')
        .format('YYYY-MM-DDTHH:mm:ss');
      this.getBuilding(
        this.building_spaceId,
        this.building_spaceTypeId,
        this.customDate.from,
        this.customDate.to,
        this.timeZone
      );
      this.getMonthlyView(
        this.selectedFloorId,
        this.customDate.from,
        this.customDate.to,
        this.timeZone
      );
    });
  }
  setPeopleData(spaceId: any, spaceTypeId: any, offset: any, limit: any) {
    this.service
      .getPeopleData(
        spaceId,
        this.customDate.from,
        this.customDate.to,
        'TimeStamp',
        'Desc',
        limit,
        offset,
        spaceTypeId,
        this.timeZone
      )
      .subscribe(
        (data: any) => {
          const temp: any = [];
          if (data.hygieneCompliances.length > 0) {
            // var shuffledData = data.hygieneCompliances.filter(function () {
            //   return 0.5 - Math.random() > 0 ? true : false;
            // });
            // if (this.floorNotifiedAsc < 50) {
            //   this.slicedLength = this.floorNotifiedAsc;
            // } else {
            //   this.slicedLength = 50;
            // }

            // var selectedData = shuffledData.slice(0, this.slicedLength);

            // let modifiedData = [...data.hygieneCompliances];
            // // console.log(modifiedData);
            // for (let i = 0; i < modifiedData.length; i++) {
            //   for (let j = 0; j < selectedData.length; j++) {
            //     if (modifiedData[i].personId == selectedData[j].personId) {
            //       modifiedData[i]['complianceFlag'] = '1';
            //     }
            //   }
            // }

            this.tabledata1 = this.tabledata1.length
              ? this.tabledata1.concat(data.hygieneCompliances)
              : data.hygieneCompliances;
            this.TABLE_DATA = new MatTableDataSource(this.tabledata1);
            this.TABLE_DATA.paginator = this.paginator.toArray()[0];
            this.TABLE_DATA.sort = this.discreteSort;
            this.dataNotFound = false;
          } else {
            this.dataNotFound = true;
            this.tabledata1 = [];
            this.TABLE_DATA = new MatTableDataSource();
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  navigateback(data: any) {
    console.log(data);
    if (data.view === 'week') {
      /* const from = moment(new Date(data.date)).startOf('day').subtract(6, 'days').valueOf();
      const end = moment(new Date(data.date)).valueOf(); */
      const from = moment()
        .startOf('day')
        .subtract(6, 'days')
        .format('YYYY-MM-DDTHH:mm:ss');
      const end = moment().format('YYYY-MM-DDTHH:mm:ss');
      this.getDailyView(this.selectedFloorId, from, end, this.timeZone);
      this.history.splice(this.history.length - 2);
      const label =
        moment().startOf('day').subtract(6, 'days').format('DD MMM') +
        '-' +
        moment().startOf('day').format('DD MMM');
      this.history.push({
        label,
        date: from,
        view: 'week',
      });
    }
    if (data.view === 'day-month') {
      // const from = moment().startOf('month').valueOf();
      const from = moment()
        .startOf('day')
        .subtract(29, 'days')
        .format('YYYY-MM-DDTHH:mm:ss');
      const end = moment().format('YYYY-MM-DDTHH:mm:ss');
      const label =
        moment().startOf('day').subtract(29, 'days').format('DD MMM') +
        '-' +
        moment().endOf('day').format('DD MMM');
      this.history.push({
        label,
        date: from,
        view: 'day-month',
      });

      this.getDailyView(this.selectedFloorId, from, end, this.timeZone);
      this.history.splice(this.history.length - 2);
    }
  }

  getCurrentDateTime() {
    let today = new Date();

    let startDate =
      today.getFullYear() +
      '-' +
      (today.getMonth() + 1) +
      '-' +
      today.getDate() +
      'T' +
      '23:59:59';
    return startDate;
  }

  getLastSeven() {
    var Onedayplus = new Date();
    Onedayplus.setDate(Onedayplus.getDate() - 6);

    let endDate =
      Onedayplus.getFullYear() +
      '-' +
      (Onedayplus.getMonth() + 1) +
      '-' +
      Onedayplus.getDate() +
      'T' +
      '00:00:00';
    return endDate;
  }

  getLastThirty() {
    var Onedayplus = new Date();
    Onedayplus.setDate(Onedayplus.getDate() - 29);

    let endDate =
      Onedayplus.getFullYear() +
      '-' +
      (Onedayplus.getMonth() + 1) +
      '-' +
      Onedayplus.getDate() +
      'T' +
      '00:00:00';
    return endDate;
  }
  navigateToDashboard() {
    this.router.navigateByUrl('/safe-buildings/dashboard');
  }

  filterChange(filter: any) {
    this.fiterValue = filter;
    this.hideDiscrete = !this.hideDiscrete;
  }

  setAggregateData(spaceId: any, spaceTypeId: any, offset: any, limit: any) {
    // if (this.floorAlerts <= 999) {
    //   this.limit = this.floorAlerts;
    // } else {
    //   this.limit = 999;
    // }
    this.service
      .getEmployeeAggregateData(
        spaceId,
        spaceTypeId,
        this.customDate.from,
        this.customDate.to,
        'NumberOfAlerts',
        'DESC',
        limit,
        offset,
        this.timeZone
      )
      .subscribe(
        (data: any) => {
          if (data.hgComplianceEmployeeAggregates.length > 0) {
            this.dataNotFound = false;
            this.tabledata2 = this.tabledata2.length
              ? this.tabledata2.concat(data.hgComplianceEmployeeAggregates)
              : data.hgComplianceEmployeeAggregates;
            this.AGGREGATE_TABLE_DATA = new MatTableDataSource(this.tabledata2);
            this.AGGREGATE_TABLE_DATA.paginator = this.paginator.toArray()[1];
            this.AGGREGATE_TABLE_DATA.sort = this.aggregateSort;
          } else {
            this.dataNotFound = true;
            this.tabledata2 = [];
            this.AGGREGATE_TABLE_DATA = new MatTableDataSource();
          }
        },
        (err: any) => {
          console.log(err);
        }
      );
  }

  getAggregatePageData(event: any) {
    let currentPageIndex = event.pageIndex + 1;
    if (currentPageIndex * event.pageSize === event.length) {
      this.dataOffset = Math.round(event.length / this.dataLimit);
      this.setAggregateData(
        this.floorSpaceId,
        this.floorSpaceTypeId,
        this.dataOffset,
        this.dataLimit
      );
    } else {
    }
  }
  getDiscretePageData(event: any) {
    let currentPageIndex = event.pageIndex + 1;
    if (currentPageIndex * event.pageSize === event.length) {
      this.discreteDataOffset = Math.round(event.length / this.dataLimit);
      this.setPeopleData(
        this.floorSpaceId,
        this.floorSpaceTypeId,
        this.discreteDataOffset,
        this.discreteDataLimit
      );
    } else {
    }
  }
}
