<mat-card class="screening employee-view" style="padding: 0px; margin-top: 10px;">
    <div class="detailedSummary">
      <mat-tab-group #tabGroup (selectedIndexChange)="setDataSource($event)" class="tab-group">
        <mat-tab *ngIf="TS_enable">
          <ng-template mat-tab-label>
            <span class="tab-number">THERMAL </span>
            <span class="tab-text">SCREENING</span>
          </ng-template>
          <mat-card style="padding: -1px;">
            <div class="row">
              <div class="dateinfo" style="padding-left: 20px;">Data of last 14 days</div>
              <div class="legend">
                <div class="status-green"></div>
                <div class="">Compliant</div>
              </div>
              <div class="legend">
                <div class="status-red"></div>
                <div class="">Non compliant</div>
              </div>
            </div>
            <table
              mat-table
              [dataSource]="dataSource"
              class="temperaturetable mat-elevation-z8"
              style="box-shadow: none;"
            >
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <div
                    class="status"
                    [ngClass]="{ greenBg: element.status == true, redBg: element.status == false }"
                  ></div>
                </td>
              </ng-container>
              <!-- <ng-container matColumnDef="eventId">
                <th mat-header-cell *matHeaderCellDef>Event ID</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.eventId }}
                  <span *ngIf="!!element.overrideValue">*</span>
                </td>
              </ng-container> -->
  
              <ng-container matColumnDef="screenAt">
                <th mat-header-cell *matHeaderCellDef>Screened At</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.screenAt }}
                  <span *ngIf="element.screenAt && element.vendorName"> - </span>
                  {{ element.vendorName }}
                </td>
              </ng-container>
  
              <ng-container matColumnDef="temperature">
                <th mat-header-cell *matHeaderCellDef>Temperature</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.temperature }}
                  <span *ngIf="!!element.overrideValue">
                    <img src="../../../assets/Group 251.png" />
                  </span>
                  <span *ngIf="!!element.overrideValue">{{ element.overrideValue }}</span>
                  <span *ngIf="!!element.overrideValue">
                    <img style="margin-left: 5px;" src="../../../assets/Group 250.png" />
                  </span>
                </td>
              </ng-container>
  
              <ng-container matColumnDef="timestamp">
                <th mat-header-cell *matHeaderCellDef>Timestamps</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.timestamp | date: 'dd MMM, hh:mm':'+0000' }}
                  {{ element.timestamp | date: 'a' | lowercase }}
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </mat-card>
          <mat-paginator #paginator [hidden]="dataSource.filteredData.length === 0" [hidePageSize]="true" [pageSize]="10">
          </mat-paginator>
        </mat-tab>
        <mat-tab *ngIf="PD_enable">
          <ng-template mat-tab-label>
            <span class="tab-number">PHYSICAL</span>
            <span class="tab-text">DISTANCING</span>
          </ng-template>
          <mat-card>
            <div class="row">
              <div class="dateinfo" style="padding-left: 4px;">Data of last 14 days</div>
              <div class="legend">
                <div class="status-green"></div>
                <div class="">Compliant</div>
              </div>
              <div class="legend">
                <div class="status-red"></div>
                <div class="">Non compliant</div>
              </div>
            </div>
            <table
              mat-table
              [dataSource]="dataSource1"
              class="temperaturetable mat-elevation-z8"
              style="box-shadow: none;"
            >
              <!--<ng-container matColumnDef="eventId">
                <th mat-header-cell *matHeaderCellDef>Event ID</th>
                <td mat-cell *matCellDef="let element">{{ element.eventId }}</td>
              </ng-container>
  
              <ng-container matColumnDef="spaceName">
                <th mat-header-cell *matHeaderCellDef>Entry Point</th>
                <td mat-cell *matCellDef="let element">{{ element.spaceName }}</td>
              </ng-container>-->
  
              <!-- <ng-container matColumnDef="complianceFlag">
                <th mat-header-cell *matHeaderCellDef>Compliance</th>
                <td mat-cell *matCellDef="let element">
                  <div
                    class="status"
                    [ngClass]="{ greenBg: element.complianceFlag == true, redBg: element.complianceFlag == false }"
                  ></div>
                </td>
              </ng-container> -->
  
              <ng-container matColumnDef="secondaryPersonName">
                <th mat-header-cell *matHeaderCellDef>Secondary Person Name</th>
                <td mat-cell *matCellDef="let element">{{ element.secondaryPersonName }}</td>
              </ng-container>
  
              <ng-container matColumnDef="secondaryPersonId">
                <th mat-header-cell *matHeaderCellDef>Secondary Person ID</th>
                <td mat-cell *matCellDef="let element">{{ element.secondaryPersonId }}</td>
              </ng-container>
  
              <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef>Duration (in sec)</th>
                <td mat-cell *matCellDef="let element">{{ element.duration }} sec</td>
              </ng-container>
  
              <ng-container matColumnDef="timeStamp">
                <th mat-header-cell *matHeaderCellDef>Timestamps</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.timeStamp | date: 'dd MMM,  hh:mm' }}
                  {{ element.timeStamp | date: 'a' | lowercase }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns1"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns1"></tr>
            </table>
          </mat-card>
          <mat-paginator
            #paginator1
            [hidePageSize]="true"
            [hidden]="dataSource1.filteredData.length === 0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
          >
          </mat-paginator>
        </mat-tab>
        <mat-tab *ngIf="HH_enable">
          <ng-template mat-tab-label>
            <span class="tab-number">HAND </span>
            <span class="tab-text">HYGIENE</span>
          </ng-template>
          <mat-card>
            <div class="row">
              <div class="dateinfo" style="padding-left: 4px;">Data of last 14 days</div>
              <div class="legend">
                <div class="status-green"></div>
                <div class="">Compliant</div>
              </div>
              <div class="legend">
                <div class="status-red"></div>
                <div class="">Non compliant</div>
              </div>
            </div>
            <table
              mat-table
              [dataSource]="dataSource2"
              class="temperaturetable mat-elevation-z8"
              style="box-shadow: none;"
            >
              <ng-container matColumnDef="complianceFlag">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <div
                    class="status"
                    [ngClass]="{ greenBg: element.complianceFlag == true, redBg: element.complianceFlag == false }"
                  ></div>
                </td>
              </ng-container>
              <ng-container matColumnDef="eventId">
                <th mat-header-cell *matHeaderCellDef>Event ID</th>
                <td mat-cell *matCellDef="let element">{{ element.eventId }}</td>
              </ng-container>
  
              <ng-container matColumnDef="spaceName">
                <th mat-header-cell *matHeaderCellDef>Entry Point</th>
                <td mat-cell *matCellDef="let element">{{ element.spaceName }}</td>
              </ng-container>
  
              <ng-container matColumnDef="timeStamp">
                <th mat-header-cell *matHeaderCellDef>Timestamps</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.timeStamp | date: 'dd MMM,  hh:mm' }} {{ element.timeStamp | date: 'a' | lowercase }}
                </td>
              </ng-container>
  
              <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns2"></tr>
            </table>
          </mat-card>
          <mat-paginator
            #paginator2
            [hidePageSize]="true"
            [hidden]="dataSource2.filteredData.length === 0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 100]"
            (page)="getHandHygineData($event)"
          >
          </mat-paginator>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card>
  