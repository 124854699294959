import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacilityService } from '../facility.service';

@Component({
  selector: 'app-floor-onboard',
  templateUrl: './floor-onboard.component.html',
  styleUrls: ['./floor-onboard.component.scss']
})
export class FloorOnboardComponent implements OnInit {
  base64textString: string;
  floorDetailsArray = new FormGroup({
    spaceId: new FormControl(''),
    floorName: new FormControl('', Validators.required),
    LayoutName: new FormControl(''),
    floorNo: new FormControl(''),
    squareFeetArea: new FormControl('', Validators.pattern('^[0-9]*$')),
    floorCapacity: new FormControl('', Validators.pattern('^[0-9]*$')),
    childSpaces: new FormControl(''),
    parentSpaceId: new FormControl(''),
    floorPlan: new FormControl(''),
    fileName: new FormControl(''),
  });

  addNew = 'new';
  fileName: any;
  fileNameFromDb: any;
  isLayout = true;

  existingFloorsList: any[] = [];
  formCollection = new FormArray([]);

  addedInfoFileType: any[] = [];

  totalArea: any;
  TotalCapacity: any;
  occpiedArea: any;
  occupiedCapacity: any;
  isExceedingArea = false;
  isExceedingCapacity = false;
  existingBuildings: any[];
  isExistingBuilding = false;
  fileError: string;
  constructor(
    public dialogRef: MatDialogRef<FloorOnboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private service: FacilityService
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    console.log('data', this.data);
    this.totalArea = this.data.totalArea;
    this.TotalCapacity = this.data.totalCapacity;
    this.occupiedCapacity = this.data.floorOccupiedCapacity;
    this.occpiedArea = this.data.floorOccupiedArea;
    this.existingBuildings = this.data.existingBuilding;
    this.floorDetailsArray.reset();
    if (!!this.data) {
      this.floorDetailsArray.patchValue({
        spaceId: this.data.spaceId,
        floorName: this.data.floorName,
        squareFeetArea: this.data.capetArea,
        floorCapacity: this.data.seatingCapacity,
      });
    }
    if (!!this.data && !!this.data.addProperty && this.data.addProperty.length > 0) {
      this.data.addProperty.map((form: any) => {
        this.formCollection.push(
          new FormGroup({
            key: new FormControl(form.key.split('_Add')[0], Validators.required),
            value: new FormControl(form.value, Validators.required),
            id: new FormControl(form.id, Validators.required),
            type: new FormControl(form.typr, Validators.required),
          })
        );
        this.addedInfoFileType.push(form.type);
      });
    } else {
      this.formCollection.push(
        new FormGroup({
          key: new FormControl('', Validators.required),
          value: new FormControl('', Validators.required),
          id: new FormControl('', Validators.required),
          type: new FormControl('', Validators.required),
        })
      );
    }

    if (this.data && !!this.data.childSpaces) {
      this.floorDetailsArray.patchValue({
        childSpaces: this.data.childSpaces,
      });
    }

    if (this.data && !!this.data.parentSpaceId) {
      this.floorDetailsArray.patchValue({
        parentSpaceId: this.data.parentSpaceId,
      });
      this.getSpaceByParentSpace(this.data.parentSpaceId);
    }

    if (this.data && !!this.data.floorNo) {
      this.floorDetailsArray.patchValue({
        floorNo: this.data.floorNo,
      });
    }

    console.log('floor name ', this.data.floorPlan);
    if (this.data && (!!this.data.floorPlan || !!this.data.fileName)) {
      this.fileName = this.data.floorPlan;
      this.fileNameFromDb = this.data.layoutName;
      this.floorDetailsArray.patchValue({
        LayoutName: this.data.layoutName,
      });
      this.floorDetailsArray.patchValue({
        fileName: this.data.floorPlan,
      });
      this.isLayout = true;
    } else {
      this.isLayout = false;
    }
  }

  onFileChange(event: any) {
    const validExts = new Array('.png', '.jpg', '.jpeg');
    let fileExt = event.target.files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));
    if (validExts.indexOf(fileExt.toLowerCase()) < 0) {
      this.fileError = 'Invalid file selected';
      return false;
    } else {
      this.fileError = '';
      this.fileName = event.target.files[0].name;
      this.floorDetailsArray.patchValue({
        floorPlan: event.target.files[0],
      });

      return true;
    }
  }

  upload() {
    const fomrdata: any = new FormData();
    fomrdata.append('file', this.floorDetailsArray.value.floorPlan);
    this.service.uploadFacilityLayout(fomrdata).subscribe((layout: any) => {
      console.log('layout', layout);
      this.fileNameFromDb = layout.imageName;
      this.fileName = this.floorDetailsArray.value.floorPlan.name;
      this.floorDetailsArray.patchValue({
        LayoutName: layout.imageName,
      });
    });
  }

  addRow() {
    this.formCollection.push(
      new FormGroup({
        key: new FormControl('', Validators.required),
        value: new FormControl('', Validators.required),
        id: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
      })
    );
  }

  deleteForm(i: any) {
    if (!!this.formCollection.controls[i].value.id) {
      this.service
        .deleteSpacePropertybySpacePropertyId(this.formCollection.controls[i].value.id)
        .subscribe((deleted: any) => {
          this.formCollection.removeAt(i);
        });
    } else {
      this.formCollection.removeAt(i);
    }
  }
  onKeyValueFileChange(event: any, index: number) {
    const fomrdata: any = new FormData();
    fomrdata.append('file', event.target.files[0]);
    this.service.uploadSpacePropertyAsFile(fomrdata).subscribe((fileparams: any) => {
      this.formCollection.controls[index].patchValue({
        value: fileparams.imageName,
        type: 'file',
      });
      this.addedInfoFileType[index] = 'file';
    });
  }

  close() {
    const formCollection: any = [];
    this.formCollection.controls.map((form: any) => {
      if (!!form.value.key) {
        formCollection.push(form.value);
      }
    });
    this.floorDetailsArray.value.addProperty = formCollection;
    this.dialogRef.close({
      ...this.floorDetailsArray,
    });
  }

  getSpaceByParentSpace(parentId: any) {
    this.service.getSpaceDetailsByParentSpace(parentId).subscribe((floors: any) => {
      if (floors.length > 0) {
        floors.forEach((floor: any) => {
          const floorObj: any = {};
          if (floor.spaceProperties.length > 0) {
            if (this.data.spaceId === undefined || floor.spaceId !== this.data.spaceId) {
              if (floor.spaceProperties.findIndex((element: any) => element.propertyKey === 'floorPlan') !== -1) {
                floor.spaceProperties.forEach((property: any) => {
                  floorObj.spaceId = floor.spaceId;
                  if (property.propertyKey === 'name') {
                    floorObj.floorName = property.propertyValue;
                  }
                });
                this.existingFloorsList.push(floorObj);
              }
            }
          }
        });
        console.log('floor list', this.existingFloorsList);
      }
    });
  }

  floorselection(floor: any) {
    console.log('floor selected', floor);

    this.service.getSpaceDetailsBySpaceId(floor.spaceId).subscribe((floorData: any) => {
      if (floorData.spaceProperties.findIndex((element: any) => element.propertyKey === 'floorPlan') !== -1) {
        floorData.spaceProperties.forEach((property: any) => {
          if (property.propertyKey === 'floorPlan') {
            this.fileName = property.propertyValue;
            this.service.getFloorImage(floor.spaceId).subscribe((image: any) => {
              console.log('image', image);
              const imageName = this.fileName;
              const imageBlob = this.dataURItoBlob(image.base64String);
              const imageFile = new File([imageBlob], imageName, { type: 'image/png' });
              this.floorDetailsArray.patchValue({
                floorPlan: imageFile,
              });
            });
          }
        });
      }
    });
  }

  dataURItoBlob(dataURI: any) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  onValueChange(event: any, index: any) {
    this.addedInfoFileType[index] = '';
  }

  downloadMoreInfoFile(index: any) {
    let filename: any;
    if (index !== -1) {
      filename = this.formCollection.controls[index].value.value;
    } else {
      filename = this.fileNameFromDb;
    }
    let fileType = '';
    if (
      filename.toLowerCase().includes('png') ||
      filename.toLowerCase().includes('jpg') ||
      filename.toLowerCase().includes('jpeg')
    ) {
      fileType = 'image/' + filename.split('.')[1];
    } else if (
      filename.toLowerCase().includes('xlsx') ||
      filename.toLowerCase().includes('csv') ||
      filename.toLowerCase().includes('ods')
    ) {
      fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
    } else {
      fileType = 'application/' + filename.split('.')[1];
    }

    this.service.getUploadedSpacePropertyFile(filename).subscribe((resp: any) => {
      console.log('file resp ', resp);
      const blob = new Blob([resp], { type: fileType });
      const link = document.createElement('a');

      link.href = URL.createObjectURL(blob);

      link.setAttribute('visibility', 'hidden');
      link.download = filename.split('.')[0];

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  }

  onCapacity(buildingCapacity: any) {
    this.occupiedCapacity = Number(this.data.floorOccupiedCapacity) + Number(buildingCapacity.target.value);

    if (this.occupiedCapacity > this.TotalCapacity) {
      this.isExceedingCapacity = true;
    } else {
      this.isExceedingCapacity = false;
    }
  }

  onArea(squareFeetArea: any) {
    this.occpiedArea = Number(this.data.floorOccupiedArea) + Number(squareFeetArea.target.value);

    if (this.occpiedArea > this.totalArea) {
      this.isExceedingArea = true;
    } else {
      this.isExceedingArea = false;
    }
  }

  nameCheck(event: any) {
    if (this.existingBuildings.includes(event.target.value.toLowerCase())) {
      this.isExistingBuilding = true;
    } else {
      this.isExistingBuilding = false;
    }
  }
}
