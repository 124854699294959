<div class="overview-wrapper">
  <div class="row d-flex">
    <mat-card
      *ngIf="role != 'PortfolioManager'"
      class="heading-card"
      (click)="onNavigate('safe')"
    >
      <div class="icon-section">
        <img src="/assets/images/building.svg" class="heading-icon" />
      </div>
      <div class="icon-text">SAFE BUILDINGS</div>
    </mat-card>
    <mat-card
      *ngIf="
        role == 'FacilityManager' ||
        role == 'PortfolioManager' ||
        role == 'Admin'
      "
      class="heading-card"
      (click)="onNavigate('energy')"
    >
      <div class="icon-section">
        <img src="/assets/images/energy.svg" class="heading-icon" />
      </div>
      <div class="icon-text">ENERGY MANAGEMENT</div>
    </mat-card>
    <mat-card
      *ngIf="
        role == 'Employee' ||
        role == 'Security' ||
        role == 'Admin' ||
        role == 'FacilityManager'
      "
      class="heading-card"
      (click)="onNavigate('workspace')"
    >
      <div class="icon-section">
        <img src="/assets/images/coworking.svg" class="heading-icon" />
      </div>
      <div class="icon-text">WORKSPACE EXPERIENCE</div>
    </mat-card>
    <!-- <mat-card
      *ngIf="role == 'FacilityManager' || role == 'Admin'"
      class="heading-card"
      (click)="onNavigate('operational')"
    >
      <div class="icon-section">
        <img src="/assets/images/process.svg" class="heading-icon" />
      </div>
      <div class="icon-text">OPERATIONAL EFFICIENCY</div>
    </mat-card> -->
  </div>
  <div class="row d-flex justify-content-center"></div>
</div>
