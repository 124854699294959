import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
const routes = {
  quote: (c: RandomQuoteContext) => `/jokes/random?category=${c.category}`,
};

export interface RandomQuoteContext {
  // The quote's category: 'dev', 'explicit'...
  category: string;
}

@Injectable({
  providedIn: 'root',
})
export class QuoteService {
  public thermal_screen_baseURL = environment.TS_baseURL + 'ebtd/';
  public space_baseURL = environment.TS_baseURL + 'space-management/';
  public physical_screen_baseURL = environment.PD_baseURL + 'PhysicalDistanceCompliance';
  public hygiene_screen_baseURL = environment.HH_baseURL + 'HygieneCompliance';
  public people_density_baseURL = environment.PD_baseURL + 'PeopleDensity';
  public airquality_baseURL = environment.AQ_baseURL + 'AirQuality';
  private _selected_spaceId: any;
  get_selected_spaceId(): any {
    return this._selected_spaceId;
  }
  set_selected_spaceId(value: any) {
    this._selected_spaceId = value;
  }
  constructor(private httpClient: HttpClient) { }


  getRandomQuote(context: RandomQuoteContext): Observable<string> {
    return this.httpClient.get(routes.quote(context)).pipe(
      map((body: any) => body.value),
      catchError(() => of('Error, could not load joke :-('))
    );
  }

  getOrganization() {
    return this.httpClient.get(`${this.space_baseURL}space/root/region/3`);
  }

  getThermalScreenData(id: any, date: any) {
    return this.httpClient.get(`${this.thermal_screen_baseURL}space/${id}/dashboard/date/${date}`);
  }

  getPhysicalScreenData(id: any, spaceTypeId: any, startTime: any, endTime: any, timeZone: string) {
    return this.httpClient.get(
      `${this.physical_screen_baseURL}/Count?SpaceId=${id}&SpaceTypeId=${spaceTypeId}&StartTime=${startTime}&EndTime=${endTime}&GroupBy=childSpaceId&timeZone=${timeZone}`
    );
  }
  getAirQualityData(id: any, startDate: any, endDate: any, timeZone: string) {
    return this.httpClient.get(
      `${this.airquality_baseURL}/Average?SpaceId=${id}&StartDate=${startDate}&EndDate=${endDate}&GroupBy=SpaceId&TimeZone=${timeZone}`
    );
  }
  getHygieneScreenData(id: any, spaceTypeId: any, startTime: any, endTime: any, timeZone: string) {
    return this.httpClient.get(
      `${this.hygiene_screen_baseURL}/Count?SpaceId=${id}&SpaceTypeId=${spaceTypeId}&StartTime=${startTime}&EndTime=${endTime}&GroupBy=childSpaceId&timeZone=${timeZone}`
    );
  }

  getPeopleData(id: any, spaceTypeId: any, startDate: any, endDate: any, endTime: any, timeZone: string) {
    return this.httpClient.get(
      `${this.people_density_baseURL}/Notifications?SpaceId=${spaceTypeId}&SpaceTypeId=${spaceTypeId}&StartDate=${startDate}&EndDate=${endDate}&StartTime=00:00&EndTime=${endTime}&TimeZone=${timeZone}`
    );
  }
}
