<div class="root">
    <div class="row popupHeader">
      <div class="col-md-11 header-text">
        <div class="title">ADMIT ACCESS</div>
      </div>
      <div class="col-md-1"><img *ngIf="!isLightTheme" (click)="close()" src="assets/Group 99.png" /><img
          *ngIf="isLightTheme" (click)="close()" src="assets/Light-Theme/Group 99.png" /></div>
    </div>
    <hr style="color: #e6e6e6;" />
    <div [formGroup]="form">
      <div class="">
        <div class="resonText">Please Select Appropriate Reason For Admission</div>
      </div>
  
      <div class="row">
        <div class="col-xl-6">
          <div class="text-label">Reason</div>
          <div class="selector">
            <mat-form-field>
              <mat-select formControlName="reason" panelClass="admin-pop" [(value)]="resonSelection"
                (selectionChange)="resonChange()">
                <mat-option class="font-14" value="Elevated">Elevated Temperature</mat-option>
                <mat-option class="font-14" value="Normal">Normal Temperature</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-xl-6">
          <label class="text-label">Rechecked Temperature</label>
          <div class="selector" *ngIf="resonSelection === 'Elevated'">
            <mat-form-field>
              <input matInput type="number" autocomplete="off" (keyup)="valaidateTemperature()"
                formControlName="temperature" min="{{ temperatureThershold }}" max="{{ elevatedTemperature }}" />
              <!-- <img *ngIf="!!form.value.temperature && tempertureVaidation == 'Normal' " src="assets/Group 255.png"
                class="validationImg" alt="">
              <img *ngIf="!!form.value.temperature && tempertureVaidation == 'Alert' " src="assets/Group 254.png"
                class="validationImg" alt=""> -->
            </mat-form-field>
          </div>
          <div class="selector" *ngIf="resonSelection !== 'Elevated'">
            <mat-form-field>
              <input matInput type="number" autocomplete="off" (keyup)="valaidateTemperature()"
                formControlName="temperature" min="{{ minimumBodyTemperature }}" max="{{ temperatureThershold }}" />
            </mat-form-field>
          </div>
        </div>
      </div>
  
      <div class="row">
        <div class="col-xl-6">
          <div class="text-label">Approved by</div>
          <div class="selector">
            <mat-form-field>
              <mat-select id="select" panelClass="admin-pop" formControlName="approvedBy" [(value)]="selected">
                <mat-option class="font-14" [value]="user">{{ user }}</mat-option>
                <!-- <mat-option class="font-14" value="Micheal">Michael Gill</mat-option> -->
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="col-xl-6">
          <div *ngIf="!!selectedReasonFlag">
            <div class="radio-option">
              <mat-checkbox class="font-14" *ngIf="docSelected == 'Received'" disabled formControlName="recivied">Received
                Medical Document</mat-checkbox>
              <mat-checkbox class="font-14" *ngIf="!docSelected" formControlName="uploaded">
                Uploaded Medical Document
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  
    <mat-dialog-actions>
      <button class="mat-raised-button cancel-btn" (click)="close()">Cancel</button>
      <button class="mat-raised-button mat-primary save-btn" (click)="save()" [disabled]="isDisabled()">Submit</button>
    </mat-dialog-actions>
  </div>