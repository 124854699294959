import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FacilityService } from '../facility.service';

@Component({
  selector: 'app-building-onboard',
  templateUrl: './building-onboard.component.html',
  styleUrls: ['./building-onboard.component.scss']
})
export class BuildingOnboardComponent implements OnInit {

  buildingDetailsArray = new FormGroup({
    spaceId: new FormControl(''),
    buildingName: new FormControl('', Validators.required),
    squareFeetArea: new FormControl('', Validators.pattern('^[0-9]*$')),
    buildingCapacity: new FormControl('', Validators.pattern('^[0-9]*$')),
    childSpaces: new FormControl(''),
    latLong: new FormControl(''),
    addProperty: new FormControl(''),
  });

  addNew = 'new';

  latitude = 0;
  longitude = 0;
  zoom = 10; // google maps zoom level

  formCollection = new FormArray([]);

  addedInfoFileType: any[] = [];

  totalArea: any;
  TotalCapacity: any;
  occpiedArea: any;
  occupiedCapacity: any;
  isExceedingArea = false;
  isExceedingCapacity = false;
  existingBuildings: any[];
  isExistingBuilding = false;

  constructor(
    public dialogRef: MatDialogRef<BuildingOnboardComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private service: FacilityService
  ) {}

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    console.log('data from parent ', this.data);
    this.totalArea = this.data.totalArea;
    this.TotalCapacity = this.data.totalCapacity;
    this.occupiedCapacity = this.data.buildingOccupiedCapacity;
    this.occpiedArea = this.data.buildingOccupiedArea;
    this.existingBuildings = this.data.existingBuilding;
    if (!!this.data) {
      this.buildingDetailsArray.patchValue({
        spaceId: this.data.spaceId,
        buildingName: this.data.buildingName,
        squareFeetArea: this.data.capetArea,
        buildingCapacity: this.data.seatingCapacity,
        childSpaces: this.data.childSpaces,
      });
    }
    if (!!this.data && !!this.data.addProperty) {
      this.data.addProperty.map((form: any) => {
        this.formCollection.push(
          new FormGroup({
            key: new FormControl(form.key.split('_Add')[0], Validators.required),
            value: new FormControl(form.value, Validators.required),
            id: new FormControl(form.id, Validators.required),
            type: new FormControl(form.typr, Validators.required),
          })
        );
        this.addedInfoFileType.push('file');
      });
    } else {
      this.formCollection.push(
        new FormGroup({
          key: new FormControl('', Validators.required),
          value: new FormControl('', Validators.required),
          id: new FormControl('', Validators.required),
          type: new FormControl('', Validators.required),
        })
      );
    }

    if (!!this.data.latLong) {
      this.buildingDetailsArray.patchValue({
        latLong: this.data.latLong,
      });
      this.latitude = Number(this.data.latLong.split(',')[0]);
      this.longitude = Number(this.data.latLong.split(',')[1]);
    }
    console.log('lat long ', this.latitude, this.longitude);
    this.dialogRef.beforeClosed().subscribe(() => {});
  }

  placeMarker(event: any) {
    console.log('marker position', event);
    this.latitude = event.coords.lat;
    this.longitude = event.coords.lng;
    this.buildingDetailsArray.patchValue({
      latLong: event.coords.lat + ',' + event.coords.lng,
    });

    console.log('form', this.buildingDetailsArray.value);
  }

  getimagename() {
    return '/assets/Group 1009.png';
  }

  selectedLocation() {
    this.dialogRef.close();
  }

  addNewBuilding() {
    this.dialogRef.close();
  }

  addRow() {
    this.formCollection.push(
      new FormGroup({
        key: new FormControl('', Validators.required),
        value: new FormControl('', Validators.required),
        id: new FormControl('', Validators.required),
        type: new FormControl('', Validators.required),
      })
    );
  }

  deleteForm(i: any) {
    if (!!this.formCollection.controls[i].value.id) {
      this.service
        .deleteSpacePropertybySpacePropertyId(this.formCollection.controls[i].value.id)
        .subscribe((deleted: any) => {
          this.formCollection.removeAt(i);
        });
    } else {
      this.formCollection.removeAt(i);
    }
  }
  onKeyValueFileChange(event: any, index: number) {
    const fomrdata: any = new FormData();
    fomrdata.append('file', event.target.files[0]);
    this.service.uploadSpacePropertyAsFile(fomrdata).subscribe((fileparams: any) => {
      this.formCollection.controls[index].patchValue({
        value: fileparams.imageName,
        type: 'file',
      });
      this.addedInfoFileType[index] = 'file';
    });
  }

  close() {
    const formCollection: any = [];
    this.formCollection.controls.map((form: any) => {
      if (!!form.value.key) {
        formCollection.push(form.value);
      }
    });
    this.buildingDetailsArray.value.addProperty = formCollection;
    this.dialogRef.close({
      ...this.buildingDetailsArray,
    });
  }

  downloadMoreInfoFile(index: any) {
    console.log('file ', this.formCollection.controls[index].value.value);
    let fileType = '';
    if (
      this.formCollection.controls[index].value.value.toLowerCase().includes('png') ||
      this.formCollection.controls[index].value.value.toLowerCase().includes('jpg') ||
      this.formCollection.controls[index].value.value.toLowerCase().includes('jpeg')
    ) {
      fileType = 'image/' + this.formCollection.controls[index].value.value.split('.')[1];
    } else if (
      this.formCollection.controls[index].value.value.toLowerCase().includes('xlsx') ||
      this.formCollection.controls[index].value.value.toLowerCase().includes('csv') ||
      this.formCollection.controls[index].value.value.toLowerCase().includes('ods')
    ) {
      fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;';
    } else {
      fileType = 'application/' + this.formCollection.controls[index].value.value.split('.')[1];
    }

    this.service
      .getUploadedSpacePropertyFile(this.formCollection.controls[index].value.value)
      .subscribe((resp: any) => {
        console.log('file resp ', resp);
        const blob = new Blob([resp], { type: fileType });
        const link = document.createElement('a');

        link.href = URL.createObjectURL(blob);

        link.setAttribute('visibility', 'hidden');
        link.download = this.formCollection.controls[index].value.value.split('.')[0];

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
  }

  onValueChange(event: any, index: any) {
    this.addedInfoFileType[index] = '';
  }

  onCapacity(buildingCapacity: any) {
    this.occupiedCapacity = Number(this.data.buildingOccupiedCapacity) + Number(buildingCapacity.target.value);

    if (this.occupiedCapacity > this.TotalCapacity) {
      this.isExceedingCapacity = true;
    } else {
      this.isExceedingCapacity = false;
    }
  }

  onArea(squareFeetArea: any) {
    this.occpiedArea = Number(this.data.buildingOccupiedArea) + Number(squareFeetArea.target.value);

    if (this.occpiedArea > this.totalArea) {
      this.isExceedingArea = true;
    } else {
      this.isExceedingArea = false;
    }
  }

  nameCheck(event: any) {
    if (this.existingBuildings.includes(event.target.value.toLowerCase())) {
      this.isExistingBuilding = true;
    } else {
      this.isExistingBuilding = false;
    }
  }

  clearLocation() {
    this.buildingDetailsArray.patchValue({
      latLong: '',
    });
  }
}
