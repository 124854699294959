import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatTableFilter } from 'mat-table-filter';
import { globalConstant } from '../../../safebuilding-global-constants';
import { environment } from '../../../../../environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { QuarantineService } from '../../quarantine.service';
import { CredentialsService } from '../../../../login/credentials.service';
import { AppService } from '../../../../app.service';
import * as moment from 'moment';
import { DeleteSurveyComponent } from '../delete-survey/delete-survey.component';
import { PreviewFormComponent } from '../preview-form/preview-form.component';
import { DeniedListComponent } from '../denied-list/denied-list.component';
import { StopPublishComponent } from '../stop-publish/stop-publish.component';
import { MatPaginator } from '@angular/material/paginator';

export class SurveyForm {
  locationName: string;
}
export class PropertyData {
  surveyForm: SurveyForm;
  status: string;
}

const PROPERTY_DATA: PropertyData[] = [{ surveyForm: { locationName: 'bentonvielle' }, status: 'completed' }];
@Component({
  selector: 'app-form-list',
  templateUrl: './form-list.component.html',
  styleUrls: ['./form-list.component.scss']
})
export class FormListComponent implements OnInit {

  surveyData: any = [];
  showFormlist: boolean = true;
  actionType: any;
  showNoData: boolean = false;
  masterSurveyData: any = [];
  formList: any = [];
  displayedColumns: any[] = [
    'formTitle',
    'locationName',
    'startDate',
    'endDate',
    'status',
    'response',
    'accessDenied',
    'action',
  ];
  dataSource = new MatTableDataSource();
  editSurveyId: any;
  employeeId: any;
  timeZone: string = globalConstant.timeZone;
  disableStopPublishButton: boolean = false;
  isLightTheme: boolean = environment.isLightTheme;
  applyFilters: boolean = false;
  filterEntity: PropertyData;
  filterType: MatTableFilter;
  countryFilters: boolean = false;
  isMobileView: boolean;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;

  constructor(
    public service: QuarantineService,
    public dialog: MatDialog,
    private credentialsService: CredentialsService,
    private appservice: AppService
  ) { }

  ngOnInit(): void {
    this.isMobileView = this.appservice.isMobileDevice();
    this.appservice.currentTheme.subscribe(theme => this.isLightTheme = (theme == 'theme-light' ? true : false));
    this.editSurveyId = '';
    this.applyFilters = false;
    this.countryFilters = false;
    this.employeeId = localStorage.getItem('employeeId');;
    this.filterEntity = new PropertyData();
    this.filterEntity.surveyForm = new SurveyForm();
    this.filterType = MatTableFilter.ANYWHERE;
    this.loadFormListData();
    console.log('RAM TIME ZONE DATA');
    console.log(this.timeZone);
    console.log(this.credentialsService);
  }
  loadFormListData() {
    this.surveyData = [];
    this.service.getMasterHealthSurvey(this.timeZone, this.employeeId).subscribe(
      (surveryInfo: any) => {
        this.masterSurveyData = surveryInfo;
        console.log(this.masterSurveyData);
        if (this.masterSurveyData) {
          this.masterSurveyData.forEach((survey: any) => {
            this.surveyData.push({
              surveyId: survey.surveyId,
              formTitle: survey.formTitle,
              startDate: survey.startDate,
              endDate: survey.endDate,
              status: survey.status,
              sortOrder: survey.status.toLowerCase() === 'live' ? 1 : 2,
              response: survey.responseReceivedCount,
              employeeCount:
                survey.surveyShownEmployeeCount && survey.surveyShownEmployeeCount > 0
                  ? survey.surveyShownEmployeeCount
                  : '',
              accessDenied: survey.accessDeniedCount,
              isStartDateGreater:
                new Date(moment(survey.startDate).format('YYYY-MM-DD')).getTime() >
                  new Date(moment(survey.endDate).format('YYYY-MM-DD')).getTime()
                  ? true
                  : false,
              action:
                survey.status.toLowerCase() === 'not published' || survey.status.toLowerCase() === 'published'
                  ? 'editdelete'
                  : survey.status.toLowerCase() === 'live'
                    ? 'withdraw'
                    : 'copytemplate',
              surveyForm: survey,
            });
          });
          this.surveyData.sort(this.compare);
        } else {
          this.showNoData = true;
        }
        this.surveyData.forEach((i: any) => {
          i.disableStopPublishButton = false;
        });

        let liveSurvey = this.surveyData.filter((survey: any) => survey.status.toLowerCase() === 'live');
        if (liveSurvey) {
          liveSurvey.forEach((i: any) => {
            let liveEndDate = i ? moment(i.endDate).format('YYYY-MM-DD') : '';
            var today = moment(new Date()).format('YYYY-MM-DD'); //'2020-07-23'
            if (new Date(today).getTime() == new Date(liveEndDate).getTime()) {
              i.disableStopPublishButton = true;
            }
          });
        }

        this.dataSource = new MatTableDataSource(this.surveyData);
        console.log(this.dataSource);
        console.log("t=surve",this.surveyData);
        this.dataSource.paginator = this.paginator;
      },
      (err: any) => {
        console.log(err);
      }
    );
  }
  compare(a: any, b: any) {
    const firstVal = a.sortOrder;
    const secondVal = b.sortOrder;

    let comparison = 0;
    if (firstVal < secondVal) {
      comparison = -1;
    } else if (firstVal > secondVal) {
      comparison = 1;
    }
    return comparison;
  }
  createForm() {
    this.applyFilters = false;
    this.countryFilters = false;
    this.showFormlist = !this.showFormlist;
    if(this.isMobileView){
      this.service.setShow(false);
    }
  }
  edit(element: any, type: any) {
    this.editSurveyId = element.surveyId;
    this.actionType = type;
    this.showFormlist = !this.showFormlist;
  }
  delete(element: any) {
    const dialogRef = this.dialog.open(DeleteSurveyComponent, {
      width: '540px',
      panelClass: 'success-container',
      data: {
        message: element.formTitle,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result.data && result.data.action === 'close') {
      } else {
        this.service.deleteMasterHealthSurvery(element.surveyId, this.timeZone).subscribe(
          (deleted: any) => {
            this.loadFormListData();
          },
          (err) => {
            console.log('In error response');
            this.loadFormListData();
          }
        );
      }
    });
  }
  showComponent(component: any) {
    this.editSurveyId = '';
    this.actionType = '';
    this.showFormlist = true;

    this.loadFormListData();
  }
  previewForm(survey: any) {
    console.log('Survey form');
    //let postData = this.preparePostJson('preview')
    const dialogRef = this.dialog.open(PreviewFormComponent, {
      width: '540px',
      panelClass: 'survey-form',
      data: { surveyData: survey },
    });
    dialogRef.afterClosed().subscribe(() => {
      console.log('RAM The dialog was closed');
    });
  }
  deniedEmployees(surveyId: any) {
    //getAccessDeniedList
    console.log('RAM Access denied survey id ', surveyId);
    const dialogRef = this.dialog.open(DeniedListComponent, {
      width: '1117px',
      panelClass: 'survey-form',
      data: {
        surveyId: surveyId,
        timeZone: this.timeZone,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('RAM dialog closed');
    });
  }
  stoppublish(element: any) {
    console.log('RAM stop publish');
    const dialogRef = this.dialog.open(StopPublishComponent, {
      width: '540px',
      panelClass: 'stop-publish-form',
      data: {
        message: element.formTitle,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('RAM dialog closed');
      if (result.data && result.data.action === 'close') {
      } else {
        let postJson = {
          surveyId: element.surveyId,
          endDate: moment(new Date()).utc().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
          status: 'Live',
          modifiedBy: this.employeeId,
          modifiedDate: moment(new Date()).utc().format('YYYY-MM-DD HH:mm:ss'),
          timeZone: this.timeZone,
        };
        this.service.withdrawLiveSurvey(postJson).subscribe(
          (response: any) => {
            this.loadFormListData();
          },
          (err: any) => {
            console.log('In error response');
          }
        );
      }
    });
  }

  showFilters() {
    this.applyFilters = true;
  }
  clearFilters() {
    this.applyFilters = false;
    this.filterEntity.status = '';
  }
  applyCountryFilter() {
    this.countryFilters = true;
    console.log('in countsssssssssss');
  }
  clearCountryFilter() {
    this.countryFilters = false;
    this.filterEntity.surveyForm.locationName = '';
    console.log('in counts');
  }
  ngOnDestroy() {
    this.applyFilters = false;
  }
}
