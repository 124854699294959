import { Component, OnInit, Inject } from '@angular/core';
import { environment } from '../../../../../environments/environment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'app-stop-publish',
  templateUrl: './stop-publish.component.html',
  styleUrls: ['./stop-publish.component.scss']
})
export class StopPublishComponent implements OnInit {

  isLightTheme: boolean = environment.isLightTheme;
  constructor(public dialogRef: MatDialogRef<StopPublishComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void { }
  cancel(action: any) {
    this.dialogRef.close({ data: { action: action } });
  }
}
