import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// import { HomeScreenComponent } from './home/home-screen/home-screen.component';
// import { LoginComponent } from './login/login.component';
// import { HomeComponent } from './safe-building/home/home.component';
import { OverviewComponent } from './overview/overview.component';
import { RoleAuthGuard } from './role.guard';

const routes: Routes = [
  // { path: "", redirectTo: "login", pathMatch: "full" },
  // {
  //   path: "login",
  //   component: LoginComponent
  // },
  {
    path: 'overview',
    // data: { roles: ['FacilityManager'] },
    component: OverviewComponent,
    canActivate: [RoleAuthGuard],
  },
  {
    path: 'safe-buildings',
    data: { roles: ['Employee'] },
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./safe-building/safe-building.module').then(
        (mod) => mod.SafeBuildingModule
      ),
  },
  {
    path: 'racetrac',
    data: { roles: ['FacilityManager'] },
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./race-trac/race-trac.module').then((mod) => mod.RaceTracModule),
  },
  {
    path: 'physicaldistance/:id',
    data: { roles: ['FacilityManager'] },
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import(
        './safe-building/physical-distance-view/physical-distance.module'
      ).then((m) => m.PhysicalDistanceModule),
  },
  {
    path: 'handhygiene/:id',
    data: { roles: ['FacilityManager'] },
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./safe-building/hand-hygiene-view/hand-hygiene.module').then(
        (m) => m.HandHygieneModule
      ),
  },
  {
    path: 'facilityview/:facilityId',
    data: { roles: ['FacilityManager'] },
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./safe-building/facility-manager/facility-manager.module').then(
        (m) => m.FacilityManagerModule
      ),
  },
  {
    path: 'airquality',
    data: { roles: ['FacilityManager'] },
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./safe-building/air-quality/air-quality.module').then(
        (m) => m.AirQualityModule
      ),
  },
  {
    path: 'peopleDensity',
    data: { roles: ['FacilityManager'] },
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./safe-building/people-density/people-density-view.module').then(
        (m) => m.PeopleDensityViewModule
      ),
  },
  {
    path: 'safe-buildings/admin',
    data: { roles: ['Admin'] },
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./safe-building/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'hrView',
    data: { roles: ['Admin'] },
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./safe-building/hraccess-view/hraccess-view.module').then(
        (m) => m.HraccessViewModule
      ),
  },
  {
    path: 'security',
    data: { roles: ['Security'] },
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./safe-building/security/security.module').then(
        (m) => m.SecurityModule
      ),
  },
  {
    path: 'employee',
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./safe-building/employee/employee.module').then(
        (m) => m.EmployeeModule
      ),
  },
  {
    path: 'workspace',
    canActivate: [RoleAuthGuard],
    data: { roles: ['Admin', 'Employee', 'Security', 'FacilityManager'] },
    loadChildren: () =>
      import('./workspace-experience/smart-workspaces.module').then(
        (m) => m.SmartWorkspacesModule
      ),
  },
  {
    path: 'energy',
    canActivate: [RoleAuthGuard],
    loadChildren: () =>
      import('./enery-management/energy-management.module').then(
        (m) => m.EnergyManagementModule
      ),
  },
  // Fallback when no prior route is matched
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
