import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { BulkUploadFacilityComponent } from '../bulk-upload-facility/bulk-upload-facility.component';
import { DeleteFacilityComponent } from '../delete-facility/delete-facility.component';
import { FacilityService } from '../facility.service';
import { FloorViewComponent } from '../floor-view/floor-view.component';

@Component({
  selector: 'app-facility-list',
  templateUrl: './facility-list.component.html',
  styleUrls: ['./facility-list.component.scss']
})
export class FacilityListComponent implements OnInit {

  displayedColumns: string[] = [
    'facilityName',
    'facilityType',
    'faciitySqFeet',
    'noOfBuildings',
    'region',
    'country',
    'city',
    'action',
  ];
  dataSource = new MatTableDataSource();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild('paginator', { static: true }) paginator: MatPaginator;
  @Output() toogleView = new EventEmitter<any>();
  @Output() getSpaceId = new EventEmitter<any>();

  @ViewChild('search') search: ElementRef;

  uploadFailure: any;

  constructor(public dialog: MatDialog, private service : FacilityService) {}

  ngOnInit(): void {
    this.getData();
    this.paginator._intl.previousPageLabel = 'Previous Page';
    this.paginator._intl.nextPageLabel = 'Next Page';
  }

  getData() {
    const datasourceFacility: any = [];
    const facility: any = [];

    this.service.getFacilityByRegion().subscribe((facilities: any) => {

      facilities.map((value: any) => {
        const temp = {
          spaceId: '',
          facilityName: '',
          facilityType: '',
          facilityCapacity: '',
          noOfBuildings: '',
          faciitySqFeet: '',
          region: '',
          country: '',
          city: '',
        };
        value.spaceProperties.map((property: any) => {
          if (property.propertyKey === 'name') {
            temp.facilityName = property.propertyValue;
            temp.spaceId = value.spaceId;
          }
          if (property.propertyKey === 'type') {
            temp.facilityType = property.propertyValue;
          }
          if (property.propertyKey === 'capacity') {
            temp.facilityCapacity = property.propertyValue;
          }
          if (property.propertyKey === 'childSpaces') {
            temp.noOfBuildings = property.propertyValue;
          }
          if (property.propertyKey === 'squareFeetArea') {
            temp.faciitySqFeet = property.propertyValue;
          }
          if (property.propertyKey === 'regionname') {
            temp.region = property.propertyValue;
          }
          if (property.propertyKey === 'countryname') {
            temp.country = property.propertyValue;
          }
          if (property.propertyKey === 'cityname') {
            temp.city = property.propertyValue;
          }
        });
        if (!!temp.spaceId) {
          facility.push(temp);
        }
      });

      facility.sort((a: any, b: any) => (a.spaceId < b.spaceId ? -1 : a.spaceId > b.spaceId ? 1 : 0));
      this.dataSource = new MatTableDataSource(facility.reverse());
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  onBoard() {
    this.toogleView.emit('onBoard');
  }

  delete(data: any) {
    const dialogRef = this.dialog.open(DeleteFacilityComponent, {
      width: '540px',
      panelClass: 'success-container',
      data: {
        message: data.facilityName,
        spaceId: data.spaceId,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.service.deleteSpace(result.data.spaceId).subscribe((deleted: any) => {
        this.getData();
      });
    });
  }

  bulkUpload() {
    const dialogRef = this.dialog.open(BulkUploadFacilityComponent, {
      width: '780px',
      panelClass: 'bulkupload',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result && result.refresh) {
        this.getData();
      }
    });
  }

  edit(data: any) {
    this.getSpaceId.emit(data.spaceId);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  showFacilityData() {
    const dialogRef = this.dialog.open(FloorViewComponent, {
      width: '90%',
      data: { spaceId: 2176 },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('result', result);
    });
  }
  download() {
    this.service.getFacilityTemplate().subscribe((template: any) => {
      const blob = new Blob([template], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;' });
      const url = window.URL.createObjectURL(blob);
      window.open(url);
    });
  }
  upload(files: FileList) {
    const formData: FormData = new FormData();
    formData.append('file', files.item(0));
    this.service.facilityBulkUpload(formData, 1, 0, 2, 1, 0).subscribe((resp: any) => {
      this.getData();
    });
  }

  toggleClass() {
    console.log(this.search.nativeElement);

    if (this.search.nativeElement.classList.contains('show')) {
      this.search.nativeElement.classList.remove('show');
    } else {
      this.search.nativeElement.classList.add('show');
    }
  }
}
