import { Component, OnInit,Inject} from '@angular/core';
import { environment } from '@env/environment';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CredentialsService } from '@app/login/credentials.service';
import { ThermalService } from '../service/thermal.service';
@Component({
  selector: 'app-admit-popup',
  templateUrl: './admit-popup.component.html',
  styleUrls: ['./admit-popup.component.scss']
})
export class AdmitPopupComponent implements OnInit {
  iotData: any;
  form: FormGroup;
  temperature: string;
  reason: any;
  approvedBy: any;
  uploadDoc = false;
  selected = 'John Doe';
  selectedReasonFlag: any;
  oldTemp: any;

  docSelected: any;

  resonSelection = '';
  tempertureVaidation = '';

  Received = false;
  Uploaded = true;
  user: any;
  minimumBodyTemperature = '96';
  temperatureThershold = '';
  elevatedTemperature = '106';
  isLightTheme: boolean = environment.isLightTheme;
  constructor(
    private fb: FormBuilder,
    //private messageService: MessageService,
    private dialogRef: MatDialogRef<AdmitPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private service: CredentialsService,
    private serviceApi: ThermalService
  ) {
    this.temperature = '';
    this.approvedBy = data.approvedBy;
    this.oldTemp = data.temperature;
    this.temperatureThershold = data.temperatureThershold;

    this.user = this.service.empdetails.username.split('@')[0].replace('.', ' ');
  }

  ngOnInit() {
    this.form = this.fb.group({
      temperature: [this.temperature, []],
      reason: [this.reason, []],
      approvedBy: [this.user, []],
      recivied: [],
      uploaded: [],
    });

    this.selected = 'John Doe';
  }

  resonChange() {
    if (this.resonSelection === 'Elevated') {
      this.selectedReasonFlag = true;
      if (this.data.uploaded) {
        this.docSelected = 'Received';
        setTimeout(() => {
          this.form.patchValue({
            recivied: true,
          });
        });
      }
    } else {
      this.selectedReasonFlag = false;
    }
  }

  save() {
    this.form.value.oldTemp = this.oldTemp;
    this.form.value.isNormal = this.resonSelection === 'Elevated' ? false : true;
    this.submitReason();
  }

  close() {
    this.dialogRef.close();
  }
  addImage(event: any) {
    this.uploadDoc = true;
  }
  submitReason() {
    console.log('this.description', this.data);

    const overrideValue = parseFloat(this.form.value.temperature);
    const overrideReason =
      !!this.form.value.reason && this.form.value.reason === 'Normal' ? 'Normal Temperature' : 'Elevated Temperature';

    this.serviceApi.postAdmitData(this.data.id, overrideReason, overrideValue).subscribe((value: any) => {
      console.log(value);
      this.dialogRef.close(this.form.value);
    });
  }

  valaidateTemperature() {
    this.tempertureVaidation = '';
    if (
      !!this.form.value &&
      parseFloat(this.form.value.temperature) <= 99.3 &&
      parseFloat(this.form.value.temperature) > 93
    ) {
      this.tempertureVaidation = 'Normal';
    } else {
      this.tempertureVaidation = 'Alert';
    }
  }

  isDisabled() {
    console.log(this.form.value.recivied, this.form.value.uploaded);
    if (
      !!this.form.value &&
      !!this.form.value.temperature &&
      !!this.form.value.approvedBy &&
      !!this.form.value.reason
    ) {
      if (this.form.value.reason === 'Elevated' && (!!this.form.value.recivied || !!this.form.value.uploaded)) {
        if (
          parseFloat(this.form.value.temperature) <= parseFloat(this.elevatedTemperature) &&
          parseFloat(this.form.value.temperature) > parseFloat(this.temperatureThershold)
        ) {
          return false;
        } else {
          return true;
        }
      }

      if (this.form.value.reason === 'Normal') {
        if (
          parseFloat(this.form.value.temperature) <= parseFloat(this.temperatureThershold) &&
          parseFloat(this.form.value.temperature) >= parseFloat(this.minimumBodyTemperature)
        ) {
          return false;
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  }

}
