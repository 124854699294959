import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root',
})
export class FacilityService {
  baseUrl = environment.Energy_TS_baseURL + 'space-management';
  apiKey = environment.GoogleMapAPIKey;
  constructor(private http: HttpClient) {}

  saveSpaceDetails(json: any) {
    return this.http.post(this.baseUrl + '/space', json);
  }

  updateSpce(json: any) {
    return this.http.put(this.baseUrl + `/space/${json.spaceId}`, json);
  }

  deleteSpaceProperty(json: any) {
    return this.http.delete(
      this.baseUrl + `/space/property?spaceId=${json.spaceId}`
    );
  }

  deleteSpacePropertybySpacePropertyId(SpacePropertyId: any) {
    return this.http.delete(
      this.baseUrl + `/space/property/${SpacePropertyId}`
    );
  }

  getSpaceDetailsByParentSpace(parentSpaceId: number) {
    return this.http.get(this.baseUrl + `/space/parent/${parentSpaceId}`);
  }

  getSpaceDetailsBySpaceId(spaceId: number) {
    return this.http.get(this.baseUrl + `/space/${spaceId}`);
  }

  getFacilityByRegion() {
    return this.http.get(this.baseUrl + `/space/parent/0`);
  }

  faciityNameCheck(name: string) {
    return this.http.get(this.baseUrl + `/space/property/check/${name}`);
  }

  deleteSpace(spaceId: any) {
    return this.http.delete(this.baseUrl + `/space/${spaceId}`);
  }

  addSpaceProperty(json: any) {
    return this.http.post(this.baseUrl + `/space/property`, json);
  }

  uploadFloorLayout(file: any, spaceId: any) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(`${this.baseUrl}/space/zone/image/${spaceId}`, file);
  }

  getUploadedFloorPlan(spaceId: any) {
    return this.http.get(`${this.baseUrl}/space/zone/image/base64/${spaceId}`);
  }

  facilityBulkUpload(
    file: any,
    organizationId: number,
    parentSpaceId: number,
    spaceTypeId: number,
    regionId: number,
    child: number
  ) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(
      this.baseUrl +
        `/facility/bulk-upload?organizationId=${organizationId}&parentSpaceId=${parentSpaceId}&regionId=${regionId}&spaceTypeId=${spaceTypeId}`,
      file
    );
  }

  buildingBulkUpload(
    file: any,
    organizationId: number,
    parentSpaceId: number,
    spaceTypeId: number,
    regionId: number,
    child: number
  ) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(
      this.baseUrl +
        `/building/bulk-upload?organizationId=${organizationId}&parentSpaceId=${parentSpaceId}&regionId=${regionId}&spaceTypeId=${spaceTypeId}`,
      file
    );
  }

  floorBulkUpload(
    file: any,
    organizationId: number,
    parentSpaceId: number,
    spaceTypeId: number,
    regionId: number,
    child: number
  ) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(
      this.baseUrl +
        `/floor/bulk-upload?organizationId=${organizationId}&parentSpaceId=${parentSpaceId}&regionId=${regionId}&spaceTypeId=${spaceTypeId}`,
      file
    );
  }

  zoneBulkUpload(
    file: any,
    organizationId: number,
    parentSpaceId: number,
    spaceTypeId: number,
    regionId: number,
    child: number
  ) {
    const headers = new HttpHeaders();
    headers.append('Content-Type', 'multipart/form-data');
    return this.http.post(
      this.baseUrl +
        `/zone/bulk-upload?organizationId=${organizationId}&parentSpaceId=${parentSpaceId}&regionId=${regionId}&spaceTypeId=${spaceTypeId}`,
      file
    );
  }

  uploadFacilityLayout(file: any) {
    return this.http.post(`${this.baseUrl}/space/property/file`, file);
  }
  uploadSpacePropertyAsFile(file: any) {
    return this.http.post(`${this.baseUrl}/space/property/file`, file);
  }

  getAllCountries() {
    return this.http.get(`${this.baseUrl}/country`);
  }

  getAllState(countryId: number) {
    return this.http.get(`${this.baseUrl}/region/root/country/${countryId}`);
  }

  getAllCities(stateID: number) {
    return this.http.get(`${this.baseUrl}/region/parent/${stateID}`);
  }

  getGlobalRegions() {
    return this.http.get(`${this.baseUrl}/global-region`);
  }

  getFacilityTypes() {
    return this.http.get(this.baseUrl + `/space/type`);
  }

  getGeoAddress(latlong: string) {
    // return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlong}&key=${this.apiKey}`);
    return this.http.get(`${this.baseUrl}/space/latlong/${latlong}`);
  }

  getFloorImage(spaveId: any) {
    return this.http.get(`${this.baseUrl}/space/zone/image/base64/${spaveId}`);
  }

  getFacilityTemplate() {
    return this.http.get(`${this.baseUrl}/bulk-upload/template/facility`, {
      responseType: 'blob',
    });
  }
  getBuildingTemplate() {
    return this.http.get(`${this.baseUrl}/bulk-upload/template/building`, {
      responseType: 'blob',
    });
  }
  getFloorTemplate() {
    return this.http.get(`${this.baseUrl}/bulk-upload/template/floor`, {
      responseType: 'blob',
    });
  }
  getZoneTemplate() {
    return this.http.get(`${this.baseUrl}/bulk-upload/template/zone`, {
      responseType: 'blob',
    });
  }

  uploadFacilityTemplate(template: any) {
    return this.http.post(
      `${this.baseUrl}/bulk-upload/template/facility`,
      template
    );
  }
  uploadBuildingTemplate(template: any) {
    return this.http.post(
      `${this.baseUrl}/bulk-upload/template/building`,
      template
    );
  }
  uploadFloorTemplate(template: any) {
    return this.http.post(
      `${this.baseUrl}/bulk-upload/template/floor`,
      template
    );
  }
  uploadZoneTemplate(template: any) {
    return this.http.post(
      `${this.baseUrl}/bulk-upload/template/zone`,
      template
    );
  }
  getFloorImageBlob(spaveId: any) {
    return this.http.get(`${this.baseUrl}/space/zone/image/${spaveId}`);
  }

  getFacilityByCountry(countryId: any) {
    return this.http.get(`${this.baseUrl}/space/root/country/${countryId}`);
  }

  getUploadedSpacePropertyFile(filename: any) {
    return this.http.get(
      `${this.baseUrl}/space/property/file?fileName=${filename}`,
      { responseType: 'blob' }
    );
  }

  getNameBySpaceId(spaceId: any) {
    return this.http.get(`${this.baseUrl}/space/name/${spaceId}`);
  }
}
