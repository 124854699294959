// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const AUTH_METHOD: { [key: string]: number } = {
  NO_AUTH: 0,
  B2C_AUTH: 1,
  COGNITO_AUTH: 2,
  AAD_AUTH: 3,
};
export const environment = {
  production: false,
  isLightTheme: false,
  base_url: 'http://connectedstore-ingress-dev.eastus.cloudapp.azure.com/',
  space_url:
    'http://connectedstore-ingress-dev.eastus.cloudapp.azure.com/space-management/',
  CT_baseURL:
    'https://onefacility-safespaces-api-01.eastus.cloudapp.azure.com/',
  HQ_baseURL:
    'https://onefacility-safespaces-api-01.eastus.cloudapp.azure.com/',
  PY_baseURL:
    'https://onefacility-safespaces-api-01.eastus.cloudapp.azure.com/',
  TS_baseURL:
    'https://onefacility-safespaces-api-01.eastus.cloudapp.azure.com/',
  PD_baseURL:
    'https://onefacility-safespaces-api-01.eastus.cloudapp.azure.com/',
  HH_baseURL:
    'https://onefacility-safespaces-api-01.eastus.cloudapp.azure.com/',
  AQ_baseURL:
    'https://onefacility-safespaces-api-01.eastus.cloudapp.azure.com/',

  TS_enable: true,
  PD_enable: true,
  HH_enable: true,
  PY_enable: true,
  HQ_enable: true,
  AQ_enable: true,
  spaceId: 1,
  PY_floorId: 253,
  PY_spaceId: 251,
  spaceName: 'Bentonville',
  loginType: AUTH_METHOD['NO_AUTH'],

  apiEndpoints: {
    deviceModel: 'https://devicemodelapi-oneconnect-eus-01.azurewebsites.net',
    institution: 'https://institutionapi-oneconnect-eus-01.azurewebsites.net/',
    deviceEnroll:
      'https://deviceenrollmentapi-oneconnect-eus-01.azurewebsites.net',
    telemetry:
      'https://telemetrysnapshotapi-oneconnect-eus-01.azurewebsites.net',
    deviceMeta:
      'https://devicemetadataapi-oneconnect-eus-01.azurewebsites.net/',
    workOrder: 'https://workorderapi-oneconnect-eus-01.azurewebsites.net/',
    rules: 'https://alarmsrulesapi-oneconnect-eus-01.azurewebsites.net/',
    ota: 'https://ctspackageapi.azurewebsites.net',
    alarms: 'https://devicealarm-oneconnect-weu-01.azurewebsites.net/',
    global: 'https://rulesapi-oneconnect-weu-01.azurewebsites.net',
    auth: 'https://iomtglobalauthservice.azurewebsites.net',
    cert: 'http://ec2-3-13-154-164.us-east-2.compute.amazonaws.com:8444', //still used in aws
    user: 'https://iomtuserroleservice.azurewebsites.net',
  },
  gKey: 'AIzaSyCWUD2mW2uQN2ft8W43WlaDnJQu39dAWUk',
  gApi: `https://maps.googleapis.com/maps/api/geocode/json?`,
  redirectUri: '/callback', // AAD Client-App's RedirectUri
  clientId: '5c43f8d1-141e-46d8-83c6-5d4a7d70bb92', // ClientID from AAD Client-App
  tenantId: '55d6e8b0-8eef-4f72-8f0a-510a3d8d482f', // AAD TenantID
  resourceURI: 'https://dev-safebuildings-ingress.eastus.cloudapp.azure.com/',
  APIClientID: 'api://60582536-f2c2-456c-84bb-60532a5a419e/access_as_user',
  apiUrl: 'https://prod.api.onefacilityworkspace.com/',
  meetingRoomUrl: 'https://prod.api.onefacilityworkspace.com/meeting/',
  graphUrl: 'https://prod.api.onefacilityworkspace.com/Graph/',
  geoserverUrl: 'https://prod.api.onefacilityworkspace.com/geoserver/',
  QRUrl:
    'http://onefacility-qr-generator.s3-website.ap-south-1.amazonaws.com/#/visitor',

  //end points for energy management
  Energy_TS_baseURL: 'https://portal.cognizant-1facility.com/',
  Energy_Base_url: 'https://portal.cognizant-1facility.com/',
  GoogleMapAPIKey: 'AIzaSyCWUD2mW2uQN2ft8W43WlaDnJQu39dAWUk',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
