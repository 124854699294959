import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

export interface Empdetails {
  // Customize received credentials here
  username: string;
  token: string;
}

const empdetailsKey = 'credentials';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root',
})
export class CredentialsService {
  Authenticated = false;
  role = '';
  roles: any[];
  userObj: any;
  private _empDetails: Empdetails | null = null;
  constructor(private http: HttpClient) {
    const savedEmpdetails = sessionStorage.getItem(empdetailsKey) || localStorage.getItem(empdetailsKey);
    if (savedEmpdetails) {
      this.Authenticated = true;
      this.role = localStorage.getItem('role');
      this.userObj = JSON.parse(localStorage.getItem('userdetails'));
      this._empDetails = JSON.parse(savedEmpdetails);
    }
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(route: any): boolean {
    // console.log(route, this.credentials);
    return this.Authenticated;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get empdetails(): Empdetails | null {
    return this._empDetails;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  setEmployeeDetails(empdetails?: Empdetails, remember?: boolean) {
    this._empDetails = empdetails || null;

    if (empdetails) {
      const storage = remember ? localStorage : sessionStorage;
      storage.setItem(empdetailsKey, JSON.stringify(empdetails));
      // CS Observation
      console.log('empdetails found');
    } else {
      console.log('No empdetails found');
      sessionStorage.removeItem(empdetailsKey);
      localStorage.removeItem(empdetailsKey);
    }
  }
}
