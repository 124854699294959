import { environment } from '@env/environment';

export const azure_authConfig = {
  auth: {
    clientId: environment.clientId,
    authority: 'https://login.microsoftonline.com/' + environment.tenantId,
    validateAuthority: true,
    redirectUri: window.location.origin,
    postLogoutRedirectUri:
      'https://login.microsoftonline.com/' +
      environment.tenantId +
      '/oauth2/v2.0/logout',
    navigateToLoginRequestUrl: false,
    popUp: false,
  },
  cache: {
    cacheLocation: 'localStorage',
  },
  scopes: {
    loginRequest: ['openid', 'profile', 'user.read', 'access_as_user'],
  },
  resources: {
    resourceUri: environment.resourceURI,
    resourceScope: environment.APIClientID,
  },
};
