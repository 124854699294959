import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import * as Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { chartColors } from '../../themes/chart-theme-variables';
import { AppService } from '@app/app.service';
import { environment } from '@env/environment';
import { Router } from '@angular/router';
import { CredentialsService } from '@app/login/credentials.service';
import { QuoteService } from '@app/safe-building/home/quote.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
})
export class OverviewComponent implements OnInit {
  role: string = localStorage.getItem('defaultRole');
  environmentVariable = environment;
  showLocation: boolean;
  facilities: any[] = [];
  spacetype: any;
  constructor(
    private router: Router,
    private service: AppService,
    private credentialService: CredentialsService,
    private quoteService: QuoteService,
  ) {}

  ngOnInit(): void {
    this.getFacility();
  }
  ngAfterContentChecked() {
    this.role = localStorage.getItem('defaultRole');
  }
  getFacility = () => {
    this.quoteService.getOrganization().subscribe((resp: any) => {
      resp.map((space: any) => {
        space['spaceProperties'].forEach((element: any) => {
          if (element.propertyKey.toLowerCase() === 'name') {
            this.facilities.push({
              value: space['spaceId'],
              viewValue: element.propertyValue,
            });
          }
        });
        this.spacetype = space['spaceType'].spaceTypeId;
        
      });
      localStorage.setItem("safebuildingSpaceId", this.facilities[0].value);
      localStorage.setItem("safebuildingSpaceTypeId", this.spacetype);
    });

  }
  onNavigate(title: any) {
    if (title == 'operational') {
      localStorage.setItem('headerText', 'operational efficiency');
      localStorage.setItem('subHeaderText', 'overview');
      this.service.showLocation.next(false);
      //this.credentialService.userObj.role = 'Sales Manager';
      if (this.role == 'FacilityManager') {
        this.router.navigate(['/racetrac']);
      } else if (this.role == 'Admin') {
        this.router.navigate(['/racetrac/admin']);
      }
    } else if (title == 'safe') {
      localStorage.setItem('headerText', 'safe buildings');
      localStorage.setItem('subHeaderText', 'overview');
      this.service.showLocation.next(false);
      // this.credentialService.userObj.role = 'Sales Manager';
      if (this.role == 'FacilityManager') {
        this.router.navigate(['/safe-buildings/dashboard'], {
          replaceUrl: true,
        });
      } else if (this.role == 'Admin') {
        this.router.navigate(['/safe-buildings/admin'], {
          replaceUrl: true,
        });
      } else if (this.role == 'HR') {
        this.router.navigate(['/safe-buildings/hrView'], {
          replaceUrl: true,
        });
      } else if (this.role == 'Employee') {
        this.router.navigate(['/safe-buildings/employee'], {
          replaceUrl: true,
        });
      } else if (this.role == 'Security') {
        this.router.navigate(['/safe-buildings/security'], {
          replaceUrl: true,
        });
      }
    } else if (title == 'workspace') {
      localStorage.setItem('headerText', 'Workspace Experience');
      localStorage.setItem('subHeaderText', 'overview');
      this.service.showLocation.next(true);

      if (this.role == 'Admin') {
        localStorage.setItem('subHeaderText', 'Organization Configuration');
        this.router.navigate(['/workspace/admin'], {
          replaceUrl: true,
        });
      } else if (this.role == 'Employee') {
        localStorage.setItem('subHeaderText', 'Home');
        this.router.navigate(['/workspace'], {
          replaceUrl: true,
        });
      } else if (this.role == 'Security') {
        localStorage.setItem('subHeaderText', 'Security');
        this.router.navigate(['/workspace/visitor-scan'], {
          replaceUrl: true,
        });
      } else if (this.role == 'FacilityManager') {
        localStorage.setItem('subHeaderText', 'Spaces');
        this.router.navigate(['/workspace/admin/space'], {
          replaceUrl: true,
        });
      }
      // localStorage.setItem('subHeaderText','overview');
      // this.credentialService.userObj.role = 'Employee';
      // this.router.navigate(['/workspace'], {
      //   replaceUrl: true,
      // });
    } else if (title == 'energy') {
      this.service.showLocation.next(false);
      localStorage.setItem('headerText', 'Energy Management');
      localStorage.setItem('subHeaderText', 'overview');
      if (this.role == 'FacilityManager') {
        this.router.navigate(['/energy/overview'], {
          replaceUrl: true,
        });
      } else if (this.role == 'PortfolioManager') {
        this.router.navigate(['/energy/dashboard'], {
          replaceUrl: true,
        });
      } else if (this.role == 'Admin') {
        this.router.navigate(['/energy/admin']);
      }
    }
  }
}
