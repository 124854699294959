<div class="lp-wrapper">
  <!-- <div class="back"><span>&lt;</span> BACK</div> -->
  <div
    class="building-card-container"
    [style.height.vh]="role == 'PortfolioManager' ? '82' : ''"
  >
    <div class="building-model">
      <canvas id="webgl"></canvas>
      <div class="zoom-btns">
        <div class="btns">
          <span (click)="zoomIn()">+</span>
          <span (click)="zoomOut()">-</span>
        </div>
      </div>
    </div>
    <div class="app-cards">
      <mat-card class="safe-buildings" *ngIf="role == 'FacilityManager'">
        <h4>AIR QUALITY</h4>
        <div class="parameters">
          <!-- <div class="param" *ngFor="let aq of airQualityData">
            <span>{{ aq.groupName }}</span>
            <span
              [ngClass]="{
                good_txt: aq?.complianceStatusId == '1',
                moderate_txt: aq?.complianceStatusId == '2',
                unhealthyforsome_txt: aq?.complianceStatusId == '3',
                unhealthy_txt: aq?.complianceStatusId == '4',
                veryunhealthy_txt: aq?.complianceStatusId == '5',
                hazardous_txt: aq?.complianceStatusId == '6'
              }"
              >{{ aq?.value + aq?.uom }}</span
            >
          </div> -->
          <!-- *ngIf="airQualityDatenergyConsumptiona.length" -->
          <div class="param">
            <span>Temperature</span>
            <!-- <span>{{ airQualityData[0]?.groupName || "Temperature" }}</span> -->
            <span
              [ngClass]="{
                good_txt: airQualityData[0]?.complianceStatusId == '1',
                moderate_txt: airQualityData[0]?.complianceStatusId == '2',
                unhealthyforsome_txt:
                  airQualityData[0]?.complianceStatusId == '3',
                unhealthy_txt: airQualityData[0]?.complianceStatusId == '4',
                veryunhealthy_txt: airQualityData[0]?.complianceStatusId == '5',
                hazardous_txt: airQualityData[0]?.complianceStatusId == '6'
              }"
              >{{ airQualityData[0]?.value + airQualityData[0]?.uom }}</span
            >
          </div>
          <div class="param">
            <!-- <span>{{ airQualityData[1]?.groupName || "Humidity" }}</span> -->
            <span>Humidity</span>
            <span
              [ngClass]="{
                good_txt: airQualityData[1]?.complianceStatusId == '1',
                moderate_txt: airQualityData[1]?.complianceStatusId == '2',
                unhealthyforsome_txt:
                  airQualityData[1]?.complianceStatusId == '3',
                unhealthy_txt: airQualityData[1]?.complianceStatusId == '4',
                veryunhealthy_txt: airQualityData[1]?.complianceStatusId == '5',
                hazardous_txt: airQualityData[1]?.complianceStatusId == '6'
              }"
              >{{ airQualityData[1]?.value + airQualityData[1]?.uom }}</span
            >
          </div>
          <div class="param">
            <span>Air Flow</span>
            <!-- <span>{{ airQualityData[2]?.groupName || "Air Flow" }}</span> -->
            <span
              [ngClass]="{
                good_txt: airQualityData[4]?.complianceStatusId == '1',
                moderate_txt: airQualityData[4]?.complianceStatusId == '2',
                unhealthyforsome_txt:
                  airQualityData[4]?.complianceStatusId == '3',
                unhealthy_txt: airQualityData[4]?.complianceStatusId == '4',
                veryunhealthy_txt: airQualityData[4]?.complianceStatusId == '5',
                hazardous_txt: airQualityData[4]?.complianceStatusId == '6'
              }"
              >{{ airQualityData[4]?.value + airQualityData[4]?.uom }}</span
            >
          </div>
          <div class="param">
            <span>PM10</span>
            <span
              [ngClass]="{
                good_txt: airQualityData[2]?.complianceStatusId == '1',
                moderate_txt: airQualityData[2]?.complianceStatusId == '2',
                unhealthyforsome_txt:
                  airQualityData[2]?.complianceStatusId == '3',
                unhealthy_txt: airQualityData[2]?.complianceStatusId == '4',
                veryunhealthy_txt: airQualityData[2]?.complianceStatusId == '5',
                hazardous_txt: airQualityData[2]?.complianceStatusId == '6'
              }"
              >{{ airQualityData[2]?.value + airQualityData[2]?.uom }}</span
            >
          </div>
          <div class="param">
            <span>PM2.5</span>
            <span
              [ngClass]="{
                good_txt: airQualityData[3]?.complianceStatusId == '1',
                moderate_txt: airQualityData[3]?.complianceStatusId == '2',
                unhealthyforsome_txt:
                  airQualityData[3]?.complianceStatusId == '3',
                unhealthy_txt: airQualityData[3]?.complianceStatusId == '4',
                veryunhealthy_txt: airQualityData[3]?.complianceStatusId == '5',
                hazardous_txt: airQualityData[3]?.complianceStatusId == '6'
              }"
              >{{ airQualityData[3]?.value + airQualityData[3]?.uom }}</span
            >
          </div>
          <div class="param more">
            <a (click)="onNavigate('safe')"> MORE DETAILS </a>
          </div>
        </div>
      </mat-card>
      <mat-card
        class="energy-management"
        *ngIf="role == 'FacilityManager' || role == 'PortfolioManager'"
      >
        <h4>ENERGY MANAGEMENT</h4>
        <div class="parameters">
          <div class="param">
            <span>Total Cost</span>
            <span>{{ totalCost | currency: "USD":"symbol":"1.0-0" }}</span>
          </div>
          <div class="param">
            <span>Cost per sq.feet</span>
            <span>{{ totalCost / facilityData?.area | currency }}</span>
          </div>
          <div class="param">
            <span>Renewable Sources</span>
            <span>{{ renewableResource | number: "":"en" }}%</span>
          </div>
          <div class="param">
            <span>CO2 Emissions</span>
            <span>{{ co2Emission | number: "":"en" }} tonnes</span>
          </div>
          <div class="param">
            <span>Consumption</span>
            <span
              >{{ energyConsumption | number: "":"en" }} <span> kWh</span></span
            >
          </div>
          <div class="param more">
            <a (click)="onNavigate('energy')"> MORE DETAILS </a>
          </div>
        </div>
      </mat-card>
      <mat-card
        class="workspace-experience"
        *ngIf="role == 'FacilityManager'"
        style="margin-bottom: 0"
      >
        <h4>OCCUPANCY AND UTILIZATION</h4>
        <div class="parameters">
          <div class="param">
            <span>Total Capacity</span>
            <span>2000</span>
          </div>
          <div class="param">
            <span>Building sq.feet</span>
            <span>3500</span>
          </div>
          <div class="param">
            <span>Current Occupancy</span>
            <span>1200</span>
            <!-- class="occ" -->
          </div>
          <div class="param">
            <span>Meeting Rooms in use</span>
            <span>180</span>
          </div>
          <div class="param">
            <span>Hotdesks in use</span>
            <span>420</span>
          </div>
          <div class="param more">
            <a (click)="onNavigate('workspace')"> MORE DETAILS </a>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
</div>
