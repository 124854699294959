import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { globalConstant } from '@app/safe-building/safebuilding-global-constants';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { EmployeeService } from '../service/employee.service';
import { MatDialog } from '@angular/material/dialog';
import { AppService } from '@app/app.service';
import { QRCodeModule } from 'angularx-qrcode';
import { CredentialsService } from '@app/login/credentials.service';
import moment from 'moment';
import { EmployeeQrcodeComponent } from '../employee-qrcode/employee-qrcode.component';
import { EmployeeQaComponent } from '../employee-qa/employee-qa.component';


export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

@Component({
  selector: 'app-employee-view',
  templateUrl: './employee-view.component.html',
  styleUrls: ['./employee-view.component.scss']
})
export class EmployeeViewComponent implements OnInit {

  isLightTheme: boolean = environment.isLightTheme;
  TS_enable: boolean = environment.TS_enable;
  PD_enable: boolean = environment.PD_enable;
  HH_enable: boolean = environment.HH_enable;
  HQ_enable: boolean = environment.HQ_enable;
  timeZone: string = globalConstant.timeZone;
  date: any;
  last14Days: any;
  displayedColumns: string[] = ['screenAt', 'temperature', 'faceMask', 'timestamp', 'status']; //'eventId',
  dataSource = new MatTableDataSource();
  displayedColumns1: string[] = ['secondaryPersonName', 'secondaryPersonId', 'duration', 'timeStamp'];
  dataSource1 = new MatTableDataSource();
  displayedColumns2: string[] = ['eventId', 'timeStamp', 'complianceFlag'];
  dataSource2 = new MatTableDataSource();
  employee: any;
  employeeId: any;
  temperatureCurent: any;
  tabledata: any;
  tabledata2: any;
  tabledata1: any;
  temperatureCurentComplaint: any;
  lastHandwash: any;
  lastHandwashComplianceFlag: any;
  physicalAlert: any;
  physicalAlertComplianceFlag: boolean = true;
  @ViewChild('paginator', { static: false }) paginator: MatPaginator;
  @ViewChild('paginator1', { static: false }) paginator1: MatPaginator;
  @ViewChild('paginator2', { static: false }) paginator2: MatPaginator;
  startTime: string;
  endTime: string;
  limit: any;
  contactTracing: any;
  screenWidth: any;
  screenHeight: any;
  isMobile: boolean;
  isShowQRcode: boolean = false;
  healthQuestionnaire: any;
  selectedEmail: any;
  expiryTime: string;
  selectedName: string;
  dataOffset: number = 0;
  dataLimit: number = 500;

  tabs =['THERMAL SCREENING','PHYSICAL DISTANCING','HAND HYGIENE'];
  tabSelected ='THERMAL SCREENING';

  constructor(
    private service: EmployeeService,
    public dialog: MatDialog,
    private appservice: AppService,
    public qrcode: QRCodeModule,
    private credentialsService: CredentialsService
  ) {}

  ngOnInit() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    console.log('h and w', this.screenHeight, this.screenWidth);
    this.isMobile = this.screenWidth >= 360 && this.screenWidth < 480 ? true : false;
    const credentials = this.credentialsService.empdetails;
    this.employeeId = localStorage.getItem("employeeId");
    console.log('id', this.employeeId);
    this.getEmployeeContactTracingData(this.employeeId);
    // this.retakeSurvey();
    this.getEmployeeData(this.employeeId);

    // this.appservice.getAllEmployees().subscribe((res) => {
    //   if (JSON.parse(JSON.stringify(res)).length > 0) {
    //     console.log('data', credentials, JSON.parse(JSON.stringify(res)));
    //     for (const element of JSON.parse(JSON.stringify(res))) {
    //       if (element.email.toLowerCase() === credentials.username.toLowerCase()) {
    //         this.employeeId = element.employeeId;
    //         break;
    //       } else {
    //         this.employeeId = null;
    //       }
    //     }
    //     console.log('id', this.employeeId);
    //     this.getEmployeeData(this.employeeId);
    //     this.getEmployeeContactTracingData(this.employeeId);
    //   }
    // });
    //this.openFeedback()
    this.appservice.currentTheme.subscribe((theme) => (this.isLightTheme = theme == 'theme-light' ? true : false));

  }

  tabChange(indexNumber: any){
    switch (indexNumber.value) {
      case 'THERMAL SCREENING':
        console.log(this.paginator)
        this.TS_enable = true;
        this.PD_enable = false;
        this.HH_enable = false;
        break;
      case 'PHYSICAL DISTANCING':
        console.log(this.paginator1)
        this.TS_enable = false;
        this.PD_enable = true;
        this.HH_enable = false;
        break;
      case 'HAND HYGIENE':
        console.log(this.paginator2)
        this.TS_enable = false;
        this.PD_enable = false;
        this.HH_enable = true;
    }

    setTimeout(() => {
      switch (indexNumber.value) {
        case 'THERMAL SCREENING':
          this.dataSource.paginator = this.paginator;
          break;
        case 'PHYSICAL DISTANCING':
          this.dataSource1.paginator = this.paginator1;
          break;
        case 'HAND HYGIENE':
          this.dataSource2.paginator = this.paginator2;
      }
    });
  }

  getName() {
    return this.employee.name;
  }

  getEmployeeData(empId: any) {
    this.date = moment().format('D MMM YYYY');
    this.last14Days = moment().subtract(13, 'days').format('D MMM YYYY');

    this.service.getEmployeeDetailsById(empId).subscribe(
      (employee: any) => {
        //  console.log('emp', employee);
        if (employee) {
          this.employee = employee.employeeList[0];
          //   console.log('########');
          //   console.log(this.employee);
          if (this.employee.name.length > 23) {
            this.selectedName = this.employee.name.substring(0, 23) + '...';
          } else {
            this.selectedName = this.employee.name;
          }

          if (this.employee.email.length > 37) {
            this.selectedEmail = this.employee.email.substring(0, 37) + '...';
          } else {
            this.selectedEmail = this.employee.email;
          }
        }
        if (this.TS_enable == true) {
          this.service.getEmployeeTemperatureTrendById(empId).subscribe(
            (data: any) => {
              console.log(data);
              if (data) {
                const temp: any = [];
                let tempVal: any;
                this.service.getThresholdTempBySpaceId(this.employee.spaceId).subscribe((tempData: any) => {
                  if (tempData.settingItems) {
                    tempData.settingItems.forEach((i: any) => {
                      if (i.settingsKeysValues === 'TSBodyTemperatureThreshold') {
                        tempVal = i.minValue;
                      }
                    });
                    // console.log('temp data', tempVal);
                    data.map((value: any, i: any) => {
                      // console.log(value, value.data);
                      if (!!value.overrideValue) {
                        temp.push({
                          eventId: data.length - i,
                          screenAt: value.spaceName,
                          temperature: Math.ceil(value.data * 10) / 10 + ' °F',
                          timestamp: value.reportedDateTime,
                          status: value.data > tempVal ? false : true,
                          overrideValue: value.overrideValue + ' °F',
                          vendorName: value.vendorName,
                          isMaskDetected: value.isMaskDetected,
                        });
                      } else {
                        temp.push({
                          eventId: data.length - i,
                          screenAt: value.spaceName,
                          temperature: Math.ceil(value.data * 10) / 10 + ' °F',
                          timestamp: value.reportedDateTime,
                          status: value.data > 99.3 ? false : true,
                          vendorName: value.vendorName,
                          isMaskDetected: value.isMaskDetected,
                        });
                      }
                    });
                    this.tabledata = temp;
                    //console.log('table', this.tabledata);
                    this.dataSource = new MatTableDataSource(this.tabledata);
                    this.dataSource.paginator = this.paginator;
                  }
                });
              }
            },
            (err) => {
              console.log(err);
            }
          );
        }
      },
      (err) => {
        console.log(err);
      }
    );
    if (this.TS_enable == true) {
      this.service.getEmployeeTemperatureById(empId).subscribe(
        (temp: any) => {
          if (temp.data) {
            this.temperatureCurent = Math.ceil(temp.data * 10) / 10;

            this.temperatureCurentComplaint = this.temperatureCurent > 99.3 ? false : true;
            const dateReportedMonth = moment(new Date(temp.reportedDateTime)).utc().get('month');
            const dateReportedYear = moment(new Date(temp.reportedDateTime)).utc().get('year');
            const dateReportedDay = moment(new Date(temp.reportedDateTime)).utc().get('date');
            const currentMonth = moment(new Date()).utc().get('month');
            const currentYear = moment(new Date()).utc().get('year');
            const currentDate = moment(new Date()).utc().get('date');

            console.log('Date Repoeted', dateReportedDay, dateReportedMonth, dateReportedYear);

            if (
              !(
                dateReportedMonth === currentMonth &&
                dateReportedYear === currentYear &&
                dateReportedDay === currentDate
              )
            ) {
              this.temperatureCurent = '-';
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
    if (this.HH_enable == true) {
      this.service.getHygieneComplianceDurationById(empId, this.timeZone).subscribe(
        (data: any) => {
          if (data) {
            console.log('hrs', data.duration);
            if (!!data.duration) {
              this.lastHandwash = parseFloat(data.duration.replace('Hours', ''));
              this.lastHandwashComplianceFlag = data.complianceFlag;
            }
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }

    // this.service.getPhysicalComplianceDurationById(empId).subscribe(
    //   (data: any) => {
    //     if (data) {
    //       this.physicalAlert = data.numberOfAlerts;
    //       this.physicalAlertComplianceFlag = data.complianceFlag;
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    this.getphysicalAlerts(empId);

    if (this.HH_enable == true) {
      this.getHygieneComplianceHistory(this.employeeId, this.dataOffset, this.dataLimit);
      /*this.service
        .getHygieneComplianceHistoryById(empId, this.getPreviousDateTime(), this.getStartDateTime(), this.timeZone, this.dataOffset, this.dataLimit)
        .subscribe(
          (data: any) => {
            const temp: any = [];
            if (data) {
              data.hygieneCompliances.map((value: any, i: any) => {
                temp.push({
                  eventId: i + 1,
                  spaceName: value.spaceName,
                  timeStamp: value.timeStamp,
                  complianceFlag: value.complianceFlag,
                });
              });
              this.tabledata2 = temp;
              this.dataSource2 = new MatTableDataSource(this.tabledata2);
              this.dataSource2.paginator = this.paginator2;
            }
          },
          (err) => {
            console.log(err);
          }
        );*/
    }

    // this.service.getPhysicalComplianceHistoryById(empId, this.getStartDateTime(), this.getEndDateTime()).subscribe(
    //   (data: any) => {
    //     const temp: any = [];
    //     if (data) {
    //       data.physicalDistanceCompliance.map((value: any, i: any) => {
    //         temp.push({
    //           eventId: i + 1,
    //           spaceName: value.spaceName,
    //           timeStamp: value.timeStamp,
    //         });
    //       });
    //       this.tabledata1 = temp;
    //       this.dataSource1 = new MatTableDataSource(this.tabledata1);
    //       this.dataSource1.paginator = this.paginator;
    //     }
    //   },
    //   (err) => {
    //     console.log(err);
    //   }
    // );
    this.dataSource.paginator = this.paginator;
  }

  getEmail() {
    return this.employee.email;
  }
  getStartDateTime() {
    let today = new Date();

    let startDate =
      today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate() + 'T' + '23:59:59';
    return startDate;
  }
  getPreviousDateTime() {
    var last13DaysDate = new Date(this.last14Days);
    let previousDate =
      last13DaysDate.getFullYear() +
      '-' +
      (last13DaysDate.getMonth() + 1) +
      '-' +
      last13DaysDate.getDate().toString().slice(-2) +
      'T' +
      '00:00:00';
    console.log(previousDate);
    return previousDate;
  }

  getEndDateTime() {
    var Onedayplus = new Date();
    Onedayplus.setUTCDate(Onedayplus.getUTCDate() + 1);

    let endDate =
      Onedayplus.getUTCFullYear() +
      '-' +
      (Onedayplus.getUTCMonth() + 1) +
      '-' +
      Onedayplus.getUTCDate() +
      'T' +
      '00:00:00';
    return endDate;
  }

  getphysicalAlerts(empId: any) {
    if (this.PD_enable == true) {
      this.service.getPhysicalComplianceDurationById(empId, this.timeZone).subscribe(
        (data: any) => {
          if (data) {
            this.physicalAlert = data.numberOfAlerts;
            if (this.physicalAlert > 0) {
              this.physicalAlertComplianceFlag = false;
            }
            //  this.physicalAlertComplianceFlag = data.complianceFlag;
            if (this.physicalAlert < 999) {
              this.limit = this.physicalAlert;
            } else {
              this.limit = 999;
            }
            console.log(this.limit);
            this.getPhysicalCompliancePeople(empId, this.limit);
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  }

  getPhysicalCompliancePeople(empId: any, limit: number) {
    console.log(limit);
    if (this.PD_enable == true) {
      this.service
        .getPhysicalComplianceHistoryById(
          empId,
          this.getPreviousDateTime(),
          this.getStartDateTime(),
          999,
          this.timeZone
        )
        .subscribe(
          (data: any) => {
            const temp: any = [];
            console.log(data);
            if (data) {
              data.physicalDistanceCompliance.map((value: any, i: any) => {
                temp.push({
                  eventId: i + 1,
                  spaceName: value.spaceName,
                  timeStamp: value.timeStamp,
                  secondaryPersonName: value.secondaryPersonName,
                  secondaryPersonId: value.secondaryPersonId,
                  duration: value.duration,
                });
              });
              this.tabledata1 = temp;
              this.dataSource1 = new MatTableDataSource(this.tabledata1);
              this.dataSource1.paginator = this.paginator1;
              console.log(this.tabledata1);
            }
          },
          (err) => {
            console.log(err);
          }
        );
    }
  }

  setDataSource(indexNumber: any) {
    console.log(indexNumber);
    setTimeout(() => {
      switch (indexNumber) {
        case 0:
          !this.dataSource.paginator ? (this.dataSource.paginator = this.paginator) : null;
          break;
        case 1:
          !this.dataSource1.paginator ? (this.dataSource1.paginator = this.paginator1) : null;
          break;
        case 2:
          !this.dataSource2.paginator ? (this.dataSource2.paginator = this.paginator2) : null;
      }
    });
  }

  openDialog(): void {
    if (!this.expiryTime && this.healthQuestionnaire) {
      this.expiryTime = this.healthQuestionnaire.expiryDate;
    }
    const dialogRef = this.dialog.open(EmployeeQrcodeComponent, {
      width: '330px',
      data: { empId: this.employeeId, expiryTime: this.expiryTime },
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  getEmployeeContactTracingData(empId: any) {
    /*this.contactTracing = {
      "personId": "234545",
      "personName": "Adam Smith",
      "designation": "Sales manager",
      "seat": "S00001",
      "duration": 0,
      "location": "ODC1",
      "status": "InQuarantine",
      "sourceId": "123123",
      "reportedDate": "2019-07-11T03:54:16.000Z",
      "exposedDate": "2019-07-11T03:54:16.000Z",
      "quarantineStartDate": "2020-04-25T03:54:16.000Z",
      "quarantineEndDate": "2020-05-25T03:54:16.000Z",
      "isAccessEnabled": true,
      "accessChangeDate": "2019-07-11T03:54:16.000Z",
      "remarks": "Access disabled because of quarantine",
      "exposedDetails": [
        null
      ]
    }*/
    console.log('from 1');
    this.service.getEmployeeContactTracin(empId, this.timeZone).subscribe(
      (contactTracing: any) => {
        if (contactTracing) {
          //  console.log(contactTracing);
          this.contactTracing = contactTracing.contactTraces[0];
          // console.log(contactTracing.contactTraces[0].status.toLowerCase());
          if (
            contactTracing.contactTraces[0].status.toLowerCase() === 'in quarantine' ||
            contactTracing.contactTraces[0].status.toLowerCase() === 'inquarantine'
          ) {
            contactTracing.contactTraces[0].status = 'in Quarantine';
          }
        }

        if (this.HQ_enable) {
          this.getEmployeeSurveyFormData();
        } else {
          this.isShowQRcode = true;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  retakeSurvey(): void {
    const dialogRef = this.dialog.open(EmployeeQaComponent, {
      width: '540px',
      panelClass: 'survey-form',
      data: { empId: this.employeeId, surveryQuestionnaire: this.healthQuestionnaire },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // console.log('RAM The dialog was closed');
      if (result) {
        this.isShowQRcode = result.data.isShowQrcode;
        this.expiryTime = result.data.expiryTime;
        this.ngOnInit();
        // console.log(result.data);
      }
    });
  }

  async getEmployeeSurveyFormData() {
    let surveyData = await this.service.getHealthQuestionnaire(this.employeeId, this.timeZone).toPromise();
    if (surveyData) {
      this.healthQuestionnaire = surveyData;
      console.log(
        'qa data',
        moment().utc(),
        moment().startOf('day'),
        moment(this.healthQuestionnaire.submitDate),
        moment(this.healthQuestionnaire.submitDate).utc(true).isBefore(moment().utc().startOf('day')),
        moment(this.healthQuestionnaire.submitDate).isBefore(moment().utc().startOf('day')),
        moment().utc().isAfter(moment(this.healthQuestionnaire.expiryDate))
      );
      if (this.healthQuestionnaire.accessFlag) this.isShowQRcode = true;
      else this.isShowQRcode = false;
      if (
        moment(this.healthQuestionnaire.submitDate).utc(true).isBefore(moment().utc().startOf('day')) ||
        moment().utc().isAfter(moment(this.healthQuestionnaire.expiryDate).utc(true))
      ) {
        this.isShowQRcode = false;
        if (
          this.contactTracing &&
          (this.contactTracing.status == '' || this.contactTracing.status.toLowerCase() == 'normal')
        ) {
          this.retakeSurvey();
        }
      }
    }
  }
  getHygieneComplianceHistory(empId: number, offset: number, limit: number) {
    this.service
      .getHygieneComplianceHistoryById(
        empId,
        this.getPreviousDateTime(),
        this.getStartDateTime(),
        this.timeZone,
        offset,
        limit
      )
      .subscribe(
        (data: any) => {
          const temp: any = [];
          if (data) {
            data.hygieneCompliances.map((value: any, i: any) => {
              temp.push({
                eventId: offset == 0 ? i + 1 : offset * limit + i + 1,
                spaceName: value.spaceName,
                timeStamp: value.timeStamp,
                complianceFlag: value.complianceFlag,
              });
            });
            //this.tabledata2 = temp;
            this.tabledata2 = this.tabledata2 ? this.tabledata2.concat(temp) : temp;
            this.dataSource2 = new MatTableDataSource(this.tabledata2);
            this.dataSource2.paginator = this.paginator2;
          }
        },
        (err) => {
          console.log(err);
        }
      );
  }
  getHandHygineData(event: any) {
    console.log(event)
    let currentPageIndex = event.pageIndex + 1;
    if (currentPageIndex * event.pageSize === event.length) {
      console.log('Page going to call api');
      this.dataOffset = Math.round(event.length / this.dataLimit);
      this.getHygieneComplianceHistory(this.employeeId, this.dataOffset, this.dataLimit);
    } else {
      console.log('next page is there');
    }
  }

}
