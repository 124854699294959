import { Component, OnInit, Inject } from '@angular/core';
import { AppService } from '../../../../app.service';
import { environment } from '../../../../../environments/environment';
import { FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-preview-form',
  templateUrl: './preview-form.component.html',
  styleUrls: ['./preview-form.component.scss']
})
export class PreviewFormComponent implements OnInit {
  isFeedback = false;
  isAccessProvided: boolean;
  healthSurveyData: any;
  isFromDescription = true;
  formDescription: string;
  employeeSurveyForm: FormGroup;
  questionSet: any[];
  formTitle: any;
  suerveyId: string;
  checkListQuestions: any[] = [];
  radioQuestions: any[] = [];
  isShowQrcode: boolean = false;
  healthQuestionnaire: any;
  expiryTime: string;
  isMainScreen: boolean = true;
  isLightTheme: boolean = environment.isLightTheme;
  constructor(public dialogRef: MatDialogRef<PreviewFormComponent>, @Inject(MAT_DIALOG_DATA) public data: any, private appservice: AppService) { }

  ngOnInit(): void {
    this.appservice.currentTheme.subscribe(theme => this.isLightTheme = (theme == 'theme-light' ? true : false));
    console.log('RAM Preview data');
    console.log(this.data);
    console.log('RAM HELLO');
    this.healthSurveyData = this.data.surveyData;
    this.formTitle = this.healthSurveyData.formTitle;
    this.formDescription = this.healthSurveyData.formDescription;
    this.suerveyId = this.healthSurveyData.surveyId;
    this.questionSet = this.healthSurveyData.MasterSurveyQuestionsPostReq;
    this.questionSet = this.healthSurveyData.MasterSurveyQuestionsPostReq
      ? this.healthSurveyData.MasterSurveyQuestionsPostReq
      : this.healthSurveyData.masterSurveyQuestions;
    //this.createForm();
  }
  surveyForm() {
    console.log('Hello RAM');
    console.log(this.data);
    this.isMainScreen = !this.isMainScreen;
  }
  submitDeclaration() {
    this.dialogRef.close();
  }
}
