import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-facility-wrapper',
  templateUrl: './facility-wrapper.component.html',
  styleUrls: ['./facility-wrapper.component.scss']
})
export class FacilityWrapperComponent implements OnInit {

  showList = true;

  editFaciltySpaceId: any;
  constructor() {}

  ngOnInit(): void {
    console.log("wrapper")
  }

  show(event: string) {
    console.log(event);
    if (event === 'faciityList') {
      this.showList = true;
      this.editFaciltySpaceId = null;
    } else {
      console.log(event,"in false");
      this.showList = false;
    }
  }

  getSpaceId(event: any) {
    this.editFaciltySpaceId = event;
    this.showList = false;
  }
}
